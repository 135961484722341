import React, { useState, useEffect } from 'react'

import { getAllLiveTests } from '../../actions/liveTestsAction'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/logo.svg';
import noImage from '../../assets/images/otp.png';
import 'react-pro-sidebar/dist/css/styles.css';
import "./AllLiveTests.scss"
import SearchItem from '../../Components/Search/SearchItem';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

function AllLiveTests() {

    const dispatch = useDispatch();

    const getLiveTests = useSelector(state => state.getAllLiveTests)
    const { loading, error, liveTests } = getLiveTests;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [searchResults, setSearchResults] = useState([])
    const [tests, setTests] = useState([])


    useEffect(() => {
        if (!error) {
            var tests = liveTests.map(item => ({
                ...item,
                name: item.testname
            }))

            setTests(tests)
            setSearchResults(tests)
        }

    }, [liveTests, error])


    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAllLiveTests())

    }, [dispatch, userInfo])


    return (
        <div className="live-tests">
            <div className="d-md-flex mx-5 justify-content-center">
                {
                    liveTests && liveTests.length !== 0 && <SearchItem
                        allItems={tests}
                        from={"live test"}
                        handleSetSearchResults={(results) => setSearchResults(results)}
                    />
                }
            </div>

            <div>
                {
                    loading ? <MyTestseriesLoading /> :

                        searchResults.length === 0 ? <div className="no-live-tests">
                            <img src={noImage} alt="" />
                            <h3>No Tests Found</h3>


                        </div> : <div className="d-flex m-2 flex-wrap justify-content-center">{
                            searchResults.map((prop) => <div key={prop.id} className="live-card">
                                <Link to={`/live-tests/${prop.id}`}>
                                    <img src={prop.imageURL ? prop.imageURL : Logo} alt="logo" />
                                    <div className="live-details">
                                        <div style={{height:'120px', width:'90%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                            <p title={prop.name} style={{margin:'0px'}}>{prop.name.substring(0,47)}{prop.name.length > 47 && '...'} </p>
                                        </div>
                                        <p className="price">Price: {(prop.Price === 0) ? "Free" : `₹ ${prop.Price}`}</p>

                                    </div>
                                </Link>
                            </div>)
                        }</div>
                }
            </div>
        </div>
    )
}

export default AllLiveTests
