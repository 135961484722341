export const GET_ALL_AUDIOBOOKS_REQUEST = 'GET_ALL_AUDIOBOOKS_REQUEST';
export const GET_ALL_AUDIOBOOKS_SUCCESS = 'GET_ALL_AUDIOBOOKS_SUCCESS';
export const GET_ALL_AUDIOBOOKS_FAIL = 'GET_ALL_AUDIOBOOKS_FAIL';

export const GET_USER_AUDIOBOOKS_REQUEST = 'GET_USER_AUDIOBOOKS_REQUEST';
export const GET_USER_AUDIOBOOKS_SUCCESS = 'GET_USER_AUDIOBOOKS_SUCCESS';
export const GET_USER_AUDIOBOOKS_FAIL = 'GET_USER_AUDIOBOOKS_FAIL';

export const PUT_LIKED_AUDIO_REQUEST = 'PUT_LIKED_AUDIO_REQUEST'
export const PUT_LIKED_AUDIO_SUCCESS = 'PUT_LIKED_AUDIO_SUCCESS'
export const PUT_LIKED_AUDIO_FAIL = 'PUT_LIKED_AUDIO_FAIL'

export const PUT_COMPLETED_AUDIO_REQUEST = 'PUT_COMPLETED_AUDIO_REQUEST'
export const PUT_COMPLETED_AUDIO_SUCCESS = 'PUT_COMPLETED_AUDIO_SUCCESS'
export const PUT_COMPLETED_AUDIO_FAIL = 'PUT_COMPLETED_AUDIO_FAIL'

export const PUT_FAVORITE_AUDIOBOOK_REQUEST = 'PUT_FAVORITE_AUDIOBOOK_REQUEST'
export const PUT_FAVORITE_AUDIOBOOK_SUCCESS = 'PUT_FAVORITE_AUDIOBOOK_SUCCESS'
export const PUT_FAVORITE_AUDIOBOOK_FAIL = 'PUT_FAVORITE_AUDIOBOOK_FAIL'

export const GET_FAVOURITE_AUDIOBOOKS_REQUEST = 'GET_FAVOURITE_AUDIOBOOKS_REQUEST'
export const GET_FAVOURITE_AUDIOBOOKS_SUCCESS = 'GET_FAVOURITE_AUDIOBOOKS_SUCCESS'
export const GET_FAVOURITE_AUDIOBOOKS_FAIL = 'GET_FAVOURITE_AUDIOBOOKS_FAIL'

export const GET_AUDIO_LIST_REQUEST = 'GET_AUDIO_LIST_REQUEST'
export const GET_AUDIO_LIST_SUCCESS = 'GET_AUDIO_LIST_SUCCESS'
export const GET_AUDIO_LIST_FAIL = 'GET_AUDIO_LIST_FAIL'

export const GET_AUDIOBOOK_BY_ID_REQUEST = 'GET_AUDIOBOOK_BY_ID_REQUEST'
export const GET_AUDIOBOOK_BY_ID_SUCCESS = 'GET_AUDIOBOOK_BY_ID_SUCCESS'
export const GET_AUDIOBOOK_BY_ID_FAIL = 'GET_AUDIOBOOK_BY_ID_FAIL'

export const POST_ENROLL_AUDIOBOOK_REQUEST = 'POST_ENROLL_AUDIOBOOK_REQUEST'
export const POST_ENROLL_AUDIOBOOK_SUCCESS = 'POST_ENROLL_AUDIOBOOK_SUCCESS'
export const POST_ENROLL_AUDIOBOOK_FAIL = 'POST_ENROLL_AUDIOBOOK_FAIL'

export const GET_AUDIOBOOK_PAYMENT_HISTORY_REQUEST = 'GET_AUDIOBOOK_PAYMENT_HISTORY_REQUEST'
export const GET_AUDIOBOOK_PAYMENT_HISTORY_SUCCESS = 'GET_AUDIOBOOK_PAYMENT_HISTORY_SUCCESS'
export const GET_AUDIOBOOK_PAYMENT_HISTORY_FAIL = 'GET_AUDIOBOOK_PAYMENT_HISTORY_FAIL'

export const POST_AUDIOBOOK_COUPON_VALIDATION_REQUEST = 'POST_AUDIOBOOK_COUPON_VALIDATION_REQUEST';
export const POST_AUDIOBOOK_COUPON_VALIDATION_SUCCESS = 'POST_AUDIOBOOK_COUPON_VALIDATION_SUCCESS';
export const POST_AUDIOBOOK_COUPON_VALIDATION_FAIL = 'POST_AUDIOBOOK_COUPON_VALIDATION_FAIL';