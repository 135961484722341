import React,{useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import AlertDismissibleExample from '../../Components/Error/error'
import { getITWDetails } from '../../actions/IssuesThisWeekAction';
import { getUserDetails } from '../../actions/userActions';
import Clock from '../../assets/icons/clock.svg';
import Cross from '../../assets/icons/cross.svg';
import Right from '../../assets/icons/RightAnswer.svg';
import DOMPurify from 'dompurify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { URL, BASEURL } from '../../config/config';


import Instructions from '../../Components/ShimmerEffect/IssuesThisWeek/Instructions';
import axios from 'axios';

// import { logout } from '../../actions/userActions';



const ItwInstructions = ({match}) => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false)
    const [disableTime, setDisableTime] = useState(false)
    const [errMsg, setErrorMsg] = useState('')

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    const getITWById = useSelector(state => state.getITWById)
    const { loading, error, itw } = getITWById;

    const getUserDetail = useSelector(state => state.getUserDetail)
    const {loading:loadingUser , error:errorUser, user} = getUserDetail;

    useEffect(()=>{
        if(!userInfo){
            document.location.href = '/login';
        }

        dispatch(getITWDetails(match.params.id))
        dispatch(getUserDetails())
    },[userInfo,dispatch,match])

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(
    //         error === message ||
    //         errorUser === message
    //     ){
    //         dispatch(logout());
    //     }
    // },[dispatch, error, errorUser ])


    const minToHours =(min)=>{
        var hr = min/60;
        if(hr<1){
          hr=0;
        }
        var m = min%60;
        return `${(hr===0)?"":(hr<10)?`0${hr.toString().split(".")[0]} hr`:`${hr.toString().split(".")[0]} hr`} ${(m<10)?`0${m}min`:`${m} min`}`
    }

    const onChange = (e) => {
        e.preventDefault();
        if(check){
            setCheck(false)
        }else{
            setCheck(true)
        }
    }

    const onChangeTD = (e) => {
        e.preventDefault();
        if(disableTime){
            setDisableTime(false);
        }else{
            setDisableTime(true);
        }
    }

    const EnrollInITW = async(test) =>{
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        try{
            await axios.post(`${BASEURL}/user/itw/${test.id}`,{} ,config)
        }catch(err){
            setErrorMsg("Issue in Enrollment, Try again later...")
            setTimeout(()=>{
                document.location.href = '/';
            },1350)
        }
    }

    const startTest = async(test) => {
        setErrorMsg('');
        if(!test.isEnrolled){
            await EnrollInITW(test);
        }
        
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        try{ 
            const {data} = await axios.get(`${BASEURL}/user/itw/analytics/${test.id}`, config)
            if(data){
                setErrorMsg("User attempted this test already!!")
                setTimeout(()=>{
                    document.location.href = `/myITW/analytics/${test.id}`;
                },1350)
            }
        }catch(err){
            if(err){
                if(!localStorage.getItem(`${test.id}_answerSheet`)){
                    localStorage.setItem(`${test.id}_answerSheet`,JSON.stringify([]));
                    localStorage.setItem(`${test.id}_attempt`, JSON.stringify([]));
                    localStorage.setItem(`${test.id}_markForReview`, JSON.stringify([]));
                }
                if(!localStorage.getItem(`${test.id}_chance`)){
                    localStorage.setItem(`${test.id}_chance`,JSON.stringify(3));
                }
                if(!disableTime){
                    let time = Date.now()
                    const testings = {
                        endingTime:time + (test.duration * 60000),
                        duration:test.duration
                    };
                    localStorage.setItem(`${test.id}_time`,JSON.stringify(testings));
                }
                window.open(
                    `${URL}/itw-mcq/${test.id}`,
                    "Issues This Week MCQ",
                    "noopoener,fullscreen"
                )
                document.location.href = '/';
            }
        }   
    }

    return (
        <div>
            <main>
                {error && <AlertDismissibleExample data={error}/>}
                {errorUser && <AlertDismissibleExample data={errorUser}/>}
                {(loading || loadingUser || error || errorUser || !user.firstname) ? <Instructions/> : (
                    <div>
                        <section className="px-5 ">
                            <div className="instruction-header">
                                <div>
                                    <h1 className="mt-3" style={{fontSize:30,fontWeight:'600', color:'rgb(1, 42, 93)'}}>
                                        Instructions
                                    </h1>
                                </div>
                                <div>
                                    <img src={Clock} alt="clock"/>
                                    <p>{minToHours(itw.duration)}</p>
                                </div>
                            </div>
                        </section>
                        <section className="px-5">
                            <div className="test-profile">
                                <h4>Profile</h4>
                                <div className="img-container">
                                    {user.imageURL ? <img src={user.imageURL} alt="avtar" /> : <div className='img-letter'>
                                        <h1>{user.firstname.slice(0,1).toUpperCase()}</h1>
                                    </div>}
                                </div>
                                <div className="test-profile-info">
                                    <p>Name: <span style={{color:'black'}}>{user.firstname} {user.lastname}</span></p>
                                    <p>DOB: <span style={{color:'black'}}>{user.dob ? user.dob : "N/A"}</span></p>
                                    <p>ID: <span style={{color:'black'}}>{user.yesmentorid}</span></p>
                                </div>
                            </div>
                        </section>
                        <section>
                            {itw.sections && itw.sections.length!==0 &&  itw.sections.map((element,i)=>{
                                return(
                                    <div className="py-3 instruction-sections" key={i}>
                                        <div className="section">
                                            <p>Section {element.section}</p>
                                        </div>
                                        <div className="test-marks">
                                            <img src={Right} alt="Right"/>
                                            <p>For Correct Answers</p>
                                            <p style={{color:"green"}}>{element.marksPerQuestion}</p>
                                        </div>
                                        <div className="test-marks">
                                            <img src={Cross} alt="cross"/>
                                            <p>For Wrong Answers</p>
                                            <p style={{color:"red"}}>{element.negativeQuestion}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </section>
                        <section className="px-5  py-2">
                            <div className="instructions">
                                <h1>INSTRUCTIONS</h1>
                                <div className="instruction-body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(itw.instruction)}} >
                                </div>
                            </div>
                        </section> 
                        <section className="px-5  py-5">
                            <div className="checkBtn" style={{marginBottom:"0px"}}>
                                <label>
                                    <Checkbox onClick={onChange} checked = {check ? true : false} className="checkBox"/>
                                    <p>I have read all the instructions carefully and checked my profile details.</p>
                                </label>
                            </div>
                            <div className="checkBtn" style={{marginTop:"0px"}}>
                                <label>
                                    <Checkbox onClick={onChangeTD} checked = {disableTime ? true : false} className="checkBox"/>
                                    <p>Disable Time Limit.</p>
                                </label>
                            </div>
                            <div className="attempt">
                                {   check ? 
                                    <button onClick={(e)=> {e.preventDefault(); startTest(itw)}}>Attempt Test</button>: 
                                    <button className="disable">Attempt Test</button> 
                                }
                            </div>
                            {(errMsg && check) !== '' && <AlertDismissibleExample data={errMsg}/>}
                        </section>
                    </div>
                )}
            </main>
        </div>
    )
}

export default ItwInstructions
