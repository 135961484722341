import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import SearchItem from '../../Components/Search/SearchItem';
import AlertDismissibleExample from '../../Components/Error/error';
import noImage from '../../assets/images/otp.png';
import { useHistory } from "react-router";

import InsightsIcon from '@mui/icons-material/Insights';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import assignment1 from '../../assets/icons/assignment1.svg';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

import './MyAskToEvaluate.scss'

import {
  getEnrollAskToEvaluate
} from '../../actions/askToEvaluateActions';

const MyAskToEvaluate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchResults, setSearchResults] = useState([]);
  const [tests, setTests] = useState([])

  const getEnrollATE = useSelector(state => state.getEnrollATE)
  const { loading, error, enrolledAskToEvaluate } = getEnrollATE;

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      document.location.href = '/login';
    }else{
      dispatch(getEnrollAskToEvaluate());
    }
  }, [dispatch, userInfo])

  useEffect(() => {
    if (enrolledAskToEvaluate.length > 0) {
      var tests = enrolledAskToEvaluate.map(item => ({
        ...item,
        name: item.Test.TestSeriesName
      }))

      setTests(tests)
      setSearchResults(tests)
    }
  }, [enrolledAskToEvaluate, error])

  const minToHours = (min) => {
    var hr = min / 60;
    if (hr < 1) {
        hr = 0;
    }
    var m = min % 60;
    return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m.toFixed(0)}min` : `${m.toFixed(0)} min`}`
  }

  const stringDate = (examDate) => {
    const event = new Date(examDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone:'UTC' };
    return event.toLocaleString('as-IN', options)
  }

  const stringTime = (examDate) => {
    const event = new Date(examDate);
    const options = { hour: '2-digit', minute: '2-digit' , timeZone:'UTC' };
    return event.toLocaleString('as-IN', options)
  }

  return (
    <div>
      {error && <AlertDismissibleExample data={error} />}
      {
        loading ? <MyTestseriesLoading/> :
        <div className="my-4 Live-test-series">
          <section className="d-md-flex mx-5 justify-content-center">
            {enrolledAskToEvaluate.length !== 0 &&
              <SearchItem
                allItems={tests}
                from={"Ask To Evaluate"}
                handleSetSearchResults={(results) => setSearchResults(results)}
              />
            }
          </section>
          <section>
            {
              searchResults.length === 0 ?
              <div className="no-live-tests">
                <img src={noImage} alt="" />
                <h3>No TestSeries Found</h3>
              </div> :
              <div className='assignments-container'>
                {
                  searchResults.map((assignment)=>(
                    <div className='assignment-card' key={assignment.Test.id}>
                      <img src={assignment1} alt="assignment" />
                      <div className='assignment-btns'>
                        {
                          (assignment.isExpired && !assignment.isCompleted) ? 
                            <>
                              <button className='assignment-expired' disabled>
                                <EventBusyIcon /> Expired
                              </button>
                              <button className='assignment-reset' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/details/${assignment.Test.id}`)}}>
                                <ChevronRightIcon /> Details
                              </button>
                            </>
                          :
                          assignment.isCompleted ? 
                            assignment.isResult ?
                              <>
                                <button className='assignment-analytics' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/analytics/${assignment.Test.id}`)}}>
                                  <InsightsIcon /> Analytics
                                </button>
                                <button className='assignment-analytics' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/details/${assignment.Test.id}`)}}>
                                  <ChevronRightIcon /> Details
                                </button>
                              </>
                            :
                              <>
                                <button className='assignment-analytics' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/details/${assignment.Test.id}`)}}>
                                  <ChevronRightIcon /> Details
                                </button>
                              </>
                          :
                        assignment.isTestTime ?
                          <>
                            <button className='assignment-reset' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/instructions/${assignment.Test.id}`)}}>
                              <ChevronRightIcon /> Attempt
                            </button>
                            <button className='assignment-analytics' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/details/${assignment.Test.id}`)}}>
                              <ChevronRightIcon /> Details
                            </button>
                          </>
                        : 
                        <button className='assignment-reset' onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/details/${assignment.Test.id}`)}}>
                          <ChevronRightIcon /> Details
                        </button>
                        }
                      </div>
                      <div className='assignment-heading'>
                        <h1>{assignment.name}</h1>
                      </div>
                      <div className='assignment-sub-heading'>
                        <h2>{assignment.Test.subHeading}</h2>
                      </div>
                      <div className='assignment-info'>
                        <p>Test Type : Descriptive</p>
                        <p>Duration : {minToHours(assignment.Test.duration)}</p>
                        <p>Exam On : {stringDate(assignment.Test.ExamOn)} {stringTime(assignment.Test.ExamOn)}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </section>
        </div>
      }
    </div>
  )
}

export default MyAskToEvaluate