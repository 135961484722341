import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../Components/main/sidebar/sidebar';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import { Link } from 'react-router-dom';

import { getPracticeTestById } from '../../actions/practiceTestsAction';

import Logo from '../../assets/icons/logo.svg';
import { Event, Schedule, DonutLargeRounded, HourglassFullTwoTone } from '@material-ui/icons';

import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import { Col, Row } from 'react-bootstrap';
import { getAllPracticeTestSeries } from '../../actions/EnrolledTestSeries';

import DOMPurify from 'dompurify';

const PracticeTestSeries = ({ match }) => {
    const dispatch = useDispatch()
    const [testSeries, setTestSeries] = useState({})

    const getPracticeTestDetails = useSelector(state => state.getPracticeTestDetails)
    const { loading, error, practiceTest } = getPracticeTestDetails;

    const getAllPracticeTest = useSelector(state => state.getAllPracticeTest)
    const { loading:loadingEnrollTs, error:errorEnrollTs, PracticeTest } = getAllPracticeTest;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(()=>{
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getPracticeTestById(match.params.id))
        dispatch(getAllPracticeTestSeries());
    },[dispatch,userInfo ,match])


    useEffect(()=>{
        if(PracticeTest && PracticeTest.length !== 0){
            const test = PracticeTest.find((element)=> element.paidTest.id === match.params.id)
            setTestSeries(test)
        }
    },[PracticeTest,match.params.id])

    const minToHours =(min)=>{
        var hr = min/60;
        if(hr<1){
          hr=0;
        }
        var m = min%60;
        return `${(hr===0)?"":(hr<10)?`0${hr.toString().split(".")[0]} hr`:`${hr.toString().split(".")[0]} hr`} ${(m<10)?`0${m}min`:`${m} min`}`
    }

    const attemptTest = (e) => {
        e.preventDefault();
        document.location.href = `/testseries/practice/instructions/${match.params.id}`;
    }

    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const stringTime = (examDate) => {
        const event = new Date(examDate);
        const options = { hour: '2-digit', minute: '2-digit' ,timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    return (
        <div>
            <Sidebar activeLink={'mytestseries'}/>
            <main className="main">
                {loadingEnrollTs && <Loader/>}
                {errorEnrollTs && <AlertDismissibleExample data={errorEnrollTs} />}
                {
                    loading ? <Loader/> : error ? <AlertDismissibleExample data={error} /> :
                    <div className="practice-test-overview">
                        <div className="test-banner" >
                            <Link to='/mytestseries'>
                                <img src={leftArrow} alt="<" style={{ width: 17, margin: 20, cursor: 'pointer' }} />
                            </Link>
                            <div>
                                <img style={{ background: "#dfdfdfe7", padding: 10,width:'300px' , borderRadius: "15px" }} src={Logo} alt="" />
                            </div>
                        </div>
                        <div className="test-content p-5">
                            <div className="d-flex test-info my-0 justify-content-between align-items-center">
                                <h2 className="m-0">{practiceTest.testName}</h2>
                            </div>
                            <div className="d-flex  test-info my-0 justify-content-between align-items-center">
                                <p>Type: {practiceTest.testType}</p>
                            </div>
                            <div className="test-info">
                                <h3>About the test</h3>
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(practiceTest.description)}}></p>
                            </div>
                            <div className="test-info">
                                <Row>
                                    <Col className="md-6">
                                        <div className="test-details">
                                            <div>
                                                <span style={{fontWeight:600}}><HourglassFullTwoTone /> Duration:</span> {minToHours(practiceTest.duration)}
                                            </div>
                                            <div>
                                                <span style={{fontWeight:600}}><DonutLargeRounded/> Papers Left:</span> {practiceTest.totalAttempts-testSeries.attempts} out of {practiceTest.totalAttempts}
                                            </div>
                                            {
                                                testSeries.paidTest && <div>
                                                    <div>
                                                        <span style={{fontWeight:600}}><Event/> Date:</span> {stringDate(testSeries.paidTest.paperTime)}
                                                    </div>
                                                    <div>
                                                        <span style={{fontWeight:600}}><Schedule/> Time:</span> {stringTime(testSeries.paidTest.paperTime)}
                                                    </div>
                                                </div>
                                            }
                                            
                                        </div>
                                    </Col>
                                    <Col>
                                        {
                                            testSeries.paidTest && (
                                                <div>
                                                    <Row>
                                                        {
                                                            testSeries.attempts > 0 ? 
                                                            <Link className="practice-test-btn" style={{textDecoration:'none'}} to={`/testseries/practice/analytics/${match.params.id}`}>
                                                                View Analytics
                                                            </Link> :
                                                            <button disabled className="practice-test-btn">
                                                                View Analytics
                                                            </button>
                                                        }
                                                    </Row>  
                                                    <Row>
                                                        {
                                                            testSeries.attempts === practiceTest.totalAttempts || 
                                                            !testSeries.paidTest.isQuestionAvailable || 
                                                            !testSeries.paidTest.isTestTime ?
                                                            <button className="practice-test-btn" disabled>
                                                                Attempt Test
                                                            </button> : 
                                                            <button className="practice-test-btn" onClick={attemptTest}>
                                                                Attempt Test
                                                            </button>
                                                        }
                                                    </Row>
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                }
            </main>
        </div>
    )
}

export default PracticeTestSeries
