import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../../Components/main/sidebar/sidebar';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import Logo from '../../assets/images/preb4pre.png'
import { Col, Row } from 'react-bootstrap';
import { BiPieChart } from 'react-icons/bi';
import { BsHourglassSplit } from 'react-icons/bs';
import { MdOutlineTimelapse } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import './MyTestSeries.scss'
import { getPreb4preTestSeries } from '../../actions/preb4preTestAction';
import DOMPurify from 'dompurify';

function Preb4preTestSeries({ match, history }) {

    const dispatch = useDispatch()

    const getPreb4preTest = useSelector(state => state.getPreb4preTestSeries)
    const { loading, error, preb4preTest } = getPreb4preTest

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getPreb4preTestSeries(match.params.id))
    }, [dispatch,userInfo ,match])

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    // const isActive = (startingTime, endingTime) => {
    //     let today = new Date()

    //     let st = new Date(startingTime)
    //     let StartingTime = st.getTime() + (st.getTimezoneOffset() * 60000);

    //     let et = new Date(endingTime)
    //     let EndingTime = et.getTime() + (et.getTimezoneOffset() * 60000);

    //     if (StartingTime > today.getTime() || today.getTime() > EndingTime) {
    //         return false
    //     } else {
    //         return true;
    //     }
    // }

    const attemptTest = (e) => {
        e.preventDefault();
        document.location.href = `/testseries/preb4pre/instructions/${match.params.id}`;
    }

    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const stringTime = (examDate) => {
        const event = new Date(examDate);
        const options = { hour: '2-digit', minute: '2-digit' ,timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    return (
        <div>
            <Sidebar activeLink={'preB4Pre'} />
            <main className='main'>
                {loading && <Loader />}
                {error && <AlertDismissibleExample data={error} />}
                {
                    (!loading && !error && preb4preTest) && (
                        <div className='practice-test-overview'>
                            <div className='test-banner'>
                                <div onClick={()=> history.push('/myPreB4Pre')}>
                                    <img src={leftArrow} alt="<" style={{ width: 17, margin: 20, cursor: 'pointer' }} />
                                </div>
                                <div>
                                    <img style={{ background: "#EEF1F6", padding: 20, width: '300px', borderRadius: "15px" }} src={Logo} alt="" />
                                </div>

                            </div>
                            <div className="test-content py-5">
                                <div className="d-flex test-info my-0 justify-content-between align-items-center">
                                    <h2 className="m-0">{preb4preTest.name}</h2>
                                </div>
                                <div className="d-flex test-info my-0 justify-content-between align-items-center">
                                    <p className="m-0">{preb4preTest.subHeading}</p>
                                </div>
                                <div className="d-flex  test-info my-3 justify-content-between align-items-center">
                                    <p>Type: {preb4preTest.testType}</p>
                                </div>
                                <div className="test-info">
                                    <h3>About</h3>
                                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(preb4preTest.description)}}></p>
                                </div>
                                <div className="test-info">
                                    <Row>
                                        <Col md={12} >
                                            <h3 className='mb-4'>Paper Timing</h3>
                                            <Row className='justify-content-around'>
                                                {preb4preTest.slot.map((slots, i)=>(
                                                    <Col md={5} className="test-details" key={i}>
                                                        <div>
                                                            <p>
                                                                <span style={{ fontWeight: 600 }}> 
                                                                    <BsHourglassSplit />Duration:{` `}
                                                                </span>
                                                                {minToHours(slots.duration)}
                                                            </p>
                                                        </div>
                                                
                                                        <div>
                                                            <p>
                                                                <span style={{ fontWeight: 600 }}> 
                                                                    <FaRegCalendarAlt /> Exam On: 
                                                                </span> {stringDate(slots.startingTime)} 
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                <span style={{ fontWeight: 600 }}> 
                                                                    <BiPieChart /> Starting Time: 
                                                                </span> {stringTime(slots.startingTime)} 
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                <span style={{ fontWeight: 600 }}> 
                                                                    <MdOutlineTimelapse /> Ending Time:
                                                                </span> {stringTime(slots.endingTime)}
                                                            </p>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                {
                                                    (preb4preTest.isResultTime && preb4preTest.isAttempted) ?
                                                    <button className="practice-test-btn pre" onClick={()=> history.push(`/testseries/preb4pre/analytics/${match.params.id}`)} style={{ textDecoration: 'none' }} >
                                                        View Analytics
                                                    </button> :
                                                    <button className="practice-test-btn pre" disabled>
                                                        View Analytics
                                                    </button>
                                                }
                                                {
                                                    (preb4preTest.isTestTime && !preb4preTest.isCompleted) ?
                                                        <button className="practice-test-btn pre" onClick={attemptTest}>
                                                            Attempt
                                                        </button> :
                                                        <button className="practice-test-btn pre" disabled>
                                                            Attempt
                                                        </button>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </div>
                    )

                }

            </main>


        </div>
    )
}

export default Preb4preTestSeries
