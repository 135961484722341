import React, { useState, useEffect } from 'react'
import Close from '@material-ui/icons/Close';
import { Search } from '@material-ui/icons';
import './SearchItem.scss'


function SearchItem({ allItems, from, handleSetSearchResults }) {

    const [search, setSearch] = useState("")

    useEffect(() => {
        if (search === "") {
            handleSetSearchResults(allItems);
        }
        else {
            const results = allItems.filter(prop =>
                prop.name.toLowerCase().includes(search.toLowerCase())
            );
            handleSetSearchResults(results);
        }
    // eslint-disable-next-line
    }, [search])

    return (
        <div className="search-box">
            <input
                style={{ border: "none", background: "none", width: "100%" }}
                id="search"
                name="search"
                autoComplete="off"
                type="text"
                placeholder={`Search ${from}`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            {
                search.length === 0 ? <Search /> : <span onClick={() => setSearch("")} ><Close /></span>
            }

        </div>

    )
}

export default SearchItem
