export const GET_ALL_LIVETESTS_REQUEST = 'GET_ALL_LIVETESTS_REQUEST';
export const GET_ALL_LIVETESTS_SUCCESS = 'GET_ALL_LIVETESTS_SUCCESS';
export const GET_ALL_LIVETESTS_FAIL = 'GET_ALL_LIVETESTS_FAIL';

export const GET_LIVETEST_BY_ID_REQUEST = 'GET_LIVETEST_BY_ID_REQUEST';
export const GET_LIVETEST_BY_ID_SUCCESS = 'GET_LIVETEST_BY_ID_SUCCESS';
export const GET_LIVETEST_BY_ID_FAIL = 'GET_LIVETEST_BY_ID_FAIL';

export const POST_ENROLL_LIVETEST_REQUEST = 'POST_ENROLL_LIVETEST_REQUEST';
export const POST_ENROLL_LIVETEST_SUCCESS = 'POST_ENROLL_LIVETEST_SUCCESS';
export const POST_ENROLL_LIVETEST_FAIL = 'POST_ENROLL_LIVETEST_FAIL'

export const GET_LIVETEST_PAYMENT_HISTORY_REQUEST = 'GET_LIVETEST_PAYMENT_HISTORY_REQUEST'
export const GET_LIVETEST_PAYMENT_HISTORY_SUCCESS = 'GET_LIVETEST_PAYMENT_HISTORY_SUCCESS'
export const GET_LIVETEST_PAYMENT_HISTORY_FAIL = 'GET_LIVETEST_PAYMENT_HISTORY_FAIL'

export const POST_LIVETEST_COUPON_VALIDATION_REQUEST = 'POST_LIVETEST_COUPON_VALIDATION_REQUEST';
export const POST_LIVETEST_COUPON_VALIDATION_SUCCESS = 'POST_LIVETEST_COUPON_VALIDATION_SUCCESS';
export const POST_LIVETEST_COUPON_VALIDATION_FAIL = 'POST_LIVETEST_COUPON_VALIDATION_FAIL';