import React from 'react';
import './Graphs.scss';
import { Pie   } from 'react-chartjs-2';


const BarChart = ({data}) => {
    return (
        <div className="graph-section">
            <Pie   
                data={data}
                width={200}
                height={200}
                options={
                    {
                        maintainAspectRatio:false
                    }
                } 
            />
        </div>
    )
}

export default BarChart
