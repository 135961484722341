import axios from "axios";

import {
    GET_ALL_LIVETESTS_REQUEST,
    GET_ALL_LIVETESTS_SUCCESS,
    GET_ALL_LIVETESTS_FAIL,
    GET_LIVETEST_BY_ID_REQUEST,
    GET_LIVETEST_BY_ID_SUCCESS,
    GET_LIVETEST_BY_ID_FAIL,
    POST_ENROLL_LIVETEST_REQUEST,
    POST_ENROLL_LIVETEST_SUCCESS,
    POST_ENROLL_LIVETEST_FAIL,
    GET_LIVETEST_PAYMENT_HISTORY_FAIL,
    GET_LIVETEST_PAYMENT_HISTORY_REQUEST,
    GET_LIVETEST_PAYMENT_HISTORY_SUCCESS,
    POST_LIVETEST_COUPON_VALIDATION_REQUEST,
    POST_LIVETEST_COUPON_VALIDATION_SUCCESS,
    POST_LIVETEST_COUPON_VALIDATION_FAIL
} from '../constants/liveTestConstants';

import { BASEURL } from '../config/config';

export const getAllLiveTests = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ALL_LIVETESTS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }
        const { data } = await axios.get(`${BASEURL}/admin/live/testseries`, config)
        dispatch({
            type: GET_ALL_LIVETESTS_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_LIVETESTS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getLiveTestById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_LIVETEST_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }

        const { data } = await axios.get(`${BASEURL}/admin/live/testseries/${id}`, config)
        dispatch({
            type: GET_LIVETEST_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_LIVETEST_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const postEnrollLiveTest = (id, pid, sig, oid, coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_LIVETEST_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/live/testseries/${id}`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            coupon: coupon
        }, config)
        dispatch({
            type: POST_ENROLL_LIVETEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_ENROLL_LIVETEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getLiveTestPaymentHistory = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_LIVETEST_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/live/testseries/history`, config)

        dispatch({
            type: GET_LIVETEST_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_LIVETEST_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postCheckValidationCoupon = (code) => async (dispatch) => {
    try {

        dispatch({
            type: POST_LIVETEST_COUPON_VALIDATION_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.post(`${BASEURL}/user/livetestseries/val/coupon`,{code}, config)
        dispatch({
            type: POST_LIVETEST_COUPON_VALIDATION_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: POST_LIVETEST_COUPON_VALIDATION_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}