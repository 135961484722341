import React,{useState, useEffect} from 'react';
import Sidebar from '../../Components/main/sidebar/sidebar';
import PracticeTestSeries from '../../views/MyPracticeTestSeries/PracticeTestSeries';
import LiveTestSeries from '../../views/MyLiveTestSeries/LiveTestSeries';
import MyAskToEvaluate from '../../views/MyAskToEvaluate/MyAskToEvaluate';

import './MyTestSeries.scss';

const MyTestSeries = () => {
    const [selectPTS, setSelectPTS] = useState(false);
    const [selectLTS, setSelectLTS] = useState(false);
    const [selectATE, setSelectATE] = useState(false);

    useEffect(()=>{
        setSelectPTS(true);
        setSelectLTS(false);
        setSelectATE(false);
    },[])

    const PTSHandler = (e) => {
        e.preventDefault()
        setSelectPTS(true);
        setSelectLTS(false);
        setSelectATE(false);
    }

    const LTSHandler = (e) => {
        e.preventDefault()
        setSelectPTS(false);
        setSelectLTS(true);
        setSelectATE(false)
    }

    const ATEHandler = (e) => {
        e.preventDefault()
        setSelectPTS(false);
        setSelectLTS(false);
        setSelectATE(true)
    }
    return (
        <div>
            <Sidebar activeLink={'mytestseries'}/>
            <main className="main">
                <section className="select-item">
                    <button className={ selectPTS ? "selected-item" : ""} onClick={PTSHandler}>
                        Test Series
                    </button>
                    <button className={ selectLTS ? "selected-item" : ""} onClick={LTSHandler}>
                        Live Test
                    </button>
                    <button className={ selectATE ? "selected-item" : ""} onClick={ATEHandler}>
                        Ask To Evaluate
                    </button>
                </section>
                {selectPTS && <PracticeTestSeries/>}
                {selectLTS && <LiveTestSeries/>}
                {selectATE && <MyAskToEvaluate/>}
            </main>
        </div>
    )
}

export default MyTestSeries
