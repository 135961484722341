import React, { useState, useEffect } from 'react'

import { getAllPracticeTests } from '../../actions/practiceTestsAction'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/logo.svg';
import noImage from '../../assets/images/otp.png';
import 'react-pro-sidebar/dist/css/styles.css';
import "./AllPracticeTests.scss"
import SearchItem from '../../Components/Search/SearchItem';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

function AllPracticeTests() {

    const dispatch = useDispatch();

    const getPracticeTests = useSelector(state => state.getAllPracticeTests)
    const { loading, error, practiceTests } = getPracticeTests;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [searchResults, setSearchResults] = useState([])
    const [tests, setTests] = useState([])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAllPracticeTests())

    }, [dispatch, userInfo])

    useEffect(() => {
        if (!error) {
            var tests = practiceTests.map(item => ({
                ...item,
                name: item.testName
            }))

            setTests(tests)
            setSearchResults(tests)
        }

    }, [practiceTests, error])




    return (
        <div className="practice-test">
            <div className="d-md-flex mx-5 justify-content-center">
                {
                    practiceTests && practiceTests.length !== 0 && <SearchItem
                        allItems={tests}
                        from={"Test series"}
                        handleSetSearchResults={(results) => setSearchResults(results)}
                    />
                }
            </div>
            <div>
                {
                    loading ? <MyTestseriesLoading /> :

                        searchResults && searchResults.length === 0 ? <div className="no-live-tests">
                            <img src={noImage} alt="" />
                            <h3>No Test Found</h3>


                        </div> : <div className="d-flex m-2 flex-wrap justify-content-center">{
                            searchResults && searchResults.map((prop) => <div key={prop.id} className="practice-card">
                                <Link to={`/practice-tests/${prop.id}`}>
                                    <img src={Logo} alt="logo" />
                                    <div className="practice-details">
                                        <div style={{height:'120px', width:'90%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                            <p title={prop.testName} style={{margin:'0px'}}>{prop.testName.substring(0,47)}{prop.testName.length > 47 && '...'} </p>
                                        </div>
                                        <p className="price">Price: {(prop.price === 0) ? "Free" : `₹ ${prop.price}`}</p>

                                    </div>
                                </Link>
                            </div>)
                        }</div>
                }
            </div>
        </div>
    )
}

export default AllPracticeTests
