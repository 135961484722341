import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import './Assignment.scss';

import { getTestInfoById, getQuestionPaper, postSubmitTest } from '../../actions/courseActions';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import Timer from '../../Components/Timer/Timer';

import Question from '../../Components/question/question';
import { CgDanger } from 'react-icons/cg';
import Donut from '../../Components/Graphs/Donut';
import { Row, Col } from 'react-bootstrap';
import noImage from '../../assets/images/otp.png';
import FullScreenTimer from '../../Components/Timer/FullScreenTimer';

const TestScreen = ({ match }) => {
    const dispatch = useDispatch();
    const [question,setQuestions] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [attempt, setAttempt] = useState([]);
    const [markReview, setMarkReview] = useState([]);
    const [sections, setSections] = useState([]);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getAssignmentPaper = useSelector(state => state.getAssignmentPaper)
    const { loading: loadingPaper, error: errorPaper, paper } = getAssignmentPaper;

    const PostAssignment = useSelector(state => state.PostAssignment)
    const { loading: loadingSubmit, error: errorSubmit, message } = PostAssignment;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getTestInfoById(match.params.id))
        dispatch(getQuestionPaper(match.params.id))
        setAttempt(JSON.parse(localStorage.getItem(`${match.params.id}_attempt`)) || [])
        setMarkReview(JSON.parse(localStorage.getItem(`${match.params.id}_markForReview`)) || [])
    }, [userInfo, dispatch, match])


    useEffect(()=>{
        if(paper.length !== 0){
            for(let i=0; i<paper.length; i++){
                const idx = sections.findIndex((ele)=>(ele === paper[i].sectionName))
                if(idx < 0){
                    let arr = sections;
                    arr.push(paper[i].sectionName);
                    setSections(arr);
                }
            }
            if(sections.length !==0){
                const test = paper.filter((element)=> (element.sectionName === sections[0]))
                setQuestions(test)
                if(document.getElementsByClassName('selected-section').length > 0)
                    document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
                
                if(document.getElementById(`assignment${sections[0]}`))
                document.getElementById(`assignment${sections[0]}`).classList.add('selected-section')
            }
        }
    },[paper,sections])


    const submitHandler = () => {
        const answer = JSON.parse(localStorage.getItem(`${match.params.id}_answerSheet`));
        dispatch(postSubmitTest(match.params.id,answer));
        localStorage.removeItem(`${match.params.id}_answerSheet`);
        localStorage.removeItem(`${match.params.id}_chance`);
        localStorage.removeItem(`${match.params.id}_markForReview`);
        localStorage.removeItem(`${match.params.id}_attempt`);
        localStorage.removeItem(`${match.params.id}_fullScreen`);
        setTimeout(()=>{
            localStorage.removeItem(`${match.params.id}_time`);
            window.close()
        }, 1350);
    }

    const selectSection = (section) => {
        if(document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')

        if(document.getElementById(`assignment${section}`))
        document.getElementById(`assignment${section}`).classList.add('selected-section')

        if(sections.length !==0 && paper && paper.length !== 0){
            const test = paper.filter((element)=> (element.sectionName === section))
            setQuestions(test)
        }else{
            setQuestions([])
        }
    }


    document.addEventListener('fullscreenchange', event =>{
        if(!document.fullscreenElement){
            if(!localStorage.getItem(`${match.params.id}_fullScreen`))
                localStorage.setItem(`${match.params.id}_fullScreen`,JSON.stringify(20));
            setIsFullScreen(false);
        }else{
            localStorage.removeItem(`${match.params.id}_fullScreen`);
            setIsFullScreen(true);
        }
    })

    document.addEventListener('visibilitychange', event=>{
        if(document.visibilityState !== "visible"){
            setIsFullScreen(true);
        }
    })

    const disableFunction = (e) => {
        e.preventDefault()
        return false;
    }

    const fullScreen = () => {
        if (document) {
            document.documentElement.requestFullscreen();
        }
        localStorage.setItem(`${match.params.id}_chance`,JSON.stringify(localStorage.getItem(`${match.params.id}_chance`) - 1));
        let chance = localStorage.getItem(`${match.params.id}_chance`)
        if(chance === '-1') {
            submitHandler();
        }
    }

    return (
        <div 
            className="test-Container"
            onCopy = {disableFunction}
            onContextMenu = {disableFunction}
            onPaste = {disableFunction}
            onCut = {disableFunction}
        >
            {(loadingPaper || loadingSubmit) && <Loader />}
            {errorPaper && <AlertDismissibleExample data={errorPaper} />}
            {errorSubmit && <AlertDismissibleExample data={errorSubmit} />}
            {message && <AlertSuccessExample data={message} />}

            {!isFullScreen &&
            <div className="blur">
                <div className="content px-5 py-5">
                    <CgDanger style={{width:'120px', height:'120px', color:'rgb(163, 5, 5)'}}/>
                    <p>The Window Should Be in Full Screen Mode during the Exam.</p>
                    {localStorage.getItem(`${match.params.id}_chance`) > 0 ? (
                        <p>If This warning occurs more then {localStorage.getItem(`${match.params.id}_chance`) > 2 ? '2' : localStorage.getItem(`${match.params.id}_chance`) } times, then Exam will be automatically submitted.</p>):(
                        <p>This Was Your Last Warning!!</p>
                    )}
                    {(localStorage.getItem(`${match.params.id}_chance`) <= 2) && ( <p>Exam will automatically submitted in <FullScreenTimer id={match.params.id} type={'assignment'} />, Click on Full Screen Button. </p>) }
                    
                    <button onClick={(e)=>{e.preventDefault();fullScreen()}}>Full Screen</button>
                </div>
            </div>}

            {(!loadingPaper && !errorPaper) && (
                <>
                    <main className='px-5 py-5'>
                        <Timer id={match.params.id} type={'assignment'}/>
                        <div>
                            <Donut
                                attempted={attempt.length}
                                notAttempted={paper.length - attempt.length}
                                MarkForReview={markReview.length} 
                            />
                        </div>
                        <div className='Container'>
                            <div className='section-btns'>
                                { 
                                    sections && sections.length !==0 &&
                                    sections.map((section, i)=>{
                                        return (
                                            <button id={`assignment${section}`} key={i}  onClick={(e)=>{ e.preventDefault(); selectSection(section)}} className={i===0 ? 'selected-section' : ''} >{section}</button>
                                        )
                                    })
                                }
                            </div>
                            <Row>
                                <Col md={8} className='questions-Container'>
                                    {question.length !== 0 ? (
                                        question.map((question,i)=>(
                                            <div key={question.id}>
                                                <Question 
                                                    testid={match.params.id} 
                                                    qno={i+1} 
                                                    ques={question.description} 
                                                    img={question.imageURL} 
                                                    opt={question.options} 
                                                    qid={question.id} 
                                                    section={question.sectionName} 
                                                    totalAttempt = {(result) => setAttempt(result)}
                                                    totalMarkForReview = {(result)=>setMarkReview(result)}
                                                />
                                            </div>
                                        ))
                                    ):(
                                        <div className='questions-notfound'>
                                            <img src={noImage} alt="" />
                                            <h3>No questions found</h3>
                                        </div>
                                    )}
                                </Col>
                                <Col md={4} className='question-tracking'>
                                    <div className='tracker-container'>
                                        { 
                                            sections && sections.length !==0 &&
                                            sections.map((section)=>{
                                                return (    
                                                    <div key={section}>
                                                        <div className='tracker'>
                                                            <div className='tracker-section'>{section}</div>
                                                            <div className='tracker-no-of-ques'>
                                                                {(paper && paper.length !== 0) ? (
                                                                    <h5><strong>Total Questions</strong> - {paper.filter((element)=> (element.sectionName === section)).length}</h5>
                                                                ): (
                                                                    <h5><strong>Total Questions</strong> - 0</h5>
                                                                )}
                                                            </div>
                                                            <div className='tracker-balls'>
                                                                <div className='ball-cont'>
                                                                    <div style={{backgroundColor:'rgb(102, 183, 49)'}} className='ball'></div>
                                                                    <p>{attempt.filter((ele)=> (ele.section === section)).length}</p>
                                                                </div>
                                                                <div className='ball-cont'>
                                                                    <div style={{backgroundColor:'rgb(239, 114, 116)'}} className='ball'></div>
                                                                    <p>{paper.filter((element)=> (element.sectionName === section)).length - attempt.filter((ele)=> (ele.section === section)).length}</p>
                                                                </div>
                                                                <div className='ball-cont'>
                                                                    <div style={{backgroundColor:'#8E70C8'}} className='ball'></div>
                                                                    <p>{markReview.filter((element)=> (element.section === section)).length}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tracker-reviews'>
                                                            {(markReview.filter((element)=> (element.section === section)).length !== 0) && (
                                                                <h6><strong>Marked For Review</strong> - </h6>
                                                            )}
                                                            <div className='tracker-review-inner'>
                                                                {markReview.filter((element)=> (element.section === section)).map((ele,i)=>(
                                                                    <div key={i}>{ele.qno}</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <button className="submitBtn" onClick={(e)=>{e.preventDefault();submitHandler()}}>Submit Test</button>
                        </div>
                    </main>
                </>
            )}
        </div>
    )
}

export default TestScreen;