import React, { useState, useEffect } from 'react';
import './LiveTestSeries.scss';
import { useDispatch, useSelector } from 'react-redux';

import { getAllLiveTestSeries } from '../../actions/EnrolledTestSeries';
import SearchItem from '../../Components/Search/SearchItem';
import AlertDismissibleExample from '../../Components/Error/error';
import noImage from '../../assets/images/otp.png';
import { useHistory } from "react-router";

import assignment1 from '../../assets/icons/assignment1.svg';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

import { Row, Col } from 'react-bootstrap'


const LiveTestSeries = () => {
    const [searchResults, setSearchResults] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();

    const getAllLiveTest = useSelector(state => state.getAllLiveTest)
    const { loading, error, LiveTest } = getAllLiveTest;

    const [tests, setTests] = useState([])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAllLiveTestSeries());
    }, [dispatch, userInfo])

    useEffect(() => {
        if (!error) {
            var tests = LiveTest.map(item => ({
                ...item,
                name: item.Test.TestSeriesName
            }))

            setTests(tests)
            setSearchResults(tests)
        }

    }, [LiveTest, error])

    const colors = [
        { bg: '#590196' },
        { bg: '#9f8400' },
        { bg: '#007947' },
        { bg: '#5b2e3d' }
    ]

    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return event.toLocaleDateString(undefined, options)
    }

    return (
        <div>
            {error && <AlertDismissibleExample data={error} />}
            {loading ? <MyTestseriesLoading/> : (
                <div className="my-4 Live-test-series">
                    <section className="d-md-flex mx-5 justify-content-center">
                        {LiveTest && LiveTest.length !== 0 &&
                            <SearchItem
                                allItems={tests}
                                from={"Live TestSeries"}
                                handleSetSearchResults={(results) => setSearchResults(results)}
                            />
                        }
                    </section>
                    <section>
                        {
                            searchResults.length === 0 ?
                            <div className="no-live-tests">
                                <img src={noImage} alt="" />
                                <h3>No TestSeries Found</h3>
                            </div> :
                            <Row className="testSeries py-5 px-5">
                                {
                                    searchResults.map((element, i) => {
                                        return (
                                            <Col 
                                                lg={5} xs={12} 
                                                className="testSeries-card my-2" 
                                                key={element.id} 
                                                style={{ backgroundColor: `${colors[i < colors.length ? i : i % colors.length].bg}`, cursor: 'pointer' }}
                                                onClick={(e)=>{e.preventDefault(); history.push(`/testseries/live/${element.Test.id}`)}}    
                                            >      
                                                <img src={assignment1} alt="assignment" />
                                                <div>
                                                    <p style={{ fontSize: 20, fontWeight: '500'}} title={element.Test.TestSeriesName}>
                                                        {element.Test.TestSeriesName.slice(0,16)}
                                                        {element.Test.TestSeriesName.length > 16 && '...'}
                                                    </p>
                                                    <p style={{ fontSize: 14, opacity: 0.7 }}>Type : {element.Test.TestSeriesType ? element.Test.TestSeriesType : 'objective'}</p>
                                                    <p style={{ fontSize: 14, opacity: 0.7 }}>Exam On : {stringDate(element.Test.ExamOn)}</p>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        }
                    </section>
                </div>
            )}
        </div>
    )
}

export default LiveTestSeries
