import React,{useEffect, useState} from 'react'

import { getAllAskToEvaluate } from '../../actions/askToEvaluateActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/logo.svg';
import noImage from '../../assets/images/otp.png';
import 'react-pro-sidebar/dist/css/styles.css';
import SearchItem from '../../Components/Search/SearchItem';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

const AllAskToEvaluate = () => {
    const dispatch = useDispatch();

    const GetAllAskToEvaluate = useSelector(state => state.GetAllAskToEvaluate)
    const { loading, error, askToEvaluate } = GetAllAskToEvaluate;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [searchResults, setSearchResults] = useState([])
    const [tests, setTests] = useState([])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAllAskToEvaluate())

    }, [dispatch, userInfo])

    useEffect(() => {
        if (!error && askToEvaluate) {
            var tests = askToEvaluate.map(item => ({
                ...item,
                name: item.testname
            }))

            setTests(tests)
            setSearchResults(tests)
        }

    }, [askToEvaluate, error])

    return (
        <div className="live-tests">
            <div className="d-md-flex mx-5 justify-content-center">
                {
                    askToEvaluate && askToEvaluate.length !== 0 && <SearchItem
                        allItems={tests}
                        from={"Ask To Evaluate"}
                        handleSetSearchResults={(results) => setSearchResults(results)}
                    />
                }
            </div>
            <div>
                {
                    loading ? <MyTestseriesLoading /> :
                    searchResults.length === 0 ? <div className="no-live-tests">
                        <img src={noImage} alt="" />
                        <h3>No Tests Found</h3>
                    </div> : <div className="d-flex m-2 flex-wrap justify-content-center">
                        {
                            searchResults.map((prop) =>(
                                <div key={prop.id} className="live-card">
                                    <Link to={`/ask-to-evaluate/${prop.id}`}>
                                        <img src={Logo} alt="logo" />
                                        <div className="live-details">
                                            <div style={{height:'120px', width:'90%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                                <p title={prop.name} style={{margin:'0px'}}>{prop.name.substring(0,47)}{prop.name.length > 47 && '...'} </p>
                                            </div>
                                            <p className="price">Price: {(prop.Price === 0) ? "Free" : `₹ ${prop.Price}`}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div> 
                }
            </div>
        </div>
    )
}

export default AllAskToEvaluate