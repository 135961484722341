import axios from 'axios';
import {
    GET_ALL_COURSES_REQUEST,
    GET_ALL_COURSES_SUCCESS,
    GET_ALL_COURSES_FAIL,
    GET_COURSES_BY_ID_REQUEST,
    GET_COURSES_BY_ID_SUCCESS,
    GET_COURSES_BY_ID_FAIL,
    GET_USER_COURSES_REQUEST,
    GET_USER_COURSES_SUCCESS,
    GET_USER_COURSES_FAIL,
    POST_ENROLL_COURSES_REQUEST,
    POST_ENROLL_COURSES_SUCCESS,
    POST_ENROLL_COURSES_FAIL,
    GET_SUBJECTS_BY_ID_REQUEST,
    GET_SUBJECTS_BY_ID_SUCCESS,
    GET_SUBJECTS_BY_ID_FAIL,
    GET_CHAPTERS_BY_ID_REQUEST,
    GET_CHAPTERS_BY_ID_SUCCESS,
    GET_CHAPTERS_BY_ID_FAIL,
    GET_TOPICS_BY_ID_REQUEST,
    GET_TOPICS_BY_ID_SUCCESS,
    GET_TOPICS_BY_ID_FAIL,
    PUT_COMPLETED_STATUS_REQUEST,
    PUT_COMPLETED_STATUS_SUCCESS,
    PUT_COMPLETED_STATUS_FAIL,
    GET_COURSE_TEST_INFO_REQUEST,
    GET_COURSE_TEST_INFO_SUCCESS,
    GET_COURSE_TEST_INFO_FAIL,
    GET_TESTSERIES_PROGRESS_REQUEST,
    GET_TESTSERIES_PROGRESS_SUCCESS,
    GET_TESTSERIES_PROGRESS_FAIL,
    GET_ASSIGNMENT_QPAPER_REQUEST,
    GET_ASSIGNMENT_QPAPER_SUCCESS,
    GET_ASSIGNMENT_QPAPER_FAIL,
    POST_SUBMIT_TEST_REQUEST,
    POST_SUBMIT_TEST_SUCCESS,
    POST_SUBMIT_TEST_FAIL,
    GET_COURSE_CONTENT_REQUEST,
    GET_COURSE_CONTENT_SUCCESS,
    GET_COURSE_CONTENT_FAIL,
    GET_COURSE_PAYMENT_HISTORY_FAIL,
    GET_COURSE_PAYMENT_HISTORY_REQUEST,
    GET_COURSE_PAYMENT_HISTORY_SUCCESS,
    POST_RESET_TEST_REQUEST,
    POST_RESET_TEST_SUCCESS,
    POST_RESET_TEST_FAIL,
    GET_ANALYTICS_BY_ID_REQUEST,
    GET_ANALYTICS_BY_ID_SUCCESS,
    GET_ANALYTICS_BY_ID_FAIL,
    GET_ANSWER_SHEET_REQUEST,
    GET_ANSWER_SHEET_SUCCESS,
    GET_ANSWER_SHEET_FAIL,
    POST_REVIEW_REQUEST,
    POST_REVIEW_SUCCESS,
    POST_REVIEW_FAIL,
    POST_RECENT_SUBJECTS_REQUEST,
    POST_RECENT_SUBJECTS_SUCCESS,
    POST_RECENT_SUBJECTS_FAIL,
    GET_RECENT_SUBJECTS_REQUEST,
    GET_RECENT_SUBJECTS_SUCCESS,
    GET_RECENT_SUBJECTS_FAIL,
    POST_COURSE_COUPON_VALIDATION_REQUEST,
    POST_COURSE_COUPON_VALIDATION_SUCCESS,
    POST_COURSE_COUPON_VALIDATION_FAIL,
    GET_ALL_FOUNDATIONAL_COURSES_REQUEST,
    GET_ALL_FOUNDATIONAL_COURSES_SUCCESS,
    GET_ALL_FOUNDATIONAL_COURSES_FAIL,
    GET_FOUNDATIONAL_COURSES_BY_ID_REQUEST,
    GET_FOUNDATIONAL_COURSES_BY_ID_SUCCESS,
    GET_FOUNDATIONAL_COURSES_BY_ID_FAIL,
    POST_ENROLL_FOUNDATIONAL_COURSES_REQUEST,
    POST_ENROLL_FOUNDATIONAL_COURSES_SUCCESS,
    POST_ENROLL_FOUNDATIONAL_COURSES_FAIL,
    POST_FOUNDATIONAL_REVIEW_REQUEST,
    POST_FOUNDATIONAL_REVIEW_SUCCESS,
    POST_FOUNDATIONAL_REVIEW_FAIL,
    GET_ENROLL_FOUNDATIONAL_COURSES_REQUEST,
    GET_ENROLL_FOUNDATIONAL_COURSES_SUCCESS,
    GET_ENROLL_FOUNDATIONAL_COURSES_FAIL,
    GET_FOUNDATIONAL_CONTENT_REQUEST,
    GET_FOUNDATIONAL_CONTENT_SUCCESS,
    GET_FOUNDATIONAL_CONTENT_FAIL,
    GET_FOUNDATIONAL_PAYMENT_HISTORY_REQUEST,
    GET_FOUNDATIONAL_PAYMENT_HISTORY_SUCCESS,
    GET_FOUNDATIONAL_PAYMENT_HISTORY_FAIL,
    POST_INSTALLMENT_PAYMENT_REQUEST,
    POST_INSTALLMENT_PAYMENT_SUCCESS,
    POST_INSTALLMENT_PAYMENT_FAIL,
    PUT_FOUNDATIONAL_COMPLETED_STATUS_REQUEST,
    PUT_FOUNDATIONAL_COMPLETED_STATUS_SUCCESS,
    PUT_FOUNDATIONAL_COMPLETED_STATUS_FAIL,
    PUT_FOUNDATIONAL_RECENT_TOPICS_REQUEST,
    PUT_FOUNDATIONAL_RECENT_TOPICS_SUCCESS,
    PUT_FOUNDATIONAL_RECENT_TOPICS_FAIL,
    GET_FOUNDATIONAL_COURSE_TEST_INFO_REQUEST,
    GET_FOUNDATIONAL_COURSE_TEST_INFO_SUCCESS,
    GET_FOUNDATIONAL_COURSE_TEST_INFO_FAIL,
    GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_REQUEST,
    GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_SUCCESS,
    GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_FAIL,
    POST_SUBMIT_FOUNDATIONAL_TEST_REQUEST,
    POST_SUBMIT_FOUNDATIONAL_TEST_SUCCESS,
    POST_SUBMIT_FOUNDATIONAL_TEST_FAIL,
    PUT_RESET_FOUNDATIONAL_TEST_REQUEST,
    PUT_RESET_FOUNDATIONAL_TEST_SUCCESS,
    PUT_RESET_FOUNDATIONAL_TEST_FAIL,
    GET_FOUNDATIONAL_ANALYTICS_BY_ID_REQUEST,
    GET_FOUNDATIONAL_ANALYTICS_BY_ID_SUCCESS,
    GET_FOUNDATIONAL_ANALYTICS_BY_ID_FAIL,
    GET_FOUNDATIONAL_ANSWER_SHEET_REQUEST,
    GET_FOUNDATIONAL_ANSWER_SHEET_SUCCESS,
    GET_FOUNDATIONAL_ANSWER_SHEET_FAIL
} from '../constants/courseConstants';

import { BASEURL } from '../config/config';

export const getAllCourses = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ALL_COURSES_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }
        const { data } = await axios.get(`${BASEURL}/admin/content/courses`, config)
        dispatch({
            type: GET_ALL_COURSES_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ALL_COURSES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAllFoundationalCourses = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ALL_FOUNDATIONAL_COURSES_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }
        const { data } = await axios.get(`${BASEURL}/admin/foundation/courses`, config)
        dispatch({
            type: GET_ALL_FOUNDATIONAL_COURSES_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ALL_FOUNDATIONAL_COURSES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getCourseById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_COURSES_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }
        const { data } = await axios.get(`${BASEURL}/admin/content/metadata/${id}`, config)
        dispatch({
            type: GET_COURSES_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_COURSES_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getFoundationalCourseContent = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FOUNDATIONAL_CONTENT_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/foundational/course/${id}`, config)
        dispatch({
            type: GET_FOUNDATIONAL_CONTENT_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_FOUNDATIONAL_CONTENT_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getCourseInstallmentHistory = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FOUNDATIONAL_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/foundational/course/${id}/payment/history`, config)
        dispatch({
            type: GET_FOUNDATIONAL_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_FOUNDATIONAL_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getFoundationalCourseById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FOUNDATIONAL_COURSES_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }
        const { data } = await axios.get(`${BASEURL}/admin/foundation/course/${id}`, config)
        dispatch({
            type: GET_FOUNDATIONAL_COURSES_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_FOUNDATIONAL_COURSES_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}



export const getAllUserCourses = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_USER_COURSES_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/enroll/courses`, config)
        dispatch({
            type: GET_USER_COURSES_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_USER_COURSES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getFoundationalAssignmentMetaData = (id, eid) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_FOUNDATIONAL_COURSE_TEST_INFO_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/foundational/assignment/${id}/${eid}`, config)
        dispatch({
            type: GET_FOUNDATIONAL_COURSE_TEST_INFO_SUCCESS,
            payload: data[0].data
        })


    }catch(error){
        dispatch({
            type: GET_FOUNDATIONAL_COURSE_TEST_INFO_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAllUserFoundationalCourses = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ENROLL_FOUNDATIONAL_COURSES_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/foundational/course`, config)
        dispatch({
            type: GET_ENROLL_FOUNDATIONAL_COURSES_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ENROLL_FOUNDATIONAL_COURSES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const postEnrollCourses = (id, pid, sig, oid, coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_COURSES_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/courses/${id}`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            coupon: coupon
        }, config)
        dispatch({
            type: POST_ENROLL_COURSES_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_ENROLL_COURSES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postInstallmentPayments = (aid, pid, sig, oid, amount) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_INSTALLMENT_PAYMENT_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/foundational/course/${aid}/payment`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            amount: amount
        }, config)
        dispatch({
            type: POST_INSTALLMENT_PAYMENT_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_INSTALLMENT_PAYMENT_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postEnrollFoundationalCourses = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_FOUNDATIONAL_COURSES_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/foundational/course/${id}`, {}, config)
        dispatch({
            type: POST_ENROLL_FOUNDATIONAL_COURSES_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_ENROLL_FOUNDATIONAL_COURSES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getSubjects = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_SUBJECTS_BY_ID_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.get(`${BASEURL}/admin/content/sub/${id}`, config)
        dispatch({
            type: GET_SUBJECTS_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_SUBJECTS_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getChapters = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CHAPTERS_BY_ID_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.get(`${BASEURL}/admin/content/chp/${id}`, config)
        dispatch({
            type: GET_CHAPTERS_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_CHAPTERS_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getTopics = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_TOPICS_BY_ID_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.get(`${BASEURL}/admin/content/top/${id}`, config)
        dispatch({
            type: GET_TOPICS_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_TOPICS_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const putCompleteStatus = (tid, cid) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_COMPLETED_STATUS_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.put(`${BASEURL}/user/content/top/completed/${tid}/${cid}`, {}, config)
        dispatch({
            type: PUT_COMPLETED_STATUS_SUCCESS,
            payload: data[0].message
        })
    } catch (error) {
        dispatch({
            type: PUT_COMPLETED_STATUS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const putFoundationalCompleteStatus = (tid, cid, status) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_FOUNDATIONAL_COMPLETED_STATUS_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.put(`${BASEURL}/user/foundational/course/${cid}/status/topic/${tid}`, {status:status}, config)
        dispatch({
            type: PUT_FOUNDATIONAL_COMPLETED_STATUS_SUCCESS,
            payload: data[0].message
        })
    } catch (error) {
        dispatch({
            type: PUT_FOUNDATIONAL_COMPLETED_STATUS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const putFoundationalRecentTopicStatus = (tid, cid) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_FOUNDATIONAL_RECENT_TOPICS_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.put(`${BASEURL}/user/foundational/course/${cid}/recent/topic`, {tid:tid}, config)
        dispatch({
            type: PUT_FOUNDATIONAL_RECENT_TOPICS_SUCCESS,
            payload: data[0].message
        })
    } catch (error) {
        dispatch({
            type: PUT_FOUNDATIONAL_RECENT_TOPICS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getTestInfoById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_COURSE_TEST_INFO_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.get(`${BASEURL}/admin/testseries/test/${id}`, config)
        dispatch({
            type: GET_COURSE_TEST_INFO_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_COURSE_TEST_INFO_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getAssignmentProgress = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_TESTSERIES_PROGRESS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/testseries/progress`, config)
        dispatch({
            type: GET_TESTSERIES_PROGRESS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_TESTSERIES_PROGRESS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getQuestionPaper = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ASSIGNMENT_QPAPER_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/admin/testseries/qpaper/${id}`, config)
        dispatch({
            type: GET_ASSIGNMENT_QPAPER_SUCCESS,
            payload: data[0].data.objectiveQuestion
        })

    } catch (error) {
        dispatch({
            type: GET_ASSIGNMENT_QPAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getFoundationalQuestionPaper = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/foundational/assignment/${id}`, config)
    
        dispatch({
            type: GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_SUCCESS,
            payload: data[0].data.map(questions => {
                return {
                    id: questions.id,
                    qNumber: questions.qNumber,
                    description: questions.description,
                    section: questions.sectionName,
                    imageURL: questions.imageURL,
                    category: questions.category,
                    classification: questions.classification,
                    difficulty: questions.difficulty,
                    options: questions.options,
                    explainText: questions.explainText ? questions.explainText : '',
                    correctOption: questions.correctOption,
                    createdAt: questions.createdAt,
                    updatedAt: questions.updatedAt,
                }
            })
        })

    } catch (error) {
        dispatch({
            type: GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postSubmitTest = (id, answers) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SUBMIT_TEST_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/testseries/${id}`, { answers }, config)
        dispatch({
            type: POST_SUBMIT_TEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_SUBMIT_TEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postSubmitFoundationalTest = (id, answers) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SUBMIT_FOUNDATIONAL_TEST_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/foundation/assignment/${id}/submit`, { answers }, config)
        dispatch({
            type: POST_SUBMIT_FOUNDATIONAL_TEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_SUBMIT_FOUNDATIONAL_TEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getCourseContent = (id) => async (dispatch) => {
    try {

        dispatch({
            type: GET_COURSE_CONTENT_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }

        const { data } = await axios.get(`${BASEURL}/admin/content/List/${id}`, config)
        dispatch({
            type: GET_COURSE_CONTENT_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_COURSE_CONTENT_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getCoursePaymentHistory = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_COURSE_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/courses/history`, config)
        dispatch({
            type: GET_COURSE_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_COURSE_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const resetTest = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_RESET_TEST_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/testseries/reset/${id}`, {}, config)
        dispatch({
            type: POST_RESET_TEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_RESET_TEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const resetFoundationalTest = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_RESET_FOUNDATIONAL_TEST_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.put(`${BASEURL}/user/foundational/assignment/${id}/reset`, {}, config)
        dispatch({
            type: PUT_RESET_FOUNDATIONAL_TEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: PUT_RESET_FOUNDATIONAL_TEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAssignmentAnalytics = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ANALYTICS_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/testseries/${id}`, config)
        dispatch({
            type: GET_ANALYTICS_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ANALYTICS_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getUserAnswerSheet = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ANSWER_SHEET_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/testseries/answersheet/${id}`, config)
        dispatch({
            type: GET_ANSWER_SHEET_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ANSWER_SHEET_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getFoundationalAssignmentAnalytics = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_FOUNDATIONAL_ANALYTICS_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/foundation/assignment/${id}/analytics`, config)
        dispatch({
            type: GET_FOUNDATIONAL_ANALYTICS_BY_ID_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: GET_FOUNDATIONAL_ANALYTICS_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getFoundationalAnswerSheet = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_FOUNDATIONAL_ANSWER_SHEET_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/foundation/assignment/${id}/answersheet`, config)
        dispatch({
            type: GET_FOUNDATIONAL_ANSWER_SHEET_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_FOUNDATIONAL_ANSWER_SHEET_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const postFeedback = (id, review) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_REVIEW_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/feedback/${id}`, { review } ,config)
        dispatch({
            type: POST_REVIEW_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_REVIEW_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postFoundationalFeedback = (id, review) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_FOUNDATIONAL_REVIEW_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/foundational/course/${id}/review`, { review } ,config)
        dispatch({
            type: POST_FOUNDATIONAL_REVIEW_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_FOUNDATIONAL_REVIEW_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postRecentSubjects = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_RECENT_SUBJECTS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/recsub/${id}`, {} ,config)
        dispatch({
            type: POST_RECENT_SUBJECTS_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_RECENT_SUBJECTS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getRecentSubjects = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_RECENT_SUBJECTS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/recsub`, config)
        dispatch({
            type: GET_RECENT_SUBJECTS_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_RECENT_SUBJECTS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postCheckValidationCoupon = (code) => async (dispatch) => {
    try {

        dispatch({
            type: POST_COURSE_COUPON_VALIDATION_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.post(`${BASEURL}/user/courses/val/coupon`,{code}, config)
        dispatch({
            type: POST_COURSE_COUPON_VALIDATION_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: POST_COURSE_COUPON_VALIDATION_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}