import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getTopics, putCompleteStatus } from '../../actions/courseActions';
import { OndemandVideo, PictureAsPdf, Audiotrack } from '@mui/icons-material';
import Loader from '../../Components/Loader';
import { BASEURL } from '../../config/config';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import Pdf from '../../Components/PdfViewer/Pdf';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import axios from 'axios';

// import { logout } from '../../actions/userActions';

function TopicsList({ id, index, match }) {

    const dispatch = useDispatch();
    const getTopicsById = useSelector(state => state.getTopicsById)
    const { loading: LoadingTopics, error: errorTopics, Topics } = getTopicsById;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;
    const putCompletedTopic = useSelector(state => state.putCompletedTopic)
    const { loading: loadingStatus, error: errorStatus, status } = putCompletedTopic;


    const [show, setShow] = useState(false);
    const [SingleTopicOBJ, setSingleTopicOBJ] = useState({});
    const [checkStatus, SetCheckStatus] = useState(false);
    const [type, SetType] = useState('pdf');

    useEffect(() => {
        dispatch(getTopics(id))
    }, [dispatch, id])

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(errorTopics === message || errorStatus === message){
    //         dispatch(logout());
    //     }
    // },[dispatch, errorTopics, errorStatus])

    useEffect(() => {
        if (show) {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
        } else {
            document.body.style.position = '';
            document.body.style.top = '';

        }
    }, [show])
    const onChange = (e) => {
        e.preventDefault();
        dispatch(putCompleteStatus(SingleTopicOBJ.id, match.params.cid));
        if (checkStatus) {
            SetCheckStatus(false)
        } else {
            SetCheckStatus(true)
        }
    }


    const handleModalClose = () => {
        setShow(false)
        if (type === "pdf") {
            if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen()
            }
        }
    }

    const getSingleTopic = async (topic, type) => {
        setShow(true)
        SetType(type);
        if (type === "pdf") {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            }
        }
        setSingleTopicOBJ(topic);
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/content/top/completed/${topic.id}`, config);
        SetCheckStatus(data[0].data.completed);

    }

    return (
        <div>
            {LoadingTopics ? <Loader /> : errorTopics ? (
                <p>No topics available!</p>
            ) : (Topics.length !== 0) && (
                Topics.map((topic, id) => {
                    return <div className="continue-card d-flex justify-content-between" key={topic.id} style={{ width: '100%' }}>
                        <div>
                            {/* <img src={playImage} alt="playLogo" /> */}

                            <div className='p-2'>
                                <p style={{ fontSize: 16, fontWeight: '500' }}> {index + 1}.{id + 1} {topic.topicName}</p>
                                <p style={{ fontSize: 14 }}>
                                    {topic.key && (
                                        <span className="link" onClick={(e) => { e.preventDefault(); getSingleTopic(topic, 'pdf') }}><PictureAsPdf /></span>
                                    )}
                                    {topic.video && (
                                        <span className="link" onClick={(e) => { e.preventDefault(); getSingleTopic(topic, 'video') }}><OndemandVideo /></span>
                                    )}
                                    {topic.audio && (
                                        <span className="link" onClick={(e) => { e.preventDefault(); getSingleTopic(topic, 'audio') }}><Audiotrack /></span>
                                    )}
                                </p>
                            </div>
                        </div>
                        <div>
                            <p>{topic.timeRequire}</p>
                        </div>
                    </div>
                })
            )}
            {
                show && <div className="modal-view-new" >
                    <div className="modal-custom-content">
                        <div className="modal-custom-header">
                            <h3>
                                {SingleTopicOBJ.topicName}
                            </h3>
                        </div>
                        <div>
                            <div className="modal-custom-body" >
                                {type === 'pdf' ? (
                                    <div>
                                        <Pdf url={SingleTopicOBJ.key} />
                                    </div>
                                ) : type === 'video' ? (<div className="video-styles">
                                    <video width="100%" height="100%" controls>
                                        <source src={SingleTopicOBJ.video} type="video/mp4" />
                                    </video>
                                </div>) : (
                                    <div className="audio-styles">
                                        <audio controls>
                                            <source src={SingleTopicOBJ.audio} />
                                        </audio>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-custom-footer d-flex flex-column flex-md-row align-items-center justify-content-between" >

                            <label>
                                <Checkbox onClick={onChange} checked={checkStatus ? true : false} />
                                &nbsp; Mark as Read
                            </label>
                            <button onClick={() => handleModalClose()}>Close</button>

                            {loadingStatus ? <Loader /> : errorStatus ? <AlertDismissibleExample data={errorStatus} /> : status && <AlertSuccessExample data={status} />}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TopicsList
