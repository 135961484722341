import React from 'react'
import './question.scss'
import DOMPurify from 'dompurify';
import edit from '../../assets/icons/edit.svg';

const Question = ({ className,testid, qno, ques, img, opt, qid, section, totalAttempt, totalMarkForReview}) => {
    const selectedOption = (id,answer) => {
        const Answers =  JSON.parse(localStorage.getItem(`${testid}_answerSheet`));
        const attempt = JSON.parse(localStorage.getItem(`${testid}_attempt`));
        const idx = Answers.findIndex((element)=> (element.qid === id));
        if(idx > -1){
            Answers[idx].answer = answer
        }else{
            Answers.push({
                qid:id,
                answer:answer
            })
            attempt.push({
                qid:id,
                section:section
            })
            localStorage.removeItem(`${testid}_attempt`);
            localStorage.setItem(`${testid}_attempt`, JSON.stringify(attempt));
            totalAttempt(JSON.parse(localStorage.getItem(`${testid}_attempt`)))
        }
        localStorage.removeItem(`${testid}_answerSheet`);
        localStorage.setItem(`${testid}_answerSheet`,JSON.stringify(Answers));
    }

    const isChecked = (id, answer) => {
        const Answers =  JSON.parse(localStorage.getItem(`${testid}_answerSheet`));
        const attempt = JSON.parse(localStorage.getItem(`${testid}_attempt`));
        const i = Answers.findIndex((element)=>(element.qid === id && element.answer === answer));
        if(i>-1){
            const temp = Answers[i];
            Answers[i] = Answers[Answers.length -1];
            Answers[Answers.length -1] = temp;
            Answers.pop();
            document.querySelector(`input[name="${id}"]:checked`).checked = false;

            const idx = attempt.findIndex((ele)=>(ele.qid === id));
            if(i>-1){
                const temp2 = attempt[idx];
                attempt[idx] = attempt[attempt.length -1];
                attempt[attempt.length -1] = temp2;
                attempt.pop();
            }
        }
        localStorage.removeItem(`${testid}_answerSheet`);
        localStorage.setItem(`${testid}_answerSheet`,JSON.stringify(Answers));
        localStorage.removeItem(`${testid}_attempt`);
        localStorage.setItem(`${testid}_attempt`, JSON.stringify(attempt));
        totalAttempt(JSON.parse(localStorage.getItem(`${testid}_attempt`)))
    }

    const markForRev = (id, section, qno) =>{
        const MFR = JSON.parse(localStorage.getItem(`${testid}_markForReview`));
        const i = MFR.findIndex((ele)=>(ele.qid === id));
        if(i>-1){
            const temp = MFR[i];
            MFR[i] = MFR[MFR.length -1];
            MFR[MFR.length -1] = temp;
            MFR.pop();
        }else{
            MFR.push({
                qid:id,
                section:section,
                qno:qno
            })
        }
        localStorage.removeItem(`${testid}_markForReview`);
        localStorage.setItem(`${testid}_markForReview`,JSON.stringify(MFR));
        totalMarkForReview(JSON.parse(localStorage.getItem(`${testid}_markForReview`)))
    }
    
    return (
        <div className={`questions-container ${className}`}>
            <div className="question">
                <div className="ques-line1">
                    <div className="qus-number">
                        <h1>{qno}</h1>
                    </div> 
                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(ques)}}></p>
                    <div className='markForReview' onClick={(e)=>{e.preventDefault(); markForRev(qid, section, qno)}}>
                        <img src={edit} alt="Marked for review"/>
                    </div>
                </div>
                {img && (
                    <div className="question-Img">
                        <img src={img} alt="questionimg"/>
                    </div>
                )}
                <div className="options">
                    {opt.map((option, i)=>(
                        <label className="option"key={i} >{option}
                            <input type="radio" name={qid} value={option} onChange={()=>selectedOption(qid,option)} onClick={() => isChecked(qid,option)}  defaultChecked={(localStorage.getItem(`${testid}_answerSheet`) && JSON.parse(localStorage.getItem(`${testid}_answerSheet`)).find((el)=>(el.qid === qid && el.answer === option))) && true }/>
                            <span className="checkmark"></span>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Question
