import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProfileLogo from '../../ProfileLogo/ProfileLogo';
import AlertDismissibleExample from '../../Error/error';

import {
    ProSidebar,
    SidebarHeader,
    SidebarContent,
    Menu,
    MenuItem,
    // SidebarFooter
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

import 'react-pro-sidebar/dist/css/styles.css';
import './sidebar.scss';

import {  getUserDetails, authorization } from '../../../actions/userActions';

import {
    HomeIcon,
    CourseIcon,
    Books,
    ExploreIcon,
    MoreIcon,
    HelpIcon,
    TestIcon,
    Prebepre
} from '../../../utils/svgComponent';
import logo from '../../../assets/images/logo-white.svg';
import { Close, Menu as MenuIcon } from '@material-ui/icons';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const Sidebar = ({ activeLink }) => {
    const dispatch = useDispatch();

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [mobileView, setMobileView] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showMenu, setShowMenu] = useState(false)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getUserDetail = useSelector(state => state.getUserDetail)
    const {loading:loadingUser , error:errorUser, user} = getUserDetail;

    const userAuthorization = useSelector(state => state.userAuthorization)
    const { error:authError } = userAuthorization;

    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth)
        }, 1000)
        window.addEventListener('resize', debouncedHandleResize)
        return _ => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })

    useEffect(()=>{
        if(userInfo){
            dispatch(authorization());
            dispatch(getUserDetails())
        }
    },[userInfo, dispatch])

    useEffect(()=>{
        if(userInfo){
            if(authError){
                localStorage.removeItem("userInfo");
                document.location.href = '/login';
            }
        }
    },[authError, userInfo])

    useEffect(() => {
        if (windowWidth <= 726) {
            setMobileView(true)
        } else {
            setMobileView(false)
        }
    }, [windowWidth])


    if (mobileView) {

        return <div className="mobile-view-sidebar">
            <div style={{ height: "100%", padding: "20px" }} className="d-flex justify-content-between align-items-center">
                    <>
                        <div className="logotext-ev" style={{height:"65px"}}>
                            <img src={logo} alt="Yes Mentor" style={{height:"65px"}} />
                            {/* {loadingUser && <div style={{height:"65px"}}></div>}
                            {!loadingUser && !errorUser && user.id &&
                                <ProfileLogo 
                                    text={`${user.firstname.slice(0,1)}`}
                                    img={user.imageURL}
                                    name={user.firstname}
                                />} */}
                        </div>
                        <div className="closemenu" onClick={menuIconClick}>
                        </div>
                    </>
                {
                    !showMenu && <button onClick={() => setShowMenu(true)} ><MenuIcon /></button>
                }
                {
                    showMenu && <button onClick={() => setShowMenu(false)} ><Close /> </button>
                }

            </div>
            {
                showMenu && <div className="mobile-header">
                    <ProSidebar collapsed={menuCollapse}>


                        <SidebarContent>
                            <Menu>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === '' ? true : false} icon={<HomeIcon />}>
                                    Home <Link to="/" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'myfoundationalcourse' ? true : false} icon={<Books />}>
                                    Pro Courses <Link to="/my-foundational-course" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'mycourse' ? true : false} icon={<CourseIcon />}>
                                    My Courses <Link to="/mycourse" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'myTestseries' ? true : false} icon={<TestIcon />}>
                                    My Test Series <Link to="/myTestseries" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'preB4Pre' ? true : false} icon={<Prebepre />}>
                                    Pre-B4-Pre<Link to="/myPreB4Pre" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'explore' ? true : false} icon={<ExploreIcon />}>
                                    Explore <Link to="/explore" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'more' ? true : false} icon={<MoreIcon />}>
                                    More <Link to="/more" />
                                </MenuItem>
                                <MenuItem onClick={() => setShowMenu(false)} active={activeLink === 'help' ? true : false} icon={<HelpIcon />}>
                                    Get Help!<Link to="/help" />
                                </MenuItem>
                            </Menu>
                        </SidebarContent>
                        {/* <SidebarFooter>
                            <Menu iconShape="square">
                                <MenuItem icon={<FiLogOut />} onClick={Logout}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </SidebarFooter> */}
                    </ProSidebar>
                </div>
            }
        </div>

    }

    return (
        <div>
            {authError && <AlertDismissibleExample data ={authError}/>}
            <div id='header'>
                <ProSidebar collapsed={menuCollapse}>
                    <SidebarHeader>
                        <div className="logotext">
                            {(loadingUser) && <div style={{height:"65px"}}></div>}
                            {!loadingUser && !errorUser && user.id &&
                                <ProfileLogo 
                                    text={`${user.firstname.slice(0,1)}`}
                                    img={user.imageURL}
                                    name={user.firstname}
                                />}
                        </div>
                        <div className="closemenu" onClick={menuIconClick}>
                        </div>
                    </SidebarHeader>

                    <SidebarContent>
                        <Menu>
                            <MenuItem active={activeLink === '' ? true : false} icon={<HomeIcon />}>
                                Home <Link to="/" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'myfoundationalcourse' ? true : false} icon={<Books />}>
                                Pro Courses<Link to="/my-foundational-course" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'mycourse' ? true : false} icon={<CourseIcon />}>
                                My Courses <Link to="/mycourse" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'mytestseries' ? true : false} icon={<TestIcon />}>
                                My Test Series <Link to="/mytestseries" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'preB4Pre' ? true : false} icon={<Prebepre />}>
                                Pre-B4-Pre<Link to="/myPreB4Pre" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'explore' ? true : false} icon={<ExploreIcon />}>
                                Explore <Link to="/explore" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'more' ? true : false} icon={<MoreIcon />}>
                                More <Link to="/more" />
                            </MenuItem>
                            <MenuItem active={activeLink === 'help' ? true : false} icon={<HelpIcon />}>
                                Get Help!<Link to="/help" />
                            </MenuItem>
                        </Menu>
                    </SidebarContent>
                    {/* <SidebarFooter>
                        <Menu iconShape="square">
                            <MenuItem icon={<FiLogOut />} onClick={Logout}>
                                Logout
                            </MenuItem>
                        </Menu>
                    </SidebarFooter> */}
                </ProSidebar>
            </div>
        </div>
    )
}

export default Sidebar
