import { BrowserRouter as Router, Route } from 'react-router-dom'
import DashBoard from './Screens/dashboard/dashboard';
import LoginScreen from './Screens/LoginScreen';
import ForgotPasswordScreen from './Screens/ForgotPasswordScreen';
import SignUpScreen from './Screens/SignUpScreen';
import CourseDetailsScreen from './Screens/courseDetails/CourseDetailsScreen';
import MyCourse from './Screens/myCourses/myCourse';
import More from './Screens/more/more';
import Chapters from './Screens/chapters/chapters';
import AssignmentScreen from './Screens/Assignments/AssignmentScreen';
import TestScreen from './Screens/Assignments/TestScreen';

import './App.scss';
import Explore from './Screens/explore/Explore';
import LiveTestDetails from './Screens/LiveTestDetails/LiveTestDetails';
// import AudiobookDetails from './Screens/AudiobookDetails/AudiobookDetails';
import PracticeTestDetails from './Screens/PracticeTestDetails/PracticeTestDetails';
import PersonalDetails from './Screens/PersonalDetails/PersonalDetails';
import AssignmentAnalyticsScreen from './Screens/AssignmentAnalytics/AssignmentAnalyticsScreen';
import MyTestSeries from './Screens/MyTestSeries/MyTestSeries';
import PracticeTestSeries from './Screens/MyTestSeries/PracticeTestSeries';
import LiveTestSeries from './Screens/MyTestSeries/LiveTestSeries';
import PracticeInstructions from './Screens/TestSeries/PracticeInstructions';
import LiveInstructions from './Screens/TestSeries/LiveInstructions';
import PracticeTestScreen from './Screens/TestSeries/PracticeTestScreen';
import LiveTestScreen from './Screens/TestSeries/LiveTestScreen';
import PracticeTestAnalytics from './Screens/TestSeriesAnalytics/PracticeTestAnalytics';
import ResetPassword from './Screens/ResetPassword/ResetPassword';
import Invoices from './Screens/Invoices/Invoices';
import PrivacyPolicy from './Screens/Policies/PrivacyPolicy';
import TermsAndConditions from './Screens/Policies/TermsAndConditions';


import LiveTestAnalytics from './Screens/TestSeriesAnalytics/LiveTestAnalytics';
import GetHelp from './Screens/GetHelp/GetHelp';
import EnrollPreB4Pre from './Screens/EnrollPreB4Pre/EnrollPreB4Pre';
import EnrollOfflinePreB4Pre from './Screens/EnrollPreB4Pre/EnrollOfflinePreB4Pre';
import MyPreb4preTests from './Screens/MyPreb4preTests/MyPreb4preTests';
import Preb4preTestSeries from './Screens/MyTestSeries/Preb4preTestSeries';
import Preb4preInstructions from './Screens/TestSeries/Preb4preInstructions';
import Preb4preTestScreen from './Screens/TestSeries/Preb4preTestScreen';
import Preb4preTestAnalytics from './Screens/TestSeriesAnalytics/Preb4preTestAnalytics';
import IssuesThisWeek from './Screens/IssuesThisWeek/IssuesThisWeek';
import ItwInstructions from './Screens/IssuesThisWeek/ItwInstructions';
import createHost from 'cross-domain-storage/host';
import ListItw from './Screens/IssuesThisWeek/ListITW';
import ItwAnalytics from './Screens/IssuesThisWeek/ItwAnalytics';
import MainPreB4Pre from './Screens/MainPreB4Pre/MainPreB4Pre';
import RedirectToPreB4Pre from './Screens/Redirect/RedirectToPreB4Pre';
import MyOfflinePreb4Pre from './Screens/MyPreb4preTests/MyOfflinePreb4Pre';
import OfflinePreB4PreDetails from './Screens/OffilePreb4PreDetails/OfflinePreB4PreDetails';
import FoundationalCourseDetailsScreen from './Screens/FoundationalCourseDetails/FoundationalCourseDetailsScreen';
import EnrolledFoundationalCourses from './Screens/MyFoundationalCourse/CourseList/EnrolledFoundationalCourses';
import EnrollCourseDetails from './Screens/MyFoundationalCourse/EnrollCourseDetails/EnrollCourseDetails';
import PaymentDetails from './Screens/MyFoundationalCourse/PaymentDetails/PaymentDetails';
import AssignmentInstructions from './Screens/MyFoundationalCourse/Assignments/AssignmentInstructions';
import Assignment from './Screens/MyFoundationalCourse/Assignments/Assignment';
import FoundationalAssignmentAnalytics from './Screens/MyFoundationalCourse/Assignments/AssignmentAnalytics';
import AskToEvaluateDetails from './Screens/AskToEvaluate/AskToEvaluateDetails';
import AskToEvaluateInstructions from './Screens/TestSeries/AskToEvaluateInstructions';
import EnrollAskToEvaluate from './Screens/MyTestSeries/EnrollAskToEvaluate';
import AskToEvaluateTestScreen from './Screens/TestSeries/AskToEvaluateTestScreen';
import AskToEvaluateAnalytics from './Screens/TestSeriesAnalytics/AskToEvaluateAnalytics';

import {isIOS, isAndroid } from 'react-device-detect';


createHost([
    {
        origin:'http://localhost:3000',
        allowedMethods:['get','set','remove']
    },
    {
        origin:'https://yesmentor.in',
        allowedMethods:['get','set','remove']
    }
])

const App = () => {
    // document.addEventListener("contextmenu", function (e) {
    //   e.preventDefault();
    // });

    // document.onkeydown = function (e) {
    //   if (e.keyCode === 123) {
    //     return false;
    //   }
    //   if (e.ctrlKey) {
    //     return false;
    //   }
    // };
    return (
        <Router>
            <div>
            {(isIOS || isAndroid) && (
                <div className='redirect'>
                    <div className='redirect-card'>
                        <h3>Taking you to our Mobile App.</h3>
                        <p>Explore this site in our mobile 
                        application for the better experience!</p>
                        <hr/>
                        {isAndroid && <button onClick={(e)=>{e.preventDefault(); window.location.replace("https://yesmentor.page.link/openapp")}}>Let's go</button>}
                        {isIOS && <button>Let's go</button>}
                    </div>
                </div>
            )}
            
            <Route path='/' exact component={DashBoard} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/forgotpassword" component={ForgotPasswordScreen} />
            <Route path="/signup" component={SignUpScreen} />
            <Route path="/course/:id" exact component={CourseDetailsScreen} />
            <Route path="/mycourse" component={MyCourse} />
            <Route path="/myPreB4Pre" component={MyPreb4preTests} />
            <Route path="/chapter/:sid/:name/:cid" component={Chapters} />
            {/* <Route path="/audiobooks/:aid" component={AudiobookDetails} /> */}
            <Route path="/practice-tests/:ptid" component={PracticeTestDetails} />
            <Route path="/live-tests/:ltid" component={LiveTestDetails} />
            <Route path="/more" component={More} />
            <Route path="/explore" component={Explore} />
            <Route path="/assignment/test/:id" exact component={TestScreen} />
            <Route path="/course/assignment/:id" exact component={AssignmentScreen} />
            <Route path="/course/assignment/analyst/:id" exact component={AssignmentAnalyticsScreen} />
            <Route path="/user/profile" component={PersonalDetails} />
            <Route path="/enroll-pre-b4-pre" component={EnrollPreB4Pre} />
            <Route path="/enroll-Offline-pre-b4-pre" component={EnrollOfflinePreB4Pre} />
            <Route path="/preb4pre" exact component={MainPreB4Pre}/>
            <Route path="/mytestseries" component={MyTestSeries} />
            <Route path="/testseries/preb4pre/instructions/:id" exact component={Preb4preInstructions} />
            <Route path="/testseries/preb4pre/:id" exact component={Preb4preTestSeries} />
            <Route path="/testseries/preb4pre/analytics/:id" exact component={Preb4preTestAnalytics} />
            <Route path="/testseries/practice/instructions/:id" exact component={PracticeInstructions} />
            <Route path="/testseries/practice/:id" exact component={PracticeTestSeries} />
            <Route path="/testseries/practice/analytics/:id" exact component={PracticeTestAnalytics} />
            <Route path="/testseries/live/:id" exact component={LiveTestSeries} />
            <Route path="/testseries/live/analytics/:id" exact component={LiveTestAnalytics} />
            <Route path="/testseries/live/instructions/:id" exact component={LiveInstructions} />
            <Route path="/preb4pre/test/:id" component={Preb4preTestScreen} />
            <Route path="/practice/test/:id" component={PracticeTestScreen} />
            <Route path="/live/test/:id" component={LiveTestScreen} />
            <Route path="/user/reset-password" component={ResetPassword} />
            <Route path="/user/invoices" component={Invoices} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/help" component={GetHelp} />
            <Route path="/itw-mcq/instructions/:id" exact component={ItwInstructions} />
            <Route path="/itw-mcq/:id" exact component={IssuesThisWeek} />
            <Route path="/myITW" exact component={ListItw} />
            <Route path="/myITW/analytics/:id" exact component={ItwAnalytics} />
            <Route path="/redirect/:type/preb4pre/:token/:userID" exact component={RedirectToPreB4Pre}/>
            <Route path="/offline/my-pre-b4-pre" exact component={MyOfflinePreb4Pre} />
            <Route path="/offline/my-pre-b4-pre/:id" exact component={OfflinePreB4PreDetails}/>
            <Route path="/foundational-course/:id" exact component={FoundationalCourseDetailsScreen} />
            <Route path="/my-foundational-course" exact component={EnrolledFoundationalCourses} />
            <Route path="/enroll/foundational/course/:id/:eid" exact component={EnrollCourseDetails} />
            <Route path="/foundational-payment-details/:id/:eid" exact component={PaymentDetails} />
            <Route path="/foundational-assignment/instructions/:id/:eid" exact component={AssignmentInstructions} />
            <Route path="/foundational-assignment/:id" exact component={Assignment} />
            <Route path="/foundational-assignment/:id/analytics" exact component={FoundationalAssignmentAnalytics} />
            <Route path="/ask-to-evaluate/:id" exact component={AskToEvaluateDetails} />
            <Route path="/ask-to-evaluate/instructions/:id" exact component={AskToEvaluateInstructions} />
            <Route path="/ask-to-evaluate/details/:id" exact component={EnrollAskToEvaluate} />
            <Route path="/ask-to-evaluate/exam/:id" exact component={AskToEvaluateTestScreen} />
            <Route path="/ask-to-evaluate/analytics/:id" exact component={AskToEvaluateAnalytics} />
        </div>
        </Router>
    );
}

export default App;
