import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import leftArrow from '../../assets/icons/back-arrow.svg';
// import Logo from '../../assets/icons/logo.svg';
import axios from 'axios';
import defaultImage from "../../assets/images/overview.svg"
import { BASEURL, RazorpayKey } from '../../config/config';
import "./LiveTestDetails.scss"
import { getLiveTestById, postEnrollLiveTest,postCheckValidationCoupon } from '../../actions/liveTestsAction';
import { CreditCard, Event, Schedule } from '@material-ui/icons';
import { HourglassTop } from '@mui/icons-material';
// import cover from "../../assets/images/cover.png"
import { getUserDetails } from '../../actions/userActions';
import ProcessingModal from '../../Components/ProcessingModal';
import SuccessfulModal from '../../Components/SuccessfulModal';
import DOMPurify from 'dompurify';

function LiveTestDetails({ match }) {

    const dispatch = useDispatch()
    const history = useHistory()
    const getLiveTestDetails = useSelector(state => state.getLiveTestDetails)
    const { loading, error, liveTest } = getLiveTestDetails

    const enrollLiveTest = useSelector(state => state.postEnrollLiveTest)
    const { loading: loadingEnroll, error: errorEnroll, message } = enrollLiveTest

    const postLiveCoupon = useSelector(state => state.postLiveCoupon)
    const { loading: loadingCoupon, error: errorCoupon, couponDetails } = postLiveCoupon;

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { user } = getUserDetail

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [coupon, setCoupon] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getLiveTestById(match.params.ltid))
        dispatch(getUserDetails())
    }, [userInfo, dispatch, match])

    useEffect(()=>{
        if(liveTest.price !== undefined && couponDetails.discountPercentage !== undefined){
            if(((liveTest.price)*(couponDetails.discountPercentage / 100)) > couponDetails.maxDiscount){
                setCouponDiscount(couponDetails.maxDiscount);
            }else{
                setCouponDiscount(((liveTest.price)*(couponDetails.discountPercentage / 100)));
            }
        }
    },[couponDetails, liveTest])

    const getEnrolled = (e) => {
        e.preventDefault()
        dispatch(postEnrollLiveTest(match.params.ltid, "", "", "", ""))
    }

    const getPaidEnrolled = async (e) => {
        e.preventDefault()
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        if(couponDetails.discountCode){
            let { data } = await axios.post(`${BASEURL}/user/razorpay/order/live/${match.params.ltid}`, {coupon:couponDetails.discountCode}, config)
            var options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollLiveTest(match.params.ltid, response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id, couponDetails.discountCode))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }else{
            let { data } = await axios.post(`${BASEURL}/user/razorpay/order/live/${match.params.ltid}`, {}, config)

            options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollLiveTest(match.params.ltid, response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id, ""))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
        
        const rzp1 = new window.Razorpay(options);
        rzp1.open();

    }

    const CouponValidation = (e) =>{
        e.preventDefault();
        dispatch(postCheckValidationCoupon(coupon));
    }

    return (
        <div>
            {loadingCoupon && <Loader/>}
            {errorEnroll && <AlertDismissibleExample data={error} />}
            {loadingEnroll && <ProcessingModal />}
            {message && <SuccessfulModal data={"Successfully enrolled!"} path="/mytestseries" />}
            <main>
                {loading ? <Loader /> : error ? <AlertDismissibleExample data={error} /> :
                    <div className="live-test-overview">
                        <div>
                            <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                                <img src={leftArrow} alt="<" style={{ margin: 20 }} />
                            </button>

                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <h2>{liveTest.testName}</h2>
                            <img src={defaultImage} alt="" width="200" />
                        </div>
                        <div className="test-content p-2 p-md-5">

                            <div className="d-flex  test-info my-0 justify-content-between align-items-center">
                                <p>Type: {liveTest.testType}</p>
                            </div>
                            <div className="test-info">
                                <h3>About the test</h3>
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(liveTest.description)}}></p>
                            </div>
                            <div className="test-info">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="test-details">
                                            <div>
                                                <Event /> Date:  {liveTest.startingTime && liveTest.startingTime.split('T')[0]}
                                            </div>
                                            <div>
                                                <HourglassTop /> Duration: {liveTest.duration} Minutes
                                            </div>
                                            <div>
                                                <Schedule />  Start at: {liveTest.startingTime && liveTest.startingTime.split("T")[1].substring(0, 5)}
                                            </div>
                                            {
                                                (liveTest.discountPrice === null || liveTest.discountPrice === 0) ? <div>

                                                    <CreditCard /> Cost: {liveTest.price === 0 ? "Free" : "₹ " + liveTest.price}
                                                </div> : <div>

                                                    <CreditCard /> Cost: <span style={{ textDecoration: "line-through" }}> ₹ {liveTest.price}</span>  ₹ {liveTest.price - liveTest.discountPrice}
                                                </div>
                                            }

                                        </div>
                                        <div className='pb-5 pt-2'>
                                        {user.verified ? (liveTest.isEnrolled ? (
                                            <button className="enroll-btn" style={{ cursor: 'no-drop', position: "absolute" }} onClick={null}>Enrolled</button>
                                        ) : (liveTest.price !== 0) ? (
                                            <button className="enroll-btn" style={{ cursor: 'pointer', position: "absolute" }} onClick={getPaidEnrolled}>
                                                Enroll
                                            </button>
                                        ) : (
                                            <button className="enroll-btn" style={{ cursor: 'pointer', position: "absolute" }} onClick={getEnrolled}>
                                                Enroll
                                            </button>
                                        )) : <div>
                                            <button className="no-enroll-btn" style={{ cursor: 'not-allowed' }} onClick={getPaidEnrolled}>
                                                Enroll
                                            </button>
                                            <p style={{ color: '#ff0101', fontSize: 12 }}>
                                                Please verify your email address to enroll in the live Test
                                            </p>
                                        </div>
                                        }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {liveTest.price !== 0 && !liveTest.isEnrolled &&  
                                            <div className='coupons'>
                                                <h2>Coupon</h2>
                                                <div>
                                                    <input type='text' value={coupon} placeholder='Enter Coupon Code' onChange={(e) => setCoupon(e.target.value)}/>
                                                    <button onClick={CouponValidation}>Apply</button>
                                                </div>
                                                {errorCoupon && <p>{errorCoupon}</p>}
                                                
                                                {couponDetails.discountCode && <p style={{color:'green'}}>Congratulations, you got a discount of ₹{couponDiscount}/-</p>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </main>
        </div>
    )
}

export default LiveTestDetails
