import {
    GET_PUBLISHED_OFFLINE_PREB4PRE_REQUEST,
    GET_PUBLISHED_OFFLINE_PREB4PRE_SUCCESS,
    GET_PUBLISHED_OFFLINE_PREB4PRE_FAIL,
    POST_ENROLL_OFFLINE_PREB4PRE_REQUEST,
    POST_ENROLL_OFFLINE_PREB4PRE_SUCCESS,
    POST_ENROLL_OFFLINE_PREB4PRE_FAIL,
    GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_REQUEST,
    GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_SUCCESS,
    GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_FAIL,
    GET_ENROLLED_OFFLINE_PREB4PRE_FAIL,
    GET_ENROLLED_OFFLINE_PREB4PRE_REQUEST,
    GET_ENROLLED_OFFLINE_PREB4PRE_SUCCESS,
    GET_OFFLINE_PREB4PRE_DETAILS_REQUEST,
    GET_OFFLINE_PREB4PRE_DETAILS_SUCCESS,
    GET_OFFLINE_PREB4PRE_DETAILS_FAIL
} from '../constants/OfflinePreB4PreConstants';

export const getPublishedOfflinePreb4preReducer = (state = { offlinePreb4pre: [] }, action) => {
    switch (action.type) {
        case GET_PUBLISHED_OFFLINE_PREB4PRE_REQUEST:
            return { loading: true, offlinePreb4pre: [] }
        case GET_PUBLISHED_OFFLINE_PREB4PRE_SUCCESS:
            return { loading: false, offlinePreb4pre: action.payload }
        case GET_PUBLISHED_OFFLINE_PREB4PRE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollOfflinePreB4preReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_OFFLINE_PREB4PRE_REQUEST:
            return { loading: true }
        case POST_ENROLL_OFFLINE_PREB4PRE_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_OFFLINE_PREB4PRE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getOnlinePreB4prePaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getEnrolledOfflinePreb4preReducer = (state = { OfflinePreb4pre: [] }, action) => {
    switch (action.type) {
        case GET_ENROLLED_OFFLINE_PREB4PRE_REQUEST:
            return { loading: true, OfflinePreb4pre: [] }
        case GET_ENROLLED_OFFLINE_PREB4PRE_SUCCESS:
            return { loading: false, OfflinePreb4pre: action.payload }
        case GET_ENROLLED_OFFLINE_PREB4PRE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getOfflinePreb4preTestSeriesReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_OFFLINE_PREB4PRE_DETAILS_REQUEST:
            return { loading: true }
        case GET_OFFLINE_PREB4PRE_DETAILS_SUCCESS:
            return { loading: false, offlinePreb4pre: action.payload }
        case GET_OFFLINE_PREB4PRE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}