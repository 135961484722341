import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: "16px",
        color: "#0A2B5D",
        fontWeight: 500,
    },
    background: {
        backgroundColor: "#EEF1F6",
        boxShadow: "2px 2px 6px rgba(0,0,0,0.3)",
        marginBottom: "20px",
        borderTop: "none",
        marginTop: "30px"
    },
});

function CourseDetailsAccordion({ faqs }) {

    const classes = useStyles();
    return (
        <div>
            {
                faqs && faqs.map((faq, i) =>

                    <Accordion key={i} className={classes.background}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                <strong>{faq.question}
                                </strong>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        </div>
    )
}

export default CourseDetailsAccordion
