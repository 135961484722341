import axios from "axios";

import {

    GET_ALL_AUDIOBOOKS_REQUEST,
    GET_ALL_AUDIOBOOKS_SUCCESS,
    GET_ALL_AUDIOBOOKS_FAIL,
    GET_AUDIOBOOK_BY_ID_REQUEST,
    GET_AUDIOBOOK_BY_ID_SUCCESS,

    POST_ENROLL_AUDIOBOOK_REQUEST,
    POST_ENROLL_AUDIOBOOK_SUCCESS,
    POST_ENROLL_AUDIOBOOK_FAIL,
    GET_AUDIOBOOK_BY_ID_FAIL,
    GET_AUDIOBOOK_PAYMENT_HISTORY_REQUEST,
    GET_AUDIOBOOK_PAYMENT_HISTORY_SUCCESS,
    GET_AUDIOBOOK_PAYMENT_HISTORY_FAIL,
    POST_AUDIOBOOK_COUPON_VALIDATION_REQUEST,
    POST_AUDIOBOOK_COUPON_VALIDATION_SUCCESS,
    POST_AUDIOBOOK_COUPON_VALIDATION_FAIL
    // GET_USER_AUDIOBOOKS_REQUEST,
    // GET_USER_AUDIOBOOKS_SUCCESS,
    // GET_USER_AUDIOBOOKS_FAIL,
    // PUT_LIKED_AUDIO_REQUEST,
    // PUT_LIKED_AUDIO_SUCCESS,
    // PUT_LIKED_AUDIO_FAIL,
    // PUT_COMPLETED_AUDIO_REQUEST,
    // PUT_COMPLETED_AUDIO_SUCCESS,
    // PUT_COMPLETED_AUDIO_FAIL,
    // PUT_FAVORITE_AUDIOBOOK_REQUEST,
    // PUT_FAVORITE_AUDIOBOOK_SUCCESS,
    // PUT_FAVORITE_AUDIOBOOK_FAIL,
    // GET_FAVOURITE_AUDIOBOOKS_REQUEST,
    // GET_FAVOURITE_AUDIOBOOKS_SUCCESS,
    // GET_FAVOURITE_AUDIOBOOKS_FAIL,
    // GET_AUDIO_LIST_REQUEST,
    // GET_AUDIO_LIST_SUCCESS,
    // GET_AUDIO_LIST_FAIL

} from '../constants/audiobookConstants'

import { BASEURL } from '../config/config';


export const getAllAudiobooks = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ALL_AUDIOBOOKS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }

        const { data } = await axios.get(`${BASEURL}/admin/content/audiobook`, config)
        dispatch({
            type: GET_ALL_AUDIOBOOKS_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ALL_AUDIOBOOKS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getAudiobookById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_AUDIOBOOK_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }

        const { data } = await axios.get(`${BASEURL}/admin/content/audiobook/${id}`, config)
        dispatch({
            type: GET_AUDIOBOOK_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_AUDIOBOOK_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const postEnrollAudioBook = (id, pid, sig, oid, coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_AUDIOBOOK_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/Audiobooks/${id}`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            coupon: coupon
        }, config)

        dispatch({
            type: POST_ENROLL_AUDIOBOOK_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_ENROLL_AUDIOBOOK_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAudiobookPaymentHistory = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_AUDIOBOOK_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/audiobooks/history`, config)
        dispatch({
            type: GET_AUDIOBOOK_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_AUDIOBOOK_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postCheckABValidationCoupon = (code) => async (dispatch) => {
    try {

        dispatch({
            type: POST_AUDIOBOOK_COUPON_VALIDATION_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.post(`${BASEURL}/user/audiobooks/val/coupon`,{code}, config)
        dispatch({
            type: POST_AUDIOBOOK_COUPON_VALIDATION_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: POST_AUDIOBOOK_COUPON_VALIDATION_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}