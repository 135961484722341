import {
    GET_PUBLISHED_PREB4PRE_REQUEST,
    GET_PUBLISHED_PREB4PRE_SUCCESS,
    GET_PUBLISHED_PREB4PRE_FAIL,
    GET_ENROLLED_PREB4PRE_REQUEST,
    GET_ENROLLED_PREB4PRE_SUCCESS,
    GET_ENROLLED_PREB4PRE_FAIL,
    GET_PREB4PRE_PAYMENT_DETAILS_REQUEST,
    GET_PREB4PRE_PAYMENT_DETAILS_SUCCESS,
    GET_PREB4PRE_PAYMENT_DETAILS_FAIL,
    GET_PREB4PRE_QUESTION_PAPER_REQUEST,
    GET_PREB4PRE_QUESTION_PAPER_SUCCESS,
    GET_PREB4PRE_QUESTION_PAPER_FAIL,
    GET_PREB4PRE_TEST_SERIES_REQUEST,
    GET_PREB4PRE_TEST_SERIES_SUCCESS,
    GET_PREB4PRE_TEST_SERIES_FAIL,
    GET_PREB4PRE_ANSWER_SHEET_REQUEST,
    GET_PREB4PRE_ANSWER_SHEET_SUCCESS,
    GET_PREB4PRE_ANSWER_SHEET_FAIL,
    GET_PREB4PRE_ANSWERSHEET_BY_ID_REQUEST,
    GET_PREB4PRE_ANSWERSHEET_BY_ID_SUCCESS,
    GET_PREB4PRE_ANSWERSHEET_BY_ID_FAIL,
    GET_PREB4PRE_TEST_ANALYTICS_REQUEST,
    GET_PREB4PRE_TEST_ANALYTICS_SUCCESS,
    GET_PREB4PRE_TEST_ANALYTICS_FAIL,
    POST_PREB4PRE_SUBMIT_ANSWERS_REQUEST,
    POST_PREB4PRE_SUBMIT_ANSWERS_SUCCESS,
    POST_PREB4PRE_SUBMIT_ANSWERS_FAIL,
    POST_ENROLL_PREB4PRE_FAIL,
    POST_ENROLL_PREB4PRE_REQUEST,
    POST_ENROLL_PREB4PRE_SUCCESS,
    POST_PREB4PRE_COUPON_VALIDATION_REQUEST,
    POST_PREB4PRE_COUPON_VALIDATION_SUCCESS,
    POST_PREB4PRE_COUPON_VALIDATION_FAIL,
    GET_PREB4PRE_PAYMENT_HISTORY_REQUEST,
    GET_PREB4PRE_PAYMENT_HISTORY_SUCCESS,
    GET_PREB4PRE_PAYMENT_HISTORY_FAIL,
    GET_PREB4PRE_QUESTION_PAPER_SLOTID_REQUEST,
    GET_PREB4PRE_QUESTION_PAPER_SLOTID_SUCCESS,
    GET_PREB4PRE_QUESTION_PAPER_SLOTID_FAIL
} from "../constants/preB4PreTestConstant"

export const getPublishedPreb4preReducer = (state = { preb4preTests: [] }, action) => {
    switch (action.type) {
        case GET_PUBLISHED_PREB4PRE_REQUEST:
            return { loading: true, preb4preTests: [] }
        case GET_PUBLISHED_PREB4PRE_SUCCESS:
            return { loading: false, preb4preTests: action.payload }
        case GET_PUBLISHED_PREB4PRE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getEnrolledPreb4preReducer = (state = { preb4preTests: [] }, action) => {
    switch (action.type) {
        case GET_ENROLLED_PREB4PRE_REQUEST:
            return { loading: true, preb4preTests: [] }
        case GET_ENROLLED_PREB4PRE_SUCCESS:
            return { loading: false, preb4preTests: action.payload }
        case GET_ENROLLED_PREB4PRE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4prePaymentDetailsReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_PAYMENT_DETAILS_REQUEST:
            return { loading: true, ...state }
        case GET_PREB4PRE_PAYMENT_DETAILS_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_PREB4PRE_PAYMENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4preQuestionPaperReducer = (state = { paper: [] }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_QUESTION_PAPER_REQUEST:
            return { loading: true, paper: [] }
        case GET_PREB4PRE_QUESTION_PAPER_SUCCESS:
            return { loading: false, paper: action.payload }
        case GET_PREB4PRE_QUESTION_PAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4preTestSeriesReducer = (state = { }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_TEST_SERIES_REQUEST:
            return { loading: true }
        case GET_PREB4PRE_TEST_SERIES_SUCCESS:
            return { loading: false, preb4preTest: action.payload }
        case GET_PREB4PRE_TEST_SERIES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4preAnswerSheetReducer = (state = { answerSheet: {} }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_ANSWER_SHEET_REQUEST:
            return { loading: true, answerSheet: {} }
        case GET_PREB4PRE_ANSWER_SHEET_SUCCESS:
            return { loading: false, answerSheet: action.payload }
        case GET_PREB4PRE_ANSWER_SHEET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4preAnswerSheetByIdReducer = (state = { answerSheet: {} }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_ANSWERSHEET_BY_ID_REQUEST:
            return { loading: true, answerSheet: {} }
        case GET_PREB4PRE_ANSWERSHEET_BY_ID_SUCCESS:
            return { loading: false, answerSheet: action.payload }
        case GET_PREB4PRE_ANSWERSHEET_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4preTestAnalyticsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PREB4PRE_TEST_ANALYTICS_REQUEST:
            return { loading: true }
        case GET_PREB4PRE_TEST_ANALYTICS_SUCCESS:
            return { loading: false, analytics: action.payload }
        case GET_PREB4PRE_TEST_ANALYTICS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postPreb4preSubmitAnswersReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_PREB4PRE_SUBMIT_ANSWERS_REQUEST:
            return { loading: true }
        case POST_PREB4PRE_SUBMIT_ANSWERS_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_PREB4PRE_SUBMIT_ANSWERS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollPreb4preReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_PREB4PRE_REQUEST:
            return { loading: true }
        case POST_ENROLL_PREB4PRE_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_PREB4PRE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postPreb4preCouponReducer = (state = { couponDetails:{}}, action) => {
    switch (action.type) {
        case POST_PREB4PRE_COUPON_VALIDATION_REQUEST:
            return { loading: true , couponDetails:{}}
        case POST_PREB4PRE_COUPON_VALIDATION_SUCCESS:
            return { loading: false, couponDetails: action.payload }
        case POST_PREB4PRE_COUPON_VALIDATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreB4prePaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_PREB4PRE_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_PREB4PRE_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPreb4preQuestionPaperBySlotIdReducer = (state = { paper: [] }, action) => {
    switch (action.type) {
        case GET_PREB4PRE_QUESTION_PAPER_SLOTID_REQUEST:
            return { loading: true, paper: [] }
        case GET_PREB4PRE_QUESTION_PAPER_SLOTID_SUCCESS:
            return { loading: false, paper: action.payload }
        case GET_PREB4PRE_QUESTION_PAPER_SLOTID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}