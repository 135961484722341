import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import "./Auth/modal.scss"


function ProcessingModal() {
    return (
        <div className="modal-custom">
            <div className="content">
                <div className="header end-flex justify-content-center">
                    <p>Processing...</p>
                </div>
                <div className="body m-4 d-flex justify-content-center">
                    <Loader
                        type="Oval"
                        color="#002A5D"
                        height={100}
                        width={100}
                    />
                </div>

            </div>

        </div>


    )
}

export default ProcessingModal
