import axios from "axios";
import {
    GET_ASK_TO_EVALUATE_FAIL,
    GET_ASK_TO_EVALUATE_REQUEST,
    GET_ASK_TO_EVALUATE_SUCCESS,
    GET_ASK_TO_EVALUATE_BY_ID_REQUEST,
    GET_ASK_TO_EVALUATE_BY_ID_SUCCESS,
    GET_ASK_TO_EVALUATE_BY_ID_FAIL,
    POST_ASKTOEVALUATE_COUPON_VALIDATION_REQUEST,
    POST_ASKTOEVALUATE_COUPON_VALIDATION_SUCCESS,
    POST_ASKTOEVALUATE_COUPON_VALIDATION_FAIL,
    POST_ENROLL_ASKTOEVALUATE_REQUEST,
    POST_ENROLL_ASKTOEVALUATE_SUCCESS,
    POST_ENROLL_ASKTOEVALUATE_FAIL,
    GET_ASKTOEVALUATE_PAYMENT_HISTORY_REQUEST,
    GET_ASKTOEVALUATE_PAYMENT_HISTORY_SUCCESS,
    GET_ASKTOEVALUATE_PAYMENT_HISTORY_FAIL,
    GET_ENROLL_ASKTOEVALUATE_REQUEST,
    GET_ENROLL_ASKTOEVALUATE_SUCCESS,
    GET_ENROLL_ASKTOEVALUATE_FAIL,
    GET_ASKTOEVALUATE_BY_ID_REQUEST,
    GET_ASKTOEVALUATE_BY_ID_SUCCESS,
    GET_ASKTOEVALUATE_BY_ID_FAIL,
    GET_ATE_QUESTION_PAPER_REQUEST,
    GET_ATE_QUESTION_PAPER_SUCCESS,
    GET_ATE_QUESTION_PAPER_FAIL,
    POST_SUBMIT_ASK_TO_EVALUATE_SUCCESS,
    POST_SUBMIT_ASK_TO_EVALUATE_FAIL,
    POST_SUBMIT_ASK_TO_EVALUATE_REQUEST,
    GET_SUBMISSIONS_ASK_TO_EVALUATE_REQUEST,
    GET_SUBMISSIONS_ASK_TO_EVALUATE_SUCCESS,
    GET_SUBMISSIONS_ASK_TO_EVALUATE_FAIL,
    GET_ANALYTICS_ASK_TO_EVALUATE_REQUEST,
    GET_ANALYTICS_ASK_TO_EVALUATE_SUCCESS,
    GET_ANALYTICS_ASK_TO_EVALUATE_FAIL
} from '../constants/askToEvaluateContants';
import { BASEURL } from '../config/config';

export const getAllAskToEvaluate = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ASK_TO_EVALUATE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }
        const { data } = await axios.get(`${BASEURL}/admin/ask-to-evaluate`, config)
        dispatch({
            type: GET_ASK_TO_EVALUATE_SUCCESS,
            payload: data[0].data
        })
    }catch(error){
        dispatch({
            type: GET_ASK_TO_EVALUATE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAskToEvaluateById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ASK_TO_EVALUATE_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }

        const { data } = await axios.get(`${BASEURL}/admin/ask-to-evaluate/${id}`, config)
        dispatch({
            type: GET_ASK_TO_EVALUATE_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ASK_TO_EVALUATE_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postCheckValidationCoupon = (code) => async (dispatch) => {
    try {

        dispatch({
            type: POST_ASKTOEVALUATE_COUPON_VALIDATION_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.post(`${BASEURL}/user/asktoevaluate/val/coupon`,{code}, config)
        dispatch({
            type: POST_ASKTOEVALUATE_COUPON_VALIDATION_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: POST_ASKTOEVALUATE_COUPON_VALIDATION_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postEnrollAskToEvaluate = (id, pid, sig, oid, coupon, slotId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_ASKTOEVALUATE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/ask-to-evaluate/${id}`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            coupon: coupon,
            slotID: slotId
        }, config)
        dispatch({
            type: POST_ENROLL_ASKTOEVALUATE_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_ENROLL_ASKTOEVALUATE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAskToEvaluatePaymentHistory = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ASKTOEVALUATE_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/ask-to-evaluate/history`, config)

        dispatch({
            type: GET_ASKTOEVALUATE_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ASKTOEVALUATE_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getEnrollAskToEvaluate = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ENROLL_ASKTOEVALUATE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/ask-to-evaluate`, config)

        dispatch({
            type: GET_ENROLL_ASKTOEVALUATE_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ENROLL_ASKTOEVALUATE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAskToEvaluateByID = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ASKTOEVALUATE_BY_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/ask-to-evaluate/${id}`, config)

        dispatch({
            type: GET_ASKTOEVALUATE_BY_ID_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ASKTOEVALUATE_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAskToEvaluateQPaper = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ATE_QUESTION_PAPER_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/ask-to-evaluate/paper/${id}`, config)
        dispatch({
            type: GET_ATE_QUESTION_PAPER_SUCCESS,
            payload: data[0].data.desQuestions
        })

    } catch (error) {
        dispatch({
            type: GET_ATE_QUESTION_PAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postSubmitAskToEvaluate = (id, answers) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SUBMIT_ASK_TO_EVALUATE_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/ask-to-evaluate/submit/${id}`, { desAnswers:answers }, config)
        dispatch({
            type: POST_SUBMIT_ASK_TO_EVALUATE_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_SUBMIT_ASK_TO_EVALUATE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getSubmissionsAskToEvaluate = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SUBMISSIONS_ASK_TO_EVALUATE_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/ask-to-evaluate/submission/${id}`, config)
        dispatch({
            type: GET_SUBMISSIONS_ASK_TO_EVALUATE_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_SUBMISSIONS_ASK_TO_EVALUATE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAnalyticsAskToEvaluate = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ANALYTICS_ASK_TO_EVALUATE_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/ask-to-evaluate/analytics/${id}`, config)
        dispatch({
            type: GET_ANALYTICS_ASK_TO_EVALUATE_SUCCESS,
            payload: data.data[0]
        })

    } catch (error) {
        dispatch({
            type: GET_ANALYTICS_ASK_TO_EVALUATE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}
