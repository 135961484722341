import React, { useEffect } from 'react'
import Sidebar from '../../Components/main/sidebar/sidebar'
import './IssuesThisWeek.scss'
import leftArrow from '../../assets/icons/back-arrow.svg'
import { getEnrolledItw } from '../../actions/IssuesThisWeekAction'
import { useDispatch, useSelector } from 'react-redux';
import noImage from '../../assets/images/otp.png';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { BASEURL } from '../../config/config';
// import { logout } from '../../actions/userActions';

const ListITW = ({history}) => {
    const dispatch = useDispatch();

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getEnrollITW = useSelector(state => state.getEnrollITW)
    const { loading, itw } = getEnrollITW;

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(
    //         error === message 
    //     ){
    //         dispatch(logout());
    //     }
    // },[dispatch, error ])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getEnrolledItw());
        }
    }, [userInfo, dispatch])

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    const RedirectToITW = async(id) =>{
        try{ 
            const config = {
                'Content-Type': 'application/json',
                headers: { Authorization: userInfo.authToken },
            }
            const {data} = await axios.get(`${BASEURL}/user/itw/analytics/${id}`, config)
            if(data){
                history.push(`/myITW/analytics/${id}`);
            }
        }catch(err){
            if(err){
                history.push(`/itw-mcq/instructions/${id}`)
            }
        }   
    }

    return (
        <div>
            <Sidebar activeLink={'more'} />
            <div className='main' style={{color:'#edf3ff'}}>
                <div className='py-3 px-5'>
                    <div>
                        <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                            <img src={leftArrow} alt="<" style={{ margin: 10 }} />
                        </button>
                        <span>
                            <h3 style={{color:'rgb(1, 42, 93)', display:'inline-block', textAlign:'center', paddingLeft:'15px'}}>
                                Issues This Week
                            </h3>
                        </span>

                    </div>
                </div>
                {(loading || !itw || itw.length === 0) ?
                    <div>
                        <div className='content-notAvail' style={{height:'80vh'}}>
                            <img src={noImage} alt="" />
                            <h3 style={{color:'rgb(1, 42, 93)'}}>ITM Not Available</h3>
                        </div>
                    </div>
                :  
                    <div className='py-4 px-5 itw' style={{minHeight:'75vh'}}>
                        <Row className="testSeries py-2 px-5">
                            {
                                itw.map((element, i) => {
                                    return (
                                        <Col lg={5} xs={12} 
                                            className="testSeries-card my-2" 
                                            key={element.id} 
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e)=>{e.preventDefault(); RedirectToITW(element.ITW.id)}}
                                        >   
                                            <div className='image'>
                                                <img src={element.ITW.image} alt="" />
                                            </div>
                                            <div className='text'>
                                                <p>{element.ITW.TestSeriesName}</p>
                                                <p>Type : {element.ITW.TestSeriesType ? element.ITW.TestSeriesType : 'objective'}</p>
                                                <p>Duration : {minToHours(element.ITW.Duration)}</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    
                }
            </div>
        </div>
    )
}

export default ListITW