import React from 'react'
import AssignmentCards from './AssignmentCards'; 
import './Assignments.scss'

const Assignments = ({data, history, eid, tid}) => {
    return (
        <div className='assignments-container'>
            {data.tests.map((assignment)=>(
                <AssignmentCards data={assignment} history={history} key={assignment.id} eid={eid} tid={tid} />
            ))}
        </div>
    )
}

export default Assignments