import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main/sidebar/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import SearchItem from '../../Components/Search/SearchItem';
import { Link } from 'react-router-dom';

// import AlertDismissibleExample from '../../Components/Error/error';
import noImage from '../../assets/images/otp.png';
import { Event, Schedule } from '@material-ui/icons';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import defaultImage from "../../assets/images/Preb4pre_Exam.svg"
import PreB4Pre from '../../assets/images/preb4pre.png'
import "./MyPreb4preTests.scss"
import { getEnrolledPreb4pre } from '../../actions/preb4preTestAction';
import Heading from '../../Components/Heading/Heading';
// import SubHeading from '../../Components/Heading/SubHeading';
import Slider from 'react-slick';
import assignment1 from '../../assets/icons/assignment1.svg';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';
import { Row, Col } from 'react-bootstrap'

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    centerPadding: '60px',
};


function MyPreb4preTests({history}) {

    const dispatch = useDispatch()
    const [searchResults, setSearchResults] = useState([]);

    const [active, setActive] = useState([])
    const [upcoming, setUpcoming] = useState([])
    const [attempted, setAttempted] = useState([])
    const [tests, setTests] = useState([])

    const getEnrolledPreb4preTests = useSelector(state => state.getEnrolledPreb4preTest)
    const { loading, error, preb4preTests } = getEnrolledPreb4preTests

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getEnrolledPreb4pre())

    }, [userInfo, dispatch])


    useEffect(() => {
        if (!error) {
            var tests = attempted.map(item => ({
                ...item,
                name: item.Test.TestSeriesName
            }))


            setTests(tests)
            setSearchResults(tests)
        }

    }, [attempted, error])


    useEffect(() => {
        if(preb4preTests && preb4preTests.length > 0){
            let active = preb4preTests.filter(prop => (prop.Test.isExpired === false && prop.Test.isTestTime === true));
            let upcoming = preb4preTests.filter(prop => (prop.Test.isExpired === false && prop.Test.isTestTime === false));
            let attempted = preb4preTests.filter(prop => prop.Test.isExpired === true)
            setActive(active)
            setUpcoming(upcoming)
            setAttempted(attempted)
            setSearchResults(attempted)
        }
    }, [preb4preTests])

    const colors = [
        { bg: '#590196' },
        { bg: '#9f8400' },
        { bg: '#007947' },
        { bg: '#5b2e3d' }
    ]


    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const stringTime = (examDate) => {
        const event = new Date(examDate);
        const options = { hour: '2-digit', minute: '2-digit' , timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    const getDate = (time) =>{
        return new Date(time).getTime()
    }

    const sortPaper = (slots) =>{
        let sortedSlots = slots
        sortedSlots.sort((a,b)=>{
            if(getDate(a.startingTime) < getDate(b.startingTime)){
                return -1;
            }else if(getDate(a.startingTime) > getDate(b.startingTime)){
                return 1;
            }else{
                return 0;
            }
        })
        return sortedSlots
    }



    return (
        <div>
            <Sidebar activeLink={'preB4Pre'} />
            <main className='main preb4pretests'>
                <section className="d-flex flex-column align-items-center">
                    <img src={PreB4Pre} className='IMG' alt="" width="200" />
                </section>
                {(active.length === 0 && upcoming.length === 0 && attempted.length === 0) && 
                    <div className="no-live-tests">
                        <img src={noImage} alt="" />
                        <h3>Pre-B4-Pre Not Found</h3>
                    </div> 
                }
                <div className='ongoing-tests px-5 py-2'>
                    {
                        loading ? <MyTestseriesLoading /> : active.length === 0 ? <></> : active.length !== 0 && (
                            <div>
                                <Heading Text={'Ongoing'} />
                                <Slider {...settings} className='ongoing-test-list' >
                                    {
                                        active.map((test, i) => { 
                                            return (
                                                <Link key={i} to={`/testseries/preb4pre/${test.Test.id}`} style={{ textDecoration: 'none' }} >
                                                    <Row className={`active-test-card ${i % 2 === 0 ? 'darkYellow' : 'yellow'}`}>
                                                        <Col lg={5} md={12}  style={{minHeight:'250px'}}>
                                                            <img src={defaultImage} alt="pre-b4-pre" />
                                                            <strong className='my-5'> 
                                                                <p style={{ textAlign: "center", fontSize: "30px", marginTop:'20px' }} title={test.Test.TestSeriesName}>
                                                                    {test.Test.TestSeriesName.substring(0,15)}
                                                                    {test.Test.TestSeriesName.length > 15 && '...'} 
                                                                </p>
                                                            </strong>
                                                        </Col >
                                                        <Col lg={5} md={12} className='p-3' >  
                                                            <Slider {...settings}>
                                                                {sortPaper(test.Test.slot).map((slots, i)=>(
                                                                    (slots.isExpired === false && slots.isTestTime === true )  &&
                                                                    <div className='slots' key={i}>
                                                                        <div>
                                                                            <h4>Paper <span>{i+1}</span></h4>
                                                                            <p><Event/> Exam On: <span>{stringDate(slots.startingTime)}</span></p>
                                                                            <p><Schedule/> Starting At: <span>{test.Test && stringTime(slots.startingTime)}</span></p>
                                                                            <p><HourglassTopIcon/> Duration: <span>{minToHours(slots.duration)}</span></p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        )
                    }
                </div>
                <div className='upcoming-tests py-2' >
                    {
                        upcoming.length === 0 ? <></> : (upcoming.length !== 0) &&
                        <div>
                            <Heading Text={'Upcoming'} />
                            <Slider adaptiveHeight={true} {...settings} className='upcoming-test-list' >
                                {
                                    upcoming.map((test, i) => {
                                        return (
                                            <Link key={i} to={`/testseries/preb4pre/${test.Test.id}`}>
                                                <Row key={i} className={`test-card  ${i % 2 === 0 ? 'darkYellow' : 'yellow'}`} >
                                                    <Col lg={6} md={12} style={{minHeight:'250px'}}>
                                                        <img src={defaultImage} alt='' style={{ marginTop:'20px'}} />
                                                        <strong className='my-5'>
                                                            <p style={{ textAlign: "center", fontSize: "30px", marginTop:'20px' }} title={test.Test.TestSeriesName}>
                                                                {test.Test.TestSeriesName.substring(0,15)}
                                                                {test.Test.TestSeriesName.length > 15 && '...'} 
                                                            </p>
                                                        </strong> 
                                                    </Col>
                                                    <Col lg={5} md={12} className='p-3' >  
                                                        <Slider {...settings}>
                                                            {sortPaper(test.Test.slot).map((slots, i)=>(
                                                                <div className='slots' key={i}>
                                                                    <div>
                                                                        <h4>Paper <span>{i+1}</span></h4>
                                                                        <p><Event/> Exam On: <span>{stringDate(slots.startingTime)}</span></p>
                                                                        <p><Schedule/> Starting At: <span>{test.Test && stringTime(slots.startingTime)}</span></p>
                                                                        <p><HourglassTopIcon/> Duration: <span>{minToHours(slots.duration)}</span></p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Link>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    }
                </div>
                <div className='attempted-test'>
                    {
                        attempted.length === 0 ? <>

                        </> : attempted.length !== 0 &&
                        <div>
                            <div className='px-5'>
                                <Heading Text={'Expired'} />
                            </div>
                            {attempted && attempted.length !== 0 &&
                                <SearchItem
                                    allItems={tests}
                                    from={"Pre-B4-Pre"}
                                    handleSetSearchResults={(results) => setSearchResults(results)}
                                />
                            }
                            <div>
                                {
                                    searchResults.length === 0 ?
                                    <div className="no-live-tests">
                                        <img src={noImage} alt="" />
                                        <h3>No TestSeries Found</h3>
                                    </div> :
                                    <Row className="testSeries py-2 px-5">
                                        {
                                            searchResults.map((element, i) => {
                                                return (
                                                    <Col lg={5} xs={12} 
                                                        className="testSeries-card my-2" 
                                                        key={element.id} 
                                                        style={{ backgroundColor: `${colors[i < colors.length ? i : i % colors.length].bg}`, cursor: 'pointer' }}
                                                        onClick={(e)=>{e.preventDefault(); history.push(`/testseries/preb4pre/${element.Test.id}`)}}
                                                    >
                                                        <img src={assignment1} alt="assignment" />
                                                        <div>
                                                            <p style={{ fontSize: 20, fontWeight: '500' }} title={element.Test.TestSeriesName}>
                                                                {element.Test.TestSeriesName.substring(0,18)}
                                                                {element.Test.TestSeriesName.length > 18 && '...'}
                                                            </p>
                                                            <p style={{ fontSize: 14, fontWeight:'500' }} title={element.Test.subHeading}>
                                                                {element.Test.subHeading && element.Test.subHeading.substring(0,15)}
                                                                {element.Test.subHeading && element.Test.subHeading.length > 18 && '...'}
                                                            </p>
                                                            <p style={{ fontSize: 14, opacity: 0.7 }}>Type : {element.Test.TestSeriesType ? element.Test.TestSeriesType : 'objective'}</p>
                                                            <p style={{ fontSize: 14, opacity: 0.7 }}>Exam Date : {stringDate(element.Test.slot[0].startingTime)}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                }
                            </div>
                        </div>
                    }
                </div>
            </main>

        </div>
    )
}

export default MyPreb4preTests
