import axios from "axios";
import { BASEURL } from "../config/config";
import {
    GET_PUBLISHED_PREB4PRE_REQUEST,
    GET_PUBLISHED_PREB4PRE_SUCCESS,
    GET_PUBLISHED_PREB4PRE_FAIL,
    GET_ENROLLED_PREB4PRE_REQUEST,
    GET_ENROLLED_PREB4PRE_SUCCESS,
    GET_ENROLLED_PREB4PRE_FAIL,
    GET_PREB4PRE_PAYMENT_DETAILS_REQUEST,
    GET_PREB4PRE_PAYMENT_DETAILS_SUCCESS,
    GET_PREB4PRE_PAYMENT_DETAILS_FAIL,
    GET_PREB4PRE_QUESTION_PAPER_REQUEST,
    GET_PREB4PRE_QUESTION_PAPER_SUCCESS,
    GET_PREB4PRE_QUESTION_PAPER_FAIL,
    GET_PREB4PRE_TEST_SERIES_REQUEST,
    GET_PREB4PRE_TEST_SERIES_SUCCESS,
    GET_PREB4PRE_TEST_SERIES_FAIL,
    GET_PREB4PRE_ANSWER_SHEET_REQUEST,
    GET_PREB4PRE_ANSWER_SHEET_SUCCESS,
    GET_PREB4PRE_ANSWER_SHEET_FAIL,
    GET_PREB4PRE_ANSWERSHEET_BY_ID_REQUEST,
    GET_PREB4PRE_ANSWERSHEET_BY_ID_SUCCESS,
    GET_PREB4PRE_ANSWERSHEET_BY_ID_FAIL,
    GET_PREB4PRE_TEST_ANALYTICS_REQUEST,
    GET_PREB4PRE_TEST_ANALYTICS_SUCCESS,
    GET_PREB4PRE_TEST_ANALYTICS_FAIL,
    POST_PREB4PRE_SUBMIT_ANSWERS_REQUEST,
    POST_PREB4PRE_SUBMIT_ANSWERS_SUCCESS,
    POST_PREB4PRE_SUBMIT_ANSWERS_FAIL,
    POST_ENROLL_PREB4PRE_REQUEST,
    POST_ENROLL_PREB4PRE_SUCCESS,
    POST_ENROLL_PREB4PRE_FAIL,
    POST_PREB4PRE_COUPON_VALIDATION_REQUEST,
    POST_PREB4PRE_COUPON_VALIDATION_SUCCESS,
    POST_PREB4PRE_COUPON_VALIDATION_FAIL,
    GET_PREB4PRE_PAYMENT_HISTORY_REQUEST,
    GET_PREB4PRE_PAYMENT_HISTORY_SUCCESS,
    GET_PREB4PRE_PAYMENT_HISTORY_FAIL,
    GET_PREB4PRE_QUESTION_PAPER_SLOTID_REQUEST,
    GET_PREB4PRE_QUESTION_PAPER_SLOTID_SUCCESS,
    GET_PREB4PRE_QUESTION_PAPER_SLOTID_FAIL
} from "../constants/preB4PreTestConstant";

export const getPublishedPreb4preTest = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PUBLISHED_PREB4PRE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }

        const { data } = await axios.get(`${BASEURL}/admin/preb4pre/testseries`, config)
        dispatch({
            type: GET_PUBLISHED_PREB4PRE_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PUBLISHED_PREB4PRE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getEnrolledPreb4pre = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ENROLLED_PREB4PRE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries`, config)
        dispatch({
            type: GET_ENROLLED_PREB4PRE_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ENROLLED_PREB4PRE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getPreb4prePaymentDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_PAYMENT_DETAILS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries/history`, config)
        dispatch({
            type: GET_PREB4PRE_PAYMENT_DETAILS_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_PAYMENT_DETAILS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4preQuestionPaper = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_QUESTION_PAPER_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries/question/${id}`, config)
        dispatch({
            type: GET_PREB4PRE_QUESTION_PAPER_SUCCESS,
            payload: data[0].data.objQuestions
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_QUESTION_PAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4preTestSeries = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_TEST_SERIES_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries/${id}`, config)
        dispatch({
            type: GET_PREB4PRE_TEST_SERIES_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_TEST_SERIES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4preUserAnswerSheet = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_ANSWER_SHEET_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries/answersheet/${id}`, config)
        dispatch({
            type: GET_PREB4PRE_ANSWER_SHEET_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_ANSWER_SHEET_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4preAnswerSheetById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_ANSWERSHEET_BY_ID_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries/answersheet/${id}`, config)
        dispatch({
            type: GET_PREB4PRE_ANSWERSHEET_BY_ID_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_ANSWERSHEET_BY_ID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4preTestAnalytics = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_TEST_ANALYTICS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries/analytics/${id}`, config)
        dispatch({
            type: GET_PREB4PRE_TEST_ANALYTICS_SUCCESS,
            payload: data.data
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_TEST_ANALYTICS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postPreb4preSubmitAnswers = (id, answers, paperNumber) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_PREB4PRE_SUBMIT_ANSWERS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/preb4pre/testseries/submit/${id}`, { answers, paperNumber }, config)
        dispatch({
            type: POST_PREB4PRE_SUBMIT_ANSWERS_SUCCESS,
            payload: data[0].message
        })
    } catch (error) {
        dispatch({
            type: POST_PREB4PRE_SUBMIT_ANSWERS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postEnrollPreb4pre = (id, pid, sig, oid, coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_PREB4PRE_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/preb4pre/testseries/${id}`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            coupon: coupon
        }, config)
        dispatch({
            type: POST_ENROLL_PREB4PRE_SUCCESS,
            payload: data[0].message
        })
    } catch (error) {
        dispatch({
            type: POST_ENROLL_PREB4PRE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postCheckValidationCoupon = (code) => async (dispatch) => {
    try {

        dispatch({
            type: POST_PREB4PRE_COUPON_VALIDATION_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.post(`${BASEURL}/user/preb4pretestseries/val/coupon`,{code}, config)
        dispatch({
            type: POST_PREB4PRE_COUPON_VALIDATION_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: POST_PREB4PRE_COUPON_VALIDATION_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4prePaymentHistory = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_PREB4PRE_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries/history`, config)

        dispatch({
            type: GET_PREB4PRE_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getPreb4preQuestionPaperBySlotID = (id,slotid) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PREB4PRE_QUESTION_PAPER_SLOTID_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/preb4pre/testseries/question/${id}/slot/${slotid}`, config)
        dispatch({
            type: GET_PREB4PRE_QUESTION_PAPER_SLOTID_SUCCESS,
            payload: data[0].data.objQuestions
        })
    } catch (error) {
        dispatch({
            type: GET_PREB4PRE_QUESTION_PAPER_SLOTID_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}