import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
// import { Link } from 'react-router-dom';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import './chapters.scss'
import { getChapters, getTopics } from '../../actions/courseActions';
import Loader from '../../Components/Loader';
import 'rc-checkbox/assets/index.css';
import noImage from '../../assets/images/otp.png';
import { Equalizer } from '@mui/icons-material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import TopicsList from './TopicsList';
import SearchItem from '../../Components/Search/SearchItem';

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: "16px",
        color: "#0A2B5D",
        fontWeight: 500,
    },
    background: {
        backgroundColor: "#dfebef!important",
        boxShadow: "2px 2px 6px rgba(0,0,0,0.3)",
        marginBottom: "20px",
        borderTop: "none!important",
        marginTop: "30px",
        borderRadius: "10px!important",
        padding: "20px"
    },
});



const Chapters = ({ match,history }) => {
    const dispatch = useDispatch();
    const url = window.location.pathname;
    const subName = url.split("/")[3]
    const [expanded, setExpanded] = useState(false);
    const [searchResults, setSearchResults] = useState([])
    const [chapter , setChapter] = useState([])

    const getChaptersById = useSelector(state => state.getChaptersById)
    const { loading, error, Chapters } = getChaptersById;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const classes = useStyles();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getChapters(match.params.sid))

    }, [userInfo, dispatch, match])

    useEffect(() => {
        if (!error && Chapters.length > 0) {
            var chapter = Chapters.map(item => ({
                ...item,
                name: item.chapterName
            }))

            setChapter(chapter)
            setSearchResults(chapter)
        }

    }, [Chapters, error])


    useEffect(() => {
        if (Chapters && Chapters.length)
            if (Chapters.length > 0) {
                dispatch(getTopics(Chapters[0].id))
            }
    }, [Chapters, dispatch])





    return (
        <div>

            {loading ? <Loader /> : error ? (

                <section>
                    <div className='back-btn-section p-5'>

                        <div onClick={()=>history.push('/mycourse')} style={{ textDecoration: 'none' }}>
                            <img src={leftArrow} alt="<" className="backBtn" />
                        </div>
                        <h2>{subName.replaceAll("%20", " ")}</h2>

                    </div>
                    <div className="px-5  py-5">
                        <h1 className="mt- " style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                            Chapters
                        </h1>
                        <div className="px-2  py-5">
                            <div className='content-notAvail'>
                                <img src={noImage} alt="" />
                                <h3>No Chapters Available!</h3>
                            </div>
                        </div>
                    </div>
                </section>

            ) : (Chapters.length !== 0) && (
                <div className='chapters-list ' >
                    <section>
                        <div className='back-btn-section p-2 p-md-5'>

                            <div onClick={()=>history.push('/mycourse')} style={{ textDecoration: 'none' }}>
                                <img src={leftArrow} alt="<" className="backBtn" />
                            </div>
                            <h2>{subName.replaceAll("%20", " ")}</h2>

                        </div>
                        <div className='mt-5'>
                            <SearchItem
                                from={"Chapters"}
                                allItems={chapter}
                                handleSetSearchResults={(results) => setSearchResults(results)}
                            />
                        </div>
                        
                        <div className='chapters p-0 pb-3'>
                            {searchResults.map((chapter, index) => (
                                <Accordion expanded={expanded === chapter.id}
                                    TransitionProps={{ unmountOnExit: true }}
                                    key={index}
                                    onChange={handleChange(chapter.id)}
                                    className={classes.background}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>
                                            <strong><Equalizer /> {chapter.chapterName}
                                            </strong>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TopicsList
                                            id={chapter.id}
                                            index={index}
                                            match={match}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>


                    </section>
                </div>
            )}

        </div>
    )
}

export default Chapters
