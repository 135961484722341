import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import leftArrow from '../../assets/icons/back-arrow.svg';
import './courseDetails.scss';
import Slider from 'react-slick';
import courseImage from '../../assets/icons/course1.png';
import axios from 'axios';
import { BASEURL, RazorpayKey } from '../../config/config';
import { getCourseById, postEnrollCourses, getCourseContent, postFeedback, postCheckValidationCoupon } from '../../actions/courseActions';
import { AccessTime, CreditCard, Equalizer, Language } from '@material-ui/icons';
import CourseDetailsAccordion from '../../views/CourseDetailsAccordion';
import CourseSubjects from './CourseSubjects';
import defaultImage from "../../assets/images/overview.svg"
import ProcessingModal from '../../Components/ProcessingModal';
import SuccessfulModal from '../../Components/SuccessfulModal';
import { getUserDetails } from '../../actions/userActions';
import DOMPurify from 'dompurify';

const CourseDetailsScreen = ({ match }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const getCourseDetails = useSelector(state => state.getCourseDetails)
    const { loading, error, course } = getCourseDetails;

    const getCourseSubjectList = useSelector(state => state.getCourseContent)
    const { courseContent } = getCourseSubjectList

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { user } = getUserDetail


    const postEnrollCourse = useSelector(state => state.postEnrollCourse)
    const { loading: loadingEnroll, error: errorEnroll, message } = postEnrollCourse;

    const postRating = useSelector(state => state.postRating)
    const { loading: loadingRating, error: errorRating, message: RatingMsg } = postRating;

    const postCourseCoupon = useSelector(state => state.postCourseCoupon)
    const { loading: loadingCoupon, error: errorCoupon, couponDetails } = postCourseCoupon;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [review, setReview] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [coupon, setCoupon] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getCourseById(match.params.id))
        dispatch(getCourseContent(match.params.id))
        dispatch(getUserDetails())
    }, [userInfo, dispatch, match])

    useEffect(() => {
        if (course !== undefined && course.course !== undefined) {
            var reviews = (((course.course.oneStar * 1) +
                (course.course.twoStar * 2) +
                (course.course.threeStar * 3) +
                (course.course.fourStar * 4) +
                (course.course.fiveStar * 5))
                /
                (course.course.totalReviewers))
            setReview(reviews)


            if (course.course.timeRequire > 0) {
                var hour = (course.course.timeRequire / 60)
                var rhours = Math.floor(hour)
                setHours(rhours)
                var minute = (hour - rhours) * 60
                var rminutes = Math.round(minute)
                setMinutes(rminutes)
            }
            
            if(course.course.discountPrice || course.course.discountPrice===0 ){
                setDiscount(course.course.discountPrice)
            }
            
        }
    }, [course])

    useEffect(()=>{
        if(course && course.course && couponDetails.discountPercentage ){
            if(((course.course.price)*(couponDetails.discountPercentage / 100)) > couponDetails.maxDiscount){
                setCouponDiscount(couponDetails.maxDiscount);
                setDiscount(couponDetails.maxDiscount);
            }else{
                setCouponDiscount(((course.course.price)*(couponDetails.discountPercentage / 100)));
                setDiscount(((course.course.price)*(couponDetails.discountPercentage / 100)));
            }
        }
    },[couponDetails, course])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        swipeToSlide: false,
        centerPadding: '60px',
    };
    const colors = [
        { bg: '#9f8400' },
        { bg: '#5b2e3d' },
        { bg: '#007947' },
        { bg: '#590196' },
    ]
    const getPaidEnrolled = async (e) => {
        e.preventDefault();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        if(couponDetails.discountCode){
            let { data } = await axios.post(`${BASEURL}/user/razorpay/order/course/${match.params.id}`, {coupon}, config)
            var options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollCourses(match.params.id, response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id, couponDetails.discountCode))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }else{
            let { data } = await axios.post(`${BASEURL}/user/razorpay/order/course/${match.params.id}`, {}, config)
            options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollCourses(match.params.id, response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id, ""))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    const getEnrolled = (e) => {
        e.preventDefault();
        dispatch(postEnrollCourses(match.params.id, "", "", "", ""))
    }

    let faqs = [
        {
            question: "How are these courses going to help with my preparation?",
            answer:"These courses will give you all the resources you need for your preparation. It covers everything a typical competitive exam syllabus has. From concise studymaterial for general knowledge to previous year’s question papers and comprehensive practice for reasoning and mathematics, these courses cover everything.",
        },
        {
            question: "Is the course paid?",
            answer: "Yes, the course is paid so we can maintain the standards of this platform where our team of developers and experienced faculty collaborate and work hard so we can equip an aspirant with everything they need to clear various competitive exams."
        },
        {
            question: "What is the duration of the various courses?",
            answer: "For through preparation and in order to cover all the necessary topics the duration of the courses vary for different exams."
        },
        {
            question: "Who is the course coordinator for these courses?",
            answer: "We have a team of experienced faculties with us and everyone has their own niche which they expertise in hence every course has a different coordinator"
        }

    ];

    const reviewStar = (newRating) => {
        dispatch(postFeedback(match.params.id, newRating))
    }

    const CouponValidation = (e) =>{
        e.preventDefault();
        dispatch(postCheckValidationCoupon(coupon));
    }

    return (
        <div>
            {(loadingRating || loadingCoupon) && <Loader />}
            {errorRating && <AlertDismissibleExample data={errorRating} />}
            {RatingMsg && <AlertSuccessExample data={RatingMsg} />}
            {loadingEnroll && <ProcessingModal />}

            {message && <SuccessfulModal data={"Successfully enrolled!"} path={"/mycourse"} />}
            <main>

                {(loading) ? <Loader /> : error ? <AlertDismissibleExample data={error} /> : errorEnroll ? <AlertDismissibleExample data={errorEnroll} /> : message ? <AlertSuccessExample data={message} /> : course.course && (
                    <div className="course-overview">
                        <div>
                            <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                                <img src={leftArrow} alt="<" style={{ margin: 20 }} />
                            </button>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <h2>{course.course.name}</h2>
                            <img src={course.course.imageURL ? course.course.imageURL : defaultImage} alt="" width="200" />
                        </div>
                        <div className="course-content p-2 p-md-5">


                            <div className="course-info">
                                <h3>How does this course help you?</h3>
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(course.course.description)}}></p>
                            </div>

                            <div className="course-info">
                                <h4>Subjects</h4>
                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="topics">
                                            <CourseSubjects content={ courseContent && courseContent.length && courseContent[0].subject} />
                                        </div>

                                    </div>
                                    <div className="col-md-6 faq-section">
                                        <div className="d-flex flex-wrap justify-content-md-between justify-content-center ">
                                            <div>
                                                {
                                                    discount === 0 ? <div className="subjects-info my-3 d-flex">
                                                        <CreditCard />
                                                        <p className="mx-2">Cost: {course.course.price === 0 ? "Free" : `₹ ${course.course.price}`}</p>
                                                    </div>:<div className="subjects-info my-3 d-flex">
                                                        <CreditCard />
                                                        <p className="mx-2">Cost: <span style={{ textDecoration: "line-through" }}>₹ {course.course.price}</span> ₹ {course.course.price - discount} </p>
                                                    </div>
                                                 }
                                                <div className="subjects-info my-3 d-flex">
                                                    <AccessTime />
                                                    <p className="mx-2">
                                                        {
                                                            course && course.course.timeRequire === 0 ? "N/A" : <span>{hours > 0 ? `${hours} Hours ` : ""}{minutes > 0 ? `${minutes} Minutes` : ""}</span>
                                                        }
                                                    </p>
                                                </div>

                                                <div>
                                                    <div className="d-flex">
                                                        <ReactStars
                                                            count={5}
                                                            size={24}
                                                            activeColor="#FFA700"
                                                            onChange={reviewStar}
                                                            value={
                                                                ((
                                                                    (course.course.oneStar * 1) +
                                                                    (course.course.twoStar * 2) +
                                                                    (course.course.threeStar * 3) +
                                                                    (course.course.fourStar * 4) +
                                                                    (course.course.fiveStar * 5))
                                                                    /
                                                                    (course.course.totalReviewers)
                                                                ) ? ((
                                                                    (course.course.oneStar * 1) +
                                                                    (course.course.twoStar * 2) +
                                                                    (course.course.threeStar * 3) +
                                                                    (course.course.fourStar * 4) +
                                                                    (course.course.fiveStar * 5))
                                                                    /
                                                                    (course.course.totalReviewers)
                                                                ) : 0
                                                            }
                                                            edit={!course.isRated}
                                                            isHalf={course.isRated} />
                                                        <span className="mx-1 align-self-center"> {review ? review.toFixed(1) : "0.0"}</span>
                                                    </div>
                                                    <div className="d-md-block d-flex justify-content-center">
                                                        Total Reviewers: {course.course.totalReviewers}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="subjects-info my-3 d-flex ">
                                                    <Equalizer />
                                                    <p className="mx-2">{course.course.subjectCount} Subjects</p>

                                                </div>
                                                <div className="subjects-info my-3 d-flex">
                                                    <Language />
                                                    <p className="mx-2">Online</p>
                                                </div>
                                                {
                                                    user.verified ? (course.isEnrolled ? (
                                                        <span className="enroll-btn" style={{ cursor: 'no-drop' }} onClick={null}>Enrolled</span>
                                                    ) : (course.course.price !== 0) ? (
                                                        <span className="enroll-btn" style={{ cursor: 'pointer' }} onClick={getPaidEnrolled}>
                                                            Enroll
                                                        </span>
                                                    ) : (
                                                        <span className="enroll-btn" style={{ cursor: 'pointer' }} onClick={getEnrolled}>
                                                            Enroll
                                                        </span>
                                                    )) : <div>
                                                        <button className="no-enroll-btn" style={{ cursor: 'not-allowed' }}>
                                                            Enroll
                                                        </button>
                                                        <p style={{ color: '#ff0101', fontSize: 12 }}>
                                                            Please verify your email address to enroll in the course
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        { course.course.price !==0 && !course.isEnrolled &&  
                                            <div className='coupons'>
                                                <h2>Coupon</h2>
                                                <div>
                                                    <input type='text' value={coupon} placeholder='Enter Coupon Code' onChange={(e) => setCoupon(e.target.value)}/>
                                                    <button onClick={CouponValidation}>Apply</button>
                                                </div>
                                                {errorCoupon && <p>{errorCoupon}</p>}
                                                
                                                {(couponDetails && couponDetails.discountCode) && <p style={{color:'green'}}>Congratulations, you got a discount of ₹{couponDiscount}/-</p>}
                                            </div>
                                        }
                                        
                                        <div className="faqs">
                                            <h2>FAQs</h2>
                                            <CourseDetailsAccordion faqs={faqs} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <section className="mt-5">
                                {course.tests && course.tests.length !== 0 && (<h1 className="m-0">{course.tests.length} Assignments</h1>)}
                                <Slider {...settings}>
                                    {course.tests.map((assignment, idx) => {
                                        return (
                                            <div key={assignment.id}>
                                                <div style={{ backgroundColor: colors[(colors.length > idx) ? idx : (idx % colors.length)].bg, width: 400, height: 300 }} className="Slidecard">
                                                    <img src={courseImage} alt="assignment-img" />
                                                    <div>
                                                        <p style={{ color: "#f5ffff" }}>{assignment.testName}</p>
                                                        <p style={{ fontSize: 16, color: "#f5ffff" }}>
                                                            Duration : {assignment.duration} min
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </section>
                        </div>
                    </div>
                )}
            </main>
        </div>
    )
}

export default CourseDetailsScreen