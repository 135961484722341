import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { getAllCoursesReducer,getFoundationalAssignmentDetailsReducer, getFoundationalUserAnswerSheetReducer,getFoundationalAnalyticsAssignmentReducer,putResetFoundationalTestReducer,getFoundationalAssignmentPaperReducer,PostFoundationalAssignmentReducer ,getAllFoundationalCoursesReducer, putFoundationalRecentTopicsReducer,putFoundationalStatusReducer ,postInstallmentPaymentReducer, getCoursePaymentDetailsReducer,getCourseValidityDetailReducer,getUserEnrolledFoundationalCoursesReducer,postFoundationalRatingReducer,postEnrollFoundationalCourseReducer ,getFoundationalCourseDetailsReducer, postResetTestReducer, postCourseCouponReducer,postRatingReducer, getRecentSubjectReducer, postRecentSubjectReducer, getAnswerSheetReducer, getAnalyticsAssignmentReducer, getCourseDetailsReducer, PostAssignmentReducer, getAssignmentPaperReducer, getTestProgressReducer, getCourseTestInfoReducer, getUserEnrolledCoursesReducer, putCompletedTopicReducer, getTopicsByIdReducer, getChaptersByIdReducer, getSubjectsByIdReducer, postEnrollCourseReducer, getCourseContentReducer, getCoursePaymentHistoryReducer } from './reducers/courseReducer'
import { getAllAudiobooksReducer,postAudioBookCouponReducer ,getAudiobookDetailsReducer, getAudiobookPaymentHistoryReducer, postEnrollAudiobookReducer } from './reducers/audiobookReducers'
import { getAllLiveTestsReducer, getLiveTestDetailsReducer, postLiveCouponReducer,getLiveTestPaymentHistoryReducer, postEnrollLiveTestReducer } from './reducers/liveTestReducers'
import { getAllPracticeTestsReducer, getPracticeTestDetailsReducer, getPracticeTestPaymentHistoryReducer, postEnrollPracticeTestReducer, postPracticeCouponReducer } from './reducers/practiceTestsReducers'
import { userLoginReducer, forgotPasswordReducer, userAuthorizationReducer ,userRegisterReducer, getUserDetailReducer, googleLoginReducer, putUserDetailsReducer, postUserDobReducer, putUserPictureReducer, postSendMobileOtpReducer, postSendEmailOtpReducer, postMobileVerificationReducer, postEmailVerificationReducer, postResetPasswordReducer } from './reducers/userReducers';
import { bookCounsellingReducer } from './reducers/counsellingReducers';
import { getAllPracticeTestReducer, getAllLiveTestReducer, getPracticePaperReducer, PostLiveTestReducer, getLivePaperReducer, getLiveAnalyticsReducer, getLiveTestByIdReducer, PostPracticeTestReducer, getPracticeAnalyticsReducer, getPracticeAnalyticsPaperReducer, getPracticeAnswerSheetReducer } from './reducers/EnrolledPracticeTestSeries';
import { getEnrolledPreb4preReducer,getPreB4prePaymentHistoryReducer , getPreb4preAnswerSheetByIdReducer, getPreb4preAnswerSheetReducer, getPreb4prePaymentDetailsReducer, getPreb4preQuestionPaperReducer,getPreb4preQuestionPaperBySlotIdReducer, getPreb4preTestAnalyticsReducer, getPreb4preTestSeriesReducer, getPublishedPreb4preReducer, postEnrollPreb4preReducer, postPreb4preSubmitAnswersReducer, postPreb4preCouponReducer } from './reducers/preb4preTestReducers'
import { getITWByIdReducer, getITWPaperReducer, PostITWExamReducer, getEnrollITWReducer, getItwAnswerSheetByIdReducer, getItwAnalyticsReducer } from './reducers/IssuesThisWeekReducers';
import { getPublishedOfflinePreb4preReducer, getOfflinePreb4preTestSeriesReducer, postEnrollOfflinePreB4preReducer, getEnrolledOfflinePreb4preReducer, getOnlinePreB4prePaymentHistoryReducer } from './reducers/offlinePreb4preReducers';
import { GetAllAskToEvaluateReducer, getAskToEvaluateDetailsReducer, postAskToEvaluateCouponReducer, postEnrollATEReducer, getEnrollATEReducer, getAskToEvaluatePaymentHistoryReducer, getATEDetailsReducer, getATEPaperReducer, PostAskToEvaluateReducer, getATEAnalyticsReducer, getATESubmissionsReducer } from './reducers/askToEvaluateReducers';

const reducer = combineReducers({
    getAllCourses: getAllCoursesReducer,
    userLogin: userLoginReducer,
    forgotPassword: forgotPasswordReducer,
    userRegister: userRegisterReducer,
    googleLogin: googleLoginReducer,
    bookCounselling: bookCounsellingReducer,
    getCourseDetails: getCourseDetailsReducer,
    getUserEnrolledCourses: getUserEnrolledCoursesReducer,
    postEnrollCourse: postEnrollCourseReducer,
    getSubjectsById: getSubjectsByIdReducer,
    getChaptersById: getChaptersByIdReducer,
    getTopicsById: getTopicsByIdReducer,
    getAllAudiobooks: getAllAudiobooksReducer,
    getAllLiveTests: getAllLiveTestsReducer,
    getAllPracticeTests: getAllPracticeTestsReducer,
    getLiveTestDetails: getLiveTestDetailsReducer,
    postEnrollLiveTest: postEnrollLiveTestReducer,
    getPracticeTestDetails: getPracticeTestDetailsReducer,
    postEnrollPracticeTest: postEnrollPracticeTestReducer,
    getAudiobookDetails: getAudiobookDetailsReducer,
    postEnrollAudiobook: postEnrollAudiobookReducer,
    putCompletedTopic: putCompletedTopicReducer,
    getCourseTestInfo: getCourseTestInfoReducer,
    getUserDetail: getUserDetailReducer,
    getTestProgress: getTestProgressReducer,
    getAssignmentPaper: getAssignmentPaperReducer,
    PostAssignment: PostAssignmentReducer,
    getCourseContent: getCourseContentReducer,
    putUserDetails: putUserDetailsReducer,
    postUserDob: postUserDobReducer,
    putUserPicture: putUserPictureReducer,
    postSendMobileOtp: postSendMobileOtpReducer,
    postSendEmailOtp: postSendEmailOtpReducer,
    postMobileVerification: postMobileVerificationReducer,
    postEmailVerification: postEmailVerificationReducer,
    getAudiobookPaymentHistory: getAudiobookPaymentHistoryReducer,
    getCoursePaymentHistory: getCoursePaymentHistoryReducer,
    getLiveTestPaymentHistory: getLiveTestPaymentHistoryReducer,
    getPracticeTestPaymentHistory: getPracticeTestPaymentHistoryReducer,
    postResetPassword: postResetPasswordReducer,
    postResetTest: postResetTestReducer,
    getAnalyticsAssignment: getAnalyticsAssignmentReducer,
    getAnswerSheet: getAnswerSheetReducer,
    getAllPracticeTest: getAllPracticeTestReducer,
    getAllLiveTest: getAllLiveTestReducer,
    getPracticePaper: getPracticePaperReducer,
    PostPracticeTest: PostPracticeTestReducer,
    getPracticeAnalytics: getPracticeAnalyticsReducer,
    getPracticeAnalyticsPaper: getPracticeAnalyticsPaperReducer,
    getPracticeAnswerSheet: getPracticeAnswerSheetReducer,
    getLiveTestById: getLiveTestByIdReducer,
    getLiveAnalytics: getLiveAnalyticsReducer,
    getLivePaper: getLivePaperReducer,
    PostLiveTest: PostLiveTestReducer,
    postRating: postRatingReducer,
    postRecentSubject: postRecentSubjectReducer,
    getRecentSubject: getRecentSubjectReducer,
    getPublishedPreb4preTest: getPublishedPreb4preReducer,
    getEnrolledPreb4preTest: getEnrolledPreb4preReducer,
    getPreb4prePaymentDetails: getPreb4prePaymentDetailsReducer,
    getPreb4preQuestionPaper: getPreb4preQuestionPaperReducer,
    getPreb4preTestSeries: getPreb4preTestSeriesReducer,
    getPreb4preAnswerSheet: getPreb4preAnswerSheetReducer,
    getPreb4preAnswerSheetById: getPreb4preAnswerSheetByIdReducer,
    getPreb4preTestAnalytics: getPreb4preTestAnalyticsReducer,
    postPreb4preSubmitAnswers: postPreb4preSubmitAnswersReducer,
    postEnrollPreb4pre: postEnrollPreb4preReducer,
    postCourseCoupon: postCourseCouponReducer,
    postAudioBookCoupon: postAudioBookCouponReducer,
    postLiveCoupon: postLiveCouponReducer,
    postPracticeCoupon: postPracticeCouponReducer,
    postPreb4preCoupon: postPreb4preCouponReducer,
    getITWById: getITWByIdReducer,
    getITWPaper: getITWPaperReducer,
    PostITWExam: PostITWExamReducer,
    getEnrollITW: getEnrollITWReducer,
    getItwAnalytics: getItwAnalyticsReducer,
    getItwAnswerSheetById: getItwAnswerSheetByIdReducer,
    getPublishedOfflinePreb4pre: getPublishedOfflinePreb4preReducer,
    postEnrollOfflinePreB4pre: postEnrollOfflinePreB4preReducer,
    getOnlinePreB4prePaymentHistory: getOnlinePreB4prePaymentHistoryReducer,
    getPreB4prePaymentHistory: getPreB4prePaymentHistoryReducer,
    userAuthorization: userAuthorizationReducer,
    getPreb4preQuestionPaperBySlotId: getPreb4preQuestionPaperBySlotIdReducer,
    getEnrolledOfflinePreb4pre: getEnrolledOfflinePreb4preReducer,
    getOfflinePreb4preTestSeries: getOfflinePreb4preTestSeriesReducer,
    getAllFoundationalCourses: getAllFoundationalCoursesReducer,
    getFoundationalCourseDetails: getFoundationalCourseDetailsReducer,
    postEnrollFoundationalCourse:postEnrollFoundationalCourseReducer,
    postFoundationalRating:postFoundationalRatingReducer,
    getUserEnrolledFoundationalCourses : getUserEnrolledFoundationalCoursesReducer,
    getCourseValidityDetail: getCourseValidityDetailReducer,
    getCoursePaymentDetails: getCoursePaymentDetailsReducer,
    postInstallmentPayment: postInstallmentPaymentReducer,
    putFoundationalStatus: putFoundationalStatusReducer,
    putFoundationalRecentTopics: putFoundationalRecentTopicsReducer,
    getFoundationalAssignmentDetails:getFoundationalAssignmentDetailsReducer,
    PostFoundationalAssignment:PostFoundationalAssignmentReducer,
    getFoundationalAssignmentPaper: getFoundationalAssignmentPaperReducer,
    putResetFoundationalTest: putResetFoundationalTestReducer,
    getFoundationalAnalyticsAssignment: getFoundationalAnalyticsAssignmentReducer,
    getFoundationalUserAnswerSheet: getFoundationalUserAnswerSheetReducer,
    GetAllAskToEvaluate: GetAllAskToEvaluateReducer,
    getAskToEvaluateDetails:getAskToEvaluateDetailsReducer,
    postAskToEvaluateCoupon: postAskToEvaluateCouponReducer,
    postEnrollATE: postEnrollATEReducer,
    getAskToEvaluatePaymentHistory: getAskToEvaluatePaymentHistoryReducer,
    getEnrollATE: getEnrollATEReducer,
    getATEDetails: getATEDetailsReducer,
    getATEPaper: getATEPaperReducer,
    PostAskToEvaluate: PostAskToEvaluateReducer,
    getATESubmissions: getATESubmissionsReducer,
    getATEAnalytics: getATEAnalyticsReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: {
        userInfo: userInfoFromStorage
    }
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store