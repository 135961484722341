import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import Sidebar from '../../Components/main/sidebar/sidebar';
import './AssignmentAnalytics.scss'
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import { Link } from 'react-router-dom';
import BarChart from '../../Components/Graphs/BarChart';
import {MdOutlineArrowDropDown} from 'react-icons/md'
import DOMPurify from 'dompurify';
import Pdf from '../../Components/PdfViewer/Pdf';

import {getTestInfoById , getQuestionPaper, getAssignmentAnalytics, getUserAnswerSheet} from '../../actions/courseActions';

const AssignmentAnalyticsScreen = ({match}) => {
    const dispatch = useDispatch();
    const [data,setData] = useState(undefined);
    const [graphType,setGraphType] = useState('');
    const [show, setShow] = useState(false);
    const [type, setType] = useState(false);
    const [thisQuestion, setThisQuestion] = useState({});

    const myFunction = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown").classList.toggle('show');
    } 

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    const getCourseTestInfo = useSelector(state => state.getCourseTestInfo)
    const {loading , error, test} = getCourseTestInfo;

    const getAssignmentPaper = useSelector(state => state.getAssignmentPaper)
    const {loading:loadingPaper , error:errorPaper, paper} = getAssignmentPaper;

    const getAnalyticsAssignment = useSelector(state => state.getAnalyticsAssignment)
    const {loading:loadingAnalytics , error:errorAnalytics, assignmentAnalytics} = getAnalyticsAssignment;

    const getAnswerSheet = useSelector(state => state.getAnswerSheet)
    const {loading:loadingAnswerSheet , error:errorAnswerSheet, answerSheet} = getAnswerSheet;

    useEffect(()=>{
        if(!userInfo){
            document.location.href = '/login';
        }
        dispatch(getTestInfoById(match.params.id))
        dispatch(getQuestionPaper(match.params.id))
        dispatch(getAssignmentAnalytics(match.params.id))
        dispatch(getUserAnswerSheet(match.params.id))
    },[userInfo,dispatch,match])

    const handleShow = () => setShow(true);

    const graphData = (type) => {
        if(document.getElementById("myDropdown").classList.contains('show')){
            document.getElementById("myDropdown").classList.remove('show')
        }
        switch(type){
            case 'classification':
                setData({
                        labels:['Conceptual','Application','Gk'],
                        datasets:[{
                            label:'Classification',
                            data:[assignmentAnalytics.classification.conceptual,assignmentAnalytics.classification.application,assignmentAnalytics.classification.gk],
                            backgroundColor:[
                                '#0c343d',
                                '#2f5c50',
                                '#007ea7',
                            ]
                        }]
                    })
                setGraphType('Classification')
                break;
            case 'category':
                setData({
                    labels:['Category1','Category2'],
                    datasets:[{
                        label:'Category',
                        data:[assignmentAnalytics.category.category1,assignmentAnalytics.category.category2],
                        backgroundColor:[
                            '#007ea7',
                            '#113450',
                        ]
                    }]
                })
                setGraphType('Category')
                break;
            case 'difficulty':
                setData({
                    labels:['Easy','Medium','Hard'],
                    datasets:[{
                        label:'Difficulty',
                        data:[assignmentAnalytics.difficulty.easy,assignmentAnalytics.difficulty.medium,assignmentAnalytics.difficulty.hard],
                        backgroundColor:[
                            '#ffe599',
                            '#8fce00',
                            '#f44336',
                        ]
                    }]
                })
                setGraphType('Difficulty')
                break;
            case 'marks':
                let PositiveMarks = assignmentAnalytics.correctAnswer * test.marksPerQuestion;
                let NegativeMarks = assignmentAnalytics.incorrectAnswer * test.negativeMarking * -1;
                let finalScore = PositiveMarks + NegativeMarks;
                setData({
                    labels:['+ve Marks','-ve Marks ','Final Score'],
                    datasets:[{
                        label:'Marks',
                        data:[PositiveMarks,NegativeMarks,finalScore],
                        backgroundColor:[
                            '#199F6B',
                            '#B54747',
                            '#f1c232',
                        ]
                    }]
                })
                setGraphType('Marks')
                break;
            default:
                console.log('default')
        }
    }

    useEffect(()=>{
        if(assignmentAnalytics && test ){
            setData({
                labels:['Conceptual','Application','Gk'],
                datasets:[{
                    label:'Classification',
                    data:[assignmentAnalytics.classification.conceptual,assignmentAnalytics.classification.application,assignmentAnalytics.classification.gk],
                    backgroundColor:[
                        '#0c343d',
                        '#2f5c50',
                        '#007ea7',
                    ],
                }]
            })
            setGraphType('Classification')
        }
    },[assignmentAnalytics,test ])

    const getSingleQuestion = (object, type) => {
        setType(type);
        setThisQuestion(object);
        if (type === "pdf") {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            }
        }
        handleShow()
    }

    const handleModalClose = () => {
        setShow(false)
        if (type === "pdf") {
            if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen()
            }
        }
    }

    return (
        <div>
            <Sidebar activeLink={'mycourse'}/>
            <main className="main">
                {(loading || loadingPaper || loadingAnalytics || loadingAnswerSheet) && <Loader/>}
                { error && <AlertDismissibleExample data={error}/>}
                { errorPaper && <AlertDismissibleExample data={errorPaper}/>}
                { errorAnalytics && <AlertDismissibleExample data={errorAnalytics}/>}
                { errorAnswerSheet && <AlertDismissibleExample data={errorAnswerSheet}/>}
                <section className="px-5  py-5">
                    <Link to="/mycourse" style={{textDecoration:'none'}}>
                        <img  src={leftArrow} alt="<" className="backBtn"/>
                    </Link>
                </section>
                {(!loadingAnalytics && !errorAnalytics && assignmentAnalytics && test && data ) && (
                    <section className="px-5 ">
                        <div className='d-flex justify-content-center'>
                            <div className="dropdown">
                                <button className="dropbtn" onClick={myFunction}>{graphType}   {<MdOutlineArrowDropDown/>}</button>
                                <div id="myDropdown" className="dropdown-content">
                                    <p onClick={(e)=>{e.preventDefault(); graphData('classification')}}>Classification</p>
                                    <p onClick={(e)=>{e.preventDefault(); graphData('category')}}>Category</p>
                                    <p onClick={(e)=>{e.preventDefault(); graphData('difficulty')}}>Difficulty</p>
                                    <p onClick={(e)=>{e.preventDefault(); graphData('marks')}}>Marks</p>
                                </div>
                            </div>
                        </div>
                        <BarChart data={data}/>
                    </section>
                )}
                {(!loadingPaper && !loadingAnswerSheet && !errorPaper && !errorAnswerSheet && answerSheet && paper.length > 0)&&(
                    <section className="px-5 py-5">
                        <div>
                            <h1 className="mt-2" style={{fontSize:25,fontWeight:'600', color:'rgb(1, 42, 93)'}}>
                                Answer Sheet
                            </h1>
                        </div>
                        {paper.map((question,i)=>(
                            <div key={question.id} className="questions-container">
                                <div className="question">
                                    <div className="ques-line1">
                                        <div className="qus-number">
                                        <h1>{i+1}</h1>
                                        </div> 
                                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.description)}}></p>
                                    </div>
                                    {question.imageKey && (
                                        <div className="question-Img">
                                            <img src={question.imageKey} alt="questionimg"/>
                                        </div>
                                    )}
                                    <div className="options">
                                        {question.options.map((option, i)=>(
                                            <label className="option"key={i} >{option}
                                                <input type="radio" name={question.id} value={option} checked={
                                                    answerSheet?( 
                                                        (answerSheet.answers && answerSheet.answers.length !== 0) ? (
                                                            answerSheet.answers.find((el)=>(
                                                                el.qid === question.id && el.answer === option
                                                            )) ? true : false 
                                                        ) : false 
                                                    ):false} 
                                                readOnly/>
                                                <span className={`${answerSheet?(( answerSheet.answers && answerSheet.answers.length !== 0) ? (answerSheet.answers.find((el)=>(el.qid === question.id && el.answer === option)) ? ((option === question.correctOption) ? 'right' : 'wrong') : 'wrong' ) : '' ):''}`}></span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className="correctAnswer">
                                    <p>Correct Answer:  <span>{question.correctOption}</span></p>
                                </div>
                                {(question.explaination || question.pdf || question.video || question.audio) &&
                                     <div className="explainAnswer">
                                         <p><strong>Explanation-</strong></p>
                                        {question.explaination && <div><p>{question.explaination}</p></div>}
                                        <div className="d-flex justify-content-around">
                                            {question.pdf && <button onClick={(e)=> { e.preventDefault(); getSingleQuestion(question,'pdf')}}>PDF</button>}
                                            {question.video && <button onClick={(e)=> { e.preventDefault(); getSingleQuestion(question,'video')}}>Video</button>}
                                            {question.audio && <button onClick={(e)=> { e.preventDefault(); getSingleQuestion(question,'audio')}}>Audio</button>}
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </section>
                )}
            </main>
            {
                show && <div className="modal-view-new" >
                    <div className="modal-custom-content">
                        <div className="modal-custom-header">
                            <h3 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(thisQuestion.description)}}></h3>
                        </div>
                        <div>
                            <div className="modal-custom-body" >
                                {type === 'pdf' ? (
                                    <div>
                                        <Pdf url={thisQuestion.pdf} />
                                    </div>
                                ) : type === 'video' ? (<div className="video-styles">
                                    <video width="100%" height="100%" controls controlsList="nodownload">
                                        <source src={thisQuestion.video} type="video/mp4"/>
                                    </video>
                                </div>) : (
                                    <div className="audio-styles">
                                        <audio controls controlsList="nodownload">
                                            <source src={thisQuestion.audio} type='audio/mpeg'/>
                                        </audio>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-custom-footer d-flex flex-column flex-md-row align-items-center justify-content-between" style={{float:'right'}}>
                            <button onClick={() => handleModalClose()}>Close</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AssignmentAnalyticsScreen;
