import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import './AskToEvaluateDetails.scss';

//Components
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import CourseDetailsAccordion from '../../views/CourseDetailsAccordion';
import { Row, Col } from 'react-bootstrap';
import ProcessingModal from '../../Components/ProcessingModal';
import SuccessfulModal from '../../Components/SuccessfulModal';

//assets
import leftArrow from '../../assets/icons/back-arrow.svg';
import { CreditCard, Event, Schedule, Language, Equalizer } from '@material-ui/icons';
import { HourglassTop } from '@mui/icons-material';
import defaultImage from "../../assets/images/overview.svg"
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

//Config
import { BASEURL, RazorpayKey } from '../../config/config';

//actions
import { 
    getAskToEvaluateById,
    postCheckValidationCoupon,
    postEnrollAskToEvaluate
} from "../../actions/askToEvaluateActions";

import { 
    getUserDetails 
} from '../../actions/userActions';

const AskToEvaluateDetails = ({match}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [sortedSlot, setsortedSlot] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [coupon, setCoupon] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0)
    const [slot, setSlot] = useState()
    const [showSlot, setShowSlot] = useState(false)

    const getAskToEvaluateDetails = useSelector(state => state.getAskToEvaluateDetails)
    const { loading, error, askToEvaluate } = getAskToEvaluateDetails;

    const postAskToEvaluateCoupon = useSelector(state => state.postAskToEvaluateCoupon)
    const { loading: loadingCoupon, error: errorCoupon, couponDetails } = postAskToEvaluateCoupon;

    const postEnrollATE = useSelector(state => state.postEnrollATE)
    const { loading: loadingEnroll, error: errorEnroll, message } = postEnrollATE;
    
    const getUserDetail = useSelector(state => state.getUserDetail)
    const { user } = getUserDetail

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAskToEvaluateById(match.params.id))
        dispatch(getUserDetails())
    }, [userInfo, dispatch, match, message])


    const getDate = (time) =>{
        return new Date(time).getTime()
    }

    useEffect(()=>{
        if(askToEvaluate){
            let slots = askToEvaluate.slots;
            slots.sort((a,b)=>{
                if(getDate(a.startingTime) < getDate(b.startingTime)){
                    return -1;
                }else if(getDate(a.startingTime) > getDate(b.startingTime)){
                    return 1;
                }else{
                    return 0;
                }
            })
            setsortedSlot(slots)

            
            if(askToEvaluate.discountPrice){
                setDiscount(askToEvaluate.discountPrice)
            }
            
        }
    },[askToEvaluate])

    useEffect(()=>{
        if(askToEvaluate && couponDetails){
            let discountedPrice = askToEvaluate.price * (couponDetails.discountPercentage / 100);
            if(discountedPrice > couponDetails.maxDiscount){
                setCouponDiscount(couponDetails.maxDiscount);
                setDiscount(couponDetails.maxDiscount);
            }else{
                setCouponDiscount(discountedPrice);
                setDiscount(discountedPrice);
            }
        }
    },[couponDetails, askToEvaluate])

    let faqs = [
        {
            question: "Why Pre B4 Pre ?",
            answer: "Civil Service Examination is conducted by the Union Public Service Commission (UPSC) to recruit suitable candidates for IAS, IPS, IFS and other allied services. This examination is divided into three stages to properly assess a candidate, his hard work, and knowledge. Each of them is important if you want to land a suitable title for yourself and have a secure future.  This three-stage examination consists of Prelims, Mains and the interview round. To clear the first two rounds, you need to have a comprehensive understanding of the syllabus and the exam pattern. The limitation of time is another factor you must consider if you want to prepare efficiently. All of the things mentioned above can only be mastered through a lot of practice, which can be done by solving sample papers and previous year’s question papers.To make sure that no stone remains unturned when it comes to your preparation, we at YesMentor have come with Pre Before Pre. An exam for you to properly assess your preparation."
        },

        {
            question: "WHAT IS IT ? ",
            answer: "Pre Before Pre is an exam that YesMentor will conduct just before the prelims in offline and online mode, so you can choose whether you want to give it physically or not. The exam will follow the same pattern as the prelims exam with the same number of questions. To properly assess how many questions they can solve within the time limit given in the original prelim, we also provide the same time limit. Like the actual prelims, the question paper will be bilingual to eliminate any language barrier. The same marking scheme will be followed along with the negative marking so they can get the exact score as they would in the prelims. The exam results will be released within a week so you can conduct a deep analysis of your preparation and fill any plot holes before the exam. "
        },
        {
            question: "How to appear in  Pre B4 Pre  exam",
            answer: "The Pre Before Pre exam is initially for upsc and uppsc prelims exams,  specially curated according to your learning needs. Aspirants who wish to appear in this exam  have to register  themselves on our website www.yesmentor.in in the first week of April ,after registration a confirmation ticket will be sent by mail.Aspirants will get admit card in the first week of may ,with date,timings and venue (if opted offline). In between aspirants will get regular updates and tips about the exam. "
        }
    ]

    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const stringTime = (examDate) => {
        const event = new Date(examDate);
        const options = { hour: '2-digit', minute: '2-digit' , timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    const getEnrolled = (e) => {
        e.preventDefault()
        setShowSlot(false)
        dispatch(postEnrollAskToEvaluate(
            match.params.id, 
            "", 
            "", 
            "", 
            couponDetails ? couponDetails.discountCode : "",
            slot
        ))
    }

    const getPaidEnrolled = async (e) => {
        e.preventDefault()
        setShowSlot(false)
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        if(couponDetails){
            let { data } = await axios.post(
                `${BASEURL}/user/razorpay/order/asktoevaluate/${askToEvaluate.id}`, 
                {
                    coupon:couponDetails.discountCode
                }, 
                config
            )
            var options  = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollAskToEvaluate(
                            askToEvaluate.id, 
                            response.razorpay_payment_id, 
                            response.razorpay_signature, 
                            response.razorpay_order_id, 
                            couponDetails.discountCode,
                            slot
                        ))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }else{
            let { data } = await axios.post(
                `${BASEURL}/user/razorpay/order/asktoevaluate/${askToEvaluate.id}`, 
                {}, 
                config
            )
            options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollAskToEvaluate(
                            askToEvaluate.id, 
                            response.razorpay_payment_id, 
                            response.razorpay_signature, 
                            response.razorpay_order_id, 
                            "",
                            slot
                        ))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    const CouponValidation = (e) =>{
        e.preventDefault();
        dispatch(postCheckValidationCoupon(coupon));
    }

    const SelectSlots = (e) =>{
        e.preventDefault();
        setShowSlot(true)
        setSlot("")
    }

    return (
        <div>
            {(loading || loadingCoupon) && <Loader />}
            {loadingEnroll && <ProcessingModal />}
            {error && <AlertDismissibleExample data={error} />}
            {errorEnroll && <AlertDismissibleExample data={errorEnroll} />}
            {message && <SuccessfulModal data={message} path="/mytestseries" />}

            {
                showSlot && <div className='ask-to-evaluate'>
                    <div className='slot-selector'>
                        <select
                            name='slot'
                            id='slot'
                            value={slot}
                            onChange={(e) => setSlot(e.target.value)}
                        >
                            <option value="">Select Slot</option>
                            {askToEvaluate && askToEvaluate.slots.map((slot)=>(
                                <option value={slot.slotId} key={slot.slotId}>
                                    {stringDate(slot.startingTime)} &nbsp;|&nbsp; {stringTime(slot.startingTime)}&nbsp; -&nbsp; {stringTime(slot.endingTime)}
                                </option>
                            ))}
                        </select>
                        <div className='btns'>
                            <button className='cancel' onClick={(e)=> {e.preventDefault(); setShowSlot(false)}}>Cancel</button>
                            {
                                askToEvaluate && ((askToEvaluate.price - discount === 0) || (askToEvaluate.price === 0)) ?
                                <button className='enroll' disabled={!slot} onClick={getEnrolled}>Enroll</button> :
                                <button className='enroll' disabled={!slot} onClick={getPaidEnrolled}>Enroll</button>
                            }
                        </div>
                    </div>
                </div>
            }

            <main>
                {
                    askToEvaluate && (
                        <div className='course-overview'>
                            <div>
                                <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                                    <img src={leftArrow} alt="<" style={{ margin: 20 }} />
                                </button>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <img src={defaultImage} alt="" width="200" />
                            </div>
                            <div className="course-content p-2 p-md-5">
                                <div className="course-info d-flex justify-content-between align-items-center">
                                    <div>
                                        <h2>{askToEvaluate.testName}</h2>
                                        <h6>{askToEvaluate.subHeading}</h6>
                                    </div>
                                    <h5>Type: Objective</h5>
                                </div>
                                <div className="course-info">
                                    <h3>About</h3>
                                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(askToEvaluate.description)}}></p>
                                </div>
                                <div className="course-info">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h4>Paper Timing</h4>
                                            <Row>
                                                {
                                                    sortedSlot.map((slot,i)=>(
                                                        <Col xs={10} key={i} className = 'test-details my-4'>
                                                            <div>
                                                                <div>
                                                                    <h5>Paper {i+1}</h5>
                                                                </div>
                                                                <Event /> Date: <strong> 
                                                                    {stringDate(slot.startingTime)}
                                                                </strong>
                                                            </div>
                                                            <div>
                                                                <HourglassTop /> Duration: <strong>
                                                                    {minToHours(slot.duration)}
                                                                </strong>
                                                            </div>
                                                            <div>
                                                                <Schedule />  Start at: <strong> {
                                                                    stringTime(slot.startingTime)
                                                                } </strong>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row>
                                                <Col xs={6} md={12} xl={6}>
                                                    <div>
                                                        <h4>Price</h4>
                                                        <div className="subjects-info my-3 d-flex">
                                                            <AttachMoneyIcon/> Price:&nbsp;{askToEvaluate.price===0 ? <strong> Free</strong> : 
                                                                discount === 0 ?  
                                                                <strong>₹ {askToEvaluate.price}/-</strong> : 
                                                                <strong style={{ textDecoration: "line-through" }}>₹ {askToEvaluate.price}/-</strong> 
                                                            }
                                                        </div>
                                                        <div className="subjects-info my-3 d-flex">
                                                            <LocalOfferIcon/> Discount: &nbsp;{discount === 0 ? <strong>N/A</strong> : 
                                                                <strong>₹ {discount}/-</strong>
                                                            }
                                                        </div>
                                                        <div className="subjects-info my-3 d-flex">
                                                            <CreditCard/> Total Price: &nbsp;{(askToEvaluate.price===0 || (askToEvaluate.price - discount === 0)) ? <strong>Free</strong> : 
                                                                <strong>₹ {askToEvaluate.price - discount}/-</strong> 
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={12} xl={6} className='mt-xs-0 mt-md-4 mt-xl-0'>
                                                    <h4>Exam Info</h4>
                                                    <div className="subjects-info my-2 my-md-3 d-flex">
                                                        <Language /> 
                                                        <p className="">Online</p>
                                                    </div>
                                                    <div className="subjects-info my-2 my-md-3 d-flex">
                                                        <Equalizer /> 
                                                        <p className="">Result: {stringDate(askToEvaluate.resultOn)}</p>
                                                    </div>
                                                    <div className= 'd-flex'>
                                                        {
                                                            user.verified ? askToEvaluate.isEnrolled ? 
                                                            <span className="enroll-btn" style={{ cursor: 'no-drop' }} onClick={null}>
                                                                Enrolled
                                                            </span>:
                                                            <div>
                                                                <span className="enroll-btn" style={{ cursor: 'pointer' }} onClick={SelectSlots}>
                                                                    Enroll
                                                                </span>
                                                            </div>:
                                                            <div>
                                                                <button className="no-enroll-btn" disabled style={{ cursor: 'not-allowed' }}>
                                                                    Enroll
                                                                </button>
                                                                <p style={{ color: '#ff0101', fontSize: 12 }}>
                                                                    Please verify your email address to enroll in the preb4pre
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    {
                                                        askToEvaluate.price !== 0 && !askToEvaluate.isEnrolled &&
                                                        <div className='coupons'>
                                                            <h2>Coupon</h2>
                                                            <div>
                                                                <input type='text' value={coupon} placeholder='Enter Coupon Code' onChange={(e) => setCoupon(e.target.value)}/>
                                                                <button onClick={CouponValidation}>Apply</button>
                                                            </div>
                                                            {errorCoupon && <p>{errorCoupon}</p>}
                                                            {couponDetails && <p style={{color:'green'}}>Congratulations, you got a discount of ₹{couponDiscount}/-</p>}
                                                        </div>
                                                    }
                                                </Col>
                                                <Col xs={12} className='py-5'>
                                                    <h2>FAQs</h2>
                                                    <CourseDetailsAccordion faqs={faqs} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )
                }
            </main>
        </div>
    )
}

export default AskToEvaluateDetails