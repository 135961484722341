import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import noImage from '../../assets/images/otp.png';
import Logo from '../../assets/icons/logo.svg';
import "./InvoicesCard.scss"

import { getOnlinePreb4prePaymentHistory } from '../../actions/offlinePreB4PreAction';
import { getPreb4prePaymentHistory } from '../../actions/preb4preTestAction';

const PreB4PreInvoice = () => {
    const dispatch = useDispatch()

    const getOnlinePreB4prePaymentHistory = useSelector(state => state.getOnlinePreB4prePaymentHistory)
    const { loading, payment } = getOnlinePreB4prePaymentHistory

    const getPreB4prePaymentHistory = useSelector(state => state.getPreB4prePaymentHistory)
    const { loading:preb4preLoading, payment:preb4prePayment } = getPreB4prePaymentHistory

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getOnlinePreb4prePaymentHistory())
        dispatch(getPreb4prePaymentHistory())

    }, [dispatch, userInfo])


    return <div className="payments">
        {(loading || preb4preLoading) && <Loader />}

        <div className="d-flex justify-content-center">
            <h3>ONLINE PAYMENT HISTORY</h3>
           
        </div>
        {
            
            (!preb4prePayment || preb4prePayment.length === 0) ? <div className="no-payments" >
                <img src={noImage} alt="" />
                <h3>No payment done</h3>
            </div> : <div className="d-flex m-2 flex-wrap justify-content-center">
                {
                    
                    preb4prePayment && preb4prePayment.map((prop, id) =>
                        <div key={id} className="payment-card">
                            <img src={prop.image ? prop.image : Logo} alt="logo" />
                            <div className="payment-details">
                                <p>Practice Test: <strong>{prop.name}</strong></p>
                                <p>Date: <strong>{prop.createdAt.split("T")[0]}</strong></p>
                                <p>Price: <strong> {(prop.price === "0") ? "Free" : `₹ ${prop.price}`}</strong></p>
                                <p>Payment ID: <strong>{prop.paymentId}</strong></p>
                            </div>
                        </div>
                    )
                }
            </div>
        }

        <div className="d-flex justify-content-center mt-5">
            <h3>OFFLINE PAYMENT HISTORY</h3>
        </div>

        {
            (!payment || payment.length === 0) ? <div className="no-payments" >
                <img src={noImage} alt="" />
                <h3>No payment done</h3>
            </div> : <div className="d-flex m-2 flex-wrap justify-content-center">
                {
                    payment && payment.map((prop, id) =>
                        <div key={id} className="payment-card">
                            <img src={prop.image ? prop.image : Logo} alt="logo" />
                            <div className="payment-details">
                                <p>Practice Test: <strong>{prop.name}</strong></p>
                                <p>Date: <strong>{prop.createdAt.split("T")[0]}</strong></p>
                                <p>Price: <strong> {(prop.price === "0") ? "Free" : `₹ ${prop.price/100}`}</strong></p>
                                <p>Payment ID: <strong>{prop.paymentId}</strong></p>
                            </div>
                        </div>
                    )
                }
            </div>
        }
    </div>;
};

export default PreB4PreInvoice;
