import {
    GET_ITW_DETAILS_REQUEST,
    GET_ITW_DETAILS_SUCCESS,
    GET_ITW_DETAILS_FAIL,
    GET_ITW_QUESTION_PAPER_FAIL,
    GET_ITW_QUESTION_PAPER_REQUEST,
    GET_ITW_QUESTION_PAPER_SUCCESS,
    POST_SUBMIT_ITW_REQUEST,
    POST_SUBMIT_ITW_SUCCESS,
    POST_SUBMIT_ITW_FAIL,
    GET_ENROLL_ITW_REQUEST,
    GET_ENROLL_ITW_SUCCESS,
    GET_ENROLL_ITW_FAIL,
    GET_ANALYTICS_ITW_REQUEST,
    GET_ANALYTICS_ITW_SUCCESS,
    GET_ANALYTICS_ITW_FAIL,
    GET_ANSWER_SHEET_ITW_REQUEST,
    GET_ANSWER_SHEET_ITW_SUCCESS,
    GET_ANSWER_SHEET_ITW_FAIL
} from '../constants/IssuesThisWeekConstants';

import axios from 'axios';
import { BASEURL } from '../config/config';

export const getITWDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ITW_DETAILS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id }
        }

        const { data } = await axios.get(`${BASEURL}/admin/itw/${id}`, config)
        dispatch({
            type: GET_ITW_DETAILS_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ITW_DETAILS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getITWQPaper = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ITW_QUESTION_PAPER_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken }
        }
        const { data } = await axios.get(`${BASEURL}/user/itw/question/paper/${id}`, config)
        dispatch({
            type: GET_ITW_QUESTION_PAPER_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ITW_QUESTION_PAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postSubmitITW = (id, answers) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SUBMIT_ITW_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/itw/answer/${id}`, { answers }, config)
        dispatch({
            type: POST_SUBMIT_ITW_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_SUBMIT_ITW_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getEnrolledItw = () => async (dispatch,getState) => {
    try {

        dispatch({
            type: GET_ENROLL_ITW_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/itw`, config)
        dispatch({
            type: GET_ENROLL_ITW_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ENROLL_ITW_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getItwTestAnalytics = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ANALYTICS_ITW_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/itw/analytics/${id}`, config)
        dispatch({
            type: GET_ANALYTICS_ITW_SUCCESS,
            payload: data.data[0]
        })
    } catch (error) {
        dispatch({
            type: GET_ANALYTICS_ITW_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getItwAnswerSheetById = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ANSWER_SHEET_ITW_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries/answersheet/${id}`, config)
        dispatch({
            type: GET_ANSWER_SHEET_ITW_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_ANSWER_SHEET_ITW_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}