import React,{useState} from 'react'
import PdfViewer  from '../../../../Components/PdfViewer/PdfViewer.js';
import './Notes.scss'
import { Link } from 'react-router-dom'
import { Lock } from '@material-ui/icons';

const Notes = ({data, tid, eid}) => {

    const [show, setShow] = useState(false);

    const [url , setUrl] = useState("");
    const [name , setName] = useState("");

    const scrollToTopic = (e) => {
        e.preventDefault();
        const element = document.getElementsByClassName('underLine')
        if(element.length > 0){
            element[0].scrollIntoView({
                behavior:"smooth",
                block:"center",
                inline:'nearest'
            });
        }
    }

    const openPdf = (url, name) => {
        setUrl(url)
        setName(name)
        setShow(true)
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
        }
    }

    return (
        <div className='notes'>
            <div className='content'>
                <button className='current-topic' onClick={scrollToTopic}>Current Topic</button>
                {
                    data.subjects.map((prop, i) =>
                        <div key={i} className='subjects'>
                            <h5>{prop.subjectName}</h5>
                            {
                                prop.chapter && prop.chapter.map((chap, id) =>
                                    <div key={id} className='chapter'>
                                        <span className="font-weight-bold">
                                            {i + 1}.{id + 1}
                                        </span>{" "}
                                        {chap.chapterName}
                                        {chap.topics && chap.topics.map((topic, idx)=>
                                        <div key={idx} className='d-flex justify-content-between topics'>
                                            <p>
                                                <span className={(data.recentTopic === topic.topicId) ? 'underLine' : ""}>
                                                    <span  className="font-weight-bold">
                                                    {i + 1}.{id + 1}.{idx + 1}
                                                    </span>{" "}
                                                    {topic.topicName}
                                                </span>
                                            </p>
                                            {
                                                topic.content.isValidity ? <button 
                                                        onClick={(e)=>{e.preventDefault(); openPdf(topic.content.pdf, topic.topicName)}}
                                                        disabled={topic.content.pdf ? false : true}
                                                    >
                                                        view
                                                    </button> :
                                                    <Link to={`/foundational-payment-details/${tid}/${eid}`} style={{color:'#2b2b2b'}} title="Unlock">
                                                        <Lock/>
                                                    </Link> 
                                            }
                                            
                                        </div>
                                        )}
                                    </div>
                                )
                            }
                            <hr></hr>
                        </div>
                    )
                }
            </div>
            {
                show && <div className='pdf-modal'>
                    <PdfViewer 
                        url={url} 
                        name={name}
                        handleSetVisibilityResults={(results) => setShow(results)}
                    />
                </div>
            }
        </div>
    )
}

export default Notes