import {
    GET_ASK_TO_EVALUATE_FAIL,
    GET_ASK_TO_EVALUATE_REQUEST,
    GET_ASK_TO_EVALUATE_SUCCESS,
    GET_ASK_TO_EVALUATE_BY_ID_REQUEST,
    GET_ASK_TO_EVALUATE_BY_ID_SUCCESS,
    GET_ASK_TO_EVALUATE_BY_ID_FAIL,
    POST_ASKTOEVALUATE_COUPON_VALIDATION_REQUEST,
    POST_ASKTOEVALUATE_COUPON_VALIDATION_SUCCESS,
    POST_ASKTOEVALUATE_COUPON_VALIDATION_FAIL,
    POST_ENROLL_ASKTOEVALUATE_REQUEST,
    POST_ENROLL_ASKTOEVALUATE_SUCCESS,
    POST_ENROLL_ASKTOEVALUATE_FAIL,
    GET_ASKTOEVALUATE_PAYMENT_HISTORY_REQUEST,
    GET_ASKTOEVALUATE_PAYMENT_HISTORY_SUCCESS,
    GET_ASKTOEVALUATE_PAYMENT_HISTORY_FAIL,
    GET_ENROLL_ASKTOEVALUATE_REQUEST,
    GET_ENROLL_ASKTOEVALUATE_SUCCESS,
    GET_ENROLL_ASKTOEVALUATE_FAIL,
    GET_ASKTOEVALUATE_BY_ID_REQUEST,
    GET_ASKTOEVALUATE_BY_ID_SUCCESS,
    GET_ASKTOEVALUATE_BY_ID_FAIL,
    GET_ATE_QUESTION_PAPER_REQUEST,
    GET_ATE_QUESTION_PAPER_SUCCESS,
    GET_ATE_QUESTION_PAPER_FAIL,
    POST_SUBMIT_ASK_TO_EVALUATE_SUCCESS,
    POST_SUBMIT_ASK_TO_EVALUATE_FAIL,
    POST_SUBMIT_ASK_TO_EVALUATE_REQUEST,
    GET_SUBMISSIONS_ASK_TO_EVALUATE_REQUEST,
    GET_SUBMISSIONS_ASK_TO_EVALUATE_SUCCESS,
    GET_SUBMISSIONS_ASK_TO_EVALUATE_FAIL,
    GET_ANALYTICS_ASK_TO_EVALUATE_REQUEST,
    GET_ANALYTICS_ASK_TO_EVALUATE_SUCCESS,
    GET_ANALYTICS_ASK_TO_EVALUATE_FAIL
} from '../constants/askToEvaluateContants';

export const GetAllAskToEvaluateReducer = ( state={}, action) => {
    switch(action.type){
        case GET_ASK_TO_EVALUATE_REQUEST:
            return {loading:true}
        case GET_ASK_TO_EVALUATE_SUCCESS:
            return{loading:false, askToEvaluate: action.payload}
        case GET_ASK_TO_EVALUATE_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const getAskToEvaluateDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ASK_TO_EVALUATE_BY_ID_REQUEST:
            return { loading: true }
        case GET_ASK_TO_EVALUATE_BY_ID_SUCCESS:
            return { loading: false, askToEvaluate: action.payload }
        case GET_ASK_TO_EVALUATE_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postAskToEvaluateCouponReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ASKTOEVALUATE_COUPON_VALIDATION_REQUEST:
            return { loading: true }
        case POST_ASKTOEVALUATE_COUPON_VALIDATION_SUCCESS:
            return { loading: false, couponDetails: action.payload }
        case POST_ASKTOEVALUATE_COUPON_VALIDATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollATEReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_ASKTOEVALUATE_REQUEST:
            return { loading: true }
        case POST_ENROLL_ASKTOEVALUATE_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_ASKTOEVALUATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAskToEvaluatePaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_ASKTOEVALUATE_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_ASKTOEVALUATE_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_ASKTOEVALUATE_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getEnrollATEReducer = (state = { enrolledAskToEvaluate: [] }, action) => {
    switch (action.type) {
        case GET_ENROLL_ASKTOEVALUATE_REQUEST:
            return { loading: true, ...state }
        case GET_ENROLL_ASKTOEVALUATE_SUCCESS:
            return { loading: false, enrolledAskToEvaluate: action.payload }
        case GET_ENROLL_ASKTOEVALUATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getATEDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ASKTOEVALUATE_BY_ID_REQUEST:
            return { loading: true }
        case GET_ASKTOEVALUATE_BY_ID_SUCCESS:
            return { loading: false, askToEvaluate: action.payload }
        case GET_ASKTOEVALUATE_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getATEPaperReducer = (state = { Paper: [] }, action) => {
    switch (action.type) {
        case GET_ATE_QUESTION_PAPER_REQUEST:
            return { loading: true, Paper: [] }
        case GET_ATE_QUESTION_PAPER_SUCCESS:
            return { loading: false, Paper: action.payload }
        case GET_ATE_QUESTION_PAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PostAskToEvaluateReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SUBMIT_ASK_TO_EVALUATE_REQUEST:
            return { loading: true }
        case POST_SUBMIT_ASK_TO_EVALUATE_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_SUBMIT_ASK_TO_EVALUATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getATESubmissionsReducer = (state = {  }, action) => {
    switch (action.type) {
        case GET_SUBMISSIONS_ASK_TO_EVALUATE_REQUEST:
            return { loading: true }
        case GET_SUBMISSIONS_ASK_TO_EVALUATE_SUCCESS:
            return { loading: false, Submissions: action.payload }
        case GET_SUBMISSIONS_ASK_TO_EVALUATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getATEAnalyticsReducer = (state = { }, action) => {
    switch (action.type) {
        case GET_ANALYTICS_ASK_TO_EVALUATE_REQUEST:
            return { loading: true }
        case GET_ANALYTICS_ASK_TO_EVALUATE_SUCCESS:
            return { loading: false, Analytics: action.payload }
        case GET_ANALYTICS_ASK_TO_EVALUATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}