import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
// import Logo from '../../assets/icons/logo.svg';
import leftArrow from '../../assets/icons/back-arrow.svg';
import axios from 'axios';
import { BASEURL, RazorpayKey } from '../../config/config';
import "./PracticeTestDetails.scss"
import ProcessingModal from '../../Components/ProcessingModal';
import SuccessfulModal from '../../Components/SuccessfulModal';
// import cover from "../../assets/images/cover.png"
import { CreditCard } from '@material-ui/icons';
import { HourglassTop } from '@mui/icons-material';
import defaultImage from "../../assets/images/overview.svg"
import { getPracticeTestById, postEnrollPracticeTest, postCheckValidationCoupon } from "../../actions/practiceTestsAction"
import { getUserDetails } from '../../actions/userActions';
import DOMPurify from 'dompurify';

function PracticeTestDetails({ match }) {

    const dispatch = useDispatch()
    const history = useHistory()
    const getPracticeTestDetails = useSelector(state => state.getPracticeTestDetails)
    const { loading, error, practiceTest } = getPracticeTestDetails

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { user } = getUserDetail

    const enrollPracticeTest = useSelector(state => state.postEnrollPracticeTest)
    const { loading: loadingEnroll, error: errorEnroll, message } = enrollPracticeTest

    const postPracticeCoupon = useSelector(state => state.postPracticeCoupon)
    const { loading: loadingCoupon, error: errorCoupon, couponDetails } = postPracticeCoupon;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [coupon, setCoupon] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getPracticeTestById(match.params.ptid))
        dispatch(getUserDetails())
    }, [userInfo, dispatch, match])

    useEffect(()=>{
        if(practiceTest.price !== undefined && couponDetails.discountPercentage !== undefined){
            if(((practiceTest.price)*(couponDetails.discountPercentage / 100)) > couponDetails.maxDiscount){
                setCouponDiscount(couponDetails.maxDiscount);
            }else{
                setCouponDiscount(((practiceTest.price)*(couponDetails.discountPercentage / 100)));
            }
        }
    },[couponDetails, practiceTest])

    const getEnrolled = (e) => {
        e.preventDefault()
        dispatch(postEnrollPracticeTest(match.params.ptid, "", "", "", ""))
    }

    const getPaidEnrolled = async (e) => {
        e.preventDefault()
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        if(couponDetails.discountCode){
            let { data } = await axios.post(`${BASEURL}/user/razorpay/order/paid/${match.params.ptid}`, {coupon:couponDetails.discountCode}, config)
            var options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollPracticeTest(match.params.ptid, response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id,couponDetails.discountCode ))
                    } catch (error) {
                        console.log(error);
                    }
                }
            };
        }else{
            let { data } = await axios.post(`${BASEURL}/user/razorpay/order/paid/${match.params.ptid}`, {}, config)

            options = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data.order.id,
                handler: async (response) => {
                    try {
                        dispatch(postEnrollPracticeTest(match.params.ptid, response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id, ""))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
        
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    const CouponValidation = (e) =>{
        e.preventDefault();
        dispatch(postCheckValidationCoupon(coupon));
    }

    return (
        <div>
            {loadingCoupon && <Loader />}
            {errorEnroll && <AlertDismissibleExample data={errorEnroll} />}
            {loadingEnroll && <ProcessingModal />}
            {message && <SuccessfulModal data={"Successfully enrolled!"} path="/mytestseries" />}
            <main>
                {
                    loading ? <Loader /> : error ? <AlertDismissibleExample data={error} /> :
                        <div className="practice-test-overview">
                            <div>
                                <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                                    <img src={leftArrow} alt="<" style={{ margin: 20 }} />
                                </button>

                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <h2>{practiceTest.testName}</h2>
                                <img src={defaultImage} alt="" width="200" />
                            </div>
                            <div className="test-content p-2 p-md-5">

                                <div className="d-flex  test-info my-0 justify-content-between align-items-center">
                                    <p>Type: {practiceTest.testType}</p>
                                </div>
                                <div className="test-info">
                                    <h3>About the test</h3>
                                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(practiceTest.description)}}></p>
                                </div>
                                <div className="test-info">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="test-details">

                                                <div>
                                                    <HourglassTop /> Duration: {practiceTest.duration} Minutes
                                                </div>

                                                {
                                                    (practiceTest.discountPrice === null || practiceTest.discountPrice === 0) ? <div>

                                                        <CreditCard /> Cost:  {practiceTest.price === 0 ? "Free" : "₹ " + practiceTest.price}
                                                    </div> : <div>

                                                        <CreditCard />Cost: <span style={{ textDecoration: "line-through" }} >₹  {practiceTest.price}</span>  ₹ {practiceTest.price - practiceTest.discountPrice}
                                                    </div>
                                                }

                                            </div>
                                            <div className='pt-2 pb-5'>
                                                {user.verified ? (practiceTest.isEnrolled ? (
                                                    <span className="enroll-btn" style={{ cursor: 'no-drop', position: "absolute" }} onClick={null}>Enrolled</span>
                                                ) : (practiceTest.price !== 0) ? (
                                                    <span className="enroll-btn" style={{ cursor: 'pointer', position: "absolute" }} onClick={getPaidEnrolled}>
                                                        Enroll
                                                    </span>
                                                ) : (
                                                    <span className="enroll-btn" style={{ cursor: 'pointer', position: "absolute" }} onClick={getEnrolled}>
                                                        Enroll
                                                    </span>
                                                )) : <div>
                                                    <button className="no-enroll-btn" style={{ cursor: 'not-allowed' }} onClick={getPaidEnrolled}>
                                                        Enroll
                                                    </button>
                                                    <p style={{ color: '#ff0101', fontSize: 12 }}>
                                                        Please verify your email address to enroll in the practice test
                                                    </p>
                                                </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            
                                            {practiceTest.price !==0 && !practiceTest.isEnrolled &&  
                                                <div className='coupons'>
                                                    <h2>Coupon</h2>
                                                    <div>
                                                        <input type='text' value={coupon} placeholder='Enter Coupon Code' onChange={(e) => setCoupon(e.target.value)}/>
                                                        <button onClick={CouponValidation}>Apply</button>
                                                    </div>
                                                    {errorCoupon && <p>{errorCoupon}</p>}
                                                    
                                                    {couponDetails.discountCode && <p style={{color:'green'}}>Congratulations, you got a discount of ₹{couponDiscount}/-</p>}
                                                </div>
                                            }
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                }
            </main>
        </div>
    )
}

export default PracticeTestDetails
