import React from 'react';
import leftArrow from '../../assets/icons/white-Back-arrow.svg';

import './MainPreB4Pre.scss';
import PreB4Pre from '../../assets/images/preb4pre.png'

const MainPreB4Pre = ({history}) => {
  return <div>
        <main>
            <div className='MainPreB4Pre'>
                <div className='banner'>
                    <button style={{ border: "none", background: "none" }} onClick={() => history.push('/')} >
                        <img src={leftArrow} alt="<" style={{ margin: 20 }} />
                    </button>
                    <div className="d-flex flex-column align-items-center">
                        <img src={PreB4Pre} className='IMG' alt="" width="200" />
                    </div>
                </div>
                <div className='content'>
                    <h1>What is PreB4Pre?</h1>
                    <p>
                        Pre Before Pre is an exam that YesMentor will conduct just 
                        before the prelims in offline mode, so you can choose whether 
                        you want to give it physically or not. The exam will follow the same 
                        pattern as the prelims exam with the same number of questions. 
                        To properly assess how many questions they can solve within the time 
                        limit given in the original prelim, we also provide the same time limit. 
                        Like the actual prelims, the question paper will be bilingual to eliminate 
                        any language barrier. The same marking scheme will be followed along with 
                        the negative marking so they can get the exact score as they would in the prelims. 
                        The exam result will be released within a week so you can conduct a deep analysis of 
                        your preparation and fill any plot holes before the exam.
                    </p>
                    <div className='button-container'>
                        <button onClick = {(e) => {e.preventDefault(); history.push('/enroll-Offline-pre-b4-pre')}}>Offline Exam</button>
                        <p>Or</p>
                        <button onClick = {(e) => {e.preventDefault(); history.push('/enroll-pre-b4-pre')}}>Online Exam</button>
                    </div>
                </div>
            </div>
        </main>
  </div>;
};

export default MainPreB4Pre;
