import React, { useEffect, useState } from 'react'
import Sidebar from '../../Components/main/sidebar/sidebar';
import AudiobookInvoice from '../../views/InvoicesCard/AudiobookInvoice';
import CourseInvoice from '../../views/InvoicesCard/CourseInvoice';
import LiveTestInvoice from '../../views/InvoicesCard/LiveTestInvoice';
import PracticeTestInvoice from '../../views/InvoicesCard/PracticeTestInvoice';
import PreB4PreInvoice from '../../views/InvoicesCard/PreB4PreInvoice';
import AskToEvaluateInvoice from '../../views/InvoicesCard/AskToEvaluateInvoice';

import "./Invoices.scss"
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg"
import { Link } from 'react-router-dom';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}


function Invoices() {
    const [showCoursePayment, setShowCoursePayment] = useState(true)
    const [showAudiobookPayment, setShowAudiobookPayment] = useState(false)
    const [showLiveTestPayment, setShowLiveTestPayment] = useState(false)
    const [showPracticeTestPayment, setShowPracticeTestPayment] = useState(false)
    const [showPreb4prePayment, setShowPreb4prePayment] = useState(false)
    const [showAskToEvaluatePayment, setShowAskToEvaluatePayment] = useState(false)
    const [mobileView, setMobileView] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [exploreOption, setExploreOption] = useState("")

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth)
        }, 1000)
        window.addEventListener('resize', debouncedHandleResize)
        return _ => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })

    useEffect(() => {
        if (windowWidth <= 726) {
            setMobileView(true)
        } else {
            setMobileView(false)
        }
    }, [windowWidth])

    const handleShowCoursePayment = () => {
        setShowCoursePayment(true)
        setShowAudiobookPayment(false)
        setShowLiveTestPayment(false)
        setShowPracticeTestPayment(false)
        setShowPreb4prePayment(false)
        setShowAskToEvaluatePayment(false)
    }

    const handleShowAudiobookPayment = () => {
        setShowCoursePayment(false)
        setShowAudiobookPayment(true)
        setShowLiveTestPayment(false)
        setShowPracticeTestPayment(false)
        setShowPreb4prePayment(false)
        setShowAskToEvaluatePayment(false)
    }

    const handleShowLiveTestPayment = () => {
        setShowCoursePayment(false)
        setShowAudiobookPayment(false)
        setShowPreb4prePayment(false)
        setShowLiveTestPayment(true)
        setShowPracticeTestPayment(false)
        setShowAskToEvaluatePayment(false)
    }

    const handleShowPracticeTestPayment = () => {
        setShowCoursePayment(false)
        setShowAudiobookPayment(false)
        setShowLiveTestPayment(false)
        setShowPreb4prePayment(false)
        setShowPracticeTestPayment(true)
        setShowAskToEvaluatePayment(false)
    }

    const handleShowPreb4prePayment = () => {
        setShowCoursePayment(false)
        setShowAudiobookPayment(false)
        setShowLiveTestPayment(false)
        setShowPreb4prePayment(true)
        setShowPracticeTestPayment(false)
        setShowAskToEvaluatePayment(false)
    }

    const handleShowAskToEvaluatePayment = () =>{
        setShowCoursePayment(false)
        setShowAudiobookPayment(false)
        setShowLiveTestPayment(false)
        setShowPreb4prePayment(false)
        setShowPracticeTestPayment(false)
        setShowAskToEvaluatePayment(true)
    }

    const handleExploreOption = (value) => {
        if (value === "courses")
            handleShowCoursePayment()
        else if (value === "audiobook")
            handleShowAudiobookPayment()
        else if (value === "livetests")
            handleShowLiveTestPayment()
        else if (value === "practicetests")
            handleShowPracticeTestPayment()
        else if (value === "preb4pre")
            handleShowPreb4prePayment()
        else if (value === "asktoevaluate")
            handleShowAskToEvaluatePayment()
    }

    return (
        <div className="invoices">
            <Sidebar activeLink={'more'} />
            <main className="main">
                {
                    !mobileView &&
                    <div className="invoices-btn">
                            <button className={showCoursePayment ? "highlight-btn" : ""} onClick={handleShowCoursePayment} >Courses</button>
                            <button className={showAudiobookPayment ? "highlight-btn" : ""} onClick={handleShowAudiobookPayment} >Audio books</button>
                            <button className={showLiveTestPayment ? "highlight-btn" : ""} onClick={handleShowLiveTestPayment} >Live Tests</button>
                            <button className={showPracticeTestPayment ? "highlight-btn" : ""} onClick={handleShowPracticeTestPayment} >Practice Tests</button>
                            <button className={showPreb4prePayment ? "highlight-btn" : ""} onClick={handleShowPreb4prePayment} >PreB4Pre</button>
                            <button className={showAskToEvaluatePayment ? "highlight-btn" : ""} onClick={handleShowAskToEvaluatePayment} >Ask To Evaluate</button>
                    </div>
                }
                {
                    mobileView && <div className="d-flex p-3 justify-content-center">
                        <div className="invoices-options">
                            <select
                                name="invoices-items"
                                onChange={(e) => { handleExploreOption(e.target.value); setExploreOption(e.target.value) }}
                                value={exploreOption}
                            >
                                <option value="courses">Courses</option>
                                <option value="audiobooks">Audiobooks</option>
                                <option value="livetests">Live Tests</option>
                                <option value="practicetests">Practice Tests</option>
                                <option value="preb4pre">PreB4Pre</option>
                                <option value="asktoevaluate">Ask To Evaluate</option>
                            </select>
                        </div>
                    </div>
                }

                <div className="mx-5">
                    <Link to="/more"><BackArrow /></Link>
                </div>

                {showCoursePayment && <CourseInvoice />}
                {showAudiobookPayment && <AudiobookInvoice />}
                {showLiveTestPayment && <LiveTestInvoice />}
                {showPracticeTestPayment && <PracticeTestInvoice />}
                {showPreb4prePayment && <PreB4PreInvoice/>}
                {showAskToEvaluatePayment && <AskToEvaluateInvoice/>}
            </main>
        </div>
    )
}

export default Invoices
