import React, { useEffect, useState } from 'react'

import { getAllCourses } from '../../actions/courseActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/logo.svg';
import noImage from '../../assets/images/otp.png';
import 'react-pro-sidebar/dist/css/styles.css';
import "./AllCourses.scss"
import SearchItem from '../../Components/Search/SearchItem';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

function AllCourses() {

    const dispatch = useDispatch();

    const getCourses = useSelector(state => state.getAllCourses)
    const { loading, error, courses } = getCourses;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [searchResults, setSearchResults] = useState([])



    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAllCourses())

    }, [dispatch, userInfo])

    const showAuthors = (Authors) =>{
        if(Authors.toString().length > 12){
            return Authors.toString().slice(0,12)+'...';
        }else{
            return Authors.toString();
        }
    }


    return (
        <div className="all-courses">
            <div className="d-md-flex mx-5 justify-content-center">
                {
                    courses && courses.length !== 0 && <SearchItem
                        allItems={courses}
                        from={"courses"}
                        handleSetSearchResults={(results) => setSearchResults(results)}
                    />
                }
            </div>

            <div>
                {
                    loading ? <MyTestseriesLoading /> :

                        (error || searchResults.length === 0) ? <div className="no-live-tests">
                            <img src={noImage} alt="" />
                            <h3>No Courses Found</h3>
                        </div> : <div className="d-flex m-2 flex-wrap justify-content-center">{
                            searchResults.map((prop) => <div key={prop.id} className="course-card">
                                <Link to={`/course/${prop.id}`}>
                                    <img src={prop.imageURL ? prop.imageURL : Logo} alt="logo" />
                                    <div className="course-details">
                                        <div style={{height:'120px', width:'90%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                            <p title={prop.name} style={{margin:'0px'}}>{prop.name.substring(0,47)}{prop.name.length > 47 && '...'} </p>
                                        </div>
                                        <p className="authors" title={prop.authors} >
                                            by {showAuthors(prop.authors)} 
                                        </p>
                                        <p className="price">
                                            Price: {(prop.price === 0) ? "Free" : `₹ ${prop.price}`}
                                        </p>
                                    </div>
                                </Link>
                            </div>)
                        }</div>
                }
            </div>
        </div>
    )
}

export default AllCourses
