import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import Sidebar from '../../Components/main/sidebar/sidebar';
import BarChart from '../../Components/Graphs/BarChart';

import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import noImage from '../../assets/images/otp.png';

import {
    getPracticeTestAnalytics,
    getPracticeQPaper,
    getPracticeSheet
} from '../../actions/EnrolledTestSeries';

import './analytics.scss'

import DOMPurify from 'dompurify';
import Pdf from '../../Components/PdfViewer/Pdf';
import SearchItem from '../../Components/Search/SearchItem';

const PracticeTestAnalytics = ({ match }) => {
    const dispatch = useDispatch();

    const [Analytics, setAnalytics] = useState({});
    const [attempt, setAttempt] = useState('');
    const [data, setData] = useState(undefined);
    const [graphType, setGraphType] = useState('');
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState([]);
    const [type, setType] = useState(false);
    const [thisQuestion, setThisQuestion] = useState({});
    const [show, setShow] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [ sections, setSections ] = useState({});
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [scrollToQues, setScrollToQues] = useState(1);

    const getPracticeAnalytics = useSelector(state => state.getPracticeAnalytics)
    const { loading, error, analytics } = getPracticeAnalytics;

    const getPracticeAnalyticsPaper = useSelector(state => state.getPracticeAnalyticsPaper)
    const { loading: loadingPaper, error: errorPaper, Paper } = getPracticeAnalyticsPaper;

    const getPracticeAnswerSheet = useSelector(state => state.getPracticeAnswerSheet)
    const { loading: loadingAnswerSheet, error: errorAnswerSheet, answerSheet } = getPracticeAnswerSheet;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getPracticeTestAnalytics(match.params.id))
        dispatch(getPracticeQPaper(1, match.params.id))
    }, [dispatch,userInfo ,match.params.id])


    useEffect(() => {

        if (analytics) {
            if (analytics.length !== 0) {
                setAttempt('Attempt 1');
                setAnalytics(analytics[0]);
                setGraphType('Classification')
                setData({
                    labels: ['Conceptual', 'Application', 'Gk'],
                    datasets: [{
                        label: 'Classification',
                        data: [
                            analytics[0].classification.conceptual,
                            analytics[0].classification.application,
                            analytics[0].classification.gk,
                        ],
                        backgroundColor: [
                            '#0c343d',
                            '#2f5c50',
                            '#007ea7',
                        ]
                    }]
                })
                dispatch(getPracticeSheet(analytics[0].answerSheet));
            }
        }
    }, [analytics, dispatch])


    useEffect(()=>{
        if(analytics){
            if(analytics.length > 0 && !analytics[0].sections){
                setQuestions(Paper);
                setTotalQuestions(Paper.length)
            }else if(analytics.length > 0 && analytics[0].sections.length > 0){
                const questions = Paper.filter((element)=> (element.section === analytics[0].sections[0].section))
                setQuestions(questions);
                setTotalQuestions(Paper.length)
                setSections(analytics[0].sections[0])
            }
        }
    }, [analytics, Paper])

    useEffect(()=>{
        if(!errorPaper){
            var question = questions.map(item => ({
                ...item,
                name: item.description
            }))
            setQuestion(question)
            setSearchResults(question)
        }
    },[questions,errorPaper])

    const myFunction1 = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown1").classList.toggle('show');
    }
    const myFunction2 = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown2").classList.toggle('show');
    }

    const selectAnalytics = (idx) => {
        if (document.getElementById("myDropdown1").classList.contains('show')) {
            document.getElementById("myDropdown1").classList.remove('show')
        }

        setAttempt(`Attempt ${idx + 1}`);
        setAnalytics(analytics[idx]);
        setGraphType('Classification');
        setData({
            labels: ['Conceptual', 'Application', 'Gk'],
            datasets: [{
                label: 'Classification',
                data: [
                    analytics[idx].classification.conceptual,
                    analytics[idx].classification.application,
                    analytics[idx].classification.gk,
                ],
                backgroundColor: [
                    '#0c343d',
                    '#2f5c50',
                    '#007ea7',
                ]
            }]
        })

        dispatch(getPracticeQPaper(idx+1,match.params.id));
        dispatch(getPracticeSheet(analytics[idx].answerSheet));
    }

    const graphData = (type) => {
        if (document.getElementById("myDropdown2").classList.contains('show')) {
            document.getElementById("myDropdown2").classList.remove('show')
        }

        switch (type) {
            case 'classification':
                setData({
                    labels: [
                        'Conceptual',
                        'Applied',
                        'Factual'
                    ],
                    datasets: [{
                        label: 'Classification',
                        data: [
                            Analytics.classification.conceptual,
                            Analytics.classification.application,
                            Analytics.classification.gk],
                        backgroundColor: [
                            '#0c343d',
                            '#2f5c50',
                            '#007ea7',
                        ]
                    }]
                })
                setGraphType('Classification')
                break;
            case 'category':
                setData({
                    labels: [
                        'Category1',
                        'Category2',
                        'Ncert',
                        'Gs Full Test Lv1',
                        'Gs Full Test Lv2',
                        'Reference Book'
                    ],
                    datasets: [{
                        label: 'Category',
                        data: [
                            Analytics.category.category1,
                            Analytics.category.category2,
                            Analytics.category.gsFullTestLv1 ? Analytics.category.gsFullTestLv1 : 0,
                            Analytics.category.ncert ? Analytics.category.ncert : 0,
                            Analytics.category.gsFullTestLv2 ? Analytics.category.gsFullTestLv2 :0 ,
                            Analytics.category.referenceBook ? Analytics.category.referenceBook :0,
                        ],
                        backgroundColor: [
                            '#db2549',
                            '#720256',
                            '#4f3e2c',
                            '#810dd3',
                            '#cad942',
                            '#C6B269'
                        ]
                    }]
                })
                setGraphType('Category')
                break;
            case 'difficulty':
                setData({
                    labels: [
                        'Easy',
                        'Medium',
                        'Tough'
                    ],
                    datasets: [{
                        label: 'Difficulty',
                        data: [
                            Analytics.difficulty.easy,
                            Analytics.difficulty.medium,
                            Analytics.difficulty.hard
                        ],
                        backgroundColor: [
                            '#ffe599',
                            '#8fce00',
                            '#f44336',
                        ]
                    }]
                })
                setGraphType('Difficulty')
                break;
            case 'questions':
                setData({
                    labels: ['Correct', 'Wrong', 'Attempted','Unattempted', 'Total'],
                    datasets: [{
                        label: 'Questions',
                        data: [
                            Analytics.correctAnswer,
                            Analytics.incorrectAnswer,
                            Analytics.incorrectAnswer + Analytics.correctAnswer,
                            Analytics.totalQuestions - Analytics.incorrectAnswer - Analytics.correctAnswer,
                            totalQuestions
                        ],
                        backgroundColor: [
                            '#40BF09',
                            '#FF666B',
                            '#f1c232',
                            '#53C7BB',
                            '#79309C'
                        ]
                    }]
                })
                setGraphType('Questions')
                break;
            default:
                console.log('invalid input')
        }
    }

    const selectSection = (section) => {
        if (document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
        document.getElementById(`practiceAnalytics${section.section}`).classList.add('selected-section')

        const questions = Paper.filter((element) => (element.section === section.section))
        setQuestions(questions)
        setSections(section)
    }

    const getSingleQuestion = (object, type) => {
        setType(type);
        setThisQuestion(object);
        if (type === "pdf") {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            }
        }
        handleShow()
    }
    const handleShow = () => setShow(true);

    const handleModalClose = () => {
        setShow(false)
        if (type === "pdf") {
            if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen()
            }
        }
    }

    const scrollToQuestion = () => {
        if(scrollToQues){
            
            const element = document.getElementsByClassName(`question_${scrollToQues}`)
            console.log(element)
            if(element.length > 0){
                element[0].scrollIntoView({
                    behavior:"smooth",
                    block:"center",
                    inline:'nearest'
                });
            }
        }
        
    }

    return (
        <div>
            {(loading || loadingPaper || loadingAnswerSheet) && <Loader />}
            {error && <AlertDismissibleExample data={error} />}
            {errorPaper && <AlertDismissibleExample data={errorPaper} />}
            {errorAnswerSheet && <AlertDismissibleExample data={errorAnswerSheet} />}

            <Sidebar activeLink={'mytestseries'} />
            {
                (!loading && !loadingPaper && !error && !errorPaper && analytics.length !== 0 ) &&
                <main className="main practice-analytics">
                    <section className="px-5 py-5" >
                        <Link to={`/testseries/practice/${match.params.id}`} style={{ textDecoration: 'none' }}>
                            <img src={leftArrow} alt="<" className="backBtn" />
                        </Link>
                    </section>
                    <section className="px-5" >
                        {
                            (Analytics && data) &&
                            <div>
                                <div className='d-flex justify-content-around py-3'>
                                    <div className="dropdown">
                                        <button className="dropbtn" onClick={myFunction1}>
                                            {attempt} <MdOutlineArrowDropDown />
                                        </button>
                                        <div id="myDropdown1" className="dropdown-content">
                                            {analytics.map((_element, i) => {
                                                return (
                                                    <p key={i} onClick={(e) => { e.preventDefault(); selectAnalytics(i) }}>
                                                        Attempt {i + 1}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropbtn" onClick={myFunction2}>
                                            {graphType}  <MdOutlineArrowDropDown />
                                        </button>
                                        <div id="myDropdown2" className="dropdown-content">
                                            <p onClick={(e) => { e.preventDefault(); graphData('classification') }}>Classification</p>
                                            <p onClick={(e) => { e.preventDefault(); graphData('category') }}>Category</p>
                                            <p onClick={(e) => { e.preventDefault(); graphData('difficulty') }}>Difficulty</p>
                                            <p onClick={(e) => { e.preventDefault(); graphData('questions') }}>Questions</p>
                                        </div>
                                    </div>
                                </div>
                                <BarChart data={data} />
                                <div className="marks">
                                    <h3>
                                        <span>Final Score: </span>
                                        {Analytics.finalScore} out of {Analytics.totalMarks}
                                    </h3>
                                </div>
                            </div>
                        }
                    </section>
                    <section className="px-5 py-5">
                        <div>
                            <h1 className="mt-2" style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                                Answer Sheet
                            </h1>
                        </div>
                        {
                            (Paper.length !== 0) && (
                                <div>
                                    <div className="section-selector">
                                        {
                                            Analytics.sections && Analytics.sections.length !==0 &&
                                            Analytics.sections.map((obj, i)=>{
                                                return(
                                                    <button 
                                                        id={`practiceAnalytics${obj.section}`} 
                                                        key={obj.section}
                                                        className={i===0 ? 'selected-section' : ''}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            selectSection(obj);
                                                        }}>
                                                        section {obj.section}
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{marginTop:"10px"}}>
                                        {questions.length !== 0 &&
                                            <div>
                                                <SearchItem
                                                    allItems={question}
                                                    from={"Questions"}
                                                    handleSetSearchResults={(results) => setSearchResults(results)}
                                                />
                                                <div className='question-scroller'>
                                                    <label>Question Number : &nbsp;</label>
                                                    <input 
                                                        type='number' 
                                                        min={1} 
                                                        max={question.length} 
                                                        value={scrollToQues} 
                                                        onChange={e => {setScrollToQues(e.target.value)}} 
                                                    />
                                                    <button onClick={e => {e.preventDefault(); scrollToQuestion()}}>Scroll</button>
                                                </div>
                                            </div>
                                        } 
                                    </div>
                                    <div className='section-info'>
                                        <h3><span>No. Of Questions : </span>{searchResults.length}</h3>
                                        <h3><span>Positive Marking : </span>{sections.marksPerQuestion}</h3>
                                        <h3><span>Negative Marking : </span>{sections.negativeQuestion}</h3>
                                    </div>
                                    <div>
                                        {
                                            (searchResults.length !== 0) ?
                                                searchResults.map((question, i) => {
                                                    return (
                                                        <div className={`questions-container question_${i+1}`} key={question.id}>
                                                            <div className="question">
                                                                <div className="ques-line1">
                                                                    <div className="qus-number">
                                                                        <h1>{i + 1}</h1>
                                                                    </div>
                                                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.description) }}></p>
                                                                </div>
                                                                {question.imageKey && (
                                                                    <div className="question-Img">
                                                                        <img src={question.imageKey} alt="questionimg" />
                                                                    </div>
                                                                )}
                                                                <div className="options">
                                                                    {question.options.map((option, i) => (
                                                                        <label className="option" key={i} >{option}
                                                                            <input type="radio" name={question.id} value={option}
                                                                                checked={
                                                                                    (answerSheet.answers && answerSheet.answers.length !==0) ? (
                                                                                        answerSheet.answers.find((ele) => (ele.qid === question.id && ele.answer === option)) ? true : false
                                                                                    ) : false
                                                                                }
                                                                                readOnly />
                                                                            <span className={
                                                                                (answerSheet.answers && answerSheet.answers.length !==0 ) ? (
                                                                                    answerSheet.answers.find((ele) => (ele.qid === question.id && ele.answer === option)) ? (option === question.correctOption ? 'right' : 'wrong') : ''
                                                                                ) : ''
                                                                            }></span>
                                                                        </label>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="correctAnswer">
                                                                <p>Correct Answer:  <span>{question.correctOption}</span></p>
                                                            </div>
                                                            {(question.explaination || question.pdf || question.video || question.audio) &&
                                                                <div className="explainAnswer">
                                                                    <p><strong>Explanation-</strong></p>
                                                                    {question.explaination && <div><p>{question.explaination}</p></div>}
                                                                    <div className="d-flex justify-content-around">
                                                                        {question.pdf && <button onClick={(e) => { e.preventDefault(); getSingleQuestion(question, 'pdf') }}>PDF</button>}
                                                                        {question.video && <button onClick={(e) => { e.preventDefault(); getSingleQuestion(question, 'video') }}>Video</button>}
                                                                        {question.audio && <button onClick={(e) => { e.preventDefault(); getSingleQuestion(question, 'audio') }}>Audio</button>}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className='question-details'>
                                                                <h3>Question Details</h3>
                                                                <div className='d-flex justify-content-around my-4 '>
                                                                    <p><span>Classification : </span>{question.classification}</p>
                                                                    <p><span>Category : </span>{question.category}</p>
                                                                    <p><span>Difficulty : </span>{question.difficulty}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                <div className="questions-container">
                                                    <div className='content-notAvail'>
                                                        <img src={noImage} alt="" />
                                                        <h3>No Questions Available</h3>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </section>
                </main>
            }
            {
                show && <div className="modal-view-new" >
                    <div className="modal-custom-content">
                        <div className="modal-custom-header">
                            <h3 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(thisQuestion.description)}}></h3>
                        </div>
                        <div>
                            <div className="modal-custom-body" >
                                {type === 'pdf' ? (
                                    <div>
                                        <Pdf url={thisQuestion.pdf} />
                                    </div>
                                ) : type === 'video' ? (<div className="video-styles">
                                    <video width="100%" height="100%" controls controlsList="nodownload">
                                        <source src={thisQuestion.video} type="video/mp4"/>
                                    </video>
                                </div>) : (
                                    <div className="audio-styles">
                                        <audio controls controlsList="nodownload">
                                            <source src={thisQuestion.audio} type='audio/mpeg'/>
                                        </audio>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-custom-footer d-flex flex-column flex-md-row align-items-center justify-content-between" style={{float:'right'}}>
                            <button onClick={() => handleModalClose()}>Close</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PracticeTestAnalytics
