import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../Components/Loader';
import ReactStars from 'react-rating-stars-component';
import AlertDismissibleExample from '../../Components/Error/error';
import leftArrow from '../../assets/icons/back-arrow.svg';
import Slider from 'react-slick';
import courseImage from '../../assets/icons/course1.png';
import { 
    getFoundationalCourseById,
    postEnrollFoundationalCourses,
    postFoundationalFeedback
} from '../../actions/courseActions';
import { getUserDetails } from '../../actions/userActions';
import { CreditCard, Equalizer, Language, ArrowForwardIos } from '@material-ui/icons';
import CourseDetailsAccordion from '../../views/CourseDetailsAccordion';
import defaultImage from "../../assets/images/overview.svg"
import CourseSubjects from '../../Screens/courseDetails/CourseSubjects';
import DOMPurify from 'dompurify';
import "./FoundationalCourse.css"
import { Modal } from 'react-bootstrap';

const FoundationalCourseDetailsScreen = ({match}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const getFoundationalCourseDetails = useSelector(state => state.getFoundationalCourseDetails)
    const { loading, error, FoundationalCourse } = getFoundationalCourseDetails;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { user } = getUserDetail;

    const postEnrollFoundationalCourse = useSelector(state => state.postEnrollFoundationalCourse)
    const { loading: loadingEnroll, error: errorEnroll, message } = postEnrollFoundationalCourse;

    const postFoundationalRating = useSelector(state => state.postFoundationalRating)
    const { loading: loadingRating, error: errorRating, message:messageRating } = postFoundationalRating;

    const [review, setReview] = useState(0)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getFoundationalCourseById(match.params.id))
        dispatch(getUserDetails())
    }, [userInfo, dispatch, match, message, messageRating])

    useEffect(()=>{
        if (FoundationalCourse !== undefined && FoundationalCourse.course !== undefined) {
            var reviews = (((FoundationalCourse.course.oneStar * 1) +
                (FoundationalCourse.course.twoStar * 2) +
                (FoundationalCourse.course.threeStar * 3) +
                (FoundationalCourse.course.fourStar * 4) +
                (FoundationalCourse.course.fiveStar * 5))
                /
                (FoundationalCourse.course.totalReviewers))
            setReview(reviews)
        }
    },[FoundationalCourse])

    let faqs = [
        {
            question: "How are these courses going to help with my preparation?",
            answer:"These courses will give you all the resources you need for your preparation. It covers everything a typical competitive exam syllabus has. From concise studymaterial for general knowledge to previous year’s question papers and comprehensive practice for reasoning and mathematics, these courses cover everything.",
        },
        {
            question: "Is the course paid?",
            answer: "Yes, the course is paid so we can maintain the standards of this platform where our team of developers and experienced faculty collaborate and work hard so we can equip an aspirant with everything they need to clear various competitive exams."
        },
        {
            question: "What is the duration of the various courses?",
            answer: "For through preparation and in order to cover all the necessary topics the duration of the courses vary for different exams."
        },
        {
            question: "Who is the course coordinator for these courses?",
            answer: "We have a team of experienced faculties with us and everyone has their own niche which they expertise in hence every course has a different coordinator"
        }

    ];

    const reviewStar = (newRating) => {
        dispatch(postFoundationalFeedback(match.params.id, newRating))
    }

    const colors = [
        { bg: '#9f8400' },
        { bg: '#5b2e3d' },
        { bg: '#007947' },
        { bg: '#590196' },
    ]

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: false,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1126,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 880,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: false,
                }
            }
        ]
    };

    const getEnrolled = (e) => {
        e.preventDefault();
        dispatch(postEnrollFoundationalCourses(match.params.id))
    }

    return (
        <div style={{backgroundColor:'#edf3ff'}}>
            {(loading ||  loadingEnroll || loadingRating) && <Loader />}
            {error && <AlertDismissibleExample data={error} />}
            {errorEnroll && <AlertDismissibleExample data={errorEnroll} />}
            {errorRating && <AlertDismissibleExample data={errorRating} />}

            <main>
            { FoundationalCourse.course && (
                
                <div className="course-overview" style={{backgroundColor:'#edf3ff'}}>
                    <div >
                        <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                            <img src={leftArrow} alt="<" style={{ margin: 20 }} />
                        </button>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <h2>{FoundationalCourse.course.name}</h2>
                        <img src={FoundationalCourse.course.imageURL ? FoundationalCourse.course.imageURL : defaultImage} alt="" width="200" />
                    </div>

                    <div className="course-content p-2 p-md-5">
                        <div className="course-info">
                            <h3>How does this course help you?</h3>
                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(FoundationalCourse.course.description)}}></p>
                        </div>

                        <div className="course-info">
                            <h4>Subjects</h4>
                            <div className="row">
                                <div className="col-md-6">

                                    <div className="topics">
                                        <CourseSubjects content={FoundationalCourse.subjects} />
                                    </div>

                                </div>
                                <div className="col-md-6 faq-section">
                                    <div className="d-flex flex-wrap justify-content-md-around justify-content-center ">
                                        <div>
                                            <div className="subjects-info my-1">
                                                <div className='subjects-info my-0 d-flex'>
                                                    <CreditCard />
                                                    <p className="mx-2">Cost: {FoundationalCourse.course.price === 0 ? "Free" : `₹ ${FoundationalCourse.course.price}`}</p>
                                                </div>
                                                {FoundationalCourse.installments.length > 0 && <span className='installment-tag' onClick={handleShow}>View Installments <ArrowForwardIos/></span>}
                                            </div>
                                            <div className="subjects-info my-0 d-flex">
                                                <div className="d-flex">
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        activeColor="#FFA700"
                                                        onChange={reviewStar}
                                                        value={
                                                            ((
                                                                (FoundationalCourse.course.oneStar * 1) +
                                                                (FoundationalCourse.course.twoStar * 2) +
                                                                (FoundationalCourse.course.threeStar * 3) +
                                                                (FoundationalCourse.course.fourStar * 4) +
                                                                (FoundationalCourse.course.fiveStar * 5))
                                                                /
                                                                (FoundationalCourse.course.totalReviewers)
                                                            ) ? ((
                                                                (FoundationalCourse.course.oneStar * 1) +
                                                                (FoundationalCourse.course.twoStar * 2) +
                                                                (FoundationalCourse.course.threeStar * 3) +
                                                                (FoundationalCourse.course.fourStar * 4) +
                                                                (FoundationalCourse.course.fiveStar * 5))
                                                                /
                                                                (FoundationalCourse.course.totalReviewers)
                                                            ) : 0
                                                        }
                                                        edit={!FoundationalCourse.isRated}
                                                        isHalf={FoundationalCourse.isRated} />
                                                    <span className="mx-1 align-self-center"> {review ? review.toFixed(1) : "0.0"}</span>
                                                </div>
                                            </div>

                                            <div className='subjects-info my-0 d-flex'>
                                                <div className="d-md-block d-flex justify-content-center">
                                                    Total Reviewers: {FoundationalCourse.course.totalReviewers}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="subjects-info my-3 d-flex ">
                                                <Equalizer />
                                                <p className="mx-2">{FoundationalCourse.subjects.length} Subjects</p>

                                            </div>
                                            <div className="subjects-info my-3 d-flex">
                                                <Language />
                                                <p className="mx-2">Online</p>
                                            </div>
                                            {
                                                user.verified ? (FoundationalCourse.isEnrolled ? (
                                                    <span className="enroll-btn" style={{ cursor: 'no-drop' }} onClick={null}>Enrolled</span>
                                                )  : (
                                                    <span className="enroll-btn" style={{ cursor: 'pointer' }} onClick={getEnrolled}>
                                                        Enroll
                                                    </span>
                                                )) : <div>
                                                    <button className="no-enroll-btn" style={{ cursor: 'not-allowed' }}>
                                                        Enroll
                                                    </button>
                                                    <p style={{ color: '#ff0101', fontSize: 12 }}>
                                                        Please verify your email address to enroll in this course.
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    
                                    <div className="faqs">
                                        <h2>FAQs</h2>
                                        <CourseDetailsAccordion faqs={faqs} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="p-2 p-md-5">
                            {FoundationalCourse.tests.length > 0 && (<h1 className="m-0">{FoundationalCourse.tests.length} Assignments</h1>)}
                            <Slider {...settings} className='mt-4'>
                                {FoundationalCourse.tests.map((assignment, idx) => {
                                    return (
                                        <div key={assignment.id}>
                                            <div style={{ backgroundColor: colors[(colors.length > idx) ? idx : (idx % colors.length)].bg, width: 400, height: 300 }} className="Slidecard">
                                                <img src={courseImage} alt="assignment-img" />
                                                <div>
                                                    <p style={{ fontSize: 18, color: "#f5ffff",padding:'0' }}>{assignment.testname}</p>
                                                    <p style={{ fontSize: 16,margin:'0',fontWeight:'400' ,color: "rgb(233 237 237)" }}>{assignment.subHeading}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </section>
                    </div>
                </div>
            )}
            </main>
            <Modal 
                show = {show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Installment Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                FoundationalCourse.installments && FoundationalCourse.installments.map((installment,idx)=>(
                                    <tr key={idx}>
                                        <td>{(idx+1)}.</td>
                                        <td>{new Date(installment.date).toDateString()}</td>
                                        <td>₹{installment.amount}/-</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <hr></hr>
                    <p>
                        <span style={{fontWeight:"600"}}>Total Installments : </span>
                        {FoundationalCourse.installments && FoundationalCourse.installments.length}
                    </p>
                    <p>
                        <span style={{fontWeight:"600"}}>Total Amount : </span>
                        ₹ {FoundationalCourse.course && FoundationalCourse.course.price}/-
                    </p>
                    <hr></hr>
                    <p style={{fontWeight:"800"}} >Note:</p>
                    <p>{FoundationalCourse.installmentsDescription}</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FoundationalCourseDetailsScreen