import React, { useEffect } from 'react'
import "./Policies.scss"
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg"
import { Link } from 'react-router-dom';

function TermsAndConditions() {

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])


    return (
        <div className="bg-style">
            <div className="px-2 px-md-4 pt-4">
                <Link to="/more"><BackArrow /></Link>
            </div>
            <div className="policies">


                <div >
                    <h2>
                        TERMS & CONDITIONS- YES MENTOR
                    </h2>


                </div>

                <div>
                    <div>
                        <p>
                            This Website User Agreement and the Privacy Policy lays out the terms and conditions and rules, as maybe amended and supplemented, from time to time (hereinafter referred to as the “Agreement”) which shall be applicable to the access and use of the website of Yes Mentor, i.e. www.yesmentor.in (“Website”) by you, the visitor/ user (“User”) of the Website.
                        </p>
                    </div>

                    <div>
                        <ol>
                            <li>
                                <h4>ACCEPTANCE OF TERMS AND MODIFICATION THEREOF</h4>
                                <ol>
                                    <li>
                                        <p>
                                            Access of the Website by the User constitutes an acknowledgement and acceptance in full, of all the terms, conditions and notices as stated in this Agreement and without any modification and/or exception by the User of this Agreement. If the User does not agree with any part of such terms, conditions and notices as stated in this Agreement in any manner, the User must not access the Website.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Yes Mentor reserves the right to change the terms, conditions and notices pursuant to which the Website is accessed by the User, without any notice or intimation of such change.
                                        </p>
                                    </li>

                                </ol>
                            </li>
                            <li>
                                <h4>LIMITED USER</h4>
                                <ol>
                                    <li>
                                        <p>
                                            The User agrees that given the nature of the Internet, even though the Website is targeted to Indian Residents only, it may be accessed in other parts of the world. The material/information on this Website is not intended for use by persons located in, or residents in countries that restrict the distribution of such material/information or by any person in any jurisdiction where distribution or use of such material/information or usage or access of Website will be contrary to law or any regulation. It shall be the responsibility of every User to be aware of and fully observe the applicable laws and regulations of the jurisdiction which User is subject of.
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            The User further agrees and undertakes not to reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, services or intellectual property obtained from the Website in any manner whatsoever. Reproduction, copying of the content for commercial or non‐commercial purposes and unwarranted modification of data and information within the content of the Website is strictly not permitted without prior written consent from Yes Mentor and/or third party owners. However, some of the content of our services or other files may be made available for download from the website which is permitted to be copied and/or used only for personal purposes of the User. The User and/or any third party is prohibited from running or displaying this Website and /or information displayed on this Website on any other Website or frames , without prior written consent from Yes Mentor.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>DISCLAIMER OF WARRANTIES</h4>
                                <ol>
                                    <li>
                                        <p>Yes Mentor has endeavoured to ensure that all the information provided by it on this Website is correct, but it neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data or information displayed on this Website and Yes Mentor shall not be, in any manner liable for inaccuracy/error if any. Yes Mentor makes no warranty, express or implied, concerning the Website and/or its contents and disclaims all warranties of fitness for a particular purpose and warranties of merchantability in respect of information displayed and communicated through or on the Website, including any liability, responsibility or any other claim, whatsoever, in respect of any loss, whether direct or consequential, to any User or any other person, arising out of or from the use of any such information as is showed or communicated through or on the Website or the provision of the Services.</p>
                                    </li>
                                    <li>
                                        <p>Yes Mentor shall not be held responsible for non‐availability of the Website at any point in time for any reason whatsoever. The User understands and agrees that any material and/or data downloaded or otherwise found from Yes Mentor through the Website is done entirely at his discretion and risk and he will be solely accountable for any damage to his computer systems or any other loss that results from such material and/or data.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>LINKS TO THIRD PARTY SITES</h4>
                                <ol>
                                    <li>
                                        <p>
                                            The Website may contain links to other websites or may contain features of any nature of other websites on the Website ("Linked Sites"). The Linked Sites are not under the control of Yes Mentor or the Website and Yes Mentor is not responsible for the contents of any Linked Site, including without limitation any link or advertisement contained in a Linked Site, or any changes or updates to a Linked Site. Yes Mentor is not responsible for any form of transmission, whatsoever, received by the User from any Linked Site. The inclusion of any link does not imply endorsement of any nature by Yes Mentor or the Website of the Linked Sites or any association with its operators or owners.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Yes Mentor will be making calls and sending SMS through a thrid-party platform after The User's registration in order to provide our service. The User's registration means acceptance of the service.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Yes Mentor is not responsible for any errors, inclusions, omissions or representations on any Linked Site, or on any link contained in a Linked Site. The User is requested to verify the accuracy of all material on his own before undertaking any dependence on such information of such products/ services that they trust may benefit the User.
                                        </p>
                                    </li>

                                </ol>
                            </li>
                            <li>
                                <h4>USER'S OBLIGATIONS</h4>
                                <ol>
                                    <li>
                                        <p>As a condition of admission and use of the Website, the User warrants that he will not use the Website for any purpose that is unlawful or illegal under any law for the time being in force within or outside India or forbidden by this Agreement. In addition, the Website shall not be used in any manner, which could damage, disable, overburden or impair it or interfere with any other party's use and/or enjoyment of the Website or infringe any intellectual property rights of Yes Mentor or any third party.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>CONTACT US FEATURE</h4>
                                <ol>
                                    <li>
                                        <p>
                                            The Users will be provided with Contact Us features on the Website. The Users will be able to provide their contact details to enable Yes Mentor to contact them.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            The Users may further be provided with features to contact Yes Mentor, raise queries, comments or interact with Yes Mentor. However Yes Mentor shall be at its sole discretion and be within its rights to answer, reply or opt not to reply to any such queries or comments.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            By using the said features, User permits Yes Mentor to contact them on their registered details, for any clarification or to offer any other service from time to time.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    Breach
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            Without prejudice to the other remedies available to Yes Mentor under this Agreement or under applicable law, Yes Mentor may limit the User's activity, warn other Users of the User's actions, immediately temporarily / indefinitely suspend or terminate the User’s use of the Website, and/or refuse to provide the User with access to the Website if the User is in breach of this Agreement.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    OWNERSHIP AND PROPRIETARY RIGHTS
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            The content of the Website and all copyrights, patents, trademarks, service marks, trade names and all other intellectual property rights therein are owned by Yes Mentor or validly licensed to Yes Mentor and are protected by applicable Indian and international copyright and other intellectual property law. The User acknowledges, understands and agrees that he shall not have, nor be entitled to claim, any rights in and to the Website content and/or any portion thereof.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Some of the content on the Website have been permitted by the third party/ies to be used by Yes Mentor in such form and manner as may be desired by Yes Mentor and Yes Mentor will makes its best endeavours to give credit to such third party/ies during publication of such content on its Website.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Any copyrighted or other proprietary content dispersed on or through the Website with the consent of the owner must contain the appropriate copyright or other proprietary rights notice. The unauthorized submission or distribution of copyrighted or other proprietary content is illegal and could subject the User to personal liability or criminal prosecution.
                                        </p>
                                    </li>

                                </ol>
                            </li>
                            <li>
                                <h4>
                                    LIMITATION OF LIABILITY
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            The user acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the Site, Application, Services and Collective Content, your enrolment of any Course via the Site, Application and Services, and any contact you have with other users of Yes Mentor whether in person or online remains with you. Neither Yes Mentor nor any other party involved in creating, producing, or delivering the Site, Application, Services, Collective Content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with these terms, from the use of or inability to use the Site, Application, Services or Collective Content, from any communications, interactions or meetings with other users of the Site, Application, or Services or other persons with whom you communicate or interact as a result of your use of the Site, Application, Services, or from your Enrolment of any Course via the Site, Application And Services, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not Yes Mentor has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    INDEMNIFICATION
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            The User agrees to indemnify, defend and hold harmless Yes Mentor, its affiliates, group companies and their directors, officers, employees, agents, third party service providers, and any other third party providing any service in relation to the Website whether directly or indirectly, from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Yes Mentor that arise out of, result from, or may be payable by virtue of, any breach or non‐performance of any terms of this Agreement including any representation, warranty, covenant or agreement made or obligation to be performed by the User pursuant to this Agreement.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    SEVERABILITY
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            If any provision of this Agreement is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of this Agreement shall continue to be in full force and effect.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    FORCE MAJEURE
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            Yes Mentor shall not be liable for any failure to perform any of its obligations under this Agreement or provide the Services or any part thereof if the performance is prevented, hindered or delayed by a Force Majeure Event and in such case its obligations shall be suspended for so long as the Force Majeure Event continues.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    GOVERNING LAW
                                </h4>
                                <ol>
                                    <li>
                                        <p>
                                            This Agreement shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles. In the event any dispute in relation hereto is brought by the User, it shall be subject to the exclusive jurisdiction of the courts of Agra, India.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4>
                                    CONTACT INFORMATION
                                </h4>
                                <p>
                                    Yes Mentor Grievance Officer shall undertake all reasonable efforts to address your grievances at the earliest possible opportunity. You may contact it at:
                                    <br /> Legal Officer: Mr Jerry Joy
                                    <br /> Address: 29, Kailash Vihar Behind St. Conrads Inter College, Gailana Rd, Khandari, Agra, Uttar Pradesh 282002

                                </p>


                                <p>
                                    Reach out to us on legal@yesmentor.in, in case of any queries.
                                </p>
                            </li>
                        </ol>
                    </div>





                    <div>
                        <h4>Contact us</h4>
                        <ul>
                            <li><strong>Mobile:</strong>    <a href="tel:+919675512777 ">
                                +91 9675512777
                            </a> </li>
                            <li><strong>Mon-Sun:</strong> 9 AM - 9 PM IST</li>
                            <li><strong>Email:</strong> legal@yesmentor.in</li>
                            <li><strong>Address:</strong> 29, Kailash Vihar Behind St. Conrads Inter College, Gailana Rd, Khandari, Agra, Uttar Pradesh 282002 </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>


    )
}

export default TermsAndConditions
