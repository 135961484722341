export const GET_PUBLISHED_OFFLINE_PREB4PRE_REQUEST = 'GET_PUBLISHED_OFFLINE_PREB4PRE_REQUEST';
export const GET_PUBLISHED_OFFLINE_PREB4PRE_SUCCESS = 'GET_PUBLISHED_OFFLINE_PREB4PRE_SUCCESS';
export const GET_PUBLISHED_OFFLINE_PREB4PRE_FAIL = 'GET_PUBLISHED_OFFLINE_PREB4PRE_FAIL';

export const POST_ENROLL_OFFLINE_PREB4PRE_REQUEST = 'POST_ENROLL_OFFLINE_PREB4PRE_REQUEST';
export const POST_ENROLL_OFFLINE_PREB4PRE_SUCCESS = 'POST_ENROLL_OFFLINE_PREB4PRE_SUCCESS';
export const POST_ENROLL_OFFLINE_PREB4PRE_FAIL = 'POST_ENROLL_OFFLINE_PREB4PRE_FAIL';

export const GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_REQUEST = 'GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_REQUEST';
export const GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_SUCCESS = 'GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_SUCCESS';
export const GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_FAIL = 'GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_FAIL';

export const GET_ENROLLED_OFFLINE_PREB4PRE_REQUEST = 'GET_ENROLLED_OFFLINE_PREB4PRE_REQUEST';
export const GET_ENROLLED_OFFLINE_PREB4PRE_SUCCESS = 'GET_ENROLLED_OFFLINE_PREB4PRE_SUCCESS';
export const GET_ENROLLED_OFFLINE_PREB4PRE_FAIL = 'GET_ENROLLED_OFFLINE_PREB4PRE_FAIL';

export const GET_OFFLINE_PREB4PRE_DETAILS_REQUEST = 'GET_OFFLINE_PREB4PRE_DETAILS_REQUEST';
export const GET_OFFLINE_PREB4PRE_DETAILS_SUCCESS = 'GET_OFFLINE_PREB4PRE_DETAILS_SUCCESS';
export const GET_OFFLINE_PREB4PRE_DETAILS_FAIL = 'GET_OFFLINE_PREB4PRE_DETAILS_FAIL';