import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux';

import {
    getAskToEvaluateByID
} from '../../actions/askToEvaluateActions';
import { 
    getUserDetails 
} from '../../actions/userActions';

import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';

//assets
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import Clock from '../../assets/icons/clock.svg';
import Cross from '../../assets/icons/cross.svg';
import Right from '../../assets/icons/RightAnswer.svg';

import DOMPurify from 'dompurify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { URL } from '../../config/config';

import './TestSeries.scss'


const AskToEvaluateInstructions = ({match, history}) => {
    const dispatch = useDispatch();

    const [check, setCheck] = useState(false)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getATEDetails = useSelector(state => state.getATEDetails)
    const { loading, error, askToEvaluate } = getATEDetails

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { loading: loadingUser, error: errorUser, user } = getUserDetail;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }

        dispatch(getAskToEvaluateByID(match.params.id))
        dispatch(getUserDetails())
    }, [userInfo, dispatch, match])

    const onChange = (e) => {
        e.preventDefault();
        if(check){
            setCheck(false)
        }else{
            setCheck(true)
        }
    }

    const startTest = (test) => {
        window.open(
            `${URL}/ask-to-evaluate/exam/${test.id}`,
            "Ask To Evaluate",
            "noopoener,fullscreen"
        )

        if(!localStorage.getItem(`${test.id}_answerSheet`)){
            localStorage.setItem(`${test.id}_answerSheet`,JSON.stringify([]));
            localStorage.setItem(`${test.id}_attempt`, JSON.stringify([]));
            localStorage.setItem(`${test.id}_markForReview`, JSON.stringify([]));
        }

        if(!localStorage.getItem(`${test.id}_chance`)){
            localStorage.setItem(`${test.id}_chance`,JSON.stringify(3));
        }

        let endingTime = (new Date(askToEvaluate.slot.endingTime).getTime() + (new Date(askToEvaluate.slot.endingTime).getTimezoneOffset() * 60000) - 60000)
        const testings = {
            endingTime:endingTime,
            duration:askToEvaluate.slot.duration
        };
        localStorage.setItem(`${test.id}_time`,JSON.stringify(testings));
        document.location.href = '/mytestseries';
    }

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m.toFixed(0)}min` : `${m.toFixed(0)} min`}`
    }

    return (
        <div>
            <main>
                {(loading || loadingUser)  && <Loader/>}
                {error && <AlertDismissibleExample data={error}/>}
                {errorUser && <AlertDismissibleExample data={errorUser}/>}

                {
                    (Object.keys(user).length > 0 && askToEvaluate) && 
                    <div>
                        <section className="px-5  py-5">
                            <div onClick={(e)=>{e.preventDefault(); history.goBack()}} style={{textDecoration:'none'}}>
                                <img  src={leftArrow} alt="<" className="backBtn"/>
                            </div>
                        </section>
                        <section className="px-5 ">
                            <div className="instruction-header">
                                <div>
                                    <h1 className="mt-3" style={{ fontSize: 30, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                                        Instructions
                                    </h1>
                                </div>
                                <div>
                                    <img src={Clock} alt="clock" />
                                    <p>{minToHours(askToEvaluate.slot.duration)}</p>
                                </div>
                            </div>
                        </section>
                        <section className="px-5">
                            <div className="test-profile">
                                <h4>Profile</h4>
                                <div className="img-container">
                                    {user.imageURL ? <img src={user.imageURL} alt="avtar" /> : <div className='img-letter'>
                                        <h1>{user.firstname.slice(0,1).toUpperCase()}</h1>
                                    </div>}
                                </div>
                                <div className="test-profile-info">
                                    <p>Name: <span style={{ color: 'black' }}>{user.firstname} {user.lastname}</span></p>
                                    <p>DOB: <span style={{ color: 'black' }}>{user.dob ? user.dob : "N/A"}</span></p>
                                    <p>ID: <span style={{ color: 'black' }}>{user.yesmentorid}</span></p>
                                </div>
                            </div>
                        </section>
                        <section>
                            {
                                askToEvaluate.sections && askToEvaluate.sections.map((section) => (
                                    <div className="py-3 instruction-sections" key={section.section}>
                                        <div className="section">
                                            <p>Section {section.section}</p>
                                        </div>
                                        <div className="test-marks">
                                            <img src={Right} alt="Right" />
                                            <p>For Correct Answers</p>
                                            <p style={{ color: "green" }}>{section.marksPerQuestion}</p>
                                        </div>
                                        <div className="test-marks">
                                            <img src={Cross} alt="cross" />
                                            <p>For Wrong Answers</p>
                                            <p style={{ color: "red" }}>{section.negativeQuestion}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </section>
                        <section className="px-5  py-2">
                            <div className="instructions">
                                <h1>INSTRUCTIONS</h1>
                                <div className="instruction-body" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(askToEvaluate.instruction) }} >
                                </div>
                            </div>
                        </section>
                        <section className="px-5  py-5">
                            <div className="checkBtn">
                                <label>
                                    <Checkbox onClick={onChange} checked={check ? true : false} className="checkBox" />
                                    <p>I have read all the instructions carefully and checked my profile details.</p>
                                </label>
                            </div>
                            <div className="attempt">
                                {   
                                    check ?
                                    (!askToEvaluate.isAttempted ? <button onClick={(e) => { e.preventDefault(); startTest(askToEvaluate) }}>Attempt Test</button> : (
                                        <>
                                            <button className="disable">Attempt Test</button>
                                            <p style={{ textAlign: 'center', color: 'red', marginTop: '10px' }}>No more attempts left !!</p>
                                        </>
                                    )) :
                                    <button className="disable">Attempt Test</button>
                                }
                            </div>
                        </section>
                    </div>
                }
            </main>
        </div>
    )
}

export default AskToEvaluateInstructions