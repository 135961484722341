import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetTest } from '../../actions/courseActions';
import './ContentCard.scss';
import { Equalizer } from '@mui/icons-material';

const ContentCard = ({ Name, Tagline, img, rightTag, bg, testId, cursor, width }) => {

    const dispatch = useDispatch();
    const resetAssignment = (id) => {
        dispatch(resetTest(id));
    }
    return (
        <div className={`${bg} content-card mb-3`} style={{ cursor: cursor || 'default', width: width }}>
            <div className="content-line1">
                <div className="left-content">
                    <span className="graph-icon"><Equalizer /></span>
                    <div>
                        <p style={{ fontSize: 20, fontWeight: '500', }}>{Name}</p>
                        <p style={{ fontSize: 14, opacity: 0.7 }}>{Tagline}</p>
                    </div>
                </div>
                <div>
                    <p>{rightTag}</p>
                </div>
            </div>
            {testId &&
                <div className="content-line2">
                    <div>
                        <button onClick={(e) => { e.preventDefault(); resetAssignment(testId) }}>Reset Test</button>
                    </div>
                    <div>
                        <Link to={`/course/assignment/analyst/${testId}`}>View Analytics</Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default ContentCard
