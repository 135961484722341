import React from 'react'
import './Description.scss'

import DOMPurify from 'dompurify';
import CourseSubjects from '../../../../Screens/courseDetails/CourseSubjects';

const Description = ({data}) => {
  return (
    <div className='description'>
        {
            data.course ? 
            <>
                <div className='about'>
                    <h4>About This Course</h4>
                    <p style={{marginBottom:'0px'}}>
                        <span>Title : </span>
                        {data.course.name}
                    </p>
                    <p style={{fontSize:'16px'}}>
                        {data.course.subHeading}
                    </p>
                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize("<span>Description : </span>"+data.course.description)}}></p>
                    <p style={{textAlign:'right', fontWeight:'bold'}}>
                        Author : {data.course.authors.toString()}
                    </p>
                </div>
                <hr></hr>
                <div className='course-content'>
                    <h4>Course Content</h4>
                    <CourseSubjects content={data.subjects} />
                </div>
                <hr></hr>
                <div className='installment-plans'>
                    <h4>Installment Plans</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.installments && data.installments.map((installment,idx)=>(
                                    <tr key={idx}>
                                        <td>{(idx+1)}.</td>
                                        <td>{new Date(installment.date).toDateString()}</td>
                                        <td>₹{installment.amount}/-</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <p><span>Note: </span>{data.installmentsDescription}</p>
                </div>
            </>
            : <></>
        }
    </div>
  )
}

export default Description