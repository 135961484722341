import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './more.scss';
import Sidebar from '../../Components/main/sidebar/sidebar';

import MoreImage from '../../assets/images/more.svg';
import userIcon from '../../assets/icons/usericon.svg';
import RightArrow from '../../assets/icons/right-arrow.svg';
import creditCard from '../../assets/icons/creditcard.svg';
import settings from '../../assets/icons/settings.svg';
import PrivacyPolicy from '../../assets/icons/privacyPolicy.svg';
import analytics from '../../assets/icons/analytics.svg';
import email from '../../assets/icons/email.svg';
import preb4pre from '../../assets/icons/Preb4preIcon.svg';
import history from '../../assets/icons/history.svg';
import { useDispatch, useSelector } from 'react-redux';
import person from "../../assets/images/person.png";
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import { getUserDetails } from '../../actions/userActions';
// import { logout } from '../../actions/userActions';
const More = () => {

    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { loading, error, user } = getUserDetail

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getUserDetails())
        }
    }, [userInfo, dispatch])

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(
    //         error === message
    //     ){
    //         dispatch(logout());
    //     }
    // },[dispatch, error ])

    return (
        <div>
            <Sidebar activeLink={'more'} />
            <main className="main">
                <div className="p-5">
                    <Row>
                        <Col md={6}>
                            {
                                loading ? <Loader /> : error ? <AlertDismissibleExample data={error} /> : user && <div className="profile-container">
                                    <img src={user.imageURL ? user.imageURL : person} alt="logo" />
                                    <div>
                                        <p className="welcome">Welcome</p>
                                        <p className="name">{user.firstname} {user.lastname}</p>
                                    </div>
                                </div>
                            }

                            <div className="more-container mt-5">
                                <Link to="/user/profile" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3"  >
                                        <div className="first-child">
                                            <img src={userIcon} alt="logo" />
                                            <div>
                                                <p className="heading">Personal Details</p>
                                                <p className="p">Check and edit your personal details</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo" />
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/myITW" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3" >
                                        <div className="first-child">
                                            <img src={analytics} alt="logo" />
                                            <div>
                                                <p className="heading">ITW</p>
                                                <p className="p">Get the analytics for Issues this week</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo"/>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/offline/my-pre-b4-pre" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3" >
                                        <div className="first-child">
                                            <img src={preb4pre} alt="logo" width={50} />
                                            <div>
                                                <p className="heading">Offline PreB4Pre</p>
                                                <p className="p">Get details for your offline preb4pre</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo"/>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/user/reset-password" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3" >
                                        <div className="first-child">
                                            <img src={settings} alt="logo" />
                                            <div>
                                                <p className="heading">Reset Password</p>
                                                <p className="p">Reset your old password to new one</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo" />
                                        </div>
                                    </div>
                                </Link>
                                
                                <Link to="/user/invoices" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3" >
                                        <div className="first-child">
                                            <img src={creditCard} alt="logo" />
                                            <div>
                                                <p className="heading">Invoices</p>
                                                <p className="p">Check all your invoices</p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo" />
                                        </div>
                                    </div>
                                </Link>


                                <a href="mailto:support@yesmentor.in" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3"
                                    >
                                        <div className="first-child">
                                            <img src={email} alt="logo" />
                                            <div>
                                                <p className="heading">Email us</p>
                                                <p className="p"> Send us an email, If you have any query </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo" />
                                        </div>
                                    </div>
                                </a>
                                <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3" >
                                        <div className="first-child">

                                            {/* <MdOutlinePolicy width={2}/> */}
                                            <img src={PrivacyPolicy} alt="logo" />
                                            <div>
                                                <p className="heading">Privacy Policy</p>
                                                <p className="p">
                                                    Check our privacy policy
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo" />
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
                                    <div className="more-card mb-3" >
                                        <div className="first-child">
                                            <img src={history} alt="logo" />
                                            <div>
                                                <p className="heading">Terms and Conditions</p>
                                                <p className="p">
                                                    View our terms and conditions
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={RightArrow} alt="logo" />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <img className="more-image" src={MoreImage} style={{ width: '100%', marginTop: 130 }} alt="img" />
                        </Col>
                    </Row>
                </div>
            </main>
        </div>
    )
}

export default More
