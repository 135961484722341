import axios from 'axios';
import {BASEURL} from '../config/config'; 
import {
    GET_PRACTICE_TESTSERIES_REQUEST,
    GET_PRACTICE_TESTSERIES_SUCCESS,
    GET_PRACTICE_TESTSERIES_FAIL,
    GET_LIVE_TESTSERIES_REQUEST,
    GET_LIVE_TESTSERIES_SUCCESS,
    GET_LIVE_TESTSERIES_FAIL,
    GET_PRACTICE_QUESTION_PAPER_REQUEST,
    GET_PRACTICE_QUESTION_PAPER_SUCCESS,
    GET_PRACTICE_QUESTION_PAPER_FAIL,
    POST_SUBMIT_PRACTICE_TEST_REQUEST,
    POST_SUBMIT_PRACTICE_TEST_SUCCESS,
    POST_SUBMIT_PRACTICE_TEST_FAIL,
    GET_PRACTICE_TEST_ANALYTICS_REQUEST,
    GET_PRACTICE_TEST_ANALYTICS_SUCCESS,
    GET_PRACTICE_TEST_ANALYTICS_FAIL,
    GET_PRACTICE_QPAPER_REQUEST,
    GET_PRACTICE_QPAPER_SUCCESS,
    GET_PRACTICE_QPAPER_FAIL,
    GET_PRACTICE_ANSWERSHEET_REQUEST,
    GET_PRACTICE_ANSWERSHEET_SUCCESS,
    GET_PRACTICE_ANSWERSHEET_FAIL,
    GET_LIVE_TESTSERIES_DETAILS_REQUEST,
    GET_LIVE_TESTSERIES_DETAILS_SUCCESS,
    GET_LIVE_TESTSERIES_DETAILS_FAIL,
    GET_LIVE_TEST_ANALYTICS_REQUEST,
    GET_LIVE_TEST_ANALYTICS_SUCCESS,
    GET_LIVE_TEST_ANALYTICS_FAIL,
    GET_LIVE_QPAPER_REQUEST,
    GET_LIVE_QPAPER_SUCCESS,
    GET_LIVE_QPAPER_FAIL,
    POST_SUBMIT_LIVE_TEST_REQUEST,
    POST_SUBMIT_LIVE_TEST_SUCCESS,
    POST_SUBMIT_LIVE_TEST_FAIL
} from '../constants/EnrolledTestSeries';

export const getAllPracticeTestSeries = () => async (dispatch,getState) => {
    try {

        dispatch({
            type: GET_PRACTICE_TESTSERIES_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries`, config)
        dispatch({
            type: GET_PRACTICE_TESTSERIES_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PRACTICE_TESTSERIES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getAllLiveTestSeries = () => async (dispatch,getState) => {
    try {

        dispatch({
            type: GET_LIVE_TESTSERIES_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/live/testseries`, config)
        dispatch({
            type: GET_LIVE_TESTSERIES_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_LIVE_TESTSERIES_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPracticeQuestionPaper = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PRACTICE_QUESTION_PAPER_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries/question/paper/${id}`, config)
        dispatch({
            type: GET_PRACTICE_QUESTION_PAPER_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PRACTICE_QUESTION_PAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postSubmitPracticeTest = (id, answers) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SUBMIT_PRACTICE_TEST_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/paid/testseries/answer/${id}`, { answers }, config)
        dispatch({
            type: POST_SUBMIT_PRACTICE_TEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_SUBMIT_PRACTICE_TEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getPracticeTestAnalytics = (id) => async (dispatch,getState) => {
    try {

        dispatch({
            type: GET_PRACTICE_TEST_ANALYTICS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries/analytics/${id}`, config)
        dispatch({
            type: GET_PRACTICE_TEST_ANALYTICS_SUCCESS,
            payload: data.data
        })

    } catch (error) {
        dispatch({
            type: GET_PRACTICE_TEST_ANALYTICS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPracticeQPaper = (no,id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PRACTICE_QPAPER_REQUEST
        })

        const config = {
            'Content-Type': 'application/json',
        }
        const { data } = await axios.get(`${BASEURL}/admin/paid/qpaper/${no}/${id}`, config)
        dispatch({
            type: GET_PRACTICE_QPAPER_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PRACTICE_QPAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getPracticeSheet = (id) => async (dispatch,getState) => {
    try {

        dispatch({
            type: GET_PRACTICE_ANSWERSHEET_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/paid/testseries/answersheet/${id}`, config)
        dispatch({
            type: GET_PRACTICE_ANSWERSHEET_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PRACTICE_ANSWERSHEET_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getLiveTestDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_LIVE_TESTSERIES_DETAILS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/live/testseries/${id}`, config)
        dispatch({
            type: GET_LIVE_TESTSERIES_DETAILS_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_LIVE_TESTSERIES_DETAILS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getLiveTestAnalytics = (id) => async (dispatch,getState) => {
    try {

        dispatch({
            type: GET_LIVE_TEST_ANALYTICS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/live/testseries/analytics/${id}`, config)
        dispatch({
            type: GET_LIVE_TEST_ANALYTICS_SUCCESS,
            payload: data.data[0]
        })

    } catch (error) {
        dispatch({
            type: GET_LIVE_TEST_ANALYTICS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getLiveQPaper = (id) => async (dispatch,getState) => {
    try {
        dispatch({
            type: GET_LIVE_QPAPER_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/live/testseries/question/${id}`, config)
        dispatch({
            type: GET_LIVE_QPAPER_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_LIVE_QPAPER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const postSubmitLiveTest = (id, answers) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SUBMIT_LIVE_TEST_REQUEST
        })


        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/user/live/testseries/submit/${id}`, { answers }, config)
        if(data[0].message == 'Answers successfully submitted')
        {
            const { data1 } = await axios.get(`${BASEURL}/user/live/testseries/analytics/${id}`, config)
        }
        dispatch({
            type: POST_SUBMIT_LIVE_TEST_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_SUBMIT_LIVE_TEST_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}
