import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertDismissibleExample from '../Components/Error/error';
import AlertSuccessExample from '../Components/success/success';
import Loader from '../Components/Loader';

import { useForm } from "react-hook-form";

import Logo from '../assets/icons/back-arrow.svg';
import Logoback from '../assets/icons/logo.svg';
import RightArrow from '../assets/icons/right-arrow.svg';
import signupImage from '../assets/images/forgotpassword.svg';

import { forgotPass } from '../actions/userActions';

const ForgotPasswordScreen = () => {
    const dispatch = useDispatch()

    const { register, handleSubmit, formState: { errors } } = useForm();

    const forgotPassword = useSelector(state => state.forgotPassword)
    const { loading, error, message } = forgotPassword

    const onSubmit = (data) => {
        dispatch(forgotPass(data.email));
    }
    return (
        <main>
            {loading && <Loader />}

            {error && <AlertDismissibleExample data={error} />}
            {message && <AlertSuccessExample data={message} />}
            <Row className="m-0">
                <Col md="6" lg="4" className="p-0">
                    <div className="form-wrapper">
                        <div>
                            <Link to='/login'>
                                <img src={Logo} style={{ width: '16px' }} alt="signup illustration" />
                            </Link>
                            <img src={Logoback} className="ml-4" alt="logo" />
                        </div>
                        <h1>Forgot<br /><span>Password?</span></h1>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input-wrapper my-5">
                                <div className="bg">
                                    <div className="input-cont">
                                        <input type="text" autoComplete="off" {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                        <label htmlFor="name" className="label-name">
                                            <span className="content-name">
                                                Email Address
                                            </span>
                                        </label>
                                    </div>
                                    {errors.email && errors.email.type === "required" && <span style={{ color: '#ff0101', fontSize: 12 }}>Email field is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span style={{ color: '#ff0101', fontSize: 12 }}>You have entered an invalid e-mail address.</span>}
                                </div>
                            </div>
                            <button type="submit" className="next-btn mt-4">
                                <img src={RightArrow} alt=">" />
                            </button>
                        </form>
                        <p className="mt-5 text-center" style={{ color: '#002A5D', fontSize: 18, width: '72%', margin: 'auto' }}>
                            We will send you a reset link on your email.
                        </p>
                    </div>
                </Col>
                <Col md="6" lg="8" className="p-0" style={{ backgroundColor: '#0669F8' }}>
                    <div className="image-wrapper">
                        <img src={signupImage} alt="signup illustration" className="m-0" />
                        <div>
                            <p style={{ width: ' 67%', margin: 'auto' }}>Forgot password? No Worries!</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </main>
    )
}

export default ForgotPasswordScreen
