import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './mycourse.scss';
import Logo from '../../assets/icons/logo.svg';
import playImage from '../../assets/icons/myplay.svg';
import assignment1 from '../../assets/icons/assignment1.svg';

import Sidebar from '../../Components/main/sidebar/sidebar';
import { ProgressBar } from 'react-bootstrap';

import { getAllUserCourses, getAssignmentProgress, postRecentSubjects } from '../../actions/courseActions';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import Slider from 'react-slick';
import MyCoursesLoading from '../../Components/ShimmerEffect/MyCourses/MyCoursesLoading';

import { getSubjects } from '../../actions/courseActions';
import { Link } from 'react-router-dom';

import Heading from '../../Components/Heading/Heading';

import ContentCard from '../../Components/ContentCard/ContentCard';
import noImage from '../../assets/images/otp.png';
import SearchItem from '../../Components/Search/SearchItem';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: false,
    centerPadding: '60px',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
                swipeToSlide: false,
            }
        },
        {
            breakpoint: 800,
            settings: {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                swipeToSlide: false,
            }
        }
    ]
};
const settings1 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: false,
    centerPadding: '60px',
    responsive: [
        {
            breakpoint: 1126,
            settings: {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                swipeToSlide: false,
            }
        },
        {
            breakpoint: 920,
            settings: {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
                swipeToSlide: false,
            }
        },
        {
            breakpoint: 518,
            settings: {
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                swipeToSlide: false,
            }
        }
    ]
};

const MyCourse = () => {
    const dispatch = useDispatch();
    const [test, setTest] = useState();
    const [score, setScore] = useState();
    const [searchResults, setSearchResults] = useState([])
    const [subject , setSubjects] = useState([])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getUserEnrolledCourses = useSelector(state => state.getUserEnrolledCourses)
    const { loading, error, courses } = getUserEnrolledCourses;

    const getSubjectsById = useSelector(state => state.getSubjectsById)
    const { loading: loadingSubjects, error: subjectError, subjects } = getSubjectsById;

    const getTestProgress = useSelector(state => state.getTestProgress)
    const { loading: loadingProgress, error: errorprogress, testProgress } = getTestProgress;

    const postResetTest = useSelector(state => state.postResetTest)
    const { loading: loadingForReset, error: errorForReset, message } = postResetTest;

    const postRecentSubject = useSelector(state => state.postRecentSubject)
    const { loading: loadingPostRecentSub, error: errorPostRecentSub } = postRecentSubject;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getAllUserCourses());
            dispatch(getAssignmentProgress());
        }
    }, [userInfo, dispatch])

    useEffect(() => {
        if (!subjectError && subjects.length > 0) {
            var subject = subjects.map(item => ({
                ...item,
                name: item.subjectName
            }))

            setSubjects(subject)
            setSearchResults(subject)
        }

    }, [subjects, subjectError])


    useEffect(() => {
        if (courses) {
            if (courses.length > 0) {
                dispatch(getSubjects(courses[0].course.id))
                if (testProgress.length > 0) {
                    const result = testProgress.filter((obj) => (obj.courseId === courses[0].course.id && obj.isAttempted === false));
                    setTest(result)
                    const filterArr = testProgress.filter((obj) => (obj.courseId === courses[0].course.id && obj.isAttempted === true));
                    setScore(filterArr)
                }
            }
        }
        if (message) {
            window.location.reload();
        }
    }, [courses, testProgress, dispatch, message])

    const getSubject = (id) => {
        dispatch(getSubjects(id))
        if (testProgress.length > 0) {
            const result = testProgress.filter((obj) => (obj.courseId === id && obj.isAttempted === false));
            setTest(result)
            const filterArr = testProgress.filter((obj) => (obj.courseId === id && obj.isAttempted === true));
            setScore(filterArr)
        }
    }

    const colors = [
        { bg: '#590196' },
        { bg: '#9f8400' },
        { bg: '#007947' },
        { bg: '#5b2e3d' },
    ]

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    const postRecentSub = (id) => {
        dispatch(postRecentSubjects(id));
    }
    return (
        <div className="main-Container">
            <Sidebar activeLink={'mycourse'} />
            {errorForReset && <AlertDismissibleExample data={errorForReset} />}
            {errorPostRecentSub && <AlertDismissibleExample data={errorPostRecentSub} />}
            {message && <AlertSuccessExample data={message} />}
            {(loadingProgress || loading || loadingForReset || loadingPostRecentSub || loadingSubjects) ? <MyCoursesLoading/> : (
                <main className="main">
                    <section className="px-5  py-5">
                        <Heading Text={'My Courses'} />
                        {
                            (!courses || courses.length === 0) &&
                            <div className="px-5  py-5">
                                <div className='content-notAvail'>
                                    <img src={noImage} alt="" />
                                    <h3>No Courses Available</h3>
                                </div>
                            </div>
                        }
                        <Slider {...settings}>
                            {error ? <AlertDismissibleExample data={error} /> : (courses.length > 0) && (
                                courses.map((course) => {
                                    return (
                                        <div key={course.id} style={{ width: 400, height: 300 }} className="Slidecard" onClick={(e) => { e.preventDefault(); getSubject(course.course.id) }}>
                                            {' '}
                                            <div>
                                                <img src={course.course.imageURL ? course.course.imageURL : Logo} alt="courseImg" />
                                                <div style={{height:'100px', width:'80%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                                    <p className='text' style={{margin:'0px'}} title={course.course.courseName}>
                                                        {course.course.courseName.slice(0,47)}{course.course.courseName.length > 47 && '...'}
                                                    </p>
                                                </div>
                                                <p style={{ margin: 0, fontSize: 16, opacity: 0.7 }}>
                                                    Time left: {course.timeLeft}
                                                </p>
                                                <div>
                                                    <ProgressBar
                                                        now={course.analytics}
                                                        variant='info'
                                                        label={`${course.analytics}%`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </Slider>
                    </section>
                    <section className="px-5  py-2">
                        {subjectError ? (
                            <></>
                        ) : (subjects.length !== 0) && (
                            <div>
                                <div>
                                    <Heading Text={'Your lessons'} />
                                    <SearchItem
                                        from={"Lessons"}
                                        allItems={subject}
                                        handleSetSearchResults={(results) => setSearchResults(results)}
                                    />
                                </div>
                                {searchResults.map((subject) => {
                                    return (
                                        <div key={subject.id} onClick={(e)=> {e.preventDefault(); postRecentSub(subject.id)}}>
                                            <Link to={`/chapter/${subject.id}/${subject.subjectName}/${subject.courseId}`}  style={{ cursor: 'pointer' }}>
                                                <ContentCard
                                                    Name={subject.subjectName}
                                                    // Tagline='Begineer'
                                                    rightTag={subject.timeRequire}
                                                    img={playImage}
                                                    bg='#0669F8'
                                                />
                                            </Link>
                                        </div>
                                    )
                                })}
                                {searchResults.length === 0 && (
                                    <div className="px-2  py-5">
                                        <div className='content-notAvail'>
                                            <img src={noImage} alt="" />
                                            <h3>Search Not Found!</h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </section>
                    <section >
                        {(!test || errorprogress) ? (<div></div>) : (test.length === 0) ? (
                            <></>
                        ) : (test.length !== 0) && (
                            <div className="px-5  py-2">
                                <Heading Text={'Your Assignments'} />
                                <Slider {...settings1} className="assignments">
                                    {test.map((tests, i) => {
                                        return (
                                            <Link to={`/course/assignment/${tests.testId}`} key={tests.testId} style={{ textDecoration: 'none' }}>
                                                <div style={{ backgroundColor: colors[i < colors.length ? i : i % colors.length].bg, cursor: 'pointer' }} className="assignment-card" >
                                                    <img src={assignment1} alt="assignment" />
                                                    <div>
                                                        <p style={{ fontSize: 20, fontWeight: '500'}} title={tests.testName}>
                                                            {tests.testName.substring(0,8)}
                                                            {tests.testName.length > 8 && '...'}
                                                        </p>
                                                        <p style={{ fontSize: 14, opacity: 0.7 }}>Type: {tests.testType}</p>
                                                        <p style={{ fontSize: 14, opacity: 0.7 }}>Duration: {minToHours(tests.duration)}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </Slider>
                            </div>
                        )}
                    </section>
                    <section >
                        {(!score || errorprogress) ? (<div></div>) : (score.length === 0) ? (<div></div>) : (score.length !== 0) && (
                            <div className="px-5  py-2">
                                <Heading Text={'Your Score'} />
                                {score.map((result, i) => {
                                    return (
                                        <ContentCard
                                            key={result.testId}
                                            Name={result.testName}
                                            Tagline={result.testType}
                                            img={playImage}
                                            rightTag={result.score}
                                            bg={`${colors[i < colors.length ? i : colors.length % i].bg}`}
                                            testId={result.testId}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </section>
                </main>
            )}
        </div>
    )
}

export default MyCourse
