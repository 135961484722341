import React,{ useRef, useEffect, useState } from 'react'
import DOMPurify from 'dompurify';
import axios from 'axios';

//assets
import edit from '../../assets/icons/edit.svg';
import PdfFile from '../../assets/images/PDF_FILE.png';
import PNGFILE from '../../assets/images/PNG_FILE.png';
import JPGFile from '../../assets/images/JPG_File.png';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';

//config
import { BASEURL } from '../../config/config';

//Text Area
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
} from "suneditor/src/plugins";

const DescriptiveQuestion = ({
    data, 
    qno,
    testId,
    totalMarkForReview,
    totalAttempt, 
    PreviewUrl,
    showPreview,
    fileType,
    DescriptiveQuestionFunc,
    questionId,
    deleteFile,
    error
}) => {
    const [UploadingFile, setUploadingFile] = useState("");
    const [progress, setProgress] = useState(0);
    // const [AnswerSheet, setAnswerSheet] = useState(JSON.parse(localStorage.getItem(`${testId}_answerSheet`)));
    // const [Attempt, setAttempt] = useState(JSON.parse(localStorage.getItem(`${testId}_attempt`)));
    const [ isDrop, setIsDrop ] = useState(false);

    let editor = useRef(null);

    useEffect(()=>{
        DescriptiveQuestionFunc.current = delFile
        // eslint-disable-next-line
    },[])

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    }

    const markForRev = (id, section, qno) =>{
        const MFR = JSON.parse(localStorage.getItem(`${testId}_markForReview`));
        const i = MFR.findIndex((ele)=>(ele.qid === id));
        if(i>-1){
            const temp = MFR[i];
            MFR[i] = MFR[MFR.length -1];
            MFR[MFR.length -1] = temp;
            MFR.pop();
        }else{
            MFR.push({
                qid:id,
                section:section,
                qno:qno
            })
        }
        localStorage.removeItem(`${testId}_markForReview`);
        localStorage.setItem(`${testId}_markForReview`,JSON.stringify(MFR));
        totalMarkForReview(JSON.parse(localStorage.getItem(`${testId}_markForReview`)))
    }

    const UpdateAnswerSheet = (answers, attempt) =>{
        setProgress(0)
        setUploadingFile("")

        localStorage.removeItem(`${testId}_answerSheet`);
        localStorage.setItem(`${testId}_answerSheet`,JSON.stringify(answers));

        localStorage.removeItem(`${testId}_attempt`);
        localStorage.setItem(`${testId}_attempt`,JSON.stringify(attempt));
        totalAttempt(JSON.parse(localStorage.getItem(`${testId}_attempt`)));
    }

    const fileHandler = async(file)=>{
        const Answer = JSON.parse(localStorage.getItem(`${testId}_answerSheet`));
        const attempt = JSON.parse(localStorage.getItem(`${testId}_attempt`));
        const idx = Answer.findIndex((element)=> (element.qid === data.id));

        if(idx > -1){
            for(let i=0; i<file.length; i++){
                if(
                    file[i].type === 'application/pdf'   ||
                    file[i].type === 'image/jpeg'        ||
                    file[i].type === 'image/png'        
                ){
                    const isAlreadyUploaded = Answer[idx].files.find((fileInput)=>
                        fileInput.name === file[i].name
                    )
                    if(isAlreadyUploaded){
                        error(`File With Name : ${file[i].name} is Already Uploaded`);
                    }else{
                        setProgress(0)
                        setUploadingFile(file[i].name)
                        try{
                            const res = await axios.get(`${BASEURL}/gen/askToEvaluate/s3url`);
                            const s3Url = res.data[0].data;
    
                            await axios.put(s3Url, file[i], {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                                onUploadProgress: data => {
                                    setProgress(Math.round((100 * data.loaded) / data.total))
                                }
                            })

                            Answer[idx].files.push({
                                type:file[i].type,
                                url:s3Url.split('?')[0],
                                name:file[i].name
                            })
                            UpdateAnswerSheet(Answer, attempt)

                        }catch(error){
                            error(`${error.message}`)
                        }
                    }
                }else{
                    error(`File Type: ${file[i].type} Not Allowed`)
                }
            }
        }else{
            Object.values(file).map(async(key)=>{
                if(
                    key.type === 'application/pdf'   ||
                    key.type === 'image/jpeg'        ||
                    key.type === 'image/png'        
                ){
                    setProgress(0)
                    setUploadingFile(key.name)
                    
                    try{
                        const res = await axios.get(`${BASEURL}/gen/askToEvaluate/s3url`);
                        const s3Url = res.data[0].data;

                        await axios.put(s3Url, key, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            onUploadProgress: data => {
                                setProgress(Math.round((100 * data.loaded) / data.total))
                            }
                        })

                        const AnswerIndex = Answer.findIndex(answer => answer.qid === data.id)
                        if(AnswerIndex > -1){
                            Answer[AnswerIndex].files.push({
                                type:key.type,
                                url:s3Url.split('?')[0],
                                name:key.name
                            })
                        }else{
                            Answer.push({
                                qid:data.id,
                                answer:"",
                                files:[
                                    {
                                        type:key.type,
                                        url:s3Url.split('?')[0],
                                        name:key.name
                                    }
                                ]
                            })
                            attempt.push({
                                qid:data.id,
                                section:data.sectionName.toLowerCase()
                            })
                        }
                        UpdateAnswerSheet(Answer, attempt)

                    }catch(error){
                        error(`${error.message}`)
                    }
                }else{
                    error(`File Type: ${key.type} Not Allowed`)
                }
            })
        }
    }

    const delFile = async(url, id) => {
        let answers = JSON.parse(localStorage.getItem(`${testId}_answerSheet`));
        let attempt = JSON.parse(localStorage.getItem(`${testId}_attempt`));
        const answerIndex = answers.findIndex((answer)=> answer.qid === id);

        if(answerIndex > -1){
            const fileIndex = answers[answerIndex].files.findIndex(file => file.url === url);
            if(fileIndex > -1){
                try{
                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                    await axios.put(`${BASEURL}/gen/askToEvaluate/s3delete`,{
                            imageKey:`${url.split('/')[3]}/${url.split('/')[4]}`
                        },
                        config
                    ) 

                    for( let i = 0; i < answers[answerIndex].files.length; i++){        
                        if ( answers[answerIndex].files[i].url === url) { 
                            answers[answerIndex].files.splice(i, 1); 
                            i--; 
                        }
                    }

                    if( 
                        (
                            answers[answerIndex].files.length === 0 
                        ) && 
                        (
                            answers[answerIndex].answer === "" ||
                            answers[answerIndex].answer === "<p><br></p>"
                        )
                    ){
                        answers.splice(answerIndex, 1);

                        let attemptIndex = attempt.findIndex(attemp => attemp.qid === id)
                        if(attemptIndex > -1){
                            attempt.splice(attemptIndex, 1)
                        }
                    }
                    UpdateAnswerSheet(answers, attempt);
                }catch(error){
                    error(error.message)
                }
            }else{
                error("File Not Found")
            }
        }else{
            error("File Not Found")
        }
        deleteFile(false);
    }

    const showFile = (url, type) => {
        showPreview(true)
        PreviewUrl(url)
        fileType(type)
    }

    const dragOver = (e, id) => {
        e.preventDefault();
        document.querySelector(`.form_${id}`).classList.add('dragover')
        setIsDrop(true)
    }

    const dragLeave = (e, id) => {
        e.preventDefault();
        document.querySelector(`.form_${id}`).classList.remove('dragover')
        setIsDrop(false)
    }

    const drop = (e, id) => {
        e.preventDefault();

        document.querySelector(`.form_${id}`).classList.remove('dragover')
        setIsDrop(false)
           
        fileHandler(e.dataTransfer.files);
    }

    return (
        <div key={data.id} className="questions-container">
            <div className="question">
                <div className="ques-line1">
                    <div className="qus-number">
                        <h1>{qno}</h1>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.description)}}></p>
                    <div 
                        className='markForReview' 
                        onClick={(e)=>{e.preventDefault(); markForRev(data.id, data.sectionName, qno)}}
                    >
                        <img src={edit} alt="Marked for review"/>
                    </div>
                </div>
                
                {data.imageURL && (
                    <div className="question-Img">
                        <img src={data.imageURL} alt="questionimg"/>
                    </div>
                )}

                <div className="card mb-2" >
                    <SunEditor 
                        getSunEditorInstance={getSunEditorInstance}
                        lang='en'
                        key={data.id}
                        setOptions={{
                            showPathLabel:false,
                            minHeight:'30vh',
                            placeholder:'Enter your answer...',
                            plugins:[
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle
                            ],
                            buttonList:[
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                "/",
                                [
                                    "bold",
                                    "underline",
                                    "italic"
                                ],
                                [
                                    "strike",
                                    "subscript",
                                    "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table"],
                                [ 'save']
                            ],
                            formats:["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font:[
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ],
                            callBackSave:function(content, isChanged){
                                if(isChanged){
                                    let answers = JSON.parse(localStorage.getItem(`${testId}_answerSheet`));
                                    let attempt = JSON.parse(localStorage.getItem(`${testId}_attempt`));

                                    const index = answers.findIndex((element)=> (element.qid === data.id));
                                    if(index > -1){
                                        if(
                                            (content === "" || content === "<p><br></p>") &&
                                            (answers[index].files.length === 0)
                                        ){
                                            answers.splice(index, 1)

                                            const attemptIdx = attempt.findIndex((value)=> value.qid === data.id);
                                            if(attemptIdx > -1){
                                                attempt.splice(attemptIdx,1)
                                            }
                                            UpdateAnswerSheet(answers, attempt)
                                        }else{
                                            answers[index].answer = content;
                                            UpdateAnswerSheet(answers,attempt);
                                        }
                                    }else{
                                        answers.push({
                                            qid:data.id,
                                            answer:content,
                                            files:[]
                                        })
                                        attempt.push({
                                            qid:data.id,
                                            section:data.sectionName.toLowerCase()
                                        })
                                        UpdateAnswerSheet(answers, attempt)
                                    }
                                }
                            }
                        }}   
                        setContents={
                            JSON.parse(localStorage.getItem(`${testId}_answerSheet`))
                                .find((answer) => answer.qid === data.id) ? 
                            JSON.parse(localStorage.getItem(`${testId}_answerSheet`))
                                .find((answer) => answer.qid === data.id).answer : ""
                        }                    
                    />
                </div>
                <div className="card">
                    <div className="top">
                        <p>Drag & drop files {UploadingFile && 'uploading -'} <span>{UploadingFile}</span></p>
                        {
                            UploadingFile && <>
                                <div className='progress-label'>{progress} %</div>
                                <div className="progress" style={{height:'20px'}}>
                                    <div 
                                        className="progress-bar progress-bar-striped" 
                                        role="progressbar" 
                                        style={{width:`${progress}%`}} 
                                        aria-valuenow="10" 
                                        aria-valuemin="0" 
                                        aria-valuemax="100">    
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={`form form_${data.id}`}
                        onDragOver={(e)=>{dragOver(e, data.id)}}
                        onDragLeave={(e)=>{dragLeave(e, data.id)}}
                        onDrop={(e)=>{drop(e, data.id)}}
                    >
                        <span className={`inner inner_${data.id}`}>
                            Drag & drop files here 
                            {
                                !isDrop && <span>
                                    &nbsp;or 
                                    <span 
                                        className={`select select_${data.id}`}
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            document.querySelector(`.form_${data.id} input`).click();
                                        }}
                                    >
                                        Browse
                                    </span>
                                </span> 
                            }
                        </span>
                        <span >
                            Only PDF | JPEG | JPG | PNG files are allowed.
                        </span>
                        <input 
                            name={data.id}
                            type="file" 
                            className="file" 
                            multiple 
                            onChange={e=>
                                { 
                                    e.preventDefault(); 
                                    fileHandler(document.querySelector(`.form_${data.id} input`).files)
                                }
                            }
                        />
                    </div>
                    <div className="container">
                        {
                            JSON.parse(localStorage.getItem(`${testId}_answerSheet`))
                                .find((answer)=>answer.qid === data.id) && 
                            JSON.parse(localStorage.getItem(`${testId}_answerSheet`))
                                .find((answer)=>answer.qid === data.id).files.map((file,i)=>{
                                if(file.url){
                                    return (
                                        <div key={i}>
                                            <div className='image' >
                                                {
                                                    file.type === "application/pdf" ? <img  src={PdfFile} alt={'PDF FILE'}/>  :
                                                    file.type === "image/jpeg" ?  <img  src={JPGFile} alt={'jpeg FILE'}/> :
                                                    file.type === "image/png"  && <img  src={PNGFILE} alt={'png FILE'}/>
                                                }
                                                
                                                <span 
                                                    className='delete' 
                                                    onClick={e =>{ 
                                                            e.preventDefault(); 
                                                            questionId(data.id);
                                                            PreviewUrl(file.url);
                                                            deleteFile(true);
                                                        }
                                                    }
                                                    title={"Delete"}
                                                >
                                                    <DeleteIcon/>
                                                </span>
                                                <span 
                                                    className='preview' 
                                                    onClick={e =>{ 
                                                        e.preventDefault(); 
                                                        showFile(file.url, file.type)
                                                    }}
                                                    title={"Preview"}
                                                >
                                                    <PreviewIcon/>
                                                </span>
                                            </div>
                                            <p className="fileName">{file.name}</p>
                                        </div>
                                    )   
                                }else{
                                    return null
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DescriptiveQuestion