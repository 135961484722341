import React from 'react'
import './Assignments.scss'
import { useDispatch } from 'react-redux';

import assignment1 from '../../../../assets/icons/assignment1.svg';

import InsightsIcon from '@mui/icons-material/Insights';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { resetFoundationalTest } from '../../../../actions/courseActions';

import { Link } from 'react-router-dom'
import { Lock } from '@material-ui/icons';

const AssignmentCards = ({data, history, eid, tid}) => {
    const dispatch = useDispatch();

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    const resetAssignment = (e) =>{
        e.preventDefault();
        dispatch(resetFoundationalTest(data.id));
    }

    return (
        <div className='assignment-card'>
            <img src={assignment1} alt="assignment" />
            <div className='assignment-btns'>
                {data.isValidate ?
                    (data.isAttempted ? 
                        <>
                            <button className='assignment-analytics' onClick={(e)=>{e.preventDefault(); history.push(`/foundational-assignment/${data.id}/analytics`)}}>
                                <InsightsIcon /> Analytics
                            </button>
                            <button className='assignment-reset' onClick={resetAssignment}>
                                <RestartAltIcon /> Reset
                            </button>
                        </>
                    :
                        <button className='assignment-reset' onClick={(e)=>{e.preventDefault(); history.push(`/foundational-assignment/instructions/${data.id}/${eid}`)}}>
                            <ChevronRightIcon /> Attempt
                        </button>
                    ) : (
                        <Link to={`/foundational-payment-details/${tid}/${eid}`} style={{color:'#2b2b2b'}} title="Unlock">
                            <Lock/>
                        </Link> 
                    )
                }
            </div>
            <div className='assignment-heading'>
                <h1>{data.testname}</h1>
            </div>
            <div className='assignment-sub-heading'>
                <h2>{data.subHeading}</h2>
            </div>
            <div className='assignment-info'>
                <p>TestType : {data.testType}</p>
                <p>Duration : {minToHours(data.duration)}</p>
            </div>
        </div>
    )
}

export default AssignmentCards