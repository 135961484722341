import React, { useEffect } from 'react'
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import noImage from '../../assets/images/otp.png';
import Logo from '../../assets/icons/logo.svg';

import {
    getAskToEvaluatePaymentHistory
} from '../../actions/askToEvaluateActions';

const AskToEvaluateInvoice = () => {

    const dispatch = useDispatch()


    const getAskToEvaluatePayment = useSelector(state => state.getAskToEvaluatePaymentHistory)
    const { loading, payment } = getAskToEvaluatePayment

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAskToEvaluatePaymentHistory())

    }, [dispatch, userInfo])

    return (
        <div className="payments">
            {loading && <Loader />}

            <div className="d-flex justify-content-center">
                <h3>ASK TO EVALUATE PAYMENT HISTORY</h3>
            </div>

            {
                payment.length === 0 ? <div className="no-payments" >
                    <img src={noImage} alt="" />
                    <h3>Payment History Not Available!</h3>
                </div> : <div className="d-flex m-2 flex-wrap justify-content-center">
                    {
                        payment.map((prop, id) =>(
                            <div key={id} className="payment-card">
                                <img src={Logo} alt="logo" />
                                <div className="payment-details">
                                    <p>Ask To Evaluate: <strong>{prop.name}</strong></p>
                                    <p>Date: <strong>{prop.createdAt.split("T")[0]}</strong></p>
                                    <p>Price: <strong> {(prop.price === "0") ? "Free" : `₹ ${prop.price/100}`}</strong></p>
                                    <p>Payment ID: <strong>{prop.paymentId}</strong></p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default AskToEvaluateInvoice