import React from 'react'
import Slider from 'react-slick';
import './MyCoursesLoading.scss';

import Heading from '../../Heading/Heading';

const MyCoursesLoading = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: false,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    dots: true,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: false,
                }
            }
        ]
    };
    return (
        <div className="main mycoursesLoading">
            <section className="px-5  py-5">
                    <Heading Text={'My Courses'} />
                    <Slider {...settings}>
                        <div style={{ width: 400, height: 300 }} className="box">
                            <div>
                                <div className=' skeleton img'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                    <div className='skeleton text'></div>
                                </div>
                                    <div className='skeleton text'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 400, height: 300 }} className="box">
                            <div>
                                <div className='skeleton img'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                    <div className='skeleton text'></div>
                                </div>
                                    <div className='skeleton text'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 400, height: 300 }} className="box">
                            <div>
                                <div className='skeleton img'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                    <div className='skeleton text'></div>
                                </div>
                                    <div className='skeleton text'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 400, height: 300 }} className="box">
                            <div>
                                <div className='skeleton img'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                    <div className='skeleton text'></div>
                                </div>
                                    <div className='skeleton text'></div>
                                <div>
                                    <div className='skeleton text'></div>
                                </div>
                            </div>
                        </div>
                    </Slider>
            </section>
            <section className="px-5  py-2">
                <div>
                    <Heading Text={'Your lessons'} />
                    <div className='lesson'>
                        <div className={`content-card mb-3`} style={{ cursor: 'default' }}>
                            <div className='skeleton text'></div>
                            <div className='skeleton text'></div>
                        </div>
                    </div>
                    <div className='lesson'>
                        <div className={`content-card mb-3`} style={{ cursor: 'default' }}>
                            <div className='skeleton text'></div>
                            <div className='skeleton text'></div>
                        </div>
                    </div>
                    <div className='lesson'>
                        <div className={`content-card mb-3`} style={{ cursor: 'default' }}>
                            <div className='skeleton text'></div>
                            <div className='skeleton text'></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MyCoursesLoading
