import React from 'react'
import './MyTestseriesLoading.scss'
import { Col, Row } from 'react-bootstrap';

const MyTestseriesLoading = () => {
    return (
        <div className="my-4 my-test-series-s-effect">
            <section className="d-md-flex mx-5 justify-content-center">
                <div className="search-box">
                    <input
                        style={{ border: "none", background: "none", width: "100%" }}
                        id="search"
                        name="search"
                        autoComplete="off"
                        type="text"
                        disabled
                        placeholder='Search'
                    />
                </div>
            </section>
            <section>
                <Row className="testSeries py-5 px-5">
                    <Col lg={5} xs={12} className="testSeries-card my-3">
                        <div className='test'>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                        </div>
                    </Col>
                    <Col lg={5} xs={12} className="testSeries-card my-3">
                        <div className='test'>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                        </div>
                    </Col>
                    <Col lg={5} xs={12} className="testSeries-card my-3">
                        <div className='test'>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                        </div>
                    </Col>
                    <Col lg={5} xs={12} className="testSeries-card my-3">
                        <div className='test'>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                        </div>
                    </Col>
                </Row>
            </section>
        </div>
    )
}

export default MyTestseriesLoading
