import React from 'react'
import './issuesThisWeek.scss';
import Clock from '../../../assets/icons/clock.svg';
import 'rc-checkbox/assets/index.css';

const Instructions = () => {
    return (
        <div className='InstructionLoading'>
            <div style={{height:"100vh"}}>
                <section className="px-5 ">
                    <div className="instruction-header">
                        <div>
                            <h1 className="mt-3" style={{fontSize:30,fontWeight:'600', color:'rgb(1, 42, 93)'}}>
                                Instructions
                            </h1>
                        </div>
                        <div>
                            <img src={Clock} alt="clock"/>
                        </div>
                    </div>
                </section>
                <section className="px-5">
                    <div className="test-profile">
                        <h4>Profile</h4>
                        <div className="img-container">
                            <div className='img skeleton'></div>
                        </div>
                        <div className="test-profile-info">
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                            <div className='text skeleton'></div>
                        </div>
                    </div>
                </section>
                <section className='py-5 px-5'>
                    <div className='text skeleton'></div>
                    <div className='text skeleton'></div>
                    <div className='text skeleton'></div>
                    <div className='text skeleton'></div>
                </section>
            </div>
        </div>
    )
}

export default Instructions
