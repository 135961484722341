import axios from "axios";
import { BASEURL } from "../config/config";
import {
    GET_PUBLISHED_OFFLINE_PREB4PRE_REQUEST,
    GET_PUBLISHED_OFFLINE_PREB4PRE_SUCCESS,
    GET_PUBLISHED_OFFLINE_PREB4PRE_FAIL,
    POST_ENROLL_OFFLINE_PREB4PRE_REQUEST,
    POST_ENROLL_OFFLINE_PREB4PRE_SUCCESS,
    POST_ENROLL_OFFLINE_PREB4PRE_FAIL,
    GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_REQUEST,
    GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_SUCCESS,
    GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_FAIL,
    GET_ENROLLED_OFFLINE_PREB4PRE_FAIL,
    GET_ENROLLED_OFFLINE_PREB4PRE_REQUEST,
    GET_ENROLLED_OFFLINE_PREB4PRE_SUCCESS,
    GET_OFFLINE_PREB4PRE_DETAILS_REQUEST,
    GET_OFFLINE_PREB4PRE_DETAILS_SUCCESS,
    GET_OFFLINE_PREB4PRE_DETAILS_FAIL
} from '../constants/OfflinePreB4PreConstants';

export const getPublishedOfflinePreB4pre = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PUBLISHED_OFFLINE_PREB4PRE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { id: userInfo.id },
        }

        const { data } = await axios.get(`${BASEURL}/admin/offline/preb4pre`, config)
        dispatch({
            type: GET_PUBLISHED_OFFLINE_PREB4PRE_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_PUBLISHED_OFFLINE_PREB4PRE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const postEnrollOfflinePreb4pre = (id, pid, sig, oid, coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ENROLL_OFFLINE_PREB4PRE_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/offline/preb4pre/${id}`, {
            razorpay_payment_id: pid,
            razorpay_signature: sig,
            order_id: oid,
            coupon: coupon
        }, config)
        dispatch({
            type: POST_ENROLL_OFFLINE_PREB4PRE_SUCCESS,
            payload: data[0].message
        })
    } catch (error) {
        dispatch({
            type: POST_ENROLL_OFFLINE_PREB4PRE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getOnlinePreb4prePaymentHistory = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/offline/preb4pre/history`, config)

        dispatch({
            type: GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_OFFLINE_PREB4PRE_PAYMENT_HISTORY_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getOfflineEnrolledPreb4pre = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ENROLLED_OFFLINE_PREB4PRE_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/user/offline/preb4pre`, config)
        dispatch({
            type: GET_ENROLLED_OFFLINE_PREB4PRE_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: GET_ENROLLED_OFFLINE_PREB4PRE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const getPreb4preTestSeries = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_OFFLINE_PREB4PRE_DETAILS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.get(`${BASEURL}/user/offline/preb4pre/${id}`, config)
        dispatch({
            type: GET_OFFLINE_PREB4PRE_DETAILS_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {
        dispatch({
            type: GET_OFFLINE_PREB4PRE_DETAILS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}