import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Close from '@material-ui/icons/Close';


const AlertDismissibleExample = ({ data }) => {

    const [show, setShow] = useState(true)

    const hide = {
        display: 'none',
        visibility: 'hidden'
    }

    const showError = {
        position: "fixed", bottom: '10px',
        right: '10px', zIndex: '10',
        width: "max-content",
        height: "100vh",
        display: "flex",
        alignItems: "end"
    }

    return (
        <div style={show ? showError : hide}>
            <Alert variant="danger">
                <div className="d-flex justify-content-end">
                    <button style={{ background: 'none', border: 'none', color: "#721c24" }} onClick={() => setShow(false)} ><Close /></button>
                </div>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>{data}</p>
            </Alert>
        </div>
    );
}

export default AlertDismissibleExample;