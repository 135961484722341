import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

//components
import Sidebar from '../../Components/main/sidebar/sidebar';
import AlertDismissibleExample from '../../Components/Error/error';
import Loader from '../../Components/Loader';

//assets
import Logo from '../../assets/images/preb4pre.png';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import noImage from '../../assets/images/otp.png';

//api call
import { getPreb4preTestSeries } from '../../actions/offlinePreB4PreAction';

//styling
import './OfflinePreb4preDetails.scss';
import { Col, Row } from 'react-bootstrap';

const OfflinePreB4PreDetails = ({match, history}) => {
    const dispatch = useDispatch()

    const getOfflinePreb4preTestSeries = useSelector(state => state.getOfflinePreb4preTestSeries)
    const { loading, error, offlinePreb4pre } = getOfflinePreb4preTestSeries

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getPreb4preTestSeries(match.params.id))
    }, [userInfo, dispatch, match])

    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const stringTime = (examDate) => {
        const event = new Date(examDate);
        const options = { hour: '2-digit', minute: '2-digit' ,timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }


    return <div>
        {loading && <Loader />}
        {error && <AlertDismissibleExample data={error}/>}
        <Sidebar activeLink={'more'} />
        <main className='main'>
            <div className='test-banner'>
                <div onClick={()=> history.goBack()}>
                    <img src={leftArrow} alt="<" style={{ width: 17, margin: 20, cursor: 'pointer' }} />
                </div>
                <div>
                    <img style={{ background: "#EEF1F6", padding: 20, width: '300px', borderRadius: "15px" }} src={Logo} alt="" />
                </div>
            </div>
            {!offlinePreb4pre  && 
                <div className="no-live-tests py-5">
                    <img src={noImage} alt="" />
                    <h3>404 Not Found</h3>
                </div> 
            }

            {offlinePreb4pre && (
                <div className="test-content py-5">
                    <div className="d-flex test-info my-0 justify-content-between align-items-center">
                        <h2 className="m-0">{offlinePreb4pre.name}</h2>
                    </div>
                    <div className="d-flex test-info my-0 justify-content-between align-items-center">
                        <p className="m-0">{offlinePreb4pre.subHeading}</p>
                    </div>
                    <div className="d-flex  test-info my-3 justify-content-between align-items-center">
                        <p>Type: {offlinePreb4pre.testType}</p>
                    </div>
                    <div className="test-info">
                        <h3>About</h3>
                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(offlinePreb4pre.description)}}></p>
                    </div>

                    <div className="test-info">
                        <h5>Note:-</h5>
                        <p>Result will be declared on <strong>{stringDate(offlinePreb4pre.resultOn)} - {stringTime(offlinePreb4pre.resultOn)}</strong></p>
                    </div>
                  
                    <h3 className='test-info'>Details</h3>
                    <Row className="test-card-info test-info">
                        {offlinePreb4pre.slot.length > 0 &&<Col sm={12}>
                            <Col sm={12}>
                                <h4>Paper Timing</h4>
                            </Col>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan="1">ID</th>
                                        <th scope="col">Exam On</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Start At</th>
                                        <th scope="col">Ends At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {offlinePreb4pre.slot.map((slots, i)=>(
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{stringDate(slots.startingTime)}</td>
                                        <td>{minToHours(slots.duration)}</td>
                                        <td>{stringTime(slots.startingTime)}</td>
                                        <td>{stringTime(slots.endingTime)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Col>}
                        {offlinePreb4pre.sections.length > 0 && <Col sm={12} className='mt-5'>
                            <h4>Sections</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan="1">ID</th>
                                        <th scope="col">Section</th>
                                        <th scope="col">Positive Marking</th>
                                        <th scope="col">Negative Marking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {offlinePreb4pre.sections.map((section, i)=>(
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{section.section}</td>
                                        <td>{section.marksPerQuestion}</td>
                                        <td>{section.negativeQuestion}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Col>}
                        <Col>

                        </Col>
                    </Row>
                </div>
            )}
        </main>
    </div>;
};

export default OfflinePreB4PreDetails;
