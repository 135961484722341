import React,{useState,useEffect} from 'react'
import {useDispatch} from 'react-redux';
import './Timer.scss';

import { postSubmitTest, postSubmitFoundationalTest } from '../../actions/courseActions';
import { postSubmitPracticeTest } from '../../actions/EnrolledTestSeries';
import { postSubmitLiveTest } from '../../actions/EnrolledTestSeries';
import { postPreb4preSubmitAnswers } from '../../actions/preb4preTestAction';
import { postSubmitITW } from '../../actions/IssuesThisWeekAction';

const FullScreenTimer = ({id, type, paper}) => {
    const dispatch = useDispatch();
    const [text,setText] = useState("");
    const [close,setClose] = useState(false);
    const [time, SetTime] = useState(20);

    useEffect(()=>{
        
        setTimeout(()=>{
            SetTime(JSON.parse(localStorage.getItem(`${id}_fullScreen`)));

            if(time >= 0){
                let seconds = time % 60;
                setText(`${seconds>9 ? Math.floor(seconds) : "0"+Math.floor(seconds)}`)
                if(JSON.parse(localStorage.getItem(`${id}_fullScreen`))){
                    localStorage.setItem(`${id}_fullScreen`, JSON.stringify(time-1));
                }
            }
            
            if((time < 0) && !close){
                setClose(true);
                const answer = JSON.parse(localStorage.getItem(`${id}_answerSheet`));
                if(type==='assignment'){
                    dispatch(postSubmitTest(id,answer));
                }else if(type === 'liveTs'){
                    dispatch(postSubmitLiveTest(id,answer));
                }else if(type === 'practiceTs'){
                    dispatch(postSubmitPracticeTest(id,answer));
                }else if (type === 'preb4preTs'){
                    dispatch(postPreb4preSubmitAnswers(id, answer, paper));
                }else if(type === 'itwTs'){
                    dispatch(postSubmitITW(id,answer));
                }else if(type === 'f_assignment'){
                    dispatch(postSubmitFoundationalTest(id,answer));
                }
                
                setTimeout(()=>{
                    if(localStorage.getItem(`${id}_time`)){
                        localStorage.removeItem(`${id}_time`);
                    }
                    localStorage.removeItem(`${id}_answerSheet`);
                    localStorage.removeItem(`${id}_chance`);
                    localStorage.removeItem(`${id}_markForReview`);
                    localStorage.removeItem(`${id}_attempt`);
                    localStorage.removeItem(`${id}_fullScreen`);
                    window.close()
                },1350);
            }
        },500)  
    })

    return (
        <span>
            <strong>
                {text} seconds
            </strong>
        </span>
    )
}

export default FullScreenTimer
