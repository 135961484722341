import axios from 'axios';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_FORGOT_REQUEST,
    USER_FORGOT_SUCCESS,
    USER_FORGOT_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_GOOGLE_REGISTER_FAIL,
    USER_GOOGLE_REGISTER_REQUEST,
    USER_GOOGLE_REGISTER_SUCCESS,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAIL,
    PUT_USER_DETAILS_REQUEST,
    PUT_USER_DETAILS_SUCCESS,
    PUT_USER_DETAILS_FAIL,
    POST_USER_DOB_FAIL,
    POST_USER_DOB_REQUEST,
    POST_USER_DOB_SUCCESS,
    PUT_USER_PICTURE_REQUEST,
    PUT_USER_PICTURE_SUCCESS,
    PUT_USER_PICTURE_FAIL,
    POST_SEND_MOBILE_OTP_REQUEST,
    POST_SEND_MOBILE_OTP_SUCCESS,
    POST_SEND_MOBILE_OTP_FAIL,
    POST_SEND_EMAIL_OTP_FAIL,
    POST_SEND_EMAIL_OTP_REQUEST,
    POST_SEND_EMAIL_OTP_SUCCESS,
    POST_MOBILE_VERIFICATION_FAIL,
    POST_MOBILE_VERIFICATION_REQUEST,
    POST_MOBILE_VERIFICATION_SUCCESS,
    POST_EMAIL_VERIFICTION_FAIL,
    POST_EMAIL_VERIFICTION_REQUEST,
    POST_EMAIL_VERIFICTION_SUCCESS,
    POST_RESET_PASSWORD_FAIL,
    POST_RESET_PASSWORD_REQUEST,
    POST_RESET_PASSWORD_SUCCESS,
    GET_USER_AUTHORIZATION_REQUEST,
    GET_USER_AUTHORIZATION_SUCCESS,
    GET_USER_AUTHORIZATION_FAIL
} from '../constants/userConstants';

import { BASEURL } from '../config/config';

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`${BASEURL}/auth/login`, { email, password }, config)
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data[0].data,
        })

        localStorage.setItem('userInfo', JSON.stringify(data[0].data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const authorization = () => async (dispatch, getState) =>{
    try{
        dispatch({
            type: GET_USER_AUTHORIZATION_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/auth/authorization`, config)
        dispatch({
            type: GET_USER_AUTHORIZATION_SUCCESS,
            payload: data[0].isAuthenticated,
        })
    } catch (error) {
        localStorage.clear();
        dispatch({
            type: GET_USER_AUTHORIZATION_FAIL,
            payload: error.response && error.response.data[0].message ? 'Session Expired' : 'Session Expired'
        })
        document.location.href = '/login';
    }
}


export const logout = () => (dispatch) => {
    localStorage.clear();
    dispatch({ type: USER_LOGOUT })
    document.location.href = '/login';
}

export const forgotPass = (email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_FORGOT_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`${BASEURL}/auth/forgot`, { email }, config)
        dispatch({
            type: USER_FORGOT_SUCCESS,
            payload: data[0].message,
        })
    } catch (error) {
        dispatch({
            type: USER_FORGOT_FAIL,
            payload: error.response && error.response.data[0] ? error.response.data[0].message : error.message
        })
    }
}



export const registerUser = (data1) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`${BASEURL}/auth/register`, data1, config)
        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data[0].data,
        })
        localStorage.setItem('userInfo', JSON.stringify(data[0].data))
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data[0] ? error.response.data[0].message : error.message
        })
    }
}



export const googleAuth = (googleAuthToken)=> async(dispatch) =>{
    try {
        dispatch({
            type: USER_GOOGLE_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const {data} = await axios.post(`${BASEURL}/auth/google/web/register`,{ googleAuthToken }, config)
        dispatch({
            type: USER_GOOGLE_REGISTER_SUCCESS,
            payload: data[0].data,
        })
        localStorage.setItem('userInfo', JSON.stringify(data[0].data))
    } catch (error) {
        dispatch({
            type: USER_GOOGLE_REGISTER_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const getUserDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_USER_DETAILS_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.get(`${BASEURL}/auth/me`, config)
        dispatch({
            type: GET_USER_DETAILS_SUCCESS,
            payload: data[0].data,
        })
    } catch (error) {
        dispatch({
            type: GET_USER_DETAILS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const putUserDetails = (userData) => async (dispatch, getState) => {
    try {

        dispatch({
            type: PUT_USER_DETAILS_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.put(`${BASEURL}/user/putdetails`, userData, config)
        dispatch({
            type: PUT_USER_DETAILS_SUCCESS,
            payload: data[0].message
        })


    } catch (error) {
        dispatch({
            type: PUT_USER_DETAILS_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })

    }
}


export const postUserDob = (userDob) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_USER_DOB_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/user/postdob`, userDob, config)
        dispatch({
            type: POST_USER_DOB_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_USER_DOB_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}

export const putUserPicture = (userPicture) => async (dispatch, getState) => {
    try {

        dispatch({
            type: PUT_USER_PICTURE_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.put(`${BASEURL}/user/putdetails/pimage`, userPicture, config)
        dispatch({
            type: PUT_USER_PICTURE_SUCCESS,
            payload: data[0].message
        })


    } catch (error) {
        dispatch({
            type: PUT_USER_PICTURE_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}


export const postSendMobileOtp = (phone) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SEND_MOBILE_OTP_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/auth/mobile/sendOtp`, phone, config)
        dispatch({
            type: POST_SEND_MOBILE_OTP_SUCCESS,
            payload: data[0].data
        })
    } catch (error) {

        dispatch({
            type: POST_SEND_MOBILE_OTP_FAIL,
            payload: error.response && error.response.message ? error.response.message : error.message
        })

    }
}

export const postSendEmailOtp = (email) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_SEND_EMAIL_OTP_REQUEST
        })
        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const { data } = await axios.post(`${BASEURL}/auth/sendOtp`, email, config)
        dispatch({
            type: POST_SEND_EMAIL_OTP_SUCCESS,
            payload: data[0].data
        })

    } catch (error) {
        dispatch({
            type: POST_SEND_EMAIL_OTP_FAIL,
            payload: error.response && error.response.message ? error.response.message : error.message
        })
    }
}

export const postMobileVerification = (otp) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_MOBILE_VERIFICATION_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/auth/mobile/verification`, otp, config)

        dispatch({
            type: POST_MOBILE_VERIFICATION_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_MOBILE_VERIFICATION_FAIL,
            payload: error.response && error.response.message ? error.response.message : error.message
        })
    }
}

export const postEmailVerification = (otp) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_EMAIL_VERIFICTION_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/auth/verification`, otp, config)
        dispatch({
            type: POST_EMAIL_VERIFICTION_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_EMAIL_VERIFICTION_FAIL,
            payload: error.response && error.response.message ? error.response.message : error.message
        })
    }
}

export const postResetPassword = (password) => async (dispatch, getState) => {
    try {

        dispatch({
            type: POST_RESET_PASSWORD_REQUEST
        })

        const { userLogin: { userInfo } } = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }

        const { data } = await axios.post(`${BASEURL}/auth/resetpassword`, password, config)
        dispatch({
            type: POST_RESET_PASSWORD_SUCCESS,
            payload: data[0].message
        })

    } catch (error) {
        dispatch({
            type: POST_RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}