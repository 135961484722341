import {
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_FORGOT_REQUEST,
    USER_FORGOT_SUCCESS,
    USER_FORGOT_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_GOOGLE_REGISTER_FAIL,
    USER_GOOGLE_REGISTER_REQUEST,
    USER_GOOGLE_REGISTER_SUCCESS,
    GET_USER_DETAILS_REQUEST,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAIL,
    PUT_USER_DETAILS_REQUEST,
    PUT_USER_DETAILS_SUCCESS,
    PUT_USER_DETAILS_FAIL,
    POST_USER_DOB_REQUEST,
    POST_USER_DOB_SUCCESS,
    POST_USER_DOB_FAIL,
    PUT_USER_PICTURE_REQUEST,
    PUT_USER_PICTURE_SUCCESS,
    PUT_USER_PICTURE_FAIL,
    POST_SEND_MOBILE_OTP_REQUEST,
    POST_SEND_MOBILE_OTP_SUCCESS,
    POST_SEND_MOBILE_OTP_FAIL,
    POST_SEND_EMAIL_OTP_REQUEST,
    POST_SEND_EMAIL_OTP_SUCCESS,
    POST_SEND_EMAIL_OTP_FAIL,
    POST_MOBILE_VERIFICATION_REQUEST,
    POST_MOBILE_VERIFICATION_SUCCESS,
    POST_MOBILE_VERIFICATION_FAIL,
    POST_EMAIL_VERIFICTION_REQUEST,
    POST_EMAIL_VERIFICTION_SUCCESS,
    POST_EMAIL_VERIFICTION_FAIL,
    POST_RESET_PASSWORD_REQUEST,
    POST_RESET_PASSWORD_SUCCESS,
    POST_RESET_PASSWORD_FAIL,
    GET_USER_AUTHORIZATION_REQUEST,
    GET_USER_AUTHORIZATION_SUCCESS,
    GET_USER_AUTHORIZATION_FAIL
} from '../constants/userConstants';


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}

export const userAuthorizationReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_AUTHORIZATION_REQUEST:
            return { loading: true }
        case GET_USER_AUTHORIZATION_SUCCESS:
            return { loading: false, isAuthorization: action.payload }
        case GET_USER_AUTHORIZATION_FAIL:
            return { loading: false, error: action.payload, isAuthorization:false }
        default:
            return state
    }
}

export const forgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_FORGOT_REQUEST:
            return { loading: true }
        case USER_FORGOT_SUCCESS:
            return { loading: false, message: action.payload }
        case USER_FORGOT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}

export const googleLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_GOOGLE_REGISTER_REQUEST:
            return { loading: true }
        case USER_GOOGLE_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload }
        case USER_GOOGLE_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}

export const getUserDetailReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case GET_USER_DETAILS_REQUEST:
            return { loading: true, user: {} }
        case GET_USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload }
        case GET_USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const putUserDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case PUT_USER_DETAILS_REQUEST:
            return { loading: true }
        case PUT_USER_DETAILS_SUCCESS:
            return { loading: false, message: action.payload }
        case PUT_USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postUserDobReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_USER_DOB_REQUEST:
            return { loading: true }
        case POST_USER_DOB_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_USER_DOB_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const putUserPictureReducer = (state = {}, action) => {
    switch (action.type) {
        case PUT_USER_PICTURE_REQUEST:
            return { loading: true }
        case PUT_USER_PICTURE_SUCCESS:
            return { loading: false, message: action.payload }
        case PUT_USER_PICTURE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postSendMobileOtpReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SEND_MOBILE_OTP_REQUEST:
            return { loading: true }
        case POST_SEND_MOBILE_OTP_SUCCESS:
            return { loading: false, mobileOtpCode: action.payload }
        case POST_SEND_MOBILE_OTP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postSendEmailOtpReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SEND_EMAIL_OTP_REQUEST:
            return { loading: true }
        case POST_SEND_EMAIL_OTP_SUCCESS:
            return { loading: false, emailOtpCode: action.payload }
        case POST_SEND_EMAIL_OTP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postMobileVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_MOBILE_VERIFICATION_REQUEST:
            return { loading: true }
        case POST_MOBILE_VERIFICATION_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_MOBILE_VERIFICATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEmailVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_EMAIL_VERIFICTION_REQUEST:
            return { loading: true }
        case POST_EMAIL_VERIFICTION_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_EMAIL_VERIFICTION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postResetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_RESET_PASSWORD_REQUEST:
            return { loading: true }
        case POST_RESET_PASSWORD_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_RESET_PASSWORD_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}