import React from 'react'

const Heading = ({Text}) => {
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mt-4">
                <p style={{ fontSize: 25, fontWeight: '700', color: 'rgb(1, 42, 93)'}}>
                    {Text}
                </p>
            </div>
        </div>
    )
}

export default Heading
