import {
    GET_ALL_COURSES_FAIL,
    GET_ALL_COURSES_REQUEST,
    GET_ALL_COURSES_SUCCESS,
    GET_COURSES_BY_ID_REQUEST,
    GET_COURSES_BY_ID_SUCCESS,
    GET_COURSES_BY_ID_FAIL,
    GET_USER_COURSES_REQUEST,
    GET_USER_COURSES_SUCCESS,
    GET_USER_COURSES_FAIL,
    POST_ENROLL_COURSES_REQUEST,
    POST_ENROLL_COURSES_SUCCESS,
    POST_ENROLL_COURSES_FAIL,
    GET_SUBJECTS_BY_ID_REQUEST,
    GET_SUBJECTS_BY_ID_SUCCESS,
    GET_SUBJECTS_BY_ID_FAIL,
    GET_CHAPTERS_BY_ID_REQUEST,
    GET_CHAPTERS_BY_ID_SUCCESS,
    GET_CHAPTERS_BY_ID_FAIL,
    GET_TOPICS_BY_ID_REQUEST,
    GET_TOPICS_BY_ID_SUCCESS,
    GET_TOPICS_BY_ID_FAIL,
    PUT_COMPLETED_STATUS_REQUEST,
    PUT_COMPLETED_STATUS_SUCCESS,
    PUT_COMPLETED_STATUS_FAIL,
    GET_COURSE_TEST_INFO_REQUEST,
    GET_COURSE_TEST_INFO_SUCCESS,
    GET_COURSE_TEST_INFO_FAIL,
    GET_TESTSERIES_PROGRESS_REQUEST,
    GET_TESTSERIES_PROGRESS_SUCCESS,
    GET_TESTSERIES_PROGRESS_FAIL,
    GET_ASSIGNMENT_QPAPER_REQUEST,
    GET_ASSIGNMENT_QPAPER_SUCCESS,
    GET_ASSIGNMENT_QPAPER_FAIL,
    POST_SUBMIT_TEST_REQUEST,
    POST_SUBMIT_TEST_SUCCESS,
    POST_SUBMIT_TEST_FAIL,
    GET_COURSE_CONTENT_REQUEST,
    GET_COURSE_CONTENT_SUCCESS,
    GET_COURSE_CONTENT_FAIL,
    GET_COURSE_PAYMENT_HISTORY_REQUEST,
    GET_COURSE_PAYMENT_HISTORY_SUCCESS,
    GET_COURSE_PAYMENT_HISTORY_FAIL,
    POST_RESET_TEST_REQUEST,
    POST_RESET_TEST_SUCCESS,
    POST_RESET_TEST_FAIL,
    GET_ANALYTICS_BY_ID_REQUEST,
    GET_ANALYTICS_BY_ID_SUCCESS,
    GET_ANALYTICS_BY_ID_FAIL,
    GET_ANSWER_SHEET_REQUEST,
    GET_ANSWER_SHEET_SUCCESS,
    GET_ANSWER_SHEET_FAIL,
    POST_REVIEW_REQUEST,
    POST_REVIEW_SUCCESS,
    POST_REVIEW_FAIL,
    POST_RECENT_SUBJECTS_REQUEST,
    POST_RECENT_SUBJECTS_SUCCESS,
    POST_RECENT_SUBJECTS_FAIL,
    GET_RECENT_SUBJECTS_REQUEST,
    GET_RECENT_SUBJECTS_SUCCESS,
    GET_RECENT_SUBJECTS_FAIL,
    POST_COURSE_COUPON_VALIDATION_REQUEST,
    POST_COURSE_COUPON_VALIDATION_SUCCESS,
    POST_COURSE_COUPON_VALIDATION_FAIL,
    GET_ALL_FOUNDATIONAL_COURSES_REQUEST,
    GET_ALL_FOUNDATIONAL_COURSES_SUCCESS,
    GET_ALL_FOUNDATIONAL_COURSES_FAIL,
    GET_FOUNDATIONAL_COURSES_BY_ID_REQUEST,
    GET_FOUNDATIONAL_COURSES_BY_ID_SUCCESS,
    GET_FOUNDATIONAL_COURSES_BY_ID_FAIL,
    POST_ENROLL_FOUNDATIONAL_COURSES_REQUEST,
    POST_ENROLL_FOUNDATIONAL_COURSES_SUCCESS,
    POST_ENROLL_FOUNDATIONAL_COURSES_FAIL,
    POST_FOUNDATIONAL_REVIEW_REQUEST,
    POST_FOUNDATIONAL_REVIEW_SUCCESS,
    POST_FOUNDATIONAL_REVIEW_FAIL,
    GET_ENROLL_FOUNDATIONAL_COURSES_REQUEST,
    GET_ENROLL_FOUNDATIONAL_COURSES_SUCCESS,
    GET_ENROLL_FOUNDATIONAL_COURSES_FAIL,
    GET_FOUNDATIONAL_CONTENT_REQUEST,
    GET_FOUNDATIONAL_CONTENT_SUCCESS,
    GET_FOUNDATIONAL_CONTENT_FAIL,
    GET_FOUNDATIONAL_PAYMENT_HISTORY_REQUEST,
    GET_FOUNDATIONAL_PAYMENT_HISTORY_SUCCESS,
    GET_FOUNDATIONAL_PAYMENT_HISTORY_FAIL,
    POST_INSTALLMENT_PAYMENT_REQUEST,
    POST_INSTALLMENT_PAYMENT_SUCCESS,
    POST_INSTALLMENT_PAYMENT_FAIL,
    PUT_FOUNDATIONAL_COMPLETED_STATUS_REQUEST,
    PUT_FOUNDATIONAL_COMPLETED_STATUS_SUCCESS,
    PUT_FOUNDATIONAL_COMPLETED_STATUS_FAIL,
    PUT_FOUNDATIONAL_RECENT_TOPICS_REQUEST,
    PUT_FOUNDATIONAL_RECENT_TOPICS_SUCCESS,
    PUT_FOUNDATIONAL_RECENT_TOPICS_FAIL,
    GET_FOUNDATIONAL_COURSE_TEST_INFO_REQUEST,
    GET_FOUNDATIONAL_COURSE_TEST_INFO_SUCCESS,
    GET_FOUNDATIONAL_COURSE_TEST_INFO_FAIL,
    GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_REQUEST,
    GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_SUCCESS,
    GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_FAIL,
    POST_SUBMIT_FOUNDATIONAL_TEST_REQUEST,
    POST_SUBMIT_FOUNDATIONAL_TEST_SUCCESS,
    POST_SUBMIT_FOUNDATIONAL_TEST_FAIL,
    PUT_RESET_FOUNDATIONAL_TEST_REQUEST,
    PUT_RESET_FOUNDATIONAL_TEST_SUCCESS,
    PUT_RESET_FOUNDATIONAL_TEST_FAIL,
    GET_FOUNDATIONAL_ANALYTICS_BY_ID_REQUEST,
    GET_FOUNDATIONAL_ANALYTICS_BY_ID_SUCCESS,
    GET_FOUNDATIONAL_ANALYTICS_BY_ID_FAIL,
    GET_FOUNDATIONAL_ANSWER_SHEET_REQUEST,
    GET_FOUNDATIONAL_ANSWER_SHEET_SUCCESS,
    GET_FOUNDATIONAL_ANSWER_SHEET_FAIL
} from '../constants/courseConstants';

export const getAllCoursesReducer = (state = { courses: [] }, action) => {
    switch (action.type) {
        case GET_ALL_COURSES_REQUEST:
            return { loading: true, courses: [] }
        case GET_ALL_COURSES_SUCCESS:
            return { loading: false, courses: action.payload }
        case GET_ALL_COURSES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAllFoundationalCoursesReducer = (state = { FoundationalCourses: [] }, action) => {
    switch (action.type) {
        case GET_ALL_FOUNDATIONAL_COURSES_REQUEST:
            return { loading: true, FoundationalCourses: [] }
        case GET_ALL_FOUNDATIONAL_COURSES_SUCCESS:
            return { loading: false, FoundationalCourses: action.payload }
        case GET_ALL_FOUNDATIONAL_COURSES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getCourseDetailsReducer = (state = { course: { assignments: [] } }, action) => {
    switch (action.type) {
        case GET_COURSES_BY_ID_REQUEST:
            return { loading: true, ...state }
        case GET_COURSES_BY_ID_SUCCESS:
            return { loading: false, course: action.payload }
        case GET_COURSES_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getFoundationalAssignmentDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FOUNDATIONAL_COURSE_TEST_INFO_REQUEST:
            return { loading: true, ...state }
        case GET_FOUNDATIONAL_COURSE_TEST_INFO_SUCCESS:
            return { loading: false, assignmentDetails: action.payload }
        case GET_FOUNDATIONAL_COURSE_TEST_INFO_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getFoundationalCourseDetailsReducer = (state = { FoundationalCourse: { assignments: [] } }, action) => {
    switch (action.type) {
        case GET_FOUNDATIONAL_COURSES_BY_ID_REQUEST:
            return { loading: true, ...state }
        case GET_FOUNDATIONAL_COURSES_BY_ID_SUCCESS:
            return { loading: false, FoundationalCourse: action.payload }
        case GET_FOUNDATIONAL_COURSES_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getUserEnrolledCoursesReducer = (state = { courses: [] }, action) => {
    switch (action.type) {
        case GET_USER_COURSES_REQUEST:
            return { loading: true, courses: [] }
        case GET_USER_COURSES_SUCCESS:
            return { loading: false, courses: action.payload }
        case GET_USER_COURSES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getUserEnrolledFoundationalCoursesReducer = (state = { foundationalCourses: [] }, action) => {
    switch (action.type) {
        case GET_ENROLL_FOUNDATIONAL_COURSES_REQUEST:
            return { loading: true, foundationalCourses: [] }
        case GET_ENROLL_FOUNDATIONAL_COURSES_SUCCESS:
            return { loading: false, foundationalCourses: action.payload }
        case GET_ENROLL_FOUNDATIONAL_COURSES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getCourseValidityDetailReducer = (state = { course: {subjects:[]} }, action) => {
    switch (action.type) {
        case GET_FOUNDATIONAL_CONTENT_REQUEST:
            return { loading: true, ...state }
        case GET_FOUNDATIONAL_CONTENT_SUCCESS:
            return { loading: false, course: action.payload }
        case GET_FOUNDATIONAL_CONTENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getCoursePaymentDetailsReducer = (state = { paymentDetails: null }, action) => {
    switch (action.type) {
        case GET_FOUNDATIONAL_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_FOUNDATIONAL_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, paymentDetails: action.payload }
        case GET_FOUNDATIONAL_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_COURSES_REQUEST:
            return { loading: true }
        case POST_ENROLL_COURSES_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_COURSES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postInstallmentPaymentReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_INSTALLMENT_PAYMENT_REQUEST:
            return { loading: true }
        case POST_INSTALLMENT_PAYMENT_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_INSTALLMENT_PAYMENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollFoundationalCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_FOUNDATIONAL_COURSES_REQUEST:
            return { loading: true }
        case POST_ENROLL_FOUNDATIONAL_COURSES_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_FOUNDATIONAL_COURSES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const getSubjectsByIdReducer = (state = { subjects: [] }, action) => {
    switch (action.type) {
        case GET_SUBJECTS_BY_ID_REQUEST:
            return { loading: true, subjects: [] }
        case GET_SUBJECTS_BY_ID_SUCCESS:
            return { loading: false, subjects: action.payload }
        case GET_SUBJECTS_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getChaptersByIdReducer = (state = { Chapters: [] }, action) => {
    switch (action.type) {
        case GET_CHAPTERS_BY_ID_REQUEST:
            return { loading: true, Chapters: [] }
        case GET_CHAPTERS_BY_ID_SUCCESS:
            return { loading: false, Chapters: action.payload }
        case GET_CHAPTERS_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const getTopicsByIdReducer = (state = { Topics: [] }, action) => {
    switch (action.type) {
        case GET_TOPICS_BY_ID_REQUEST:
            return { loading: true, Topics: [] }
        case GET_TOPICS_BY_ID_SUCCESS:
            return { loading: false, Topics: action.payload }
        case GET_TOPICS_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const putCompletedTopicReducer = (state = {}, action) => {
    switch (action.type) {
        case PUT_COMPLETED_STATUS_REQUEST:
            return { loading: true }
        case PUT_COMPLETED_STATUS_SUCCESS:
            return { loading: false, message: action.payload }
        case PUT_COMPLETED_STATUS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const putFoundationalStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case PUT_FOUNDATIONAL_COMPLETED_STATUS_REQUEST:
            return { loading: true }
        case PUT_FOUNDATIONAL_COMPLETED_STATUS_SUCCESS:
            return { loading: false, message: action.payload }
        case PUT_FOUNDATIONAL_COMPLETED_STATUS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const putFoundationalRecentTopicsReducer = (state = {}, action) => {
    switch (action.type) {
        case  PUT_FOUNDATIONAL_RECENT_TOPICS_REQUEST:
            return { loading: true }
        case  PUT_FOUNDATIONAL_RECENT_TOPICS_SUCCESS:
            return { loading: false, message: action.payload }
        case  PUT_FOUNDATIONAL_RECENT_TOPICS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getCourseTestInfoReducer = (state = { test: {} }, action) => {
    switch (action.type) {
        case GET_COURSE_TEST_INFO_REQUEST:
            return { loading: true, test: {} }
        case GET_COURSE_TEST_INFO_SUCCESS:
            return { loading: false, test: action.payload }
        case GET_COURSE_TEST_INFO_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const getTestProgressReducer = (state = { testProgress: [] }, action) => {
    switch (action.type) {
        case GET_TESTSERIES_PROGRESS_REQUEST:
            return { loading: true, testProgress: [] }
        case GET_TESTSERIES_PROGRESS_SUCCESS:
            return { loading: false, testProgress: action.payload }
        case GET_TESTSERIES_PROGRESS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAssignmentPaperReducer = (state = { paper: [] }, action) => {
    switch (action.type) {
        case GET_ASSIGNMENT_QPAPER_REQUEST:
            return { loading: true, paper: [] }
        case GET_ASSIGNMENT_QPAPER_SUCCESS:
            return { loading: false, paper: action.payload }
        case GET_ASSIGNMENT_QPAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getFoundationalAssignmentPaperReducer = (state = {}, action) => {
    switch (action.type) {
        case  GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_REQUEST:
            return { loading: true }
        case  GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_SUCCESS:
            return { loading: false, paper: action.payload }
        case  GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PostAssignmentReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SUBMIT_TEST_REQUEST:
            return { loading: true }
        case POST_SUBMIT_TEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_SUBMIT_TEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PostFoundationalAssignmentReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SUBMIT_FOUNDATIONAL_TEST_REQUEST:
            return { loading: true }
        case POST_SUBMIT_FOUNDATIONAL_TEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_SUBMIT_FOUNDATIONAL_TEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getCourseContentReducer = (state = { courseContent: [] }, action) => {
    switch (action.type) {
        case GET_COURSE_CONTENT_REQUEST:
            return { loading: true, ...state }
        case GET_COURSE_CONTENT_SUCCESS:
            return { loading: false, courseContent: action.payload }
        case GET_COURSE_CONTENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getCoursePaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_COURSE_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_COURSE_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_COURSE_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postResetTestReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_RESET_TEST_REQUEST:
            return { loading: true }
        case POST_RESET_TEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_RESET_TEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const putResetFoundationalTestReducer = (state = {}, action) => {
    switch (action.type) {
        case PUT_RESET_FOUNDATIONAL_TEST_REQUEST:
            return { loading: true }
        case PUT_RESET_FOUNDATIONAL_TEST_SUCCESS:
            return { loading: false, message: action.payload }
        case PUT_RESET_FOUNDATIONAL_TEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAnalyticsAssignmentReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ANALYTICS_BY_ID_REQUEST:
            return { loading: true }
        case GET_ANALYTICS_BY_ID_SUCCESS:
            return { loading: false, assignmentAnalytics: action.payload }
        case GET_ANALYTICS_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const getAnswerSheetReducer = (state = { answerSheet: {} }, action) => {
    switch (action.type) {
        case GET_ANSWER_SHEET_REQUEST:
            return { loading: true, answerSheet: {} }
        case GET_ANSWER_SHEET_SUCCESS:
            return { loading: false, answerSheet: action.payload }
        case GET_ANSWER_SHEET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getFoundationalAnalyticsAssignmentReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FOUNDATIONAL_ANALYTICS_BY_ID_REQUEST:
            return { loading: true }
        case GET_FOUNDATIONAL_ANALYTICS_BY_ID_SUCCESS:
            return { loading: false, assignmentAnalytics: action.payload }
        case GET_FOUNDATIONAL_ANALYTICS_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const getFoundationalUserAnswerSheetReducer = (state = { }, action) => {
    switch (action.type) {
        case GET_FOUNDATIONAL_ANSWER_SHEET_REQUEST:
            return { loading: true }
        case GET_FOUNDATIONAL_ANSWER_SHEET_SUCCESS:
            return { loading: false, answerSheet: action.payload }
        case GET_FOUNDATIONAL_ANSWER_SHEET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postRatingReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_REVIEW_REQUEST:
            return { loading: true }
        case POST_REVIEW_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_REVIEW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postFoundationalRatingReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_FOUNDATIONAL_REVIEW_REQUEST:
            return { loading: true }
        case POST_FOUNDATIONAL_REVIEW_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_FOUNDATIONAL_REVIEW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postRecentSubjectReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_RECENT_SUBJECTS_REQUEST:
            return { loading: true }
        case POST_RECENT_SUBJECTS_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_RECENT_SUBJECTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getRecentSubjectReducer = (state = { recentSubjects: [] }, action) => {
    switch (action.type) {
        case GET_RECENT_SUBJECTS_REQUEST:
            return { loading: true, ...state }
        case GET_RECENT_SUBJECTS_SUCCESS:
            return { loading: false, recentSubjects: action.payload }
        case GET_RECENT_SUBJECTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postCourseCouponReducer = (state = { couponDetails:{}}, action) => {
    switch (action.type) {
        case POST_COURSE_COUPON_VALIDATION_REQUEST:
            return { loading: true , couponDetails:{}}
        case POST_COURSE_COUPON_VALIDATION_SUCCESS:
            return { loading: false, couponDetails: action.payload }
        case POST_COURSE_COUPON_VALIDATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}