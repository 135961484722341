import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../../Components/main/sidebar/sidebar';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import Logo from '../../assets/icons/logo.svg';
import { Col, Row } from 'react-bootstrap';
import { BiPieChart } from 'react-icons/bi';
import { BsHourglassSplit } from 'react-icons/bs';
import { MdOutlineTimelapse } from 'react-icons/md';

import { Link } from 'react-router-dom';

import { getLiveTestDetails } from '../../actions/EnrolledTestSeries';
import DOMPurify from 'dompurify';
import './MyTestSeries.scss'


const LiveTestSeries = ({match}) => {
    const dispatch = useDispatch()

    const getLiveTestById = useSelector(state => state.getLiveTestById)
    const { loading, error, liveTest } = getLiveTestById;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(()=>{
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getLiveTestDetails(match.params.id))
    },[dispatch,userInfo ,match])


    const minToHours =(min)=>{
        var hr = min/60;
        if(hr<1){
          hr=0;
        }
        var m = min%60;
        return `${(hr===0)?"":(hr<10)?`0${hr.toString().split(".")[0]} hr`:`${hr.toString().split(".")[0]} hr`} ${(m<10)?`0${m}min`:`${m} min`}`
    }

    const isActive = (startingTime, endingTime) => {
        let today = new Date()

        let st = new Date(startingTime)
        let StartingTime = st.getTime() + (st.getTimezoneOffset() * 60000);

        let et = new Date(endingTime)
        let EndingTime = et.getTime() + (et.getTimezoneOffset() * 60000);

        if( StartingTime > today.getTime() || today.getTime() > EndingTime){
            return false
        }else{
            return true;
        }
    }

    const attemptTest = (e) => {
        e.preventDefault();
        document.location.href = `/testseries/live/instructions/${match.params.id}`;
    }

    return (
        <div>
            <Sidebar activeLink={'mytestseries'}/>
            <main className="main">
                {loading && <Loader/>}
                {error && <AlertDismissibleExample data={error} />}
                {
                    (!loading && !error) && (
                        <div className="practice-test-overview">
                            <div className="test-banner">
                                <Link to='/mytestseries'>
                                    <img src={leftArrow} alt="<" style={{ width: 17, margin: 20, cursor: 'pointer' }} />
                                </Link>
                                <div>
                                    <img style={{ background: "#dfdfdfe7", padding: 10,width:'300px' , borderRadius: "15px" }} src={Logo} alt="" />
                                </div>
                            </div>
                            <div className="test-content py-5">
                                <div className="d-flex test-info my-0 justify-content-between align-items-center">
                                    <h2 className="m-0">{liveTest.name}</h2>
                                </div>
                                <div className="d-flex  test-info my-0 justify-content-between align-items-center">
                                    <p>Type: {liveTest.testType}</p>
                                </div>
                                <div className="test-info">
                                    <h3>About the test</h3>
                                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(liveTest.description)}}></p>
                                </div>
                                <div className="test-info">
                                    <Row>
                                        <Col className="md-6">
                                            <div className="test-details">
                                                <div>
                                                    <p>
                                                        <span style={{fontWeight:600}}>
                                                            <BsHourglassSplit /> Duration:
                                                        </span> 
                                                        {minToHours(liveTest.duration)}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <span style={{fontWeight:600}}>
                                                            <BiPieChart/> starting Time: 
                                                        </span> {new Date(new Date(liveTest.startingTime).getTime() + (new Date(liveTest.startingTime).getTimezoneOffset() * 60000)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit' })}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <span style={{fontWeight:600}}>
                                                            <MdOutlineTimelapse/> Ending Time: 
                                                        </span> {new Date(new Date(liveTest.endingTime).getTime() + (new Date(liveTest.endingTime).getTimezoneOffset() * 60000)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit' })}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Row>
                                                {
                                                    (liveTest.isAttempted && liveTest.isExpired) ? 
                                                    <Link className="practice-test-btn" style={{textDecoration:'none'}} to={`/testseries/live/analytics/${match.params.id}`}>
                                                        View Analytics
                                                    </Link> :
                                                    <button disabled className="practice-test-btn">
                                                        View Analytics
                                                    </button>
                                                }
                                            </Row>  
                                            <Row>
                                                {
                                                    (isActive(liveTest.startingTime, liveTest.endingTime) && !liveTest.isAttempted) ?
                                                    <button className="practice-test-btn" onClick={attemptTest}>
                                                        Attempt Test
                                                    </button>:
                                                    <button className="practice-test-btn" disabled>
                                                        Attempt Test
                                                    </button> 
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    )
                }
            </main>
        </div>
    )
}

export default LiveTestSeries
