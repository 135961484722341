export const GET_ALL_PRACTICETESTS_REQUEST = 'GET_ALL_PRACTICETESTS_REQUEST';
export const GET_ALL_PRACTICETESTS_SUCCESS = 'GET_ALL_PRACTICETESTS_SUCCESS';
export const GET_ALL_PRACTICETESTS_FAIL = 'GET_ALL_PRACTICETESTS_FAIL';

export const GET_PRACTICETEST_BY_ID_REQUEST = 'GET_PRACTICETEST_BY_ID_REQUEST';
export const GET_PRACTICETEST_BY_ID_SUCCESS = 'GET_PRACTICETEST_BY_ID_SUCCESS';
export const GET_PRACTICETEST_BY_ID_FAIL = 'GET_PRACTICETEST_BY_ID_FAIL';

export const POST_ENROLL_PRACTICETEST_REQUEST = 'POST_ENROLL_PRACTICETEST_REQUEST';
export const POST_ENROLL_PRACTICETEST_SUCCESS = 'POST_ENROLL_PRACTICETEST_SUCCESS';
export const POST_ENROLL_PRACTICETEST_FAIL = 'POST_ENROLL_PRACTICETEST_FAIL';

export const GET_PRACTICETEST_PAYMENT_HISTORY_REQUEST = 'GET_PRACTICETEST_PAYMENT_HISTORY_REQUEST';
export const GET_PRACTICETEST_PAYMENT_HISTORY_SUCCESS = 'GET_PRACTICETEST_PAYMENT_HISTORY_SUCCESS';
export const GET_PRACTICETEST_PAYMENT_HISTORY_FAIL = 'GET_PRACTICETEST_PAYMENT_HISTORY_FAIL';

export const POST_PRACTICETEST_COUPON_VALIDATION_REQUEST = 'POST_PRACTICETEST_COUPON_VALIDATION_REQUEST';
export const POST_PRACTICETEST_COUPON_VALIDATION_SUCCESS = 'POST_PRACTICETEST_COUPON_VALIDATION_SUCCESS';
export const POST_PRACTICETEST_COUPON_VALIDATION_FAIL = 'POST_PRACTICETEST_COUPON_VALIDATION_FAIL';
