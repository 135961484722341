import {
    COUNSELLING_REQUEST,
    COUNSELLING_SUCCESS,
    COUNSELLING_FAIL
} from '../constants/counsellingConstants';

export const bookCounsellingReducer = ( state={}, action) => {
    switch(action.type){
        case COUNSELLING_REQUEST:
            return {loading:true}
        case COUNSELLING_SUCCESS:
            return{loading:false, message: action.payload}
        case COUNSELLING_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}