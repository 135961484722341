import React from 'react'
import Sidebar from '../../Components/main/sidebar/sidebar';
import "./GetHelp.scss"
import CourseDetailsAccordion from "../../views/CourseDetailsAccordion"
import coverPhoto from "../../assets/images/admin.svg"

function GetHelp() {

    var faq = [
        {
            question: 'How can I purchase the course?',
            answer: 'Courses are available on the home page of Yes Mentor application. You can enroll in any of those courses according to your suitability.'
        },
        {
            question: 'What is the payment method for purchasing online courses?',
            answer: 'Payment can be done through Net Banking, UPI or debit/credit card.'
        },
        {
            question: 'What will be the nature of payment?',
            answer: 'You can pay in lump sum or in installments.'
        },
        {
            question: 'How can I enroll in a live test series?',
            answer: 'After purchasing the course, you’ll get direct access to live test series. You can also purchase them separately in case you just want to check the level of your preparation.'
        },
        {
            question: 'How can I schedule counseling sessions?',
            answer: 'You can choose the date and time given and the counselor will be available on that specific point of time.'
        },
        {
            question: 'What will be the mode of the counseling session?',
            answer: 'They will be conducted on audio calls or on google meets.'
        },
        {
            question: 'How many holidays will I get in between the courses?',
            answer: 'The courses are designed in such a manner that aspirants can get the last two months of the year for the preparation of their undergraduate course examinations along with the national holidays.'
        }
    ]

    return (

        <div>
            <Sidebar activeLink={'help'} />
            <main className="main">
                <div className="get-help">
                    <h2>Get help!</h2>
                    <div className="row">
                        <div className="col-md-7">
                            <CourseDetailsAccordion faqs={faq} />
                        </div>
                        <div className="col-md-5">
                            <img style={{ width: "inherit" }} src={coverPhoto} alt="" />
                        </div>
                    </div>
                </div>

            </main>

        </div>
    )
}

export default GetHelp
