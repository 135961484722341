import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Logo from '../assets/icons/logo.svg';
import RightArrow from '../assets/icons/right-arrow.svg';
import signupImage from '../assets/images/signin-image.svg';
// import {FcGoogle} from 'react-icons/fc'

import AlertDismissibleExample from '../Components/Error/error';
import Loader from '../Components/Loader';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
// import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// import { initializeApp } from "firebase/app";

import { useForm } from "react-hook-form";
import { registerUser, googleAuth } from '../actions/userActions';
// import {firebaseConfig} from '../config/config';
import { ClientId } from '../config/config';

import { Visibility, VisibilityOff } from '@material-ui/icons';

const SignUpScreen = () => {
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const userRegister = useSelector(state => state.userRegister)
    const { loading, error, userInfo: Reg } = userRegister;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const googleLogin = useSelector(state => state.googleLogin)
    const { loading: googleLoading, error: googleError, userInfo: googleUserInfo } = googleLogin

    const [showPass, setShowPass] = useState(false)

    useEffect(() => {
        if (userInfo || Reg || googleUserInfo) {
            document.location.href = '/';
        }
    }, [userInfo, Reg, googleUserInfo])

    const onSubmit = (data) => {
        dispatch(registerUser(data));
    }

    const successRes = (response) => {
        dispatch(googleAuth(response.tokenObj.id_token))
    }
    const failRes = (response) => {
        console.log(response)
    }
    return (
        <>
            {(loading || googleLoading) && <Loader />}
            {error && <AlertDismissibleExample data={error} />}
            {googleError && <AlertDismissibleExample data={googleError} />}
            <main>

                <Row className="m-0">
                    <Col md="6" lg="4" className="p-0">
                        <div className="form-wrapper">
                            <img src={Logo} alt="signup illustration" />
                            <h1>Sign up <br /><span>Start learning!</span></h1>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-wrapper my-2 mt-5">
                                    <div className="bg">
                                        <div className="input-cont">
                                            <input type="text" autoComplete="off" {...register("firstname", { required: true })} />
                                            <label htmlFor="firstname" className="label-name">
                                                <span className="content-name">
                                                    First Name
                                                </span>
                                            </label>
                                        </div>
                                        {errors.firstname && <span style={{ color: '#ff0101', fontSize: 12 }}>First Name field is required</span>}
                                    </div>
                                    <div className="bg">
                                        <div className="input-cont">
                                            <input type="text" autoComplete="off" {...register("lastname", { required: true })} />
                                            <label htmlFor="lastname" className="label-name">
                                                <span className="content-name">
                                                    Last Name
                                                </span>
                                            </label>
                                        </div>
                                        {errors.lastname && <span style={{ color: '#ff0101', fontSize: 12 }}>Last Name field is required</span>}
                                    </div>
                                    <div className="bg">
                                        <div className="input-cont">
                                            <input type="text" autoComplete="off"    {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                            <label htmlFor="name" className="label-name">
                                                <span className="content-name">
                                                    Email Address
                                                </span>
                                            </label>
                                        </div>
                                        {errors.email && errors.email.type === "required" && <span style={{ color: '#ff0101', fontSize: 12 }}>Email field is required</span>}
                                        {errors.email && errors.email.type === "pattern" && <span style={{ color: '#ff0101', fontSize: 12 }}>You have entered an invalid e-mail address.</span>}
                                    </div>
                                    <div className="bg">
                                        <div className="input-cont d-flex justify-content-between">
                                            <div>
                                                <input type={showPass ? "text" : "password"} autoComplete="off" {...register("password", { required: true, minLength: 6 })} />
                                                <label htmlFor="password" className="label-name">
                                                    <span className="content-name">
                                                        Password
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="align-self-center">
                                                {
                                                    !showPass && <button
                                                        onClick={() => setShowPass(true)}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}
                                                    ><Visibility /></button>
                                                }
                                                {
                                                    showPass && <button
                                                        onClick={() => setShowPass(false)}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}>
                                                        <VisibilityOff /></button>
                                                }
                                            </div>
                                        </div>
                                        {errors.password && errors.password.type === "required" && <span style={{ color: '#ff0101', fontSize: 12 }}>Password field is required</span>}
                                        {errors.password && errors.password.type === "minLength" && <span style={{ color: '#ff0101', fontSize: 12 }}>You password must be atleast 6 characters long.</span>}
                                    </div>
                                    <div className="bg">
                                        <div className="input-cont">
                                            <input type="text" autoComplete="off" maxLength="10"  {...register("phone", { required: true, minLength: 10 })} onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} />
                                            <label htmlFor="name" className="label-name">
                                                <span className="content-name">
                                                    Mobile Number
                                                </span>
                                            </label>
                                        </div>
                                        {errors.phone && errors.phone.type === "required" && <span style={{ color: '#ff0101', fontSize: 12 }}>Mobile number field is required</span>}
                                        {errors.phone && errors.phone.type === "minLength" && <span style={{ color: '#ff0101', fontSize: 12 }}>Mobile Number must be 10 numbers.</span>}
                                    </div>
                                </div>
                                <div className='next-container'>
                                    <button type="submit" className="next-btn mt-4">
                                        <img src={RightArrow} alt=">" />
                                    </button>
                                </div>
                            </form>
                            <p className="mt-5 mb-4 text-center">Already have an account? <Link to="/login" style={{ color: '#0669F8' }}>Sign In</Link></p>
                            <div className="googleBtnContainer">
                                <GoogleLogin clientId={ClientId}
                                    buttonText="Sign in with google"
                                    onSuccess={successRes}
                                    onFailure={failRes}
                                    cookiePolicy={'single_host_origin'} />
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="8" className="p-0" style={{ backgroundColor: '#0669F8' }}>
                        <div className="image-wrapper">
                            <img src={signupImage} alt="signup illustration" />
                            <div>
                                <p style={{ width: ' 67%', margin: 'auto' }}>Sign up and get access to the best study resources</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </main>
        </>
    )
}

export default SignUpScreen;
