import React,{useEffect} from 'react';

const RedirectToPreB4Pre = ({match, history}) => {
    useEffect(()=>{
        if(!localStorage.getItem('userInfo')){
            localStorage.setItem('userInfo', JSON.stringify({
                authToken:match.params.token,
                id:match.params.userID
            }))
        }
        if(localStorage.getItem('userInfo')){
            if(match.params.type === 'online'){
                document.location.href = '/enroll-pre-b4-pre';
            }else{
                document.location.href = '/enroll-Offline-pre-b4-pre';
            }
        }
    },[history,match])
    return <div></div>;
};

export default RedirectToPreB4Pre;
