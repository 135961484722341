import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {
    getFoundationalCourseContent,
    putFoundationalCompleteStatus,
    putFoundationalRecentTopicStatus,
    getFoundationalCourseById
} from '../../../actions/courseActions'; 

import Loader from '../../../Components/Loader';
import AlertDismissibleExample from '../../../Components/Error/error';
import AlertSuccessExample from '../../../Components/success/success';
// import { ImCross } from 'react-icons/im' ;
import leftArrow from '../../../assets/images/ionic-ios-arrow-back.png';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Lock, AccountCircle, KeyboardArrowDown } from '@material-ui/icons';

import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

import './EnrollCourseDetails.scss'

import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';

import Description from '../../../views/FoundationalCourse/EnrolledUser/Description/Description';
import Notes from '../../../views/FoundationalCourse/EnrolledUser/Notes/Notes';
import Assignments from '../../../views/FoundationalCourse/EnrolledUser/Assignments/Assignments';
// import Reviews from '../../../views/FoundationalCourse/EnrolledUser/Reviews/Reviews';

const Accordion = styled((props) => (<MuiAccordion disableGutters elevation={0} square {...props} />))(({ theme }) => (
        {
            border: `1px solid ${theme.palette.divider}`,
            '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    })
);

const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const EnrollCourseDetails = ({match, history}) => {
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState('subject0');
    const [chapterExpanded, setChapterExpanded] = useState('chapter0');
    const [content, setContent] = useState({
        video:"",
        id:"",
        name:""
    });
    const [showDescription, setShowDescription] = useState(true);
    const [showNotes, setShowNotes ] = useState(false);
    const [showAssignments, setShowAssignments] = useState(false);

    const displayDescription = (e) =>{
        e.preventDefault()
        setShowDescription(true)
        setShowNotes(false)
        setShowAssignments(false)
    }

    const displayNotes = (e) =>{
        e.preventDefault()
        setShowDescription(false)
        setShowNotes(true)
        setShowAssignments(false)
    }

    const displayAssignments = (e) =>{
        e.preventDefault()
        setShowDescription(false)
        setShowNotes(false)
        setShowAssignments(true)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChapterChange = (panel) => (event, newExpanded) => {
        setChapterExpanded(newExpanded ? panel : false);
    };

    const handleContent = (name, video, id) => {
        if(video){
            setContent({
                video:video, 
                name:name,
                id:id
            })
        }else{
            setContent({
                video:"", 
                name:"",
                id:id
            })
        }
        dispatch(putFoundationalRecentTopicStatus(id, match.params.id))
    };

    const getCourseValidityDetail = useSelector(state => state.getCourseValidityDetail)
    const { loading, error, course } = getCourseValidityDetail;

    const putFoundationalStatus = useSelector(state => state.putFoundationalStatus)
    const { loading:loadingStatus, error:errorStatus, message } = putFoundationalStatus;

    const putFoundationalRecentTopics = useSelector(state => state.putFoundationalRecentTopics)
    const { loading:loadingRecentTopic, error:errorRecentTopic, message:messageRecentTopic } = putFoundationalRecentTopics;

    const getFoundationalCourseDetails = useSelector(state => state.getFoundationalCourseDetails)
    const { loading:loadingCourse, error:errorCourse, FoundationalCourse } = getFoundationalCourseDetails;

    const putResetFoundationalTest = useSelector(state => state.putResetFoundationalTest)
    const { loading:loadingReset, error:errorReset, message:messageReset } = putResetFoundationalTest;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getFoundationalCourseContent(match.params.id));
            dispatch(getFoundationalCourseById(match.params.id))
        }
    }, [userInfo, dispatch, match, message, messageRecentTopic, messageReset])

    const drop = (e) =>{
        e.preventDefault();

        const isDropdownButton = e.target.matches("[data-dropdown-button]")
        if(!isDropdownButton && e.target.closest("[data-dropdown]") != null){
            return
        }
        let currentDropdown
        if(isDropdownButton){
            currentDropdown = e.target.closest("[data-dropdown]")
            currentDropdown.classList.toggle('active')
        }

        document.querySelectorAll("[data-dropdown].active").forEach(dropdown =>{
            if(dropdown === currentDropdown){
                return
            }
            dropdown.classList.remove('active')
        })
    }

    const updateStatus = (id, status) => {
        dispatch(putFoundationalCompleteStatus(id,match.params.id,status))
    }

    useEffect(()=>{
        if(course.subjects.length > 0){
            for(let i=0; i<course.subjects.length; i++){

                for(let j=0; j<course.subjects[i].chapter.length; j++){

                    for(let k=0; k<course.subjects[i].chapter[j].topics.length; k++){

                        if(course.recentTopic === course.subjects[i].chapter[j].topics[k].topicId){
                            setExpanded(`subject${i}`);
                            setChapterExpanded(`chapter${j}`);
                            setContent({
                                video:course.subjects[i].chapter[j].topics[k].content.video,
                                id:course.subjects[i].chapter[j].topics[k].topicId,
                                name:course.subjects[i].chapter[j].topics[k].topicName
                            })
                            break;
                        }
                    }
                }
            }
        }
    },[course])

    return (
        <div className='enroll-foundation' onClick={drop} >
            {(loading || loadingStatus || loadingRecentTopic || loadingCourse || loadingReset) && <Loader/>}
            {error && <AlertDismissibleExample data={error}/>}
            {errorStatus && <AlertDismissibleExample data={errorStatus}/>}
            {errorRecentTopic && <AlertDismissibleExample data={errorRecentTopic}/>}
            {errorCourse && <AlertDismissibleExample data={errorCourse}/>}
            {errorReset && <AlertDismissibleExample data={errorReset}/>}
            {messageReset && <AlertSuccessExample data={messageReset}/>}

            <div className='nav-bar'>
                <div>
                    <div className='img-container'>
                        <img  src={leftArrow} alt="<" className="backBtn" onClick={(e)=>{e.preventDefault(); history.goBack()}}/>
                        <h3>{content.name}</h3>
                    </div>
                    <div className='right-content'>
                        <div className='dropD' data-dropdown  >
                            <button className='foundational-menu' data-dropdown-button>
                                <AccountCircle/> { course.userName && course.userName } <KeyboardArrowDown/>
                            </button>
                            <div className='drop-menu'>
                                <button className='items'>Live Lecture</button>
                                <Link className='items' to={`/foundational-payment-details/${match.params.id}/${match.params.eid}`}>Payment Details</Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='nav-back'></div>
        
            <div className="foundational-content">
                <div className='heading d-flex justify-content-around align-items-center'>
                    <h4>
                        Course content
                    </h4>
                </div>
                <div className='foundational-List'>
                    {
                        course.subjects.length > 0 && course.subjects.map((subject,index)=>(
                            <Accordion className="subjects" key={index} expanded={expanded === `subject${index}`} onChange={handleChange(`subject${index}`)}>
                                <AccordionSummary>
                                    <Typography>
                                        {subject.subjectName}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className='py-0 px-0'>
                                    {subject.chapter.map((chapter,idx)=>(
                                        <Accordion className='chapters' key={idx} expanded={chapterExpanded === `chapter${idx}`} onChange={handleChapterChange(`chapter${idx}`)}>
                                            <AccordionSummary>
                                                <Typography>{chapter.chapterName}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className='py-0 px-0'>
                                                {chapter.topics.map((topics, i)=>(
                                                    <Accordion className="topics" key={i} >
                                                        <AccordionDetails className={`my-0 ${course.recentTopic === topics.topicId && "selected"}`} >
                                                            <div className={`d-flex justify-content-left ${course.recentTopic === topics.topicId && "selected"} `}>
                                                                <div style={{padding:'0px 20px'}} className={course.recentTopic === topics.topicId ? "selected":"" }>
                                                                    {
                                                                        topics.content.isValidity ? <Checkbox onClick={(e)=>{e.preventDefault(); updateStatus(topics.topicId, 'toggle')}} checked={topics.isCompleted} />:
                                                                        <Link to={`/foundational-payment-details/${match.params.id}/${match.params.eid}`} style={{color:'#2b2b2b'}} title="Unlock">
                                                                            <Lock/>
                                                                        </Link>    
                                                                    }
                                                                </div>
                                                                <button disabled={!topics.content.isValidity} onClick={(e)=>handleContent(topics.topicName, topics.content.video, topics.topicId) }>
                                                                    {topics.topicName}   
                                                                </button> 
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </div>
            </div>
            <div className="video-player">
                <ReactPlayer
                    url={content.video}
                    width="100%"
                    height={"100%"}
                    controls
                    playing={false}
                    style={{overflow:'hidden'}}
                    light={true}
                    onEnded={(e)=>{updateStatus(content.id, 'true')}}
                />
            </div>
            <div className='foundational-info'>
                <div className='foundational-info-bar'>
                    <div className='foundational-info-item'>
                        <button onClick={displayDescription} className={showDescription ? 'selected' : ""}>Description</button>
                    </div>
                    <div className='foundational-info-item'>
                        <button onClick={displayNotes} className={showNotes ? 'selected' : ""}>Notes</button>
                    </div>
                    <div className='foundational-info-item'>
                        <button onClick={displayAssignments} className={showAssignments ? 'selected' : ""}>Assignments</button>
                    </div>
                </div>
                <div className='foundational-info-container'>
                    {showDescription && <Description data={FoundationalCourse}/>}
                    {showNotes && <Notes data={course} tid={match.params.id} eid={match.params.eid}/>}
                    {showAssignments && <Assignments data={FoundationalCourse} history={history} eid={match.params.eid} tid={match.params.id}/>}
                </div>
            </div>
        </div>
    )
}

export default EnrollCourseDetails