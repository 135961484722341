export const BASEURL = 'https://api.yesmentor.in';
// export const BASEURL = 'https://develop.yesmentor.in';
// export const BASEURL = 'http://localhost:3000';
export const firebaseConfig = {
    apiKey: "AIzaSyAtCkD5CFq-Fy6AGN2F4QANhwH3QeyxGXM",
    authDomain: "ed-tech-bc30d.firebaseapp.com",
    projectId: "ed-tech-bc30d",
    storageBucket: "ed-tech-bc30d.appspot.com",
    messagingSenderId: "826224814440",
    appId: "1:826224814440:web:cf8d809ca5a5cab91c8edd",
    measurementId: "G-0WJLSNL35N"
};

// testing creds
// export const RazorpayKey = 'rzp_test_I6J6hW9af9EYCw';
// export const RazorpaySecret = 'tBVQBZRzoHTvGM3JvCCcUZ6J';

//live creds
export const RazorpayKey = 'rzp_live_2nansE4uJWy2oa';
export const RazorpaySecret = 'Z5QBI65pIGtQyoUFtQMWcRGK';


export const ClientId = '826224814440-hvnemo75ld9njmaqkfchk2ul4q0fg602.apps.googleusercontent.com'

// export const URL = 'http://test-user.yesmentor.in'
export const URL = 'https://user.yesmentor.in';
// export const URL = 'http://localhost:3001';

