import React from 'react';
import { Pie } from 'react-chartjs-2';

const Donut = ({attempted, notAttempted, MarkForReview}) => {

    const data = {
        labels: [
            'Attempted',
            'Not Attempted',
            'Marked for review',
        ],
        datasets: [{
            label: 'My First Dataset',
            data: [attempted,notAttempted,MarkForReview],
            backgroundColor: [
                'rgb(102, 183, 49)',
                'rgb(239, 114, 116)',
                '#8E70C8',
            ],
            hoverBackgroundColor: [
                'rgb(102, 183, 49)',
                'rgb(239, 114, 116)',
                '#8E70C8',
            ],
            borderColor: [
                'rgb(102, 183, 49)',
                'rgb(239, 114, 116)',
                '#8E70C8',
            ],
        }]
    };

    return (
        <div>
            <Pie  
                data={data}
                width={160}
                height={160}
                options={{ maintainAspectRatio: false }}
            />
        </div>
    )
}

export default Donut
