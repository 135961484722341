import {
    GET_ALL_PRACTICETESTS_REQUEST,
    GET_ALL_PRACTICETESTS_SUCCESS,
    GET_ALL_PRACTICETESTS_FAIL,
    GET_PRACTICETEST_BY_ID_REQUEST,
    GET_PRACTICETEST_BY_ID_SUCCESS,
    GET_PRACTICETEST_BY_ID_FAIL,
    POST_ENROLL_PRACTICETEST_REQUEST,
    POST_ENROLL_PRACTICETEST_SUCCESS,
    POST_ENROLL_PRACTICETEST_FAIL,
    GET_PRACTICETEST_PAYMENT_HISTORY_REQUEST,
    GET_PRACTICETEST_PAYMENT_HISTORY_SUCCESS,
    GET_PRACTICETEST_PAYMENT_HISTORY_FAIL,
    POST_PRACTICETEST_COUPON_VALIDATION_REQUEST,
    POST_PRACTICETEST_COUPON_VALIDATION_SUCCESS,
    POST_PRACTICETEST_COUPON_VALIDATION_FAIL
} from '../constants/practiceTestConstants';

export const getAllPracticeTestsReducer = (state = { practiceTests: [] }, action) => {
    switch (action.type) {
        case GET_ALL_PRACTICETESTS_REQUEST:
            return { loading: true, practiceTests: [] }
        case GET_ALL_PRACTICETESTS_SUCCESS:
            return { loading: false, practiceTests: action.payload }
        case GET_ALL_PRACTICETESTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPracticeTestDetailsReducer = (state = { practiceTest: {} }, action) => {
    switch (action.type) {
        case GET_PRACTICETEST_BY_ID_REQUEST:
            return { loading: true, ...state }
        case GET_PRACTICETEST_BY_ID_SUCCESS:
            return { loading: false, practiceTest: action.payload }
        case GET_PRACTICETEST_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollPracticeTestReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_PRACTICETEST_REQUEST:
            return { loading: true }
        case POST_ENROLL_PRACTICETEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_PRACTICETEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPracticeTestPaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_PRACTICETEST_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_PRACTICETEST_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_PRACTICETEST_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postPracticeCouponReducer = (state = { couponDetails:{}}, action) => {
    switch (action.type) {
        case POST_PRACTICETEST_COUPON_VALIDATION_REQUEST:
            return { loading: true , couponDetails:{}}
        case POST_PRACTICETEST_COUPON_VALIDATION_SUCCESS:
            return { loading: false, couponDetails: action.payload }
        case POST_PRACTICETEST_COUPON_VALIDATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}