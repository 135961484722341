import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../../Components/main/sidebar/sidebar';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import Logo from '../../assets/images/preb4pre.png'
import { Col, Row } from 'react-bootstrap';
import { BiPieChart } from 'react-icons/bi';
import { BsHourglassSplit } from 'react-icons/bs';
import { MdOutlineTimelapse } from 'react-icons/md';
import './MyTestSeries.scss'
import { getAskToEvaluateByID } from '../../actions/askToEvaluateActions';
import DOMPurify from 'dompurify';

import { Link } from 'react-router-dom';

const EnrollAskToEvaluate = ({ match, history }) => {
    const dispatch = useDispatch()

    const getATEDetails = useSelector(state => state.getATEDetails)
    const { loading, error, askToEvaluate } = getATEDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }else{
            dispatch(getAskToEvaluateByID(match.params.id))
        }
    }, [dispatch,userInfo ,match])

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m.toFixed(0)}min` : `${m.toFixed(0)} min`}`
    }

    const isActive = (startingTime, endingTime) => {
        let today = new Date()

        let st = new Date(startingTime)
        let StartingTime = st.getTime() + (st.getTimezoneOffset() * 60000);

        let et = new Date(endingTime)
        let EndingTime = et.getTime() + (et.getTimezoneOffset() * 60000);

        if( StartingTime > today.getTime() || today.getTime() > EndingTime){
            return false
        }else{
            return true;
        }
    }

    return (
        <div>
            <Sidebar activeLink={'mytestseries'} />
            <main className="main">
                {loading && <Loader/>}
                {error && <AlertDismissibleExample data={error} />}

                {
                    askToEvaluate && 
                    <div className="practice-test-overview">
                        <div className="test-banner">
                            <div onClick={(e)=>{e.preventDefault(); history.goBack()}}>
                                <img src={leftArrow} alt="<" style={{ width: 17, margin: 20, cursor: 'pointer' }} />
                            </div>
                            <div>
                                <img style={{ background: "#dfdfdfe7", padding: 10,width:'300px' , borderRadius: "15px" }} src={Logo} alt="" />
                            </div>
                        </div>
                        <div className="test-content py-5">
                            <div className="d-flex test-info my-0 justify-content-between align-items-center">
                                <h2 className="m-0">{askToEvaluate.name}</h2>
                            </div>
                            <div className="d-flex  test-info my-0 justify-content-between align-items-center">
                                <p>Type: {askToEvaluate.testType}</p>
                            </div>
                            <div className="test-info">
                                <h3>About the test</h3>
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(askToEvaluate.description)}}></p>
                            </div>
                            <div className="test-info">
                                <Row>
                                    <Col className="md-6">
                                        <div className="test-details">
                                            <div>
                                                <p>
                                                    <span style={{fontWeight:600}}>
                                                        <BsHourglassSplit /> Duration:
                                                    </span> 
                                                    {minToHours(askToEvaluate.slot.duration)}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span style={{fontWeight:600}}>
                                                        <BiPieChart/> starting Time: 
                                                    </span> {new Date(new Date(askToEvaluate.slot.startingTime).getTime() + (new Date(askToEvaluate.slot.startingTime).getTimezoneOffset() * 60000)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit' })}
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <span style={{fontWeight:600}}>
                                                        <MdOutlineTimelapse/> Ending Time: 
                                                    </span> {new Date(new Date(askToEvaluate.slot.endingTime).getTime() + (new Date(askToEvaluate.slot.endingTime).getTimezoneOffset() * 60000)).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit' })}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Row>
                                            {
                                                (askToEvaluate.isAttempted && askToEvaluate.isResult) ? 
                                                <Link className="practice-test-btn" style={{textDecoration:'none'}} to={`/ask-to-evaluate/analytics/${match.params.id}`}>
                                                    View Analytics
                                                </Link> :
                                                <button disabled className="practice-test-btn">
                                                    View Analytics
                                                </button>
                                            }
                                        </Row>  
                                        <Row>
                                            {
                                                (isActive(askToEvaluate.slot.startingTime, askToEvaluate.slot.endingTime) && !askToEvaluate.isAttempted) ?
                                                <button className="practice-test-btn" onClick={(e)=>{e.preventDefault(); history.push(`/ask-to-evaluate/instructions/${askToEvaluate.id}`)}}>
                                                    Attempt Test
                                                </button>:
                                                <button className="practice-test-btn" disabled>
                                                    Attempt Test
                                                </button> 
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                }
            </main>
        </div>
    )
}

export default EnrollAskToEvaluate