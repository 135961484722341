import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import './Pdf.scss';

const Pdf = ({ url }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const [numPages, setNumPages] = useState(null)
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <div className="pdf">
            <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                className="react-pdf__Page"
            >
                {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => <div> <Page pageNumber={page} /></div>)}
            </Document>
        </div>
    )
}

export default Pdf
