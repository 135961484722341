import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap'

//components
import Sidebar from '../../Components/main/sidebar/sidebar';
import SearchItem from '../../Components/Search/SearchItem';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

//assets
import PreB4Pre from '../../assets/images/preb4pre.png';
import noImage from '../../assets/images/otp.png';
import leftArrow from '../../assets/icons/back-arrow.svg'
import cardIMG from '../../assets/icons/assignment1.svg';

//styling
import "./MyPreb4preTests.scss"

//apiCall
import {
    getOfflineEnrolledPreb4pre
} from '../../actions/offlinePreB4PreAction';


const MyOfflinePreb4Pre = ({history}) => {
    const dispatch = useDispatch()

    const [tests, setTests] = useState([])
    const [searchResults, setSearchResults] = useState([]);

    const getEnrolledOfflinePreb4pre = useSelector(state => state.getEnrolledOfflinePreb4pre)
    const { loading, error, OfflinePreb4pre } = getEnrolledOfflinePreb4pre;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (document.documentElement) {
            window.scrollTo(0, 0)
        }
    }, [])

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getOfflineEnrolledPreb4pre())
    }, [userInfo, dispatch])

    useEffect(() => {
        if (!error) {
            var tests = OfflinePreb4pre.map(item => ({
                ...item,
                name: item.Test.TestSeriesName
            }))

            setTests(tests)
            setSearchResults(tests)
        }
    }, [OfflinePreb4pre, error])

    const colors = [
        { bg: '#590196' },
        { bg: '#9f8400' },
        { bg: '#007947' },
        { bg: '#5b2e3d' }
    ]

    const stringDate = (examDate) => {
        const event = new Date(examDate);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone:'UTC' };
        return event.toLocaleString('as-IN', options)
    }
    
    return <div>
        <Sidebar activeLink={'more'} />
        <main className='main preb4pretests px-5'>
            <section className="d-flex flex-column align-items-center">
                <img src={PreB4Pre} className='IMG' alt="" width="200" />
            </section>
            <section style={{position:'absolute', top:'50px'}}>
                <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                    <img src={leftArrow} alt="<" style={{ margin: 10 }} />
                </button>
            </section>

            {(loading || error) && <MyTestseriesLoading/>}

            {OfflinePreb4pre.length === 0  && 
                <div className="no-live-tests">
                    <img src={noImage} alt="" />
                    <h3>Offline Pre-B4-Pre Not Found</h3>
                </div> 
            }
            {OfflinePreb4pre.length !== 0 &&
                <div className='pt-5'>
                    <SearchItem
                        allItems={tests}
                        from={"Offline Pre-B4-Pre"}
                        handleSetSearchResults={(results) => setSearchResults(results)}
                    />
                </div>
            }

            <Row className='testSeries py-5'>
                {searchResults.map((element, i)=>(
                    <Col lg={5} xs={12} 
                        className="testSeries-card my-2" 
                        key={element.id} 
                        style={{ backgroundColor: `${colors[i < colors.length ? i : i % colors.length].bg}`, cursor: 'pointer' }}
                        onClick={(e)=>{e.preventDefault(); history.push(`/offline/my-pre-b4-pre/${element.Test.id}`)}}
                    >
                        <img src={cardIMG} alt="assignment" />
                        <div>
                            <p style={{ fontSize: 20, fontWeight: '500' }} title={element.Test.TestSeriesName}>
                                {element.Test.TestSeriesName.substring(0,18)}
                                {element.Test.TestSeriesName.length > 18 && '...'}
                            </p>
                            <p style={{ fontSize: 14, fontWeight:'500' }} title={element.Test.subHeading}>
                                {element.Test.subHeading && element.Test.subHeading.substring(0,15)}
                                {element.Test.subHeading && element.Test.subHeading.length > 15 && '...'}
                            </p>
                            <p style={{ fontSize: 14, opacity: 0.7 }}>Type : {element.Test.TestSeriesType ? element.Test.TestSeriesType : 'objective'}</p>
                            <p style={{ fontSize: 14, opacity: 0.7 }}>Exam Date : {stringDate(element.Test.slot[0].startingTime)}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </main>
    </div>;
};

export default MyOfflinePreb4Pre;
