import {

    GET_ALL_AUDIOBOOKS_REQUEST,
    GET_ALL_AUDIOBOOKS_SUCCESS,
    GET_ALL_AUDIOBOOKS_FAIL,
    GET_AUDIOBOOK_BY_ID_REQUEST,
    GET_AUDIOBOOK_BY_ID_SUCCESS,
    GET_AUDIOBOOK_BY_ID_FAIL,
    POST_ENROLL_AUDIOBOOK_REQUEST,
    POST_ENROLL_AUDIOBOOK_SUCCESS,
    POST_ENROLL_AUDIOBOOK_FAIL,
    GET_AUDIOBOOK_PAYMENT_HISTORY_REQUEST,
    GET_AUDIOBOOK_PAYMENT_HISTORY_SUCCESS,
    GET_AUDIOBOOK_PAYMENT_HISTORY_FAIL,
    POST_AUDIOBOOK_COUPON_VALIDATION_REQUEST,
    POST_AUDIOBOOK_COUPON_VALIDATION_SUCCESS,
    POST_AUDIOBOOK_COUPON_VALIDATION_FAIL
    // GET_USER_AUDIOBOOKS_REQUEST,
    // GET_USER_AUDIOBOOKS_SUCCESS,
    // GET_USER_AUDIOBOOKS_FAIL,
    // PUT_LIKED_AUDIO_REQUEST,
    // PUT_LIKED_AUDIO_SUCCESS,
    // PUT_LIKED_AUDIO_FAIL,
    // PUT_COMPLETED_AUDIO_REQUEST,
    // PUT_COMPLETED_AUDIO_SUCCESS,
    // PUT_COMPLETED_AUDIO_FAIL,
    // PUT_FAVORITE_AUDIOBOOK_REQUEST,
    // PUT_FAVORITE_AUDIOBOOK_SUCCESS,
    // PUT_FAVORITE_AUDIOBOOK_FAIL,
    // GET_FAVOURITE_AUDIOBOOKS_REQUEST,
    // GET_FAVOURITE_AUDIOBOOKS_SUCCESS,
    // GET_FAVOURITE_AUDIOBOOKS_FAIL,
    // GET_AUDIO_LIST_REQUEST,
    // GET_AUDIO_LIST_SUCCESS,
    // GET_AUDIO_LIST_FAIL

} from '../constants/audiobookConstants'

export const getAllAudiobooksReducer = (state = { audiobooks: [] }, action) => {
    switch (action.type) {
        case GET_ALL_AUDIOBOOKS_REQUEST:
            return { loading: true, audiobooks: [] }
        case GET_ALL_AUDIOBOOKS_SUCCESS:
            return { loading: false, audiobooks: action.payload }
        case GET_ALL_AUDIOBOOKS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAudiobookDetailsReducer = (state = { audiobook: {} }, action) => {
    switch (action.type) {
        case GET_AUDIOBOOK_BY_ID_REQUEST:
            return { loading: true, ...state }
        case GET_AUDIOBOOK_BY_ID_SUCCESS:
            return { loading: false, audiobook: action.payload }
        case GET_AUDIOBOOK_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollAudiobookReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_AUDIOBOOK_REQUEST:
            return { loading: true }
        case POST_ENROLL_AUDIOBOOK_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_AUDIOBOOK_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAudiobookPaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_AUDIOBOOK_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_AUDIOBOOK_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_AUDIOBOOK_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postAudioBookCouponReducer = (state = { couponDetails:{}}, action) => {
    switch (action.type) {
        case POST_AUDIOBOOK_COUPON_VALIDATION_REQUEST:
            return { loading: true , couponDetails:{}}
        case POST_AUDIOBOOK_COUPON_VALIDATION_SUCCESS:
            return { loading: false, couponDetails: action.payload }
        case POST_AUDIOBOOK_COUPON_VALIDATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}