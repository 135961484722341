export const GET_ASK_TO_EVALUATE_REQUEST = 'GET_ASK_TO_EVALUATE_REQUEST';
export const GET_ASK_TO_EVALUATE_SUCCESS = 'GET_ASK_TO_EVALUATE_SUCCESS';
export const GET_ASK_TO_EVALUATE_FAIL = 'GET_ASK_TO_EVALUATE_FAIL';

export const GET_ASK_TO_EVALUATE_BY_ID_REQUEST = 'GET_ASK_TO_EVALUATE_BY_ID_REQUEST';
export const GET_ASK_TO_EVALUATE_BY_ID_SUCCESS = 'GET_ASK_TO_EVALUATE_BY_ID_SUCCESS';
export const GET_ASK_TO_EVALUATE_BY_ID_FAIL = 'GET_ASK_TO_EVALUATE_BY_ID_FAIL';

export const POST_ASKTOEVALUATE_COUPON_VALIDATION_REQUEST = 'POST_ASKTOEVALUATE_COUPON_VALIDATION_REQUEST';
export const POST_ASKTOEVALUATE_COUPON_VALIDATION_SUCCESS = 'POST_ASKTOEVALUATE_COUPON_VALIDATION_SUCCESS';
export const POST_ASKTOEVALUATE_COUPON_VALIDATION_FAIL = 'POST_ASKTOEVALUATE_COUPON_VALIDATION_FAIL';

export const POST_ENROLL_ASKTOEVALUATE_REQUEST = 'POST_ENROLL_ASKTOEVALUATE_REQUEST';
export const POST_ENROLL_ASKTOEVALUATE_SUCCESS = 'POST_ENROLL_ASKTOEVALUATE_SUCCESS';
export const POST_ENROLL_ASKTOEVALUATE_FAIL = 'POST_ENROLL_ASKTOEVALUATE_FAIL';

export const GET_ASKTOEVALUATE_PAYMENT_HISTORY_REQUEST = 'GET_ASKTOEVALUATE_PAYMENT_HISTORY_REQUEST'
export const GET_ASKTOEVALUATE_PAYMENT_HISTORY_SUCCESS = 'GET_ASKTOEVALUATE_PAYMENT_HISTORY_SUCCESS'
export const GET_ASKTOEVALUATE_PAYMENT_HISTORY_FAIL = 'GET_ASKTOEVALUATE_PAYMENT_HISTORY_FAIL'

export const GET_ENROLL_ASKTOEVALUATE_REQUEST = 'GET_ENROLL_ASKTOEVALUATE_REQUEST';
export const GET_ENROLL_ASKTOEVALUATE_SUCCESS = 'GET_ENROLL_ASKTOEVALUATE_SUCCESS';
export const GET_ENROLL_ASKTOEVALUATE_FAIL = 'GET_ENROLL_ASKTOEVALUATE_FAIL';

export const GET_ASKTOEVALUATE_BY_ID_REQUEST = 'GET_ASKTOEVALUATE_BY_ID_REQUEST';
export const GET_ASKTOEVALUATE_BY_ID_SUCCESS = 'GET_ASKTOEVALUATE_BY_ID_SUCCESS';
export const GET_ASKTOEVALUATE_BY_ID_FAIL = 'GET_ASKTOEVALUATE_BY_ID_FAIL';

export const GET_ATE_QUESTION_PAPER_REQUEST = 'GET_ATE_QUESTION_PAPER_REQUEST';
export const GET_ATE_QUESTION_PAPER_SUCCESS = 'GET_ATE_QUESTION_PAPER_SUCCESS';
export const GET_ATE_QUESTION_PAPER_FAIL = 'GET_ATE_QUESTION_PAPER_FAIL';

export const POST_SUBMIT_ASK_TO_EVALUATE_REQUEST = 'POST_SUBMIT_ASK_TO_EVALUATE_REQUEST';
export const POST_SUBMIT_ASK_TO_EVALUATE_SUCCESS = 'POST_SUBMIT_ASK_TO_EVALUATE_SUCCESS';
export const POST_SUBMIT_ASK_TO_EVALUATE_FAIL = 'POST_SUBMIT_ASK_TO_EVALUATE_FAIL';

export const GET_SUBMISSIONS_ASK_TO_EVALUATE_REQUEST = 'GET_SUBMISSIONS_ASK_TO_EVALUATE_REQUEST';
export const GET_SUBMISSIONS_ASK_TO_EVALUATE_SUCCESS = 'GET_SUBMISSIONS_ASK_TO_EVALUATE_SUCCESS';
export const GET_SUBMISSIONS_ASK_TO_EVALUATE_FAIL = 'GET_SUBMISSIONS_ASK_TO_EVALUATE_FAIL';

export const GET_ANALYTICS_ASK_TO_EVALUATE_REQUEST = 'GET_ANALYTICS_ASK_TO_EVALUATE_REQUEST';
export const GET_ANALYTICS_ASK_TO_EVALUATE_SUCCESS = 'GET_ANALYTICS_ASK_TO_EVALUATE_SUCCESS';
export const GET_ANALYTICS_ASK_TO_EVALUATE_FAIL = 'GET_ANALYTICS_ASK_TO_EVALUATE_FAIL';