import React from 'react'
import "./Auth/modal.scss"
import { useDispatch } from 'react-redux';
import { logout } from '../actions/userActions';

function SuccessfulModal({ data, path }) {

    const dispatch = useDispatch()

    // if (path === "#") {
    //     <div className="modal-custom">
    //         <div className="content d-flex flex-column align-items-center">
    //             <div className="header">
    //                 {data}
    //             </div>
    //             <div className="footer">
    //                 <button onClick={() => { localStorage.clear(); dispatch(logout()) }}>ok!</button>
    //             </div>
    //         </div>

    //     </div>
    // }

    if (path === "#") {
        localStorage.clear(); dispatch(logout())
    }

    return (
        <div className="modal-custom">
            <div className="content d-flex flex-column align-items-center">
                <div className="header">
                    {data}
                </div>
                <div className="footer">
                    <button onClick={() => window.open(`${path}`, "_self")}>ok!</button>
                </div>
            </div>

        </div>
    )
}

export default SuccessfulModal
