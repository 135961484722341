import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllCourses, getRecentSubjects, getAllFoundationalCourses } from '../../actions/courseActions';
import './dashboard.scss';
import 'react-pro-sidebar/dist/css/styles.css';

import Sidebar from '../../Components/main/sidebar/sidebar';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import LogoutPopup from '../../Components/PopUp/LogoutPopup/LogoutPopup';
import ScheduleCounselling from '../../Components/PopUp/ScheduleCounselling/ScheduleCounselling';
import HomeLoading from '../../Components/ShimmerEffect/Home/HomeLoading';

import scheduleImage from '../../assets/images/counselling.png';
import Logo from '../../assets/icons/logo.svg';
import PreB4Pre from '../../assets/images/preb4pre.png'
import { FaPowerOff } from 'react-icons/fa';

import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const DashBoard = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [logOut, setLogout] = useState(false);

    const handleShow = () => setShow(true);

    const getCourses = useSelector(state => state.getAllCourses)
    const { loading, courses } = getCourses;

    const getAllFoundationalCourse = useSelector(state => state.getAllFoundationalCourses)
    const { loading:loadingCourse,  FoundationalCourses } = getAllFoundationalCourse;

    const bookCounselling = useSelector(state => state.bookCounselling)
    const { loading: loadingCounselling, error: errorCounselling, message } = bookCounselling;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getRecentSubject = useSelector(state => state.getRecentSubject)
    const { loading: loadingRecentSubject, error: errorRecentSubject, recentSubjects } = getRecentSubject;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }else{
            dispatch(getAllCourses())
            dispatch(getAllFoundationalCourses())
            dispatch(getRecentSubjects())
        }
    }, [dispatch, userInfo])


    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: false,
        centerPadding: '60px',
        responsive: [
            // {
            //     breakpoint: 1306,
            //     settings: {
            //         dots: false,
            //         infinite: false,
            //         speed: 500,
            //         slidesToShow: 3,
            //         slidesToScroll: 3,
            //         swipeToSlide: false,
            //     }
            // },
            {
                breakpoint: 1126,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 880,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: false,
                }
            }
        ]
    };

    const Logout = (e) => {
        e.preventDefault();
        setLogout(true);
    }
    const showAuthors = (Authors) =>{
        if(Authors.toString().length > 12){
            return Authors.toString().slice(0,12)+'...';
        }else{
            return Authors.toString();
        }
    }

    return (
        <>
        <Sidebar activeLink={''} />
        {show && <ScheduleCounselling hadelSetShow={(result)=>setShow(result)}/> }
        {logOut && <LogoutPopup handelSetLogout={(result) => setLogout(result)}/>}
        
        <main className="main">
            {errorRecentSubject && <AlertDismissibleExample data={errorRecentSubject} />}
            {errorCounselling && <AlertDismissibleExample data={errorCounselling} />}
            {message && <AlertSuccessExample data={message} />}

            {(loading || loadingCounselling || loadingRecentSubject || loadingCourse) ? <HomeLoading handelSetLogout={(result) => setLogout(result)}/> :<>
            <div className="pt-4 pr-5">
                <div style={{ width: '100%' }} className="d-flex align-items-center justify-content-between">
                    <p className='ml-5 mr-4' style={{ color: '#002A5D', fontSize: 20, fontWeight: '500' }}>
                        {
                            new Date().toLocaleDateString('en-US', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                            })
                        }
                    </p>
                    <div>
                        <button className='logout-btn' onClick={Logout}>
                            <div className='logout'>
                                <FaPowerOff color='white'/>
                            </div> Logout
                        </button> 
                    </div>
                </div>
            </div>

            <div className='container mt-4'>
                <Row className="schedule-card" onClick={handleShow}>
                    <Col lg={6}>
                    <h1>Book A Free Mentorship Session</h1>
                    <p>From Best Of Faculties</p>
                    </Col>
                    <Col lg={6}>
                    <img src={scheduleImage} alt="logo" />
                    </Col>
                </Row>
            </div>

            {FoundationalCourses && FoundationalCourses.length > 0 && <section className='px-5 my-5'>
                <div className="d-flex align-items-center justify-content-between">
                    <p style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                    Pro course
                    </p>
                    <Link to='/explore'>
                        <p style={{ fontSize: 18, fontWeight: '500', color: ' #012A5D', textDecoration: 'underline', cursor: 'pointer' }}>
                            view all
                        </p>
                    </Link>
                </div>
                <Slider {...settings}>
                    { FoundationalCourses.map((val, i) => (
                    <div key={val.id} className={`Slidecard1 ${i % 2 === 0 ? 'yellow' : 'darkYellow'}`} >
                        <Link to={`/foundational-course/${val.id}`} style={{textDecoration:'none'}}>
                            <div className='courseImage'>
                                <img src={val.imageURL ? val.imageURL : Logo} alt="logo" />
                            </div>
                            <div style={{height:'100px', width:'80%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                <p title={val.name} >{val.name.substring(0,30)}{val.name.length > 30 && '...'} </p>
                            </div>
                            <p className="authors" title={val.authors.map((author) => `${author} `)}>
                                by {showAuthors(val.authors)}</p>
                            <p className="price">Price: {(val.price === 0) ? "Free" : `₹${val.price}`}</p>
                        </Link>
                    </div>
                    )
                    )}
                </Slider>
            </section>}
            <section className='px-5 my-5'>
                <div className="d-flex align-items-center justify-content-between">
                    <p style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                    Events
                    </p>
                </div>

                <Row className='banner'>
                    <Col md="4" xs="12">
                    <img src={PreB4Pre} alt='PreB4Pre' />
                    </Col>
                    <Col md="8" xs="12">
                    <p>
                        Pre Before Pre is an exam that YesMentor will conduct just 
                        before the prelims in offline mode, so you can choose whether 
                        you want to give it physically or not. The exam will follow the same 
                        pattern as the prelims exam with the same number of questions. 
                        To properly assess how many questions they can solve within the time 
                        limit given in the original prelim, we also provide the same time limit...
                    </p>
                    <Link to='/preb4pre' >  <button>Read More</button></Link>
                    </Col>
                </Row>

            </section>
            
            {courses && courses.length > 0 && <section className='px-5 my-5'>
                <div className="d-flex align-items-center justify-content-between">
                    <p style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                    Our Courses
                    </p>
                    <Link to='/explore'>
                        <p style={{ fontSize: 18, fontWeight: '500', color: ' #012A5D', textDecoration: 'underline', cursor: 'pointer' }}>
                            view all
                        </p>
                    </Link>
                </div>
                <Slider {...settings}>
                    {(courses && courses.length > 0) && courses.map((val, i) => (
                    <div key={val.id} className={`Slidecard1 ${i % 2 === 0 ? 'yellow' : 'darkYellow'}`} >
                        <Link to={`/course/${val.id}`} style={{textDecoration:'none'}}>
                            <div className='courseImage'>
                                <img src={val.imageURL ? val.imageURL : Logo} alt="logo" />
                            </div>
                            <div style={{height:'100px', width:'80%', margin:"10px auto"}} className='d-flex justify-content-center align-items-center'>
                                <p title={val.name} >{val.name.substring(0,30)}{val.name.length > 30 && '...'} </p>
                            </div>
                            <p className="authors" title={val.authors.map((author) => `${author} `)}>
                                by {showAuthors(val.authors)}</p>
                            <p className="price">Price: {(val.price === 0) ? "Free" : `₹${val.price}`}</p>
                        </Link>
                    </div>
                    )
                    )}
                </Slider>
            </section>}
            {(recentSubjects && recentSubjects.length > 0) && (
            <section className='px-5 my-5'>
                <div className="d-flex align-items-center justify-content-between">
                <p style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                    Learned Recently
                </p>
                <Link to='/mycourse'>
                    <p style={{ fontSize: 18, fontWeight: '500', color: ' #012A5D', textDecoration: 'underline', cursor: 'pointer' }}>
                    view all
                    </p>
                </Link>
                </div>
                <Slider {...settings}>
                {errorRecentSubject ? <AlertDismissibleExample data={errorRecentSubject} /> : (recentSubjects && recentSubjects.length>0) ? recentSubjects.map((val, i) => (
                    <div key={val.id} className={`Slidecard1 ${i % 2 === 0 ? 'darkYellow' : 'yellow'}`}>
                    <Link to={`/chapter/${val.id}/${val.subjectName}/${val.course.id}`} style={{textDecoration:'none'}}>
                        <div className='courseImage'>
                            <img src={val.course.imageURL ? val.course.imageURL : Logo} alt="logo" />
                        </div>
                        <p style={{ margin: '5px 10px' }} title={val.subjectName} >
                            {val.subjectName.substring(0,12)}
                            {val.subjectName.length > 12 && '...'}
                        </p>
                        <p style={{ margin: '5px 10px' }} title={val.course.name} >
                            ({val.course.name.substring(0,12)}
                            {val.course.name.length > 12 && '...'})
                        </p>
                    </Link>
                    </div>
                )):<></>}
                </Slider>
            </section>
            )}
            </>}
        </main>
        </>
    )
}

export default DashBoard;
