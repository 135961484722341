export const GET_PUBLISHED_PREB4PRE_REQUEST = 'GET_PUBLISHED_PREB4PRE_REQUEST';
export const GET_PUBLISHED_PREB4PRE_SUCCESS = 'GET_PUBLISHED_PREB4PRE_SUCCESS';
export const GET_PUBLISHED_PREB4PRE_FAIL = 'GET_PUBLISHED_PREB4PRE_FAIL';

export const GET_ENROLLED_PREB4PRE_REQUEST = 'GET_ENROLLED_PREB4PRE_REQUEST';
export const GET_ENROLLED_PREB4PRE_SUCCESS = 'GET_ENROLLED_PREB4PRE_SUCCESS';
export const GET_ENROLLED_PREB4PRE_FAIL = 'GET_ENROLLED_PREB4PRE_FAIL';

export const POST_ENROLL_PREB4PRE_REQUEST = 'POST_ENROLL_PREB4PRE_REQUEST';
export const POST_ENROLL_PREB4PRE_SUCCESS = 'POST_ENROLL_PREB4PRE_SUCCESS';
export const POST_ENROLL_PREB4PRE_FAIL = 'POST_ENROLL_PREB4PRE_FAIL';

export const GET_PREB4PRE_PAYMENT_DETAILS_REQUEST = 'GET_PREB4PRE_PAYMENT_DETAILS_REQUEST';
export const GET_PREB4PRE_PAYMENT_DETAILS_SUCCESS = 'GET_PREB4PRE_PAYMENT_DETAILS_SUCCESS';
export const GET_PREB4PRE_PAYMENT_DETAILS_FAIL = 'GET_PREB4PRE_PAYMENT_DETAILS_FAIL';

export const GET_PREB4PRE_QUESTION_PAPER_REQUEST = 'GET_PREB4PRE_QUESTION_PAPER_REQUEST';
export const GET_PREB4PRE_QUESTION_PAPER_SUCCESS = 'GET_PREB4PRE_QUESTION_PAPER_SUCCESS';
export const GET_PREB4PRE_QUESTION_PAPER_FAIL = 'GET_PREB4PRE_QUESTION_PAPER_FAIL';

export const GET_PREB4PRE_TEST_SERIES_REQUEST = 'GET_PREB4PRE_TEST_SERIES_REQUEST';
export const GET_PREB4PRE_TEST_SERIES_SUCCESS = 'GET_PREB4PRE_TEST_SERIES_SUCCESS';
export const GET_PREB4PRE_TEST_SERIES_FAIL = 'GET_PREB4PRE_TEST_SERIES_FAIL';

export const GET_PREB4PRE_ANSWER_SHEET_REQUEST = 'GET_USER_ANSWER_SHEET_REQUEST';
export const GET_PREB4PRE_ANSWER_SHEET_SUCCESS = 'GET_USER_ANSWER_SHEET_SUCCESS';
export const GET_PREB4PRE_ANSWER_SHEET_FAIL = 'GET_USER_ANSWER_SHEET_FAIL';

export const GET_PREB4PRE_ANSWERSHEET_BY_ID_REQUEST = 'GET_ANSWERSHEET_BY_ID_REQUEST';
export const GET_PREB4PRE_ANSWERSHEET_BY_ID_SUCCESS = 'GET_ANSWERSHEET_BY_ID_SUCCESS';
export const GET_PREB4PRE_ANSWERSHEET_BY_ID_FAIL = 'GET_ANSWERSHEET_BY_ID_FAIL';

export const GET_PREB4PRE_TEST_ANALYTICS_REQUEST = 'GET_PREB4PRE_TEST_ANALYTICS_REQUEST';
export const GET_PREB4PRE_TEST_ANALYTICS_SUCCESS = 'GET_PREB4PRE_TEST_ANALYTICS_SUCCESS';
export const GET_PREB4PRE_TEST_ANALYTICS_FAIL = 'GET_PREB4PRE_TEST_ANALYTICS_FAIL';

export const POST_PREB4PRE_SUBMIT_ANSWERS_REQUEST = 'POST_PREB4PRE_SUBMIT_ANSWERS_REQUEST';
export const POST_PREB4PRE_SUBMIT_ANSWERS_SUCCESS = 'POST_PREB4PRE_SUBMIT_ANSWERS_SUCCESS';
export const POST_PREB4PRE_SUBMIT_ANSWERS_FAIL = 'POST_PREB4PRE_SUBMIT_ANSWERS_FAIL';

export const POST_PREB4PRE_COUPON_VALIDATION_REQUEST = 'POST_PREB4PRE_COUPON_VALIDATION_REQUEST';
export const POST_PREB4PRE_COUPON_VALIDATION_SUCCESS = 'POST_PREB4PRE_COUPON_VALIDATION_SUCCESS';
export const POST_PREB4PRE_COUPON_VALIDATION_FAIL = 'POST_PREB4PRE_COUPON_VALIDATION_FAIL';

export const GET_PREB4PRE_PAYMENT_HISTORY_REQUEST = 'GET_PREB4PRE_PAYMENT_HISTORY_REQUEST';
export const GET_PREB4PRE_PAYMENT_HISTORY_SUCCESS = 'GET_PREB4PRE_PAYMENT_HISTORY_SUCCESS';
export const GET_PREB4PRE_PAYMENT_HISTORY_FAIL = 'GET_PREB4PRE_PAYMENT_HISTORY_FAIL';

export const GET_PREB4PRE_QUESTION_PAPER_SLOTID_REQUEST = 'GET_PREB4PRE_QUESTION_PAPER_SLOTID_REQUEST';
export const GET_PREB4PRE_QUESTION_PAPER_SLOTID_SUCCESS = 'GET_PREB4PRE_QUESTION_PAPER_SLOTID_SUCCESS';
export const GET_PREB4PRE_QUESTION_PAPER_SLOTID_FAIL = 'GET_PREB4PRE_QUESTION_PAPER_SLOTID_FAIL';