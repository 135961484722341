import React, { useState, useEffect } from 'react';
import '../../Components/Auth/layout.scss';
import { 
    Col, 
    Row 
} from 'react-bootstrap';
import signupImage from '../../assets/images/profilepage.svg';
import Logo from '../../assets/icons/logo.svg';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';
import { 
    getUserDetails, 
    postEmailVerification, 
    postMobileVerification, 
    postSendEmailOtp, 
    postSendMobileOtp, 
    putUserDetails, 
    putUserPicture 
} from '../../actions/userActions';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AlertDismissibleExample from "../../Components/Error/error"
import "./PersonalDetails.scss"
import AlertSuccessExample from '../../Components/success/success';
import Loader from '../../Components/Loader';
import person from "../../assets/images/person.png";
import { Edit } from '@material-ui/icons';
import { BASEURL } from '../../config/config';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import SuccessfulModal from '../../Components/SuccessfulModal';
import ProcessingModal from '../../Components/ProcessingModal';
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg"

function PersonalDetails() {

    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    //image state variables
    const id = 'img';
    const [imageURL, setImageURL] = useState(null)
    const [imageURI, setImageURI] = useState(null);
    const [userPicture, setUserPicture] = useState(null)
    const [emailVerify, setEmailVerify] = useState(false)
    const [phoneVerify, setPhoneVerify] = useState(false)
    const [mobileOtp, setMobileOtp] = useState("")
    const [emailOtp, setEmailOtp] = useState("")

    const getUserDetail = useSelector(state => state.getUserDetail)
    const { loading, error, user } = getUserDetail

    const putUserDetail = useSelector(state => state.putUserDetails)
    const { loading: loadingDetail, error: errorDetail, message } = putUserDetail

    const putUserPictureFunc = useSelector(state => state.putUserPicture)
    const { loading: loadingPicture, error: errorPicture, } = putUserPictureFunc

    const sendEmailOtp = useSelector(state => state.postSendEmailOtp)
    const { loading: loadingEmail, error: errorEmail } = sendEmailOtp

    const sendMobileOtp = useSelector(state => state.postSendMobileOtp)
    const { loading: loadingMobile, error: errorMobile } = sendMobileOtp

    const mobileVerificationCode = useSelector(state => state.postMobileVerification)
    const { loading: loadingMobCode, error: errorMobCode, message: messageMobile } = mobileVerificationCode

    const emailVerificationCode = useSelector(state => state.postEmailVerification)
    const { loading: loadingEmailCode, error: errorEmailCode, message: messageEmail } = emailVerificationCode

    const [uploadingPic, setUploadingPic] = useState(false)



    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getUserDetails())
        }
    }, [userInfo, dispatch, message])


    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setValue('firstname', user.firstname)
        setValue('lastname', user.lastname)
        setValue('email', user.email)
        setValue('phone', user.phoneno)
        setValue('dob', user.dob?.split('-').reverse().join('-'))
        setImageURL(user.imageURL)

    }, [user, setValue])


    const onSubmit = async (data) => {

        if (userPicture) {
            const res = await axios.get(`${BASEURL}/gen/s3url`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': userInfo.authToken
                }
            })
            const s3url = res.data[0].data
            try {
                await axios.put(s3url, userPicture, {
                    onUploadProgress: data => {
                        setUploadingPic(true)
                    },
                    headers: {
                        'Access-Control-Allow-Origin': '*',

                    }
                })
                setUploadingPic(false)
            } catch (error) {
                alert("Photo not uploaded")
            }

            var userImageUrl = {
                url: s3url.split("?")[0]
            }

            dispatch(putUserPicture(userImageUrl))


        }


        var userData = {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            phoneno: data.phone,
            dob: data.dob?.split('-').reverse().join('-'),
            address: {}
        }

        // if (user.dob === null) {
        //     var userDob = {
        //         dob: data.dob
        //     }
        //     dispatch(postUserDob(userDob))
        // }

        dispatch(putUserDetails(userData))

        resetImage()


    };



    // const deletePicture = async () => {
    //     var data = {
    //         imageKey: imageURL.split("/")[4]
    //     }
    //     //console.log(imageUrl.split("/")[4])
    //     try {
    //         // await axios.put(`${BASEURL}/gen/user/s3delete`, data, {
    //         //     headers: {
    //         //         "Authorization": userInfo.authToken
    //         //     }
    //         // })
    //         var userImage = {
    //             url: null
    //         }
    //         dispatch(putUserPicture(userImage))
    //         dispatch(getUserDetails())
    //     } catch (error) { }
    // }

    const resetImage = () => {
        setImageURI(null)
        setUserPicture(null)
    }

    const buildImgTag = () => {
        let imgTag = null;
        //console.log(imageURI)
        if (imageURI !== null)
            imgTag = (
                <img src={imageURI} className="thumbnail" style={{ margin: 'auto' }} alt="" />

            );
        return imgTag;
    };

    const imgTag = buildImgTag();

    const readURI = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (ev) {
                setImageURI(ev.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleChange = (e) => {
        setUserPicture(e.target.files[0])
        readURI(e);
    };

    const genEmailOtp = (email) => {
        window.location.href = "#verify-location"
        setEmailVerify(true)
        setPhoneVerify(false)
        var emailData = {
            email: email
        }
        dispatch(postSendEmailOtp(emailData))

    }

    const genPhoneOtp = (phone) => {
        window.location.href = "#verify-location"
        setPhoneVerify(true)
        setEmailVerify(false)
        var phoneData = {
            phoneno: phone
        }
        dispatch(postSendMobileOtp(phoneData))
    }

    const mobileOtpVerification = () => {
        var data = {
            otp: mobileOtp
        }
        dispatch(postMobileVerification(data))
    }

    const emailOtpVerification = () => {
        var data = {
            otp: emailOtp
        }
        dispatch(postEmailVerification(data))
    }

    if (loading || loadingDetail || loadingPicture) {
        return <Loader />
    }

    return (
        <div>
            {error && <AlertDismissibleExample data={error} />}
            {uploadingPic && <Loader />}
            {loadingMobCode && <ProcessingModal />}
            {loadingEmailCode && <ProcessingModal />}
            {messageMobile && <SuccessfulModal data={messageMobile} path={"/user/profile"} />}
            {messageEmail && <SuccessfulModal data={messageEmail} path={"/user/profile"} />}
            {errorEmail && <AlertDismissibleExample data={errorEmail} />}
            {errorMobile && <AlertDismissibleExample data={errorMobile} />}
            {errorMobCode && <AlertDismissibleExample data={errorMobCode} />}
            {errorEmailCode && <AlertDismissibleExample data={errorEmailCode} />}
            {errorPicture && <AlertDismissibleExample data={errorPicture} />}
            {
                message && <AlertSuccessExample data={message} />
            }
            {
                errorDetail && <AlertDismissibleExample data={errorDetail} />
            }
            <main>
                <Row className="m-0">
                    <Col md="6" lg="4" className="p-0">
                        <div className="form-wrapper">

                            <div className="d-flex align-items-center">
                                <Link to="/more"><BackArrow /></Link>
                                <div className="mx-4">
                                    <img src={Logo} alt="signup illustration" />
                                </div>
                            </div>

                            <div className="add-user-pic d-flex flex-column">

                                {imageURI ? imgTag : <img style={{ margin: "auto" }} src={`${imageURL ? imageURL : person}`} alt="Upload" />}

                                <label
                                    htmlFor={id}
                                    className="button">
                                    <Edit />
                                    Upload Picture
                                </label>
                                <input
                                    style={{ visibility: "hidden", display: 'none' }}
                                    id={id}
                                    type="file"
                                    accept="image/png,image/jpg,image/jpeg"
                                    onChange={e => handleChange(e)}
                                    className="show-for-sr" />
                                {
                                    imageURI && <div className="d-flex m-2 justify-content-center">
                                        <button onClick={() => resetImage()}>
                                            Cancel
                                        </button>
                                    </div>
                                }

                                {/* {
                                    imageURL && <div className="d-flex m-2 justify-content-center">
                                        <button
                                            onClick={() => deletePicture()}
                                        >Delete Photo</button>
                                    </div>
                                } */}

                            </div>


                            <div className="personal-details">
                                <div>
                                    <label>First Name</label>
                                    <input
                                        id="name"
                                        type="text"
                                        autoComplete="off"
                                        name="name"
                                        {...register("firstname", { required: true })}
                                    />
                                    {errors.name && <span style={{ color: '#ff0101', fontSize: 12 }}>Name field is required</span>}

                                </div>
                                <div>
                                    <label>Last Name</label>
                                    <input
                                        id="name"
                                        type="text"
                                        autoComplete="off"
                                        name="name"
                                        {...register("lastname", { required: true })}
                                    />
                                    {errors.name && <span style={{ color: '#ff0101', fontSize: 12 }}>Name field is required</span>}

                                </div>
                                <div>
                                    <label>Email</label>
                                    <input
                                        id="email"
                                        type="email"
                                        autoComplete="off"
                                        name="email"
                                        disabled
                                        {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })}
                                    />
                                    {errors.email && errors.email.type === "required" && <span style={{ color: '#ff0101', fontSize: 12 }}>Email field is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span style={{ color: '#ff0101', fontSize: 12 }}>You have entered an invalid e-mail address.</span>}
                                    <span>
                                        {user.verified ? <p style={{ color: "#03AC13" }}>Verified</p> : <p onClick={() => genEmailOtp(getValues("email"))} style={{ color: "#CA0B00", cursor: "pointer" }}> Verify</p>}
                                    </span>
                                </div>

                                <div>
                                    <label>Phone number</label>
                                    <input
                                        id="phone"
                                        type="text"
                                        name="phone number"
                                        {...register("phone", { required: true, minLength: 10, maxLength: 10 })}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.contactno && errors.contactno.type === "required" && <span style={{ color: '#ff0101', fontSize: 12 }}>Mobile number field is required</span>}
                                    {errors.contactno && errors.contactno.type === "minLength" && <span style={{ color: '#ff0101', fontSize: 12 }}>Mobile Number must be 10 numbers.</span>}
                                    {errors.contactno && errors.contactno.type === "maxLength" && <span style={{ color: '#ff0101', fontSize: 12 }}>Mobile Number must be 10 numbers.</span>}
                                    <span>
                                        {user.mobVerified ? <p style={{ color: "#03AC13" }}>Verified</p> : <p onClick={() => genPhoneOtp(getValues("phone"))} style={{ color: "#CA0B00", cursor: "pointer" }}> Verify</p>}
                                    </span>

                                </div>
                                <div>
                                    <label>Date of Birth</label>
                                    <input
                                        id="dob"
                                        type="date"
                                        name="dob"
                                        style={{ textTransform: "uppercase" }}
                                        {...register("dob", { required: true })}
                                    />
                                    {errors.dob && <span style={{ color: '#ff0101', fontSize: 12 }}>Date of Birth is required</span>}

                                </div>

                                <div>
                                    <button onClick={handleSubmit(onSubmit)} >Save</button>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col
                        md="6"
                        lg="8"
                        className="p-0"
                        style={{ backgroundColor: '#0669F8' }}
                    >
                        {
                            (!emailVerify && !phoneVerify) &&
                            <div className="image-pd-wrapper">
                                <img src={signupImage} alt="signup illustration" />
                                <div>
                                    <p style={{ width: ' 67%', margin: 'auto' }}>
                                        View and edit your profile details
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            (emailVerify && !phoneVerify) &&
                            < div id="verify-location" className="image-pd-wrapper">
                                <div className="otp-style">
                                    <h4>Email Verification</h4>
                                    {
                                        loadingEmail ? <ProcessingModal /> : <>
                                            <p>Enter the OTP</p>
                                            <OtpInput
                                                value={emailOtp}
                                                onChange={setEmailOtp}
                                                numInputs={6}
                                                separator={<span>&nbsp; &nbsp;</span>}
                                            />
                                            <button onClick={() => emailOtpVerification()} >Done</button>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (phoneVerify && !emailVerify) &&
                            < div id="verify-location" className="image-pd-wrapper" >
                                <div className="otp-style">
                                    <h4>Mobile Verification</h4>
                                    {loadingMobile ? <ProcessingModal /> : <>
                                        <p>Enter the OTP</p>
                                        <OtpInput
                                            value={mobileOtp}
                                            onChange={setMobileOtp}
                                            numInputs={6}
                                            separator={<span>&nbsp; &nbsp;</span>}
                                        />
                                        <button onClick={() => mobileOtpVerification()} >Done</button>
                                    </>}
                                </div>
                            </div>
                        }
                    </Col>

                </Row>

            </main>
        </div >
    );
}

export default PersonalDetails
