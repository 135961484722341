import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLogin } from 'react-google-login';

// import {FcGoogle} from 'react-icons/fc';
import Logo from '../assets/icons/logo.svg';
import RightArrow from '../assets/icons/right-arrow.svg';
import signupImage from '../assets/images/signup-image.svg';
// import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// import { initializeApp } from "firebase/app";

import { Link } from 'react-router-dom';

import '../Components/Auth/layout.scss';
import AlertDismissibleExample from '../Components/Error/error';

import { login, googleAuth } from '../actions/userActions';
import Loader from '../Components/Loader';

// import {firebaseConfig} from '../config/config';

import { ClientId } from '../config/config'
import { Visibility, VisibilityOff } from '@material-ui/icons';



const LoginScreen = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const userLogin = useSelector(state => state.userLogin)
    const { loading, error, userInfo } = userLogin

    const googleLogin = useSelector(state => state.googleLogin)
    const { loading: googleLoading, error: googleError, userInfo: googleUserInfo } = googleLogin

    const [showPass, setShowPass] = useState(false)

    useEffect(() => {
        if (userInfo || googleUserInfo) {
            document.location.href = '/';
        }
    }, [userInfo, googleUserInfo])

    const onSubmit = (data) => {
        dispatch(login(data.email, data.password))
    }

    const successRes = (response) => {
        dispatch(googleAuth(response.tokenObj.id_token))
    }
    const failRes = (response) => {
        console.log(response)
    }
    return (
        <>
            {error && <AlertDismissibleExample data={error} />}
            {googleError && <AlertDismissibleExample data={googleError} />}
            {(loading || googleLoading) && <Loader />}

            <main>


                <Row className='m-0'>
                    <Col md="6" lg="4" className="p-0">
                        <div className="form-wrapper">
                            <img src={Logo} alt="signup illustration" />
                            <h1>Welcome <span>Back!</span></h1>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-wrapper my-2">
                                    <div className="bg">
                                        <div className="input-cont">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                {...register('email', {
                                                    required: true,
                                                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                                })}
                                            />
                                            <label htmlFor="name" className="label-name">
                                                <span className="content-name">Email Address</span>
                                            </label>
                                        </div>
                                        {errors.email && errors.email.type === 'required' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                Email field is required
                                            </span>
                                        )}
                                        {errors.email && errors.email.type === 'pattern' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                You have entered an invalid e-mail address.
                                            </span>
                                        )}
                                    </div>
                                    <div className="bg m-0">
                                        <div className="input-cont d-flex justify-content-between">
                                            <div>
                                                <input
                                                    type={showPass ? "text" : "password"}
                                                    autoComplete="off"
                                                    {...register('password', {
                                                        required: true,
                                                        minLength: 6,
                                                    })}
                                                />
                                                <label htmlFor="password" className="label-name">
                                                    <span className="content-name">Password</span>
                                                </label>
                                            </div>
                                            <div className="align-self-center">
                                                {
                                                    !showPass && <button
                                                        onClick={(e) => {e.preventDefault(); setShowPass(true)}}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}
                                                    ><VisibilityOff /></button>
                                                }
                                                {
                                                    showPass && <button
                                                        onClick={(e) => {e.preventDefault(); setShowPass(false)}}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}>
                                                        <Visibility/></button>
                                                }
                                            </div>
                                        </div>
                                        {errors.password && errors.password.type === 'required' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                Password field is required
                                            </span>
                                        )}
                                        {errors.password && errors.password.type === 'minLength' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                You password must be atleast 6 characters long.
                                            </span>
                                        )}
                                        <Link
                                            to="/forgotpassword"
                                            className="mt-2"
                                            style={{ color: '#0669F8', fontSize: '14px' }}
                                        >Forgot Password?</Link>
                                    </div>
                                </div>
                                <div className='next-container'>
                                    <button type="submit" className="next-btn mt-4">
                                        <img src={RightArrow} alt=">" />
                                    </button>
                                </div>
                            </form>
                            <p className="mt-4 text-center">
                                Don’t have an account?{' '}
                                <Link to="/signup" style={{ color: '#0669F8' }}>
                                    Sign up
                                </Link>
                            </p>
                            <div className="googleBtnContainer mt-2">
                                <GoogleLogin clientId={ClientId}
                                    buttonText="Sign in with google"
                                    onSuccess={successRes}
                                    onFailure={failRes}
                                    cookiePolicy={'single_host_origin'} />
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="8" className="p-0" style={{ backgroundColor: '#0669F8' }}>
                        <div className="image-wrapper">
                            <img src={signupImage} alt="signup illustration" className="m-0" />
                            <div>
                                <p style={{ width: ' 67%', margin: 'auto' }}>
                                    Login and continue where you left
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row >
            </main >
        </>
    )
}

export default LoginScreen;
