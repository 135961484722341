import React from 'react'
import { logout } from '../../../actions/userActions';
import { useDispatch } from 'react-redux';
import './LogoutPopup.scss';


const LogoutPopup = ({handelSetLogout}) => {

    const dispatch = useDispatch();

    const Logout = (e) => {
        e.preventDefault();
        dispatch(logout());
    }
    return (
        <div className='logout-popup'>
            <div className='card'>
                <h3>Are you sure?</h3>
                <div className='popup-btn'>
                    <button onClick={(e)=>{e.preventDefault(); handelSetLogout(false)}}>Cancel</button>
                    <button onClick={Logout}>Logout</button>
                </div>
            </div>
        </div>
    )
}

export default LogoutPopup
