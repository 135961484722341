import React, { useEffect } from 'react'
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import noImage from '../../assets/images/otp.png';
import Logo from '../../assets/icons/logo.svg';
import "./InvoicesCard.scss"
import { getAudiobookPaymentHistory } from '../../actions/audiobooksAction';
// import { logout } from '../../actions/userActions';

function AudiobookInvoice() {

    const dispatch = useDispatch()

    const audiobookPayment = useSelector(state => state.getAudiobookPaymentHistory)
    const { loading, error, payment } = audiobookPayment

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAudiobookPaymentHistory())

    }, [dispatch, userInfo])

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(error === message){
    //         dispatch(logout());
    //     }
    // },[dispatch, error ])

    return (
        <div className="payments">
            {loading && <Loader />}

            <div className="d-flex justify-content-center">
                <h3>AUDIOBOOK PAYMENT HISTORY</h3>
            </div>

            {
                (error || payment.length === 0) ? <div className="no-payments" >
                    <img src={noImage} alt="" />
                    <h3>No payment done</h3>
                </div> : <div className="d-flex m-2 flex-wrap justify-content-center">
                    {
                        payment && payment.map((prop, id) =>
                            <div key={id} className="payment-card">
                                <img src={prop.image ? prop.image : Logo} alt="logo" />
                                <div className="payment-details">
                                    <p>Audiobook: <strong>{prop.name}</strong></p>
                                    <p>Date: <strong>{prop.createdAt.split("T")[0]}</strong></p>
                                    <p>Price: <strong> {(prop.price === "0") ? "Free" : `₹ ${prop.price/100}`}</strong></p>
                                    <p>Payment ID: <strong>{prop.paymentId}</strong></p>

                                </div>
                            </div>
                        )
                    }

                </div>
            }


        </div>
    )
}

export default AudiobookInvoice
