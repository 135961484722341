import React, { useEffect, useState } from 'react'

import Sidebar from '../../Components/main/sidebar/sidebar';
// import AllAudiobooks from '../../views/AllAudiobooks/AllAudiobooks';
import AllCourses from '../../views/AllCourses/AllCourses';
import AllLiveTests from '../../views/AllLiveTests/AllLiveTests';
import AllPracticeTests from '../../views/AllPracticeTests/AllPracticeTests';
import AllFoundationalCourses from '../../views/AllFoundationalCourses/AllFoundationalCourses';
import AllAskToEvaluate from '../../views/AllAskToEvaluate/AllAskToEvaluate';
import './Explore.scss'

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}


function Explore() {

    const [showAllCourses, setShowAllCourses] = useState(false)
    // const [showAllAudiobooks, setShowAllAudiobooks] = useState(false)
    const [showAllLiveTests, setShowAllLiveTests] = useState(false)
    const [showAllPracticeTests, setShowAllPracticeTests] = useState(false)
    const [showAllFoundationalCourses, setShowAllFoundationalCourses] = useState(true)
    const [showAllAskToEvaluate, setShowAllAskToEvaluate] = useState(false)
    const [mobileView, setMobileView] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [exploreOption, setExploreOption] = useState("")

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth)
        }, 1000)
        window.addEventListener('resize', debouncedHandleResize)
        return _ => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })


    useEffect(() => {
        if (windowWidth <= 726) {
            setMobileView(true)
        } else {
            setMobileView(false)
        }
    }, [windowWidth])

    const handleShowAllCourses = () => {
        setShowAllCourses(true)
        // setShowAllAudiobooks(false)
        setShowAllLiveTests(false)
        setShowAllPracticeTests(false)
        setShowAllFoundationalCourses(false)
        setShowAllAskToEvaluate(false)
    }

    // const handleShowAllAudiobooks = () => {
    //      setShowAllCourses(false)
    //      setShowAllAudiobooks(true)
    //      setShowAllLiveTests(false)
    //      setShowAllPracticeTests(false)
    //      setShowAllFoundationalCourses(false)
    //      setShowAllAskToEvaluate(false)
    // }

    const handleShowAllLiveTests = () => {
        setShowAllCourses(false)
        // setShowAllAudiobooks(false)
        setShowAllLiveTests(true)
        setShowAllPracticeTests(false)
        setShowAllFoundationalCourses(false)
        setShowAllAskToEvaluate(false)
    }

    const handleShowAllPracticeTests = () => {
        setShowAllCourses(false)
        // setShowAllAudiobooks(false)
        setShowAllLiveTests(false)
        setShowAllFoundationalCourses(false)
        setShowAllPracticeTests(true)
        setShowAllAskToEvaluate(false)
    }

    const handleShowAllFoundationalCourse = () => {
        setShowAllCourses(false)
        // setShowAllAudiobooks(false)
        setShowAllLiveTests(false)
        setShowAllPracticeTests(false)
        setShowAllFoundationalCourses(true)
        setShowAllAskToEvaluate(false)
    }

    const handleShowAllAskToEvaluate = () =>{
        setShowAllCourses(false)
        // setShowAllAudiobooks(false)
        setShowAllLiveTests(false)
        setShowAllPracticeTests(false)
        setShowAllFoundationalCourses(false)
        setShowAllAskToEvaluate(true)
    }

    const handleExploreOption = (value) => {

        if (value === "courses")
            handleShowAllCourses()
        // else if (value === "audiobooks")
        //     handleShowAllAudiobooks()
        else if (value === "livetests")
            handleShowAllLiveTests()
        else if (value === "practicetests")
            handleShowAllPracticeTests()
        else if (value === "foundationalcourse")
            handleShowAllFoundationalCourse()
        else if(value === "asktoevaluate")
            handleShowAllAskToEvaluate()
    }



    return (
        <div className="explore">
            <Sidebar activeLink={'explore'} />
            <main className="main">
                {
                    !mobileView &&
                    <div className="explore-btns ">
                        <button className={showAllFoundationalCourses ? "highlight-btn" : ""} onClick={handleShowAllFoundationalCourse} >Pro Courses</button>
                        <button className={showAllCourses ? "highlight-btn" : ""} onClick={handleShowAllCourses} >Courses</button>
                        {/* <button className={showAllAudiobooks ? "highlight-btn" : ""} onClick={handleShowAllAudiobooks} >Audio books</button> */}
                        <button className={showAllPracticeTests ? "highlight-btn" : ""} onClick={handleShowAllPracticeTests} >Test Series</button>
                        <button className={showAllLiveTests ? "highlight-btn" : ""} onClick={handleShowAllLiveTests} >Live Tests</button>
                        <button className={showAllAskToEvaluate ? "highlight-btn" : ""} onClick={handleShowAllAskToEvaluate} >Ask To Evaluate</button>
                    </div>
                }
                {
                    mobileView && <div className="d-flex p-3 justify-content-center" style={{marginTop:'90px'}}>
                        <div className="explore-options">
                            <select
                                name="explore-items"
                                onChange={(e) => { handleExploreOption(e.target.value); setExploreOption(e.target.value) }}
                                value={exploreOption}
                            >
                                <option value="foundationalcourse" >Pro Course</option>
                                <option value="courses">Courses</option>
                                {/* <option value="audiobooks">Audiobooks</option> */}
                                <option value="livetests">Live Tests</option>
                                <option value="practicetests" >Test Series</option>
                                <option value="asktoevaluate" >Ask To Evaluate</option>
                            </select>
                        </div>
                    </div>
                }
                {showAllCourses && <AllCourses />}
                {/* {showAllAudiobooks && <AllAudiobooks />} */}
                {showAllLiveTests && <AllLiveTests />}
                {showAllPracticeTests && <AllPracticeTests />}
                {showAllFoundationalCourses && <AllFoundationalCourses/>}
                {showAllAskToEvaluate && <AllAskToEvaluate/>}
            </main>
        </div>
    )
}

export default Explore
