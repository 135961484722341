import React,{ useEffect, useState } from 'react'

import './analytics.scss'

import {
    getSubmissionsAskToEvaluate,
    getAnalyticsAskToEvaluate
} from '../../actions/askToEvaluateActions';

//redux
import {
    useSelector, 
    useDispatch
} from 'react-redux';

//components
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import BarChart from '../../Components/Graphs/BarChart';
import SearchItem from '../../Components/Search/SearchItem';
import Pdf from '../../Components/PdfViewer/Pdf';

//assets
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import {MdOutlineArrowDropDown} from 'react-icons/md'
import noImage from '../../assets/images/otp.png';
import PdfFile from '../../assets/images/PDF_FILE.png';
import PNGFILE from '../../assets/images/PNG_FILE.png';
import JPGFile from '../../assets/images/JPG_File.png';
import PreviewIcon from '@mui/icons-material/Preview';
import CloseIcon from '@mui/icons-material/Close';

import DOMPurify from 'dompurify';

const AskToEvaluateAnalytics = ({match, history}) => {
    const dispatch = useDispatch();

    const [graphType,setGraphType] = useState('');
    const [data,setData] = useState(undefined);
    const [questions, setQuestions] = useState([]);
    const [ sections, setSections ] = useState({});
    const [question, setQuestion] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [Type, setType] = useState("");
    const [scrollToQues, setScrollToQues] = useState(1);

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    const getATEAnalytics = useSelector(state => state.getATEAnalytics)
    const {loading, error, Analytics} = getATEAnalytics;

    const getATESubmissions = useSelector(state => state.getATESubmissions)
    const { loading: loadingSubmission, error: errorSubmission, Submissions } = getATESubmissions;

    useEffect(()=>{
        if(!userInfo){
            history.push('/login');
        }else{
            dispatch(getSubmissionsAskToEvaluate(match.params.id))
            dispatch(getAnalyticsAskToEvaluate(match.params.id))
        }
    },[userInfo,dispatch,match, history])

    useEffect(()=>{
        if(Analytics){
            setData({
                labels:['Conceptual','Application','Factual'],
                datasets:[{
                    label:'Classification',
                    data:[
                        Analytics.classification.conceptual,
                        Analytics.classification.application,
                        Analytics.classification.gk
                    ],
                    backgroundColor:[
                        '#0c343d',
                        '#2f5c50',
                        '#007ea7',
                    ],
                }]
            })
            setGraphType('Classification')
        }
    },[Analytics])

    useEffect(()=>{
        if(!errorSubmission){
            var question = questions.map(item => ({
                ...item,
                name: item.question
            }))
            setQuestion(question)
            setSearchResults(question)
        }
    },[questions,errorSubmission])

    useEffect(()=>{
        if(Analytics && Submissions){
            if(Submissions.desAnswers.length > 0 && !Analytics.sections){
                setQuestions(Submissions.desAnswers)
            }else if(Submissions.desAnswers.length > 0 && Analytics.sections){
                if(document.getElementsByClassName('selected-section').length > 0){
                    document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
                }
                document.getElementById(`askToEvaluate${Analytics.sections[0].section.toLowerCase()}`).classList.add('selected-section')

                const Paper = Submissions.desAnswers.filter((element)=> (element.sectionName.toLowerCase() === Analytics.sections[0].section.toLowerCase()))
                setQuestions(Paper)
                setSections(Analytics.sections[0])
            }
        }
    },[Analytics, Submissions])

    const showFile = (url, type) => {
        setShowPreview(true)
        setPreviewUrl(url)
        setType(type)
    }

    const myFunction = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown").classList.toggle('show');
    } 

    const graphData = (type) => {
        if(document.getElementById("myDropdown").classList.contains('show')){
            document.getElementById("myDropdown").classList.remove('show')
        }
        switch(type){
            case 'classification':
                setData({
                    labels:['Conceptual','Application','Factual'],
                    datasets:[{
                        label:'Classification',
                        data:[
                            Analytics.classification.conceptual,
                            Analytics.classification.application,
                            Analytics.classification.gk
                        ],
                        backgroundColor:[
                            '#0c343d',
                            '#2f5c50',
                            '#007ea7',
                        ]
                    }]
                })
                setGraphType('Classification')
                break;
            case 'category':
                setData({
                    labels:['Category1','Category2', 'Gs-Full-Test-Lv1', 'Gs-Full-Test-Lv2', 'N.C.E.R.T', 'Reference Book'],
                    datasets:[{
                        label:'Category',
                        data:[
                            Analytics.category.category1,
                            Analytics.category.category2,
                            Analytics.category.gsFullTestLv1,
                            Analytics.category.gsFullTestLv2,
                            Analytics.category.ncert,
                            Analytics.category.referenceBook,
                        ],
                        backgroundColor:[
                            '#db2549',
                            '#720256',
                            '#4f3e2c',
                            '#810dd3',
                            '#cad942',
                            '#C6B269'
                        ]
                    }]
                })
                setGraphType('Category')
                break;
            case 'difficulty':
                setData({
                    labels:['Easy','Medium','Tough'],
                    datasets:[{
                        label:'Difficulty',
                        data:[
                            Analytics.difficulty.easy,
                            Analytics.difficulty.medium,
                            Analytics.difficulty.hard
                        ],
                        backgroundColor:[
                            '#ffe599',
                            '#8fce00',
                            '#f44336',
                        ]
                    }]
                })
                setGraphType('Difficulty')
                break;
            case 'questions':
                setData({
                    labels:['Correct','Wrong','Attempted','Unattempted', 'Total'],
                    datasets:[{
                        label:'Questions Analysis',
                        data:[
                            Analytics.correctAnswer,
                            Analytics.incorrectAnswer,
                            Analytics.incorrectAnswer + Analytics.correctAnswer ,
                            Analytics.totalQuestions - Analytics.incorrectAnswer - Analytics.correctAnswer,
                            Analytics.totalQuestions
                        ],
                        backgroundColor:[
                            '#40BF09',
                            '#FF666B',
                            '#f1c232',
                            '#53C7BB',
                            '#79309C'
                        ]
                    }]
                })
                setGraphType('Questions Analysis')
                break;
            default:
                console.log('default')
        }
    }

    const selectSection = (section) => {
        if(document.getElementsByClassName('selected-section').length > 0){
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
        }
        document.getElementById(`askToEvaluate${section.section.toLowerCase()}`).classList.add('selected-section')
  
        const Paper = Submissions.desAnswers.filter((element)=> (element.sectionName.toLowerCase() === section.section.toLowerCase()))
        setQuestions(Paper)
        setSections(section)
    }

    const scrollToQuestion = () => {
        if(scrollToQues){
            
            const element = document.getElementsByClassName(`question_${scrollToQues}`)
            console.log(element)
            if(element.length > 0){
                element[0].scrollIntoView({
                    behavior:"smooth",
                    block:"start",
                    inline:'nearest'
                });
            }
        }
    }

    return (
        <main className='foundational-assignments'>
            {(loadingSubmission || loading) && <Loader/>}
            { error && <AlertDismissibleExample data={error} />}
            { errorSubmission && <AlertDismissibleExample data={errorSubmission} />}

            {
                Analytics && <>
                    <div>
                        <section className='px-5 py-5'>
                            <div className='a' onClick={(e)=>{e.preventDefault(); history.goBack()}}>
                                <img  src={leftArrow} alt="<" className="backBtn"/>
                            </div>
                        </section>
                        <div className='d-flex justify-content-around py-3'>
                            <div className="dropdown">
                                <button className="dropbtn" onClick={myFunction}>
                                    {graphType}  <MdOutlineArrowDropDown />
                                </button>
                                <div id="myDropdown" className="dropdown-content">
                                    <p onClick={(e) => { e.preventDefault(); graphData('classification') }}>Classification</p>
                                    <p onClick={(e) => { e.preventDefault(); graphData('category') }}>Category</p>
                                    <p onClick={(e) => { e.preventDefault(); graphData('difficulty') }}>Difficulty</p>
                                    <p onClick={(e) => { e.preventDefault(); graphData('questions') }}>Questions</p>
                                </div>
                            </div>
                        </div>
                        { data && <BarChart data={data}/>}
                        <div className="marks">
                            <h3>
                                <span>Final Score: </span>
                                {Analytics.finalScore} out of {Analytics.totalMarks}
                            </h3>
                        </div>
                    </div>
                    <section className="px-5 py-5">
                        <div className='d-flex justify-content-between'>
                            <h1 className="mt-2" style={{fontSize:25,fontWeight:'600', color:'rgb(1, 42, 93)'}}>
                                Answer Sheet
                            </h1>
                        </div>
                        {
                            Submissions && Submissions.desAnswers.length > 0 && <div>
                                <div className="section-selector">
                                    {
                                        Analytics.sections && Analytics.sections.length > 0 &&
                                        Analytics.sections.map((obj, i)=>(
                                            <button
                                                id={`askToEvaluate${obj.section.toLowerCase()}`} 
                                                key={obj.section}
                                                className={i===0 ? 'selected-section' : ''}
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    selectSection(obj);
                                                }}
                                            >
                                                section {obj.section}
                                            </button>
                                        ))
                                    }
                                </div>
                                <div style={{marginTop:"10px"}}>
                                    {questions.length !== 0 &&
                                        <SearchItem
                                            allItems={question}
                                            from={"Questions"}
                                            handleSetSearchResults={(results) => setSearchResults(results)}
                                        />
                                    } 
                                </div>
                                <div className='section-info'>
                                    <h3><span>No. Of Questions : </span>{searchResults.length}</h3>
                                    <h3><span>Positive Marking : </span>{sections.marksPerQuestion}</h3>
                                    <h3><span>Negative Marking : </span>{sections.negativeQuestion}</h3>
                                </div>
                                <div>
                                <div className='question-scroller'>
                                        <label>Question Number : &nbsp;</label>
                                        <input 
                                            type='number' 
                                            min={1} 
                                            max={questions.length} 
                                            value={scrollToQues} 
                                            onChange={e => {setScrollToQues(e.target.value)}} 
                                        />
                                        <button onClick={e => {e.preventDefault(); scrollToQuestion()}}>Scroll</button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        searchResults.length !== 0 ? searchResults.map((question, i)=>(
                                            <div key={i}>
                                                <div className={`questions-container question_${i+1}`} key={question.id}>
                                                    <div className="question">
                                                        <div className="ques-line1">
                                                            <div className="qus-number">
                                                                <h1>{i+1}</h1>
                                                            </div> 
                                                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.question)}}></p>
                                                        </div>

                                                        {question.imageURL && (
                                                            <div className="question-Img">
                                                                <img src={question.imageURL} alt="questionimg"/>
                                                            </div>
                                                        )}
                                                        <div className='answer-cards'>
                                                            <div className='d-flex justify-content-between'>
                                                                <h5>Answers:</h5>
                                                                <h5 style={{fontSize:"16px"}}>Marks: {question.marks ? question.marks : 0}/{question.totalMarks}</h5>
                                                            </div>

                                                            <div className='textArea'>
                                                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.answer)}}></p>
                                                            </div>
                                                        </div>
                                                        <div className='answer-cards'>
                                                            <h5>Attachments:</h5>
                                                            <div className="container">
                                                                {
                                                                    question.files.length > 0 && question.files.map((file,i)=>{
                                                                        if(file.url){
                                                                            return (
                                                                                <div key={i}>
                                                                                    <div className='image' >
                                                                                        {
                                                                                            file.type === "application/pdf" ? <img  src={PdfFile} alt={'PDF FILE'}/>  :
                                                                                            file.type === "image/jpeg" ?  <img  src={JPGFile} alt={'jpeg FILE'}/> :
                                                                                            file.type === "image/png"  && <img  src={PNGFILE} alt={'png FILE'}/>
                                                                                        }
                                                                                        <span 
                                                                                            className='preview' 
                                                                                            onClick={e =>{ 
                                                                                                e.preventDefault(); 
                                                                                                showFile(file.url, file.type)
                                                                                            }}
                                                                                            title={"Preview"}
                                                                                        >
                                                                                            <PreviewIcon/>
                                                                                        </span>
                                                                                    </div>
                                                                                    <p className="fileName">{file.name}</p>
                                                                                </div>
                                                                            )   
                                                                        }else{
                                                                            return null
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='answer-cards'>
                                                            <h5>Comment:</h5>
                                                            <div className='textArea'>
                                                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.comment)}}></p>
                                                            </div>
                                                        </div>

                                                        <div className='answer-cards question-details'>
                                                            <h3>Question Details</h3>
                                                            <div className='d-flex justify-content-around my-4 '>
                                                                <p><span>Classification : </span>{question.classification}</p>
                                                                <p><span>Category : </span>{question.category}</p>
                                                                <p><span>Difficulty : </span>{question.difficulty}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )): 
                                        <div className="questions-container">
                                            <div className='content-notAvail'>
                                                <img src={noImage} alt="" />
                                                <h3>No Questions Available</h3>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </section>
                    {
                        showPreview && <div className='file-preview-container'>
                            <div className='file-preview-card'>
                                <div className='file'>
                                    {
                                        Type === 'application/pdf' ? <Pdf url={previewUrl} /> : 
                                        <img src={previewUrl} alt='preview' />
                                    }
                                </div>
                                <button className='close_btn' onClick={e => setShowPreview(false)}>
                                    <CloseIcon/>
                                </button>
                            </div>
                        </div>
                    }
                </>
            }
        </main>
    )
}

export default AskToEvaluateAnalytics