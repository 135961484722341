import React, {useEffect, useState}  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BarChart from '../../Components/Graphs/BarChart'
import { logout } from '../../actions/userActions';
import { 
    getITWQPaper, 
    getITWDetails,
    getItwTestAnalytics,
    getItwAnswerSheetById 
} from '../../actions/IssuesThisWeekAction'

import Sidebar from '../../Components/main/sidebar/sidebar'
import { MdOutlineArrowDropDown } from 'react-icons/md';
import leftArrow from '../../assets/icons/back-arrow.svg'

import DOMPurify from 'dompurify';

const ItwAnalytics = ({match,history}) => {
    const dispatch = useDispatch();

    const [graphType,setGraphType] = useState('');
    const [data, setData] = useState(undefined);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getITWById = useSelector(state => state.getITWById)
    const { loading, error, itw } = getITWById;

    const getITWPaper = useSelector(state => state.getITWPaper)
    const { loading:loadingPaper, error:errorPaper, paper } = getITWPaper;

    const getItwAnalytics = useSelector(state => state.getItwAnalytics)
    const { loading:loadingAnalytics, error:errorAnalytics, analytics } = getItwAnalytics;

    const getItwAnswerSheet = useSelector(state => state.getItwAnswerSheetById)
    const { loading: loadingAnswerSheet, error: errorAnswerSheet, answerSheet } = getItwAnswerSheet;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getITWDetails(match.params.id))
            dispatch(getItwTestAnalytics(match.params.id))
            dispatch(getITWQPaper(match.params.id))
        }
    }, [userInfo, dispatch, match])

    useEffect(()=>{
        const message = 'User is not authenticated, try logging in again...';
        if(
            error === message ||
            errorPaper === message ||
            errorAnswerSheet === message ||
            errorAnalytics === message
        ){
            dispatch(logout());
        }
    },[dispatch, error, errorAnalytics, errorPaper, errorAnswerSheet ])

    useEffect(()=>{
        if(analytics){
            setGraphType('Classification')
            setData({
                labels:['Conceptual','Application','Gk'],
                datasets:[{
                    label:'Classification',
                    data:[
                        analytics.classification.conceptual,
                        analytics.classification.application,
                        analytics.classification.gk,
                    ],
                    backgroundColor:[
                        '#0c343d',
                        '#2f5c50',
                        '#007ea7',
                    ]
                }]
            })
            dispatch(getItwAnswerSheetById(analytics.answerSheet))
        }
    },[analytics,dispatch])

    const myFunction = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown").classList.toggle('show');
    }

    const graphData = (type) => {
        if (document.getElementById("myDropdown").classList.contains('show')) {
            document.getElementById("myDropdown").classList.remove('show')
        }

        switch (type) {
            case 'classification':
                setGraphType('Classification')
                setData({
                    labels: [
                        'Conceptual',
                        'Application',
                        'Gk'
                    ],
                    datasets: [{
                        label: 'Classification',
                        data: [
                            analytics.classification.conceptual,
                            analytics.classification.application,
                            analytics.classification.gk],
                        backgroundColor: [
                            '#0c343d',
                            '#2f5c50',
                            '#007ea7',
                        ]
                    }]
                })
                break;
            case 'category':
                setData({
                    labels: [
                        'Category1',
                        'Category2'
                    ],
                    datasets: [{
                        label: 'Category',
                        data: [
                            analytics.category.category1,
                            analytics.category.category2
                        ],
                        backgroundColor: [
                            '#007ea7',
                            '#113450',
                        ]
                    }]
                })
                setGraphType('Category')
                break;
            case 'difficulty':
                setData({
                    labels: [
                        'Easy',
                        'Medium',
                        'Hard'
                    ],
                    datasets: [{
                        label: 'Difficulty',
                        data: [
                            analytics.difficulty.easy,
                            analytics.difficulty.medium,
                            analytics.difficulty.hard
                        ],
                        backgroundColor: [
                            '#ffe599',
                            '#8fce00',
                            '#f44336',
                        ]
                    }]
                })
                setGraphType('Difficulty')
                break;
            case 'questions':
                setData({
                    labels: ['Correct Questions', 'Wrong Questions', 'Attempted Questions'],
                    datasets: [{
                        label: 'Questions',
                        data: [
                            analytics.correctAnswer,
                            analytics.incorrectAnswer,
                            analytics.incorrectAnswer + analytics.correctAnswer,
                        ],
                        backgroundColor: [
                            '#199F6B',
                            '#B54747',
                            '#f1c232',
                        ]
                    }]
                })
                setGraphType('Questions')
                break;
            default:
                console.log('invalid input')
        }
    }

    return (
        <div className='itw-analytics'>
            <Sidebar activeLink={'more'}/>
            <div className='main'>
                <div className='py-3 px-5'>
                    <button style={{ border: "none", background: "none" }} onClick={() => history.goBack()} >
                        <img src={leftArrow} alt="<" style={{ margin: 10 }} />
                    </button>
                    <span>
                        <h3 style={{color:'rgb(1, 42, 93)', display:'inline-block', textAlign:'center', paddingLeft:'15px'}}>
                            Issues This Week
                        </h3>
                    </span>
                </div>
                {((!loading || !error || !loadingAnalytics || !errorAnalytics) && (itw.id && analytics)) && <div>
                    <div className='py-2 px-5'>
                        <div className="paragraph">
                            <div className='image'>
                                <img src={itw.image} alt="img"/>
                            </div>
                            <div className='text' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(itw.description)}}>
                            </div>
                        </div>
                    </div>
                    <div className='py-3 px-5'>
                        <div className='d-flex justify-content-center'>
                            <div className="dropdown">
                                <button className="dropbtn" onClick={myFunction}>
                                    {graphType}  <MdOutlineArrowDropDown />
                                </button>
                                <div id="myDropdown" className="dropdown-content">
                                    <p onClick={(e) => { e.preventDefault(); graphData('classification') }}>Classification</p>
                                    <p onClick={(e) => { e.preventDefault(); graphData('category') }}>Category</p>
                                    <p onClick={(e) => { e.preventDefault(); graphData('difficulty') }}>Difficulty</p>
                                    <p onClick={(e) => { e.preventDefault(); graphData('questions') }}>Questions</p>
                                </div>
                            </div>
                        </div>
                        {data && <BarChart data={data} />}
                        <div className="marks">
                            <h3>
                                <span>Final Score: </span>
                                {analytics.finalScore} out of {analytics.totalMarks}
                            </h3>
                        </div>
                    </div>
                </div>}

                {((!loadingPaper || !loadingAnswerSheet || !errorPaper || !errorAnswerSheet) && (paper.length>0 && answerSheet.answers)) && 
                    paper.map((question, i) => {
                        return (
                        <div className="questions-container" key={question.id}>
                            <div className="question">
                                <div className="ques-line1">
                                    <div className="qus-number">
                                        <h1>{i + 1}</h1>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.description) }}></p>
                                </div>
                                {question.imageURL && (
                                    <div className="question-Img">
                                        <img src={question.imageURL} alt="questionimg" />
                                    </div>
                                )}
                                <div className="options">
                                    {question.options.map((option, i) => (
                                        <label className="option" key={i} >{option}
                                            <input type="radio" name={question.id} value={option}
                                                checked={
                                                    answerSheet.answers.length !== 0 ? (
                                                        answerSheet.answers.find((ele) => (ele.qid === question.id && ele.answer === option)) ? true : false
                                                    ) : false
                                                }
                                                readOnly />
                                            <span
                                                className={
                                                    answerSheet.answers.length !== 0 ? (
                                                        answerSheet.answers.find((ele) => (ele.qid === question.id && ele.answer === option)) ? (option === question.correctOption ? 'right' : 'wrong') : ''
                                                    ) : ''}
                                            ></span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                            <div className="correctAnswer">
                                <p>Correct Answer:  <span>{question.correctAnswer}</span></p>
                            </div>
                            {(question.explaination || question.pdf || question.video || question.audio) &&
                                <div className="explainAnswer">
                                    <p><strong>Explanation-</strong></p>
                                    {question.explaination && <div><p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.explaination) }}></p></div>}
                                </div>
                            }
                        </div>)
                    })
                }
            </div>
        </div>
    )
}

export default ItwAnalytics
