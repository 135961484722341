import React,{useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/main/sidebar/sidebar';
import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import {getUserDetails} from '../../actions/userActions';
import { getPracticeTestById } from '../../actions/practiceTestsAction';
import { getAllPracticeTestSeries } from '../../actions/EnrolledTestSeries';

import Cross from '../../assets/icons/cross.svg';
import Right from '../../assets/icons/RightAnswer.svg';
import Clock from '../../assets/icons/clock.svg';

import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

import AlertDismissibleExample from '../../Components/Error/error';
import Loader from '../../Components/Loader';
import DOMPurify from 'dompurify';

import './TestSeries.scss'
import { URL } from '../../config/config';
// import { logout } from '../../actions/userActions';

const Instructions = ({match}) => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false)
    const [testSeries, setTestSeries] = useState({})

    const getUserDetail = useSelector(state => state.getUserDetail)
    const {loading:loadingUser , error:errorUser, user} = getUserDetail;

    const getPracticeTestDetails = useSelector(state => state.getPracticeTestDetails)
    const { loading, error, practiceTest } = getPracticeTestDetails;

    const getAllPracticeTest = useSelector(state => state.getAllPracticeTest)
    const { loading:loadingEnrollTs, error:errorEnrollTs, PracticeTest } = getAllPracticeTest;

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    useEffect(()=>{
        if(!userInfo){
            document.location.href = '/login';
        }
       
        dispatch(getPracticeTestById(match.params.id))
        dispatch(getAllPracticeTestSeries())
        dispatch(getUserDetails())
    },[userInfo,dispatch,match])

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(
    //         error === message ||
    //         errorUser === message
    //     ){
    //         dispatch(logout());
    //     }
    // },[dispatch, error, errorUser])

    useEffect(()=>{
        if(PracticeTest && PracticeTest.length !== 0){
            const test = PracticeTest.find((element)=> element.paidTest.id === match.params.id)
            setTestSeries(test)
        }
    },[PracticeTest,match.params.id])


    const minToHours =(min)=>{
        var hr = min/60;
        if(hr<1){
          hr=0;
        }
        var m = min%60;
        return `${(hr===0)?"":(hr<10)?`0${hr.toString().split(".")[0]} hr`:`${hr.toString().split(".")[0]} hr`} ${(m<10)?`0${m}min`:`${m} min`}`
    }

    const onChange = (e) => {
        e.preventDefault();
        if(check){
            setCheck(false)
        }else{
            setCheck(true)
        }
    }

    const startPracticeTest = (test) => {
        window.open(
            `${URL}/practice/test/${test.id}`,
            "Practice Test",
            "resizezble, noopoener"
        )
        if(!localStorage.getItem(`${test.id}_answerSheet`)){
            localStorage.setItem(`${test.id}_answerSheet`,JSON.stringify([]));
            localStorage.setItem(`${test.id}_attempt`, JSON.stringify([]));
            localStorage.setItem(`${test.id}_markForReview`, JSON.stringify([]));
        }
        if(!localStorage.getItem(`${test.id}_chance`)){
            localStorage.setItem(`${test.id}_chance`,JSON.stringify(3));
        }
        let time = Date.now()
        const testings = {
            endingTime:time + (test.duration * 60000),
            duration:test.duration
        };
        localStorage.setItem(`${test.id}_time`,JSON.stringify(testings));
        document.location.href = '/mytestseries';
    }

    return (
        <div>
            <Sidebar activeLink={'mytestseries'}/>
            <main className="main">
                {(loading || loadingUser || loadingEnrollTs)  && <Loader/>}
                {error && <AlertDismissibleExample data={error}/>}
                {errorUser && <AlertDismissibleExample data={errorUser}/>}
                {errorEnrollTs && <AlertDismissibleExample data={errorEnrollTs}/>}

                <section className="px-5  py-5">
                    <Link to={`/testseries/practice/${match.params.id}`} style={{textDecoration:'none'}}>
                        <img  src={leftArrow} alt="<" className="backBtn"/>
                    </Link>
                    {(!loading && !error) && (
                        <div>
                            <div className="instruction-header">
                                <div>
                                    <h1 className="mt-3" style={{fontSize:30,fontWeight:'600', color:'rgb(1, 42, 93)'}}>
                                        Instructions
                                    </h1>
                                </div>
                                <div>
                                    <img src={Clock} alt="clock"/>
                                    <p>{minToHours(practiceTest.duration)}</p>
                                </div>
                            </div>
                            {(!loadingUser && !errorUser && user.firstname) && (
                                <div className="test-profile">
                                    <h4>Profile</h4>
                                    <div className="img-container">
                                        {user.imageURL ? <img src={user.imageURL} alt="avtar" /> : <div className='img-letter'>
                                            <h1>{user.firstname.slice(0,1).toUpperCase()}</h1>
                                        </div>}
                                    </div>
                                    <div className="test-profile-info">
                                        <p>Name: <span style={{color:'black'}}>{user.firstname} {user.lastname}</span></p>
                                        <p>DOB: <span style={{color:'black'}}>{user.dob ? user.dob : "N/A"}</span></p>
                                        <p>ID: <span style={{color:'black'}}>{user.yesmentorid}</span></p>
                                    </div>
                                </div>
                            )}
                            {practiceTest.sections && practiceTest.sections.length!==0 &&  practiceTest.sections.map((element,i)=>{
                                return(
                                    <div className="py-3 instruction-sections" key={i}>
                                        <div className="section">
                                            <p>Section {element.section}</p>
                                        </div>
                                        <div className="test-marks">
                                            <img src={Right} alt="Right"/>
                                            <p>For Correct Answers</p>
                                            <p style={{color:"green"}}>{element.marksPerQuestion}</p>
                                        </div>
                                        <div className="test-marks">
                                            <img src={Cross} alt="cross"/>
                                            <p>For Wrong Answers</p>
                                            <p style={{color:"red"}}>{element.negativeQuestion}</p>
                                        </div>
                                    </div>
                                )
                            })}
                            
                            <div className="instructions">
                                <h1>INSTRUCTIONS</h1>
                                <div className="instruction-body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(practiceTest.instruction)}} >
                                </div>
                            </div>
                            <div className="checkBtn">
                                <label>
                                    <Checkbox onClick={onChange} checked = {check ? true : false} className="checkBox"/>
                                    <p>I have read all the instructions carefully and checked my profile details.</p>
                                </label>
                            </div>
                            <div className="attempt">
                                {
                                    check ? 
                                    (
                                        practiceTest.totalAttempts !== testSeries.attempts ? 
                                        <button onClick={(e)=> {e.preventDefault(); startPracticeTest(practiceTest)}}>
                                            Attempt Test
                                        </button> :
                                        <div>
                                            <button className="disable">
                                                Attempt Test
                                            </button>
                                            <AlertDismissibleExample data='No more attempts left!'/>
                                        </div>
                                    ) : 
                                        <button className="disable">
                                            Attempt Test
                                        </button> 
                                }
                            </div>
                            
                        </div>
                    )}
                </section>
            </main>
        </div>
    )
}

export default Instructions
