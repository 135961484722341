import React,{ useEffect, useState } from 'react'

import './Assignments.scss'

//actions
import {
    getFoundationalAssignmentAnalytics,
    getFoundationalAnswerSheet,
    getFoundationalQuestionPaper
} from '../../../actions/courseActions';

//redux
import {
    useSelector, 
    useDispatch
} from 'react-redux';

//components
import Loader from '../../../Components/Loader';
import AlertDismissibleExample from '../../../Components/Error/error';
import BarChart from '../../../Components/Graphs/BarChart';
import SearchItem from '../../../Components/Search/SearchItem';

//assets
import leftArrow from '../../../assets/images/ionic-ios-arrow-back.png';
import {MdOutlineArrowDropDown} from 'react-icons/md'
import noImage from '../../../assets/images/otp.png';

import DOMPurify from 'dompurify';


const AssignmentAnalytics = ({match, history}) => {
    const dispatch = useDispatch();

    const [graphType,setGraphType] = useState('');
    const [data,setData] = useState(undefined);
    const [questions, setQuestions] = useState([]);
    const [ sections, setSections ] = useState({});
    const [question, setQuestion] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    const getFoundationalAnalyticsAssignment = useSelector(state => state.getFoundationalAnalyticsAssignment)
    const {loading:loadingAnalytics , error:errorAnalytics, assignmentAnalytics} = getFoundationalAnalyticsAssignment;

    const getFoundationalUserAnswerSheet = useSelector(state => state.getFoundationalUserAnswerSheet)
    const {loading:loadingAnswerSheet , error:errorAnswerSheet, answerSheet} = getFoundationalUserAnswerSheet;

    const getFoundationalAssignmentPaper = useSelector(state => state.getFoundationalAssignmentPaper)
    const { loading: loadingPaper, error: errorPaper, paper } = getFoundationalAssignmentPaper;

    useEffect(()=>{
        if(!userInfo){
            history.push('/login');
        }else{
            dispatch(getFoundationalAssignmentAnalytics(match.params.id))
            dispatch(getFoundationalAnswerSheet(match.params.id))
            dispatch(getFoundationalQuestionPaper(match.params.id))
        }
    },[userInfo,dispatch,match, history])

    useEffect(()=>{
        if(assignmentAnalytics){
            setData({
                labels:['Conceptual','Application','Factual'],
                datasets:[{
                    label:'Classification',
                    data:[
                        assignmentAnalytics[0].classification.conceptual,
                        assignmentAnalytics[0].classification.application,
                        assignmentAnalytics[0].classification.gk
                    ],
                    backgroundColor:[
                        '#0c343d',
                        '#2f5c50',
                        '#007ea7',
                    ],
                }]
            })
            setGraphType('Classification')
        }
    },[assignmentAnalytics])

    useEffect(()=>{
        if(!errorPaper){
            var question = questions.map(item => ({
                ...item,
                name: item.description
            }))
            setQuestion(question)
            setSearchResults(question)
        }
    },[questions,errorPaper])

    useEffect(()=>{
        if(assignmentAnalytics && paper){
            if(paper.length !== 0 && !assignmentAnalytics[0].sections){
                setQuestions(paper)
            }else if(paper.length !== 0 && assignmentAnalytics[0].sections){
                if(document.getElementsByClassName('selected-section').length > 0)
                    document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
                document.getElementById(`foundational_${assignmentAnalytics[0].sections[0].section}`).classList.add('selected-section')

                const Paper = paper.filter((element)=> (element.section === assignmentAnalytics[0].sections[0].section))
                setQuestions(Paper)
                setSections(assignmentAnalytics[0].sections[0])
            }
        }
    },[assignmentAnalytics, paper])


    const myFunction = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown").classList.toggle('show');
    } 

    const graphData = (type) => {
        if(document.getElementById("myDropdown").classList.contains('show')){
            document.getElementById("myDropdown").classList.remove('show')
        }
        switch(type){
            case 'classification':
                setData({
                    labels:['Conceptual','Application','Factual'],
                    datasets:[{
                        label:'Classification',
                        data:[
                            assignmentAnalytics[0].classification.conceptual,
                            assignmentAnalytics[0].classification.application,
                            assignmentAnalytics[0].classification.gk
                        ],
                        backgroundColor:[
                            '#0c343d',
                            '#2f5c50',
                            '#007ea7',
                        ]
                    }]
                })
                setGraphType('Classification')
                break;
            case 'category':
                setData({
                    labels:['Category1','Category2', 'Gs-Full-Test-Lv1', 'Gs-Full-Test-Lv2', 'N.C.E.R.T', 'Reference Book'],
                    datasets:[{
                        label:'Category',
                        data:[
                            assignmentAnalytics[0].category.category1,
                            assignmentAnalytics[0].category.category2,
                            assignmentAnalytics[0].category.gsFullTestLv1,
                            assignmentAnalytics[0].category.gsFullTestLv2,
                            assignmentAnalytics[0].category.ncert,
                            assignmentAnalytics[0].category.referenceBook,
                        ],
                        backgroundColor:[
                            '#db2549',
                            '#720256',
                            '#4f3e2c',
                            '#810dd3',
                            '#cad942',
                            '#C6B269'
                        ]
                    }]
                })
                setGraphType('Category')
                break;
            case 'difficulty':
                setData({
                    labels:['Easy','Medium','Tough'],
                    datasets:[{
                        label:'Difficulty',
                        data:[
                            assignmentAnalytics[0].difficulty.easy,
                            assignmentAnalytics[0].difficulty.medium,
                            assignmentAnalytics[0].difficulty.hard
                        ],
                        backgroundColor:[
                            '#ffe599',
                            '#8fce00',
                            '#f44336',
                        ]
                    }]
                })
                setGraphType('Difficulty')
                break;
            case 'questions':
                setData({
                    labels:['Correct','Wrong','Attempted','Unattempted', 'Total'],
                    datasets:[{
                        label:'Questions Analysis',
                        data:[
                            assignmentAnalytics[0].correctAnswer,
                            assignmentAnalytics[0].incorrectAnswer,
                            assignmentAnalytics[0].incorrectAnswer + assignmentAnalytics[0].correctAnswer ,
                            assignmentAnalytics[0].totalQuestions - assignmentAnalytics[0].incorrectAnswer - assignmentAnalytics[0].correctAnswer,
                            assignmentAnalytics[0].totalQuestions
                        ],
                        backgroundColor:[
                            '#40BF09',
                            '#FF666B',
                            '#f1c232',
                            '#53C7BB',
                            '#79309C'
                        ]
                    }]
                })
                setGraphType('Questions Analysis')
                break;
            default:
                console.log('default')
        }
    }

    const selectSection = (section) => {
        if(document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
        document.getElementById(`foundational_${section.section}`).classList.add('selected-section')
  
        const Paper = paper.filter((element)=> (element.section === section.section))
        setQuestions(Paper)
        setSections(section)
    }


    return (
        <div className='foundational-assignments'>
            {(loadingPaper || loadingAnalytics || loadingAnswerSheet) && <Loader/>}
            { errorPaper && <AlertDismissibleExample data={errorPaper}/>}
            { errorAnalytics && <AlertDismissibleExample data={errorAnalytics}/>}
            { errorAnswerSheet && <AlertDismissibleExample data={errorAnswerSheet}/>}

            <section className='px-5 py-5'>
                <div className='a' onClick={(e)=>{e.preventDefault(); history.goBack()}}>
                    <img  src={leftArrow} alt="<" className="backBtn"/>
                </div>
            </section>
            {(assignmentAnalytics && data) && (
                <section className="px-5">
                    <div className='d-flex justify-content-center'>
                        <div className="dropdown">
                            <button className="dropbtn" onClick={myFunction}>{graphType}   {<MdOutlineArrowDropDown/>}</button>
                            <div id="myDropdown" className="dropdown-content">
                                <p onClick={(e)=>{e.preventDefault(); graphData('classification')}}>Classification</p>
                                <p onClick={(e)=>{e.preventDefault(); graphData('category')}}>Category</p>
                                <p onClick={(e)=>{e.preventDefault(); graphData('difficulty')}}>Difficulty</p>
                                <p onClick={(e)=>{e.preventDefault(); graphData('questions')}}>Questions Analysis</p>
                            </div>
                        </div>
                    </div>
                    <BarChart data={data}/>
                    <div className="marks">
                        <h3>
                            <span>Final Score: </span>
                            {assignmentAnalytics[0].finalScore.toFixed(2)} out of {assignmentAnalytics[0].totalMarks}
                        </h3>
                    </div>
                </section>
            )}
            {(paper && answerSheet && assignmentAnalytics) && (
                <>
                    <section className="px-5 ">
                        <div className='d-flex justify-content-between'>
                            <h1 className="mt-2" style={{fontSize:25,fontWeight:'600', color:'rgb(1, 42, 93)'}}>
                                Answer Sheet
                            </h1>
                        </div>
                    </section>
                    <section className="px-5 pb-5">
                        <div className="section-selector">
                            {
                                assignmentAnalytics[0].sections.map((section, i)=>{
                                    return(
                                        <button 
                                            id={`foundational_${section.section}`} 
                                            key={section.section}
                                            className={i===0 ? 'selected-section' : ''}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                selectSection(section);
                                            }}>
                                                section {section.section}
                                        </button>
                                    )
                                })
                            }
                        </div>
                        <div style={{marginTop:"10px"}}>
                            {questions.length !== 0 &&
                                <SearchItem
                                    allItems={question}
                                    from={"Questions"}
                                    handleSetSearchResults={(results) => setSearchResults(results)}
                                />
                            } 
                        </div>
                        <div className='section-info'>
                            <h3><span>No. Of Questions : </span>{searchResults.length}</h3>
                            <h3><span>Positive Marking : </span>{sections.marksPerQuestion}</h3>
                            <h3><span>Negative Marking : </span>{sections.negativeQuestion}</h3>
                        </div>
                        {searchResults.map((question,i)=>(
                            <div key={question.id} className="questions-container">
                                <div className="question">
                                    <div className="ques-line1">
                                        <div className="qus-number">
                                            <h1>{i+1}</h1>
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.description)}}></p>
                                    </div>
                                    {question.imageURL && (
                                        <div className="question-Img">
                                            <img src={question.imageURL} alt="questionimg"/>
                                        </div>
                                    )}
                                    <div className="options">
                                        {question.options.map((option, i)=>(
                                            <label className="option"key={i} >
                                                {option}
                                                <input type="radio" name={question.id} value={option} checked={
                                                    answerSheet?( 
                                                        (answerSheet.answers && answerSheet.answers.length !== 0) ? (
                                                            answerSheet.answers.find((el)=>(
                                                                el.qid === question.id && el.answer === option
                                                            )) ? true : false 
                                                        ) : false 
                                                    ):false} 
                                                readOnly/>
                                                <span className={`${answerSheet?(( answerSheet.answers && answerSheet.answers.length !== 0) ? (answerSheet.answers.find((el)=>(el.qid === question.id && el.answer === option)) ? ((option === question.correctOption) ? 'right' : 'wrong') : 'wrong' ) : '' ):''}`}></span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className="correctAnswer">
                                    <p>Correct Answer:  <span>{question.correctOption}</span></p>
                                </div>
                                {(question.explainText) &&
                                    <div className="explainAnswer">
                                        <p><strong>Explanation-</strong></p>
                                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.explainText)}}></p>
                                    </div>
                                }
                            </div>
                        ))}
                        {searchResults.length === 0 && (
                            <div className='questions-notfound'>
                                <img src={noImage} alt="" />
                                <h3>No questions found</h3>
                            </div>
                        )}
                    </section>
                </>
            )}

        </div>
    )
}

export default AssignmentAnalytics