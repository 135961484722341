import React from 'react';
import './ProfileLogo.scss';

const ProfileLogo = ({text, img, name}) => {
    return (
        <div className='profile-logo'>
            <div className='img-container'>
                {img ? <img className="skeleton" src={img} alt="IMG"/> : <div className='img-letter'>
                    <h1>{text.toUpperCase()}</h1>
                </div>}
            </div>
            <div className='name-container'>
                <h3>{name.slice(0,1).toUpperCase()}{name.slice(1)}</h3>
            </div>
        </div>
    )
}

export default ProfileLogo
