import {
    GET_ALL_LIVETESTS_REQUEST,
    GET_ALL_LIVETESTS_SUCCESS,
    GET_ALL_LIVETESTS_FAIL,
    GET_LIVETEST_BY_ID_REQUEST,
    GET_LIVETEST_BY_ID_SUCCESS,
    GET_LIVETEST_BY_ID_FAIL,
    POST_ENROLL_LIVETEST_REQUEST,
    POST_ENROLL_LIVETEST_SUCCESS,
    POST_ENROLL_LIVETEST_FAIL,
    GET_LIVETEST_PAYMENT_HISTORY_REQUEST,
    GET_LIVETEST_PAYMENT_HISTORY_SUCCESS,
    GET_LIVETEST_PAYMENT_HISTORY_FAIL,
    POST_LIVETEST_COUPON_VALIDATION_REQUEST,
    POST_LIVETEST_COUPON_VALIDATION_SUCCESS,
    POST_LIVETEST_COUPON_VALIDATION_FAIL
} from '../constants/liveTestConstants';

export const getAllLiveTestsReducer = (state = { liveTests: [] }, action) => {
    switch (action.type) {
        case GET_ALL_LIVETESTS_REQUEST:
            return { loading: true, liveTests: [] }
        case GET_ALL_LIVETESTS_SUCCESS:
            return { loading: false, liveTests: action.payload }
        case GET_ALL_LIVETESTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getLiveTestDetailsReducer = (state = { liveTest: {} }, action) => {
    switch (action.type) {
        case GET_LIVETEST_BY_ID_REQUEST:
            return { loading: true, ...state }
        case GET_LIVETEST_BY_ID_SUCCESS:
            return { loading: false, liveTest: action.payload }
        case GET_LIVETEST_BY_ID_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postEnrollLiveTestReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_ENROLL_LIVETEST_REQUEST:
            return { loading: true }
        case POST_ENROLL_LIVETEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_ENROLL_LIVETEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getLiveTestPaymentHistoryReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case GET_LIVETEST_PAYMENT_HISTORY_REQUEST:
            return { loading: true, ...state }
        case GET_LIVETEST_PAYMENT_HISTORY_SUCCESS:
            return { loading: false, payment: action.payload }
        case GET_LIVETEST_PAYMENT_HISTORY_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postLiveCouponReducer = (state = { couponDetails:{}}, action) => {
    switch (action.type) {
        case POST_LIVETEST_COUPON_VALIDATION_REQUEST:
            return { loading: true , couponDetails:{}}
        case POST_LIVETEST_COUPON_VALIDATION_SUCCESS:
            return { loading: false, couponDetails: action.payload }
        case POST_LIVETEST_COUPON_VALIDATION_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}