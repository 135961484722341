import React,{useEffect,useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import './TestSeries.scss';
import { getPracticeTestById } from '../../actions/practiceTestsAction';
import { getPracticeQuestionPaper , postSubmitPracticeTest } from '../../actions/EnrolledTestSeries';
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import Timer from '../../Components/Timer/Timer';
import Question from '../../Components/question/question';
import noImage from '../../assets/images/otp.png';
import {CgDanger} from 'react-icons/cg';
import Donut from '../../Components/Graphs/Donut';
import { Col, Row } from 'react-bootstrap';
import FullScreenTimer from '../../Components/Timer/FullScreenTimer';

const PracticeTestScreen = ({match}) => {
    const dispatch = useDispatch();
    const [question,setQuestions] = useState([]);
    const [isFullScreen,setIsFullScreen] = useState(false);
    const [attempt, setAttempt] = useState([]);
    const [markReview, setMarkReview] = useState([]);
    const [scrollToQues, setScrollToQues] = useState(1);
    
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    const getPracticeTestDetails = useSelector(state => state.getPracticeTestDetails)
    const { loading, error, practiceTest } = getPracticeTestDetails;

    const getPracticePaper = useSelector(state => state.getPracticePaper)
    const { loading:loadingPaper, error:errorPaper, paper } = getPracticePaper;

    const PostPracticeTest = useSelector(state => state.PostPracticeTest)
    const { loading:loadingSubmit, error:errorSubmit, message } = PostPracticeTest;

    useEffect(()=>{
        if(!userInfo){
            document.location.href = '/login';
        }
        dispatch(getPracticeTestById(match.params.id))
        dispatch(getPracticeQuestionPaper(match.params.id))
        setAttempt(JSON.parse(localStorage.getItem(`${match.params.id}_attempt`)) || [])
        setMarkReview(JSON.parse(localStorage.getItem(`${match.params.id}_markForReview`)) || [])
    },[userInfo,dispatch,match])


    useEffect(()=>{
        if(practiceTest.sections && paper && paper.length !== 0){
            const test = paper.filter((element)=> (element.sectionName === practiceTest.sections[0].section))
            setQuestions(test)
            if(document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
            document.getElementById(`practiceTest${practiceTest.sections[0].section}`).classList.add('selected-section')
        }
    },[paper,practiceTest])
    

    const submitHandler = () => {
        const answer = JSON.parse(localStorage.getItem(`${match.params.id}_answerSheet`));
        dispatch(postSubmitPracticeTest(match.params.id,answer));
        localStorage.removeItem(`${match.params.id}_answerSheet`);
        localStorage.removeItem(`${match.params.id}_chance`);
        localStorage.removeItem(`${match.params.id}_markForReview`);
        localStorage.removeItem(`${match.params.id}_attempt`);
        localStorage.removeItem(`${match.params.id}_fullScreen`);
        setTimeout(()=>{
            localStorage.removeItem(`${match.params.id}_time`);
            window.close()
        },1350);
    }

    const selectSection = (section) => {
        if(document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
        document.getElementById(`practiceTest${section}`).classList.add('selected-section')

        if(practiceTest.sections && paper && paper.length !== 0){
            const test = paper.filter((element)=> (element.sectionName === section))
            setQuestions(test)
        }else{
            setQuestions([])
        }
    }

    const disableFunction = (e) => {
        e.preventDefault()  
        return false;
    }

    document.addEventListener('fullscreenchange', event =>{
        if(!document.fullscreenElement){
            localStorage.setItem(`${match.params.id}_fullScreen`,JSON.stringify(20));
            setIsFullScreen(false);
        }else{
            setIsFullScreen(true);
        }
    })

    document.addEventListener('visibilitychange', event=>{
        if(document.visibilityState !== "visible"){
            setIsFullScreen(true);
        }
    })

    const fullScreen = () =>{
        if(document){
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        }
        localStorage.setItem(`${match.params.id}_chance`,JSON.stringify(localStorage.getItem(`${match.params.id}_chance`) - 1));
        let chance = localStorage.getItem(`${match.params.id}_chance`)
        if(chance === '-1') {
            submitHandler();
        }
    }

    const scrollToQuestion = () => {
        if(scrollToQues){
            
            const element = document.getElementsByClassName(`question_${scrollToQues}`)
            console.log(element)
            if(element.length > 0){
                element[0].scrollIntoView({
                    behavior:"smooth",
                    block:"center",
                    inline:'nearest'
                });
            }
        }
    }

    return (
        <div 
            className="test-Container"
            onCopy = {disableFunction}
            onContextMenu = {disableFunction}
            onPaste = {disableFunction}
            onCut = {disableFunction}
        >
            {(loading || loadingPaper || loadingSubmit) && <Loader/>}
            {error && <AlertDismissibleExample data={error}/>}
            {errorPaper && <AlertDismissibleExample data={errorPaper}/>}
            {errorSubmit && <AlertDismissibleExample data={errorSubmit}/>}
            {message && <AlertSuccessExample data={message}/>}

            {!isFullScreen &&
            <div className="blur">
                <div className="content px-5 py-5">
                    <CgDanger style={{width:'120px', height:'120px', color:'rgb(163, 5, 5)'}}/>
                    <p>The Window Should Be in Full Screen Mode during the Exam.</p>
                    {localStorage.getItem(`${match.params.id}_chance`) > 0 ? (
                        <p>If This warning occurs more then {localStorage.getItem(`${match.params.id}_chance`) > 2 ? '2' : localStorage.getItem(`${match.params.id}_chance`) } times, then Exam will be automatically submitted.</p>):(
                        <p>This Was Your Last Warning!!</p>
                    )}

                    {(localStorage.getItem(`${match.params.id}_chance`) <= 2) && ( <p>Exam will automatically submitted in <FullScreenTimer id={match.params.id} type={'practiceTs'} />, Click on Full Screen Button. </p>) }
                    
                    <button onClick={(e)=>{e.preventDefault();fullScreen()}}>Full Screen</button>
                </div>
            </div>}

            {(!loading && !error && !loadingPaper && !errorPaper) && (
                <>
                    <main className='px-5 py-5'>
                        <Timer id={match.params.id} type={'practiceTs'}/>
                        <div>
                            <Donut
                                attempted={attempt.length}
                                notAttempted={paper.length - attempt.length}
                                MarkForReview={markReview.length} 
                            />
                        </div>
                        <div className='Container'>
                            <div className='section-btns'>
                                { 
                                    practiceTest.sections && practiceTest.sections.length !==0 &&
                                    practiceTest.sections.map((section,i)=>{
                                        return (
                                            <button id={`practiceTest${section.section}`} key={section.section} className={i===0 ? 'selected-section' : ''}  onClick={(e)=>{ e.preventDefault(); selectSection(section.section)}}>Section {section.section}</button>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <div className='question-scroller'>
                                    <label>Question Number : &nbsp;</label>
                                    <input 
                                        type='number' 
                                        min={1} 
                                        max={question.length} 
                                        value={scrollToQues} 
                                        onChange={e => {setScrollToQues(e.target.value)}} 
                                    />
                                    <button onClick={e => {e.preventDefault(); scrollToQuestion()}}>Scroll</button>
                                </div>
                            </div>
                            <Row>
                                <Col md={8} className={`questions-Container`}>
                                    <div>
                                        {((!loadingPaper && !errorPaper) && question.length !== 0 ? (
                                            question.map((question,i)=>(
                                                <Question 
                                                    className = {`question_${i+1}`}
                                                    testid={match.params.id} 
                                                    qno={i+1} 
                                                    ques={question.description} 
                                                    img={question.imageURL} 
                                                    opt={question.options} 
                                                    qid={question.id} 
                                                    key={question.id}
                                                    section={question.sectionName} 
                                                    totalAttempt = {(result) => setAttempt(result)}
                                                    totalMarkForReview = {(result)=>setMarkReview(result)}
                                                />
                                            ))
                                            ):(
                                                <div className='questions-notfound'>
                                                    <img src={noImage} alt="" />
                                                    <h3>No questions found</h3>
                                                </div>
                                            )
                                        )}
                                    </div>

                                </Col>
                                <Col md={4} className='question-tracking'>
                                    <div className='tracker-container'>
                                        { 
                                            practiceTest.sections && practiceTest.sections.length !==0 &&
                                            practiceTest.sections.map((section)=>{
                                                return (    
                                                    <>
                                                        <div className='tracker' key={section.section}>
                                                            <div className='tracker-section'>Section {section.section}</div>
                                                            <div className='tracker-no-of-ques'>
                                                                {(paper && paper.length !== 0) ? (
                                                                    <h5><strong>Total Questions</strong> - {paper.filter((element)=> (element.sectionName === section.section)).length}</h5>
                                                                ): (
                                                                    <h5><strong>Total Questions</strong> - 0</h5>
                                                                )}
                                                            </div>
                                                            <div className='tracker-balls'>
                                                                <div className='ball-cont'>
                                                                    <div style={{backgroundColor:'rgb(102, 183, 49)'}} className='ball'></div>
                                                                    <p>{attempt.filter((ele)=> (ele.section === section.section)).length}</p>
                                                                </div>
                                                                <div className='ball-cont'>
                                                                    <div style={{backgroundColor:'rgb(239, 114, 116)'}} className='ball'></div>
                                                                    <p>{paper.filter((element)=> (element.sectionName === section.section)).length - attempt.filter((ele)=> (ele.section === section.section)).length}</p>
                                                                </div>
                                                                <div className='ball-cont'>
                                                                    <div style={{backgroundColor:'#8E70C8'}} className='ball'></div>
                                                                    <p>{markReview.filter((element)=> (element.section === section.section)).length}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='tracker-reviews'>
                                                            {(markReview.filter((element)=> (element.section === section.section)).length !== 0) && (
                                                                <h6><strong>Marked For Review</strong> - </h6>
                                                            )}
                                                            <div className='tracker-review-inner'>
                                                                {markReview.filter((element)=> (element.section === section.section)).map((ele,i)=>(
                                                                    <div key={i}>{ele.qno}</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <button className="submitBtn" onClick={(e)=>{e.preventDefault();submitHandler()}}>Submit Test</button>
                        </div>
                    </main>
                </>
            )}
            
        </div>
    )
}

export default PracticeTestScreen;
