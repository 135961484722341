import {
    GET_PRACTICE_TESTSERIES_REQUEST,
    GET_PRACTICE_TESTSERIES_SUCCESS,
    GET_PRACTICE_TESTSERIES_FAIL,
    GET_LIVE_TESTSERIES_REQUEST,
    GET_LIVE_TESTSERIES_SUCCESS,
    GET_LIVE_TESTSERIES_FAIL,
    GET_PRACTICE_QUESTION_PAPER_REQUEST,
    GET_PRACTICE_QUESTION_PAPER_SUCCESS,
    GET_PRACTICE_QUESTION_PAPER_FAIL,
    POST_SUBMIT_PRACTICE_TEST_REQUEST,
    POST_SUBMIT_PRACTICE_TEST_SUCCESS,
    POST_SUBMIT_PRACTICE_TEST_FAIL,
    GET_PRACTICE_TEST_ANALYTICS_REQUEST,
    GET_PRACTICE_TEST_ANALYTICS_SUCCESS,
    GET_PRACTICE_TEST_ANALYTICS_FAIL,
    GET_PRACTICE_QPAPER_REQUEST,
    GET_PRACTICE_QPAPER_SUCCESS,
    GET_PRACTICE_QPAPER_FAIL,
    GET_PRACTICE_ANSWERSHEET_REQUEST,
    GET_PRACTICE_ANSWERSHEET_SUCCESS,
    GET_PRACTICE_ANSWERSHEET_FAIL,
    GET_LIVE_TESTSERIES_DETAILS_REQUEST,
    GET_LIVE_TESTSERIES_DETAILS_SUCCESS,
    GET_LIVE_TESTSERIES_DETAILS_FAIL,
    GET_LIVE_TEST_ANALYTICS_REQUEST,
    GET_LIVE_TEST_ANALYTICS_SUCCESS,
    GET_LIVE_TEST_ANALYTICS_FAIL,
    GET_LIVE_QPAPER_REQUEST,
    GET_LIVE_QPAPER_SUCCESS,
    GET_LIVE_QPAPER_FAIL,
    POST_SUBMIT_LIVE_TEST_REQUEST,
    POST_SUBMIT_LIVE_TEST_SUCCESS,
    POST_SUBMIT_LIVE_TEST_FAIL
} from '../constants/EnrolledTestSeries';

export const getAllPracticeTestReducer = (state = { PracticeTest: [] }, action) => {
    switch (action.type) {
        case GET_PRACTICE_TESTSERIES_REQUEST:
            return { loading: true, PracticeTest: [] }
        case GET_PRACTICE_TESTSERIES_SUCCESS:
            return { loading: false, PracticeTest: action.payload }
        case GET_PRACTICE_TESTSERIES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getAllLiveTestReducer = (state = { LiveTest: [] }, action) => {
    switch (action.type) {
        case GET_LIVE_TESTSERIES_REQUEST:
            return { loading: true, LiveTest: [] }
        case GET_LIVE_TESTSERIES_SUCCESS:
            return { loading: false, LiveTest: action.payload }
        case GET_LIVE_TESTSERIES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPracticePaperReducer = (state = { paper: [] }, action) => {
    switch (action.type) {
        case GET_PRACTICE_QUESTION_PAPER_REQUEST:
            return { loading: true, paper: [] }
        case GET_PRACTICE_QUESTION_PAPER_SUCCESS:
            return { loading: false, paper: action.payload }
        case GET_PRACTICE_QUESTION_PAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PostPracticeTestReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SUBMIT_PRACTICE_TEST_REQUEST:
            return { loading: true }
        case POST_SUBMIT_PRACTICE_TEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_SUBMIT_PRACTICE_TEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPracticeAnalyticsReducer = (state = {analytics:[]}, action) => {
    switch (action.type) {
        case GET_PRACTICE_TEST_ANALYTICS_REQUEST:
            return { loading: true ,analytics:[]}
        case GET_PRACTICE_TEST_ANALYTICS_SUCCESS:
            return { loading: false, analytics: action.payload }
        case GET_PRACTICE_TEST_ANALYTICS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPracticeAnalyticsPaperReducer = (state = { Paper: [] }, action) => {
    switch (action.type) {
        case GET_PRACTICE_QPAPER_REQUEST:
            return { loading: true, Paper: [] }
        case GET_PRACTICE_QPAPER_SUCCESS:
            return { loading: false, Paper: action.payload }
        case GET_PRACTICE_QPAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPracticeAnswerSheetReducer = (state = {answerSheet:{}}, action) => {
    switch (action.type) {
        case GET_PRACTICE_ANSWERSHEET_REQUEST:
            return { loading: true , answerSheet:{}}
        case GET_PRACTICE_ANSWERSHEET_SUCCESS:
            return { loading: false, answerSheet: action.payload }
        case GET_PRACTICE_ANSWERSHEET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getLiveTestByIdReducer = (state = { liveTest: {} }, action) => {
    switch (action.type) {
        case GET_LIVE_TESTSERIES_DETAILS_REQUEST:
            return { loading: true, ...state }
        case GET_LIVE_TESTSERIES_DETAILS_SUCCESS:
            return { loading: false, liveTest: action.payload }
        case GET_LIVE_TESTSERIES_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getLiveAnalyticsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LIVE_TEST_ANALYTICS_REQUEST:
            return { loading: true }
        case GET_LIVE_TEST_ANALYTICS_SUCCESS:
            return { loading: false, analytics: action.payload }
        case GET_LIVE_TEST_ANALYTICS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getLivePaperReducer = (state = { paper: [] }, action) => {
    switch (action.type) {
        case GET_LIVE_QPAPER_REQUEST:
            return { loading: true, paper: [] }
        case GET_LIVE_QPAPER_SUCCESS:
            return { loading: false, paper: action.payload }
        case GET_LIVE_QPAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PostLiveTestReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SUBMIT_LIVE_TEST_REQUEST:
            return { loading: true }
        case POST_SUBMIT_LIVE_TEST_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_SUBMIT_LIVE_TEST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}