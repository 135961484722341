import * as React from "react"

export function HomeIcon(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 15h12V0H0v15zm0 12h12v-9H0v9zm15 0h12V12H15v15zm0-27v9h12V0H15z"
        fill="#fff"
      />
    </svg>
  )
}

export function TestIcon(props) {
  return (
    <svg width={24} height={30} viewBox="0 0 78 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_6_50)">
        <path d="M4 10C4 4.47718 8.47787 2.81287e-05 14.0007 2.73082e-05C32.6888 2.45319e-05 45.9247 -1.57156e-05 63.9964 6.83233e-06C69.5193 1.37231e-05 74 4.47716 74 10V82C74 87.5229 69.5228 92 64 92H14C8.47715 92 4 87.5229 4 82V10Z" fill="white" />
      </g>
      <line x1="20.5" y1="24.5" x2="36.5" y2="24.5" stroke="#0669F8" strokeWidth="3" strokeLinecap="round" />
      <line y1="-1.5" x2="12.9113" y2="-1.5" transform="matrix(0.756354 -0.654163 0.599778 0.800167 51.4246 30.4461)" stroke="#0669F8" strokeWidth="3" />
      <line y1="-1.5" x2="7.40997" y2="-1.5" transform="matrix(0.597105 0.802163 -0.758735 0.6514 47 24.5021)" stroke="#0669F8" strokeWidth="3" />

      <line y1="-1.5" x2="12.9113" y2="-1.5" transform="matrix(0.756354 -0.654163 0.599778 0.800167 51.4246 50.4461)" stroke="#0669F8" strokeWidth="3" />
      <line y1="-1.5" x2="7.40997" y2="-1.5" transform="matrix(0.597105 0.802163 -0.758735 0.6514 47 44.5021)" stroke="#0669F8" strokeWidth="3" />
      <line x1="20.5" y1="45" x2="36.5" y2="45" stroke="#0669F8" strokeWidth="3" strokeLinecap="round" />

      <line x1="20.5" y1="65.5" x2="36.5" y2="65.5" stroke="#0669F8" strokeWidth="3" strokeLinecap="round" />
      <line y1="-1.5" x2="12.9113" y2="-1.5" transform="matrix(0.756354 -0.654163 0.599778 0.800167 51.4246 70.4461)" stroke="#0669F8" strokeWidth="3" />
      <line y1="-1.5" x2="7.40997" y2="-1.5" transform="matrix(0.597105 0.802163 -0.758735 0.6514 47 64.5021)" stroke="#0669F8" strokeWidth="3" />
      <defs>
        <filter id="filter0_d_6_50" x="0" y="0" width="78" height="100" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6_50" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6_50" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export function CourseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={26}
      viewBox="0 0 24 30"
      {...props}
    >
      <g data-name="Group 165">
        <g data-name="Group 164">
          <path
            data-name="Icon material-class"
            d="M21 0H3a3.009 3.009 0 00-3 3v24a3.009 3.009 0 003 3h18a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zM3 3h7.5v12l-3.75-2.25L3 15z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export function ExploreIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        data-name="Icon material-explore"
        d="M15 13.35A1.65 1.65 0 1016.65 15 1.645 1.645 0 0015 13.35zM15 0a15 15 0 1015 15A15.005 15.005 0 0015 0zm3.285 18.285L6 24l5.715-12.285L24 6z"
        fill="#fff"
      />
    </svg>
  )
}

export function ResourceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={30}
      viewBox="0 0 31.5 36"
      {...props}
    >
      <g data-name="Group 166">
        <path
          data-name="Icon awesome-book"
          d="M31.5 25.313V1.688A1.683 1.683 0 0029.813 0H6.75A6.752 6.752 0 000 6.75v22.5A6.752 6.752 0 006.75 36h23.063a1.683 1.683 0 001.687-1.687v-1.125a1.7 1.7 0 00-.626-1.315 15.68 15.68 0 010-5.252 1.676 1.676 0 00.626-1.308zM9 9.422A.423.423 0 019.422 9h14.906a.423.423 0 01.422.422v1.406a.423.423 0 01-.422.422H9.422A.423.423 0 019 10.828zm0 4.5a.423.423 0 01.422-.422h14.906a.423.423 0 01.422.422v1.406a.423.423 0 01-.422.422H9.422A.423.423 0 019 15.328zM26.817 31.5H6.75a2.25 2.25 0 010-4.5h20.067a25.313 25.313 0 000 4.5z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export function MoreIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={21}
      viewBox="0 0 30 21"
      {...props}
    >
      <g data-name="Group 167">
        <g
          data-name="Icon feather-menu"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
        >
          <path data-name="Path 333" d="M1.5 10.5h27" />
          <path data-name="Path 334" d="M1.5 1.5h27" />
          <path data-name="Path 335" d="M1.5 19.5h27" />
        </g>
      </g>
    </svg>
  )
}

export function HelpIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={30}
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        data-name="Icon material-help"
        d="M15 0a15 15 0 1015 15A15.005 15.005 0 0015 0zm1.5 25.5h-3v-3h3zm3.1-11.625l-1.35 1.38A5.106 5.106 0 0016.5 19.5h-3v-.75a6.038 6.038 0 011.755-4.245l1.86-1.89A2.933 2.933 0 0018 10.5a3 3 0 00-6 0H9a6 6 0 1112 0 4.773 4.773 0 01-1.4 3.375z"
        fill="#fff"
      />
    </svg>
  )
}

export function Prebepre(props){
    return(
        <svg width={618} height={385} viewBox="0 0 618 385" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="310.5" cy="199.5" r="148.5" stroke="white" strokeWidth="8"/>
        <path d="M171.69 142L4 169.418V272.113L211 310" stroke="white" strokeWidth="8"/>
        <path d="M446.5 139L614 170.5V273.5L411 310.5" stroke="white" strokeWidth="8"/>
        <path d="M3 172H164" stroke="white" strokeWidth="8"/>
        <path d="M4 267H179.5" stroke="white" strokeWidth="8"/>
        <path d="M614 172H458M614 271H443.5" stroke="white" strokeWidth="8"/>
        <path d="M39.3037 228.221H27.6523V217.37H39.3037C40.665 217.37 41.7594 217.036 42.5869 216.369C43.4411 215.702 44.055 214.781 44.4287 213.606C44.8291 212.405 45.0293 211.057 45.0293 209.562C45.0293 207.961 44.8291 206.479 44.4287 205.118C44.055 203.757 43.4411 202.662 42.5869 201.835C41.7594 200.981 40.665 200.554 39.3037 200.554H32.6572V248H18.9639V189.703H39.3037C43.3343 189.703 46.8044 190.557 49.7139 192.266C52.6501 193.974 54.9056 196.323 56.4805 199.312C58.082 202.275 58.8828 205.665 58.8828 209.482C58.8828 213.246 58.082 216.529 56.4805 219.332C54.9056 222.135 52.6501 224.324 49.7139 225.898C46.8044 227.447 43.3343 228.221 39.3037 228.221ZM66.1699 189.703H86.1094C90.0065 189.703 93.3831 190.37 96.2393 191.705C99.0954 193.04 101.284 195.015 102.806 197.631C104.354 200.247 105.128 203.477 105.128 207.32C105.128 210.657 104.701 213.446 103.847 215.688C103.019 217.931 101.845 219.786 100.323 221.254C98.8018 222.695 97.0133 223.883 94.958 224.817L90.5137 227.42H74.8584L74.7783 216.569H85.7891C87.0436 216.569 88.0846 216.249 88.9121 215.608C89.7396 214.968 90.3535 214.06 90.7539 212.886C91.181 211.685 91.3945 210.257 91.3945 208.602C91.3945 206.92 91.1943 205.479 90.7939 204.277C90.3936 203.076 89.8063 202.155 89.0322 201.515C88.2848 200.874 87.3105 200.554 86.1094 200.554H79.8633V248H66.1699V189.703ZM91.9951 248L81.665 222.215L96.1592 222.135L106.689 247.399V248H91.9951ZM147.089 237.189V248H121.584V237.189H147.089ZM126.349 189.703V248H112.655V189.703H126.349ZM143.766 212.846V223.296H121.584V212.846H143.766ZM147.209 189.703V200.554H121.584V189.703H147.209Z" fill="white"/>
        <path d="M493.304 230.221H481.652V219.37H493.304C494.665 219.37 495.759 219.036 496.587 218.369C497.441 217.702 498.055 216.781 498.429 215.606C498.829 214.405 499.029 213.057 499.029 211.562C499.029 209.961 498.829 208.479 498.429 207.118C498.055 205.757 497.441 204.662 496.587 203.835C495.759 202.981 494.665 202.554 493.304 202.554H486.657V250H472.964V191.703H493.304C497.334 191.703 500.804 192.557 503.714 194.266C506.65 195.974 508.906 198.323 510.48 201.312C512.082 204.275 512.883 207.665 512.883 211.482C512.883 215.246 512.082 218.529 510.48 221.332C508.906 224.135 506.65 226.324 503.714 227.898C500.804 229.447 497.334 230.221 493.304 230.221ZM520.17 191.703H540.109C544.007 191.703 547.383 192.37 550.239 193.705C553.095 195.04 555.284 197.015 556.806 199.631C558.354 202.247 559.128 205.477 559.128 209.32C559.128 212.657 558.701 215.446 557.847 217.688C557.019 219.931 555.845 221.786 554.323 223.254C552.802 224.695 551.013 225.883 548.958 226.817L544.514 229.42H528.858L528.778 218.569H539.789C541.044 218.569 542.085 218.249 542.912 217.608C543.74 216.968 544.354 216.06 544.754 214.886C545.181 213.685 545.395 212.257 545.395 210.602C545.395 208.92 545.194 207.479 544.794 206.277C544.394 205.076 543.806 204.155 543.032 203.515C542.285 202.874 541.311 202.554 540.109 202.554H533.863V250H520.17V191.703ZM545.995 250L535.665 224.215L550.159 224.135L560.689 249.399V250H545.995ZM601.089 239.189V250H575.584V239.189H601.089ZM580.349 191.703V250H566.655V191.703H580.349ZM597.766 214.846V225.296H575.584V214.846H597.766ZM601.209 191.703V202.554H575.584V191.703H601.209Z" fill="white"/>
        <path d="M259.484 179.953H217.016L216.734 147.609H251.047C256.766 147.609 261.266 146.766 264.547 145.078C267.828 143.297 270.172 140.719 271.578 137.344C273.078 133.969 273.828 129.75 273.828 124.688C273.828 118.875 273.078 114.187 271.578 110.625C270.078 107.063 267.688 104.484 264.406 102.891C261.219 101.203 257.047 100.359 251.891 100.359H235.016V267H186.922V62.25H251.891C262.859 62.25 272.656 63.4219 281.281 65.7656C289.906 68.0156 297.219 71.4844 303.219 76.1719C309.312 80.8594 313.953 86.7656 317.141 93.8906C320.328 100.922 321.922 109.219 321.922 118.781C321.922 127.125 320.375 134.953 317.281 142.266C314.281 149.578 309.078 155.531 301.672 160.125C294.266 164.625 284.094 166.969 271.156 167.156L259.484 179.953ZM257.797 267H205.062L221.656 229.031H257.797C262.672 229.031 266.469 228 269.188 225.938C271.906 223.781 273.828 220.969 274.953 217.5C276.078 213.938 276.641 210 276.641 205.688C276.641 200.438 276.078 195.891 274.953 192.047C273.922 188.203 272.188 185.25 269.75 183.188C267.312 181.031 263.891 179.953 259.484 179.953H223.344L223.625 147.609H265.391L276.922 160.547C289.203 159.984 298.812 161.906 305.75 166.312C312.688 170.719 317.562 176.531 320.375 183.75C323.188 190.969 324.594 198.516 324.594 206.391C324.594 219.797 322.109 231 317.141 240C312.266 249 304.859 255.75 294.922 260.25C284.984 264.75 272.609 267 257.797 267Z" fill="white"/>
        <path d="M441.688 233.984V270.547H317.094L314.281 241.578L380.938 109.25H417.078L379.25 175.766L357.734 233.984H441.688ZM426.781 109.25V314H380.516V109.25H426.781Z" fill="white"/>
        </svg>
    )
}

export function Books(props){
  return(
    <svg width={620} style={{marginLeft:'0px'}} height={470} viewBox="-100 0 620 470" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="path-1-inside-1_105_8" fill="white">
    <path d="M0 75C0 71.6863 2.68629 69 6 69H138V512H6C2.68629 512 0 509.314 0 506V75Z"/>
    </mask>
    <path d="M0 75C0 71.6863 2.68629 69 6 69H138V512H6C2.68629 512 0 509.314 0 506V75Z" stroke="#FFFEFE" strokeWidth="30" mask="url(#path-1-inside-1_105_8)"/>
    <rect x="8" y="84" width="122" height="38" fill="white"/>
    <rect x="12" y="460" width="114" height="38" fill="white"/>
    <rect x="12" y="141" width="114" height="299" fill="white"/>
    <mask id="path-5-inside-2_105_8" fill="white">
    <path d="M284 6C284 2.68629 286.686 0 290 0H401C404.314 0 407 2.68629 407 6V506C407 509.314 404.314 512 401 512H284V6Z"/>
    </mask>
    <path d="M284 6C284 2.68629 286.686 0 290 0H401C404.314 0 407 2.68629 407 6V506C407 509.314 404.314 512 401 512H284V6Z" stroke="white" strokeWidth="30" mask="url(#path-5-inside-2_105_8)"/>
    <rect x="290" y="11" width="111" height="33" fill="white"/>
    <rect x="290" y="465" width="111" height="33" fill="white"/>
    <rect x="290" y="411" width="111" height="33" fill="white"/>
    <rect x="296" y="65" width="111" height="33" fill="white"/>
    <rect x="290" y="118" width="111" height="273" fill="white"/>
    <mask id="path-11-inside-3_105_8" fill="white">
    <path d="M138 39C138 35.6863 140.686 33 144 33H284.068V512.452H138V39Z"/>
    </mask>
    <path d="M138 39C138 35.6863 140.686 33 144 33H284.068V512.452H138V39Z" stroke="white" strokeWidth="30" mask="url(#path-11-inside-3_105_8)"/>
    <rect x="151" y="110" width="120" height="319" fill="white"/>
    <rect x="150.534" y="43.5352" width="120.136" height="46.3127" fill="white"/>
    <rect x="151" y="456" width="128.097" height="46.3127" fill="white"/>
    </svg>
  )
}



