import React from 'react'
import './HomeLoading.scss'
import { FaPowerOff } from 'react-icons/fa'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const HomeLoading = ({handelSetLogout}) => {
    const Logout = (e) => {
        e.preventDefault();
        handelSetLogout(true);
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        swipeToSlide: false,
        centerPadding: '60px',
        responsive: [
          {
            breakpoint: 1306,
            settings: {
              dots: false,
              infinite: false,
              speed: 500,
              slidesToShow: 3,
              slidesToScroll: 3,
              swipeToSlide: false,
            }
          },
          {
            breakpoint: 1126,
            settings: {
              dots: false,
              infinite: false,
              speed: 500,
              slidesToShow: 2,
              slidesToScroll: 2,
              swipeToSlide: false,
            }
          },
          {
            breakpoint: 518,
            settings: {
              dots: false,
              infinite: false,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              swipeToSlide: false,
            }
          }
        ]
      };
    return (
        <>
            <div className='HomeLoading'>
                <div className="pt-4 pr-5">
                    <div style={{ width: '100%' }} className="d-flex align-items-center justify-content-between">
                        <div className='ml-5 mr-4'>
                            <div className='text skeleton' style={{ height: "20px", width:"220px" }}></div>
                        </div>
                        <div>
                            <button className='logout-btn' onClick={Logout}><div className='logout'><FaPowerOff color='white'/></div> Logout</button> 
                        </div>
                    </div>
                </div>
                <div className='container mt-4'>
                    <div className="schedule-card">
                        <div style={{ width: '100%' }} className='d-flex align-items-center justify-content-between'>
                            <div>
                                <div className='text skeleton' style={{ height: ".8rem", width:"300px" }}></div>
                                <div className='text skeleton' style={{ height: ".8rem", width:"300px" }}></div>
                                <div className='text skeleton' style={{ height: ".8rem", width:"250px" }}></div>
                                <div className='text skeleton' style={{ marginTop:'.6rem', height: "10px", width:"" }}></div>
                            </div>
                            <div className='skeleton img' />
                        </div>
                    </div>
                </div>
            </div>
            <section className='px-5 my-5'>
                <div className="d-flex align-items-center justify-content-between">
                    <p style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                        Top Courses
                    </p>
                    <Link to='/explore'>
                        <p style={{ fontSize: 18, fontWeight: '500', color: ' #012A5D', textDecoration: 'underline', cursor: 'pointer' }}>
                            view all
                        </p>
                    </Link>
                </div>
                <Slider {...settings}>
                    <div className='Slidecard1'>
                        <div>
                            <div className="img skeleton" style={{borderRadius:"50%", width:"120px" , height:"120px", marginLeft:"auto", marginRight:"auto", marginBottom:"20px"}}></div> 
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"250px" }}></div>
                        </div>
                    </div>
                    <div className='Slidecard1'>
                        <div>
                            <div className="img skeleton" style={{borderRadius:"50%", width:"120px" , height:"120px", marginLeft:"auto", marginRight:"auto", marginBottom:"20px"}}></div> 
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"250px" }}></div>
                        </div>
                    </div>
                    <div className='Slidecard1'>
                        <div>
                            <div className="img skeleton" style={{borderRadius:"50%", width:"120px" , height:"120px", marginLeft:"auto", marginRight:"auto", marginBottom:"20px"}}></div> 
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"250px" }}></div>
                        </div>
                    </div>
                    <div className='Slidecard1'>
                        <div>
                            <div className="img skeleton" style={{borderRadius:"50%", width:"120px" , height:"120px", marginLeft:"auto", marginRight:"auto", marginBottom:"20px"}}></div> 
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className='text skeleton' style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"300px" }}></div>
                            <div className="text skeleton" style={{ borderRadius:".125rem", marginBottom:".25rem", height: ".8rem", width:"250px" }}></div>
                        </div>
                    </div>
                </Slider>
        </section>
        </>
    )
}

export default HomeLoading
