import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Close from '@material-ui/icons/Close';

export default function AlertSuccessExample({ data }) {

    const [show, setShow] = useState(true)

    const hide = {
        display: 'none',
        visibility: 'hidden'
    }

    const showError = { position: "fixed", bottom: '10px', right: '10px', zIndex: '1' }


    return (
        <div style={show ? showError : hide}>
            <Alert variant="success" style={{ position: "absolute", bottom: '10px', right: '10px', zIndex: '1', width: "max-content" }} >
                <div className="d-flex justify-content-end">
                    <button style={{ background: 'none', border: 'none', color: "#155724" }} onClick={() => setShow(false)} ><Close /></button>
                </div>
                <Alert.Heading>Success message!</Alert.Heading>
                <p>{data}</p>
            </Alert>
        </div>
    );
}