import {
    GET_ITW_DETAILS_REQUEST,
    GET_ITW_DETAILS_SUCCESS,
    GET_ITW_DETAILS_FAIL,
    GET_ITW_QUESTION_PAPER_SUCCESS,
    GET_ITW_QUESTION_PAPER_REQUEST,
    GET_ITW_QUESTION_PAPER_FAIL,
    POST_SUBMIT_ITW_REQUEST,
    POST_SUBMIT_ITW_SUCCESS,
    POST_SUBMIT_ITW_FAIL,
    GET_ENROLL_ITW_REQUEST,
    GET_ENROLL_ITW_SUCCESS,
    GET_ENROLL_ITW_FAIL,
    GET_ANALYTICS_ITW_REQUEST,
    GET_ANALYTICS_ITW_SUCCESS,
    GET_ANALYTICS_ITW_FAIL,
    GET_ANSWER_SHEET_ITW_REQUEST,
    GET_ANSWER_SHEET_ITW_SUCCESS,
    GET_ANSWER_SHEET_ITW_FAIL
} from '../constants/IssuesThisWeekConstants';

export const getITWByIdReducer = (state = { itw: {} }, action) => {
    switch (action.type) {
        case GET_ITW_DETAILS_REQUEST:
            return { loading: true, ...state }
        case GET_ITW_DETAILS_SUCCESS:
            return { loading: false, itw: action.payload }
        case GET_ITW_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getITWPaperReducer = (state = { paper: [] }, action) => {
    switch (action.type) {
        case GET_ITW_QUESTION_PAPER_REQUEST:
            return { loading: true, paper: [] }
        case GET_ITW_QUESTION_PAPER_SUCCESS:
            return { loading: false, paper: action.payload }
        case GET_ITW_QUESTION_PAPER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PostITWExamReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_SUBMIT_ITW_REQUEST:
            return { loading: true }
        case POST_SUBMIT_ITW_SUCCESS:
            return { loading: false, message: action.payload }
        case POST_SUBMIT_ITW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getEnrollITWReducer = (state = { itw: [] }, action) => {
    switch (action.type) {
        case GET_ENROLL_ITW_REQUEST:
            return { loading: true, itw: [] }
        case GET_ENROLL_ITW_SUCCESS:
            return { loading: false, itw: action.payload }
        case GET_ENROLL_ITW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getItwAnalyticsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ANALYTICS_ITW_REQUEST:
            return { loading: true }
        case GET_ANALYTICS_ITW_SUCCESS:
            return { loading: false, analytics: action.payload }
        case GET_ANALYTICS_ITW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getItwAnswerSheetByIdReducer = (state = { answerSheet: {} }, action) => {
    switch (action.type) {
        case GET_ANSWER_SHEET_ITW_REQUEST:
            return { loading: true, answerSheet: {} }
        case GET_ANSWER_SHEET_ITW_SUCCESS:
            return { loading: false, answerSheet: action.payload }
        case GET_ANSWER_SHEET_ITW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}