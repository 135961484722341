import React,   {
    useEffect,
    useState, 
    useRef
} from 'react'

import {
    useSelector, 
    useDispatch
} from 'react-redux';

//Components
import Loader from '../../Components/Loader';
import AlertDismissibleExample from '../../Components/Error/error';
import AlertSuccessExample from '../../Components/success/success';
import Donut from '../../Components/Graphs/Donut';
import { Col, Row } from 'react-bootstrap';
import DescriptiveQuestion from '../../Components/question/DescriptiveQuestion';
import Pdf from '../../Components/PdfViewer/Pdf';
import Timer from '../../Components/Timer/Timer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//assets
import noImage from '../../assets/images/otp.png';
import CloseIcon from '@mui/icons-material/Close';
// import {CgDanger} from 'react-icons/cg';

//actions
import { 
    getAskToEvaluateQPaper,
    getAskToEvaluateByID,
    postSubmitAskToEvaluate
} from '../../actions/askToEvaluateActions';

const AskToEvaluateTestScreen = ({match}) => {
    const dispatch = useDispatch();
    const DescriptiveQuestionFunc = useRef(null)

    const [attempt, setAttempt] = useState([]);
    const [markReview, setMarkReview] = useState([]);
    const [question,setQuestions] = useState([]);

    const [showPreview, setShowPreview] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [Type, setType] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [deleteFile, setDeleteFile] = useState(false);

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin;

    const getATEDetails = useSelector(state => state.getATEDetails)
    const { loading, error, askToEvaluate } = getATEDetails

    const getATEPaper = useSelector(state => state.getATEPaper)
    const {loading:loadingPaper , error:errorPaper, Paper} = getATEPaper;

    const PostAskToEvaluate = useSelector(state => state.PostAskToEvaluate)
    const {loading:loadingSubmit , error:errorSubmit, message} = PostAskToEvaluate;

    useEffect(()=>{
        if(!userInfo){
            document.location.href = '/login';
        }
        dispatch(getAskToEvaluateByID(match.params.id))
        dispatch(getAskToEvaluateQPaper(match.params.id))
        setAttempt(JSON.parse(localStorage.getItem(`${match.params.id}_attempt`)) || [])
        setMarkReview(JSON.parse(localStorage.getItem(`${match.params.id}_markForReview`)) || [])
    },[userInfo, dispatch, match])

    useEffect(()=>{
        if(message){
            setTimeout(()=>{
                localStorage.removeItem(`${match.params.id}_answerSheet`);
                localStorage.removeItem(`${match.params.id}_chance`);
                localStorage.removeItem(`${match.params.id}_markForReview`);
                localStorage.removeItem(`${match.params.id}_attempt`);
                localStorage.removeItem(`${match.params.id}_fullScreen`);
                localStorage.removeItem(`${match.params.id}_time`);
                window.close()
            },1350);
        }
    },[message,match])

    useEffect(()=>{
        if(askToEvaluate && askToEvaluate.sections && Paper.length !== 0){
            setQuestions(Paper.filter((element)=> (element.sectionName.toLowerCase() === askToEvaluate.sections[0].section.toLowerCase())))
            if(document.getElementsByClassName('selected-section').length > 0)
                document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
            document.getElementById(`askToEvaluate_${askToEvaluate.sections[0].section.toLowerCase()}`).classList.add('selected-section')
        }
    },[Paper,askToEvaluate])

    const selectSection = (section) => {
        if(document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
        document.getElementById(`askToEvaluate_${section.toLowerCase()}`).classList.add('selected-section')

        const test = Paper.filter((element)=> (element.sectionName.toLowerCase() === section.toLowerCase()))
        setQuestions(test)
    }

    const submitHandler = () =>{
        const answer = JSON.parse(localStorage.getItem(`${match.params.id}_answerSheet`));
        dispatch(postSubmitAskToEvaluate(match.params.id,answer));
    }

    const disableFunction = (e) => {
        e.preventDefault()  
        return false;
    }

    return (
        <div 
            className="test-Container"
            onCopy = {disableFunction}
            onContextMenu = {disableFunction}
            onPaste = {disableFunction}
            onCut = {disableFunction}
        >
            {(loading || loadingPaper || loadingSubmit) && <Loader/>}
            {error && <AlertDismissibleExample data={error}/>}
            {errorPaper && <AlertDismissibleExample data={errorPaper}/>}
            {errorSubmit && <AlertDismissibleExample data={errorSubmit}/>}
            {message && <AlertSuccessExample data={message} />}

            {
                (Paper.length > 0 && askToEvaluate) && (
                    <>
                        <main className='px-5 py-5'>
                            <ToastContainer 
                                hideProgressBar={false}
                                newestOnTop={true}
                                autoClose={6000}
                                position="bottom-right"
                            />
                            <Timer id={match.params.id} type={'askToEvaluate'} />
                            <div>
                                <Donut
                                    attempted={attempt.length}
                                    notAttempted={Paper.length - attempt.length}
                                    MarkForReview={markReview.length} 
                                />
                            </div>
                            <div className='Container'>
                                <div className='section-btns'>
                                    { 
                                        askToEvaluate.sections && askToEvaluate.sections.length !==0 &&
                                        askToEvaluate.sections.map((section)=>
                                        (
                                            <button 
                                                id={`askToEvaluate_${section.section.toLowerCase()}`} 
                                                key={section.section.toLowerCase()}  
                                                onClick={(e)=>{ e.preventDefault(); selectSection(section.section)}} 
                                            >
                                                Section {section.section}
                                            </button>
                                        ))
                                    }
                                </div>
                                <Row>
                                    <Col md={8} className='questions-Container'>
                                        <div>
                                            {
                                                question.length > 0 ? <>
                                                    {question.map((data, i)=>(
                                                        <DescriptiveQuestion 
                                                            key={data.id}
                                                            data={data} 
                                                            qno={i+1}
                                                            testId = {askToEvaluate.id}
                                                            totalMarkForReview = {(result)=>setMarkReview(result)}
                                                            totalAttempt = {(result) => { setAttempt(result)}}
                                                            showPreview = {(result) => setShowPreview(result)}
                                                            PreviewUrl = {(result) => setPreviewUrl(result)}
                                                            fileType = {(result) => setType(result)}
                                                            questionId = {(result) => setQuestionId(result)}
                                                            deleteFile = {(result) => setDeleteFile(result)}
                                                            DescriptiveQuestionFunc = {DescriptiveQuestionFunc}
                                                            error = {(result) => toast(result)}
                                                        />
                                                    ))}
                                                </> :
                                                <>
                                                    <div className='questions-notfound'>
                                                        <img src={noImage} alt="" />
                                                        <h3>No questions found</h3>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                    <Col md={4} className='question-tracking'>
                                        <div className='tracker-container'>
                                            {
                                                askToEvaluate.sections && askToEvaluate.sections.length !==0 &&
                                                askToEvaluate.sections.map((section)=>{
                                                    return (
                                                        <div key={section.section}>
                                                            <div className='tracker'>
                                                                <div className='tracker-section'>
                                                                    Section {section.section}
                                                                </div>
                                                                <div className='tracker-no-of-ques'>
                                                                    <h5>
                                                                        <strong>Total Questions</strong> - 
                                                                        {Paper.filter((element)=> (element.sectionName.toLowerCase() === section.section.toLowerCase())).length}
                                                                    </h5>
                                                                </div>
                                                                <div className='tracker-balls'>
                                                                    <div className='ball-cont'>
                                                                        <div style={{backgroundColor:'rgb(102, 183, 49)'}} className='ball'></div>
                                                                        <p>{attempt.filter((ele)=> (ele.section.toLowerCase() === section.section.toLowerCase())).length}</p>
                                                                    </div>
                                                                    <div className='ball-cont'>
                                                                        <div style={{backgroundColor:'rgb(239, 114, 116)'}} className='ball'></div>
                                                                        <p>{Paper.filter((element)=> (element.sectionName.toLowerCase() === section.section.toLowerCase())).length - attempt.filter((ele)=> (ele.section.toLowerCase() === section.section.toLowerCase())).length}</p>
                                                                    </div>
                                                                    <div className='ball-cont'>
                                                                        <div style={{backgroundColor:'#8E70C8'}} className='ball'></div>
                                                                        <p>{markReview.filter((element)=> (element.section.toLowerCase() === section.section.toLowerCase())).length}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tracker-reviews'>
                                                                {(markReview.filter((element)=> (element.section.toLowerCase() === section.section.toLowerCase())).length !== 0) && (
                                                                    <h6><strong>Marked For Review</strong> - </h6>
                                                                )}
                                                                <div className='tracker-review-inner'>
                                                                    {markReview.filter((element)=> (element.section.toLowerCase() === section.section.toLowerCase())).map((ele, i)=>(
                                                                        <div key={i}>{ele.qno}</div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <button className="submitBtn" onClick={(e)=>{e.preventDefault();submitHandler()}}>Submit Test</button>
                            </div>
                        </main>
                        {
                            showPreview && <div className='file-preview-container'>
                                <div className='file-preview-card'>
                                    <div className='file'>
                                        {
                                            Type === 'application/pdf' ? <Pdf url={previewUrl} /> : 
                                            <img src={previewUrl} alt='preview' />
                                        }
                                    </div>
                                    <button className='close_btn' onClick={e => setShowPreview(false)}>
                                        <CloseIcon/>
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            deleteFile && <div className='file-delete-container'>
                                <div className='file-delete-card'>
                                    <p>Do you want to delete the file?</p>
                                    <div>
                                        <button 
                                            onClick={() => setDeleteFile(false)} 
                                            style={{background:'rgb(6, 105, 248)'}}
                                        >
                                            No
                                        </button>
                                        <button 
                                            onClick={()=>{
                                                DescriptiveQuestionFunc.current(previewUrl, questionId)
                                            }}
                                            style={{background:'rgba(255, 0, 0, 0.8)'}}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        }
                    </>
                )
            }
        </div>
    )
}

export default AskToEvaluateTestScreen