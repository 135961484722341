export const GET_ITW_DETAILS_REQUEST = 'GET_ITW_DETAILS_REQUEST';
export const GET_ITW_DETAILS_SUCCESS = 'GET_ITW_DETAILS_SUCCESS';
export const GET_ITW_DETAILS_FAIL = 'GET_ITW_DETAILS_FAIL';

export const GET_ITW_QUESTION_PAPER_REQUEST = 'GET_ITW_QUESTION_PAPER_REQUEST';
export const GET_ITW_QUESTION_PAPER_SUCCESS = 'GET_ITW_QUESTION_PAPER_SUCCESS';
export const GET_ITW_QUESTION_PAPER_FAIL = 'GET_ITW_QUESTION_PAPER_FAIL';

export const POST_SUBMIT_ITW_REQUEST = 'POST_SUBMIT_ITW_REQUEST';
export const POST_SUBMIT_ITW_SUCCESS = 'POST_SUBMIT_ITW_SUCCESS';
export const POST_SUBMIT_ITW_FAIL = 'POST_SUBMIT_ITW_FAIL';

export const GET_ENROLL_ITW_REQUEST = 'GET_ENROLL_ITW_REQUEST';
export const GET_ENROLL_ITW_SUCCESS = 'GET_ENROLL_ITW_SUCCESS';
export const GET_ENROLL_ITW_FAIL = 'GET_ENROLL_ITW_FAIL';

export const GET_ANALYTICS_ITW_REQUEST = 'GET_ANALYTICS_ITW_REQUEST';
export const GET_ANALYTICS_ITW_SUCCESS = 'GET_ANALYTICS_ITW_SUCCESS';
export const GET_ANALYTICS_ITW_FAIL = 'GET_ANALYTICS_ITW_FAIL';

export const GET_ANSWER_SHEET_ITW_REQUEST = 'GET_ANSWER_SHEET_ITW_REQUEST';
export const GET_ANSWER_SHEET_ITW_SUCCESS = 'GET_ANSWER_SHEET_ITW_SUCCESS';
export const GET_ANSWER_SHEET_ITW_FAIL = 'GET_ANSWER_SHEET_ITW_FAIL';