import React,{useState} from 'react'
import { useDispatch } from 'react-redux';
import { bookUserCounselling } from '../../../actions/counsellingActions';

import './ScheduleCounselling.scss';

const ScheduleCounselling = ({hadelSetShow}) => {
    const dispatch = useDispatch();
    const [scheduleDate, setscheduleDate] = useState('');

    const schedule = (e) => {
        e.preventDefault();
        dispatch(bookUserCounselling(scheduleDate));
        hadelSetShow(false);
    }
    return (
        <div>
            <div className='schedule-popup'>
                <div className='card'>
                    <h3>Schedule Counseling</h3>
                    <label htmlFor="date">Pick a date</label>
                    <input type='date' id="date" placeholder="Select Date" onChange={(e) => setscheduleDate(e.target.value)} />
                    <div className='popup-btn'>
                        <button onClick={(e)=>{e.preventDefault(); hadelSetShow(false)}}>Cancel</button>
                        <button onClick={schedule}>Schedule</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleCounselling
