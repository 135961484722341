export const GET_PRACTICE_TESTSERIES_REQUEST = 'GET_PRACTICE_TESTSERIES_REQUEST';
export const GET_PRACTICE_TESTSERIES_SUCCESS = 'GET_PRACTICE_TESTSERIES_SUCCESS';
export const GET_PRACTICE_TESTSERIES_FAIL = 'GET_PRACTICE_TESTSERIES_FAIL';

export const GET_LIVE_TESTSERIES_REQUEST = 'GET_LIVE_TESTSERIES_REQUEST';
export const GET_LIVE_TESTSERIES_SUCCESS = 'GET_LIVE_TESTSERIES_SUCCESS';
export const GET_LIVE_TESTSERIES_FAIL = 'GET_LIVE_TESTSERIES_FAIL';

export const GET_PRACTICE_QUESTION_PAPER_REQUEST = 'GET_PRACTICE_QUESTION_PAPER_REQUEST';
export const GET_PRACTICE_QUESTION_PAPER_SUCCESS = 'GET_PRACTICE_QUESTION_PAPER_SUCCESS';
export const GET_PRACTICE_QUESTION_PAPER_FAIL = 'GET_PRACTICE_QUESTION_PAPER_FAIL';

export const POST_SUBMIT_PRACTICE_TEST_REQUEST = 'POST_SUBMIT_PRACTICE_TEST_REQUEST';
export const POST_SUBMIT_PRACTICE_TEST_SUCCESS = 'POST_SUBMIT_PRACTICE_TEST_SUCCESS';
export const POST_SUBMIT_PRACTICE_TEST_FAIL = 'POST_SUBMIT_PRACTICE_TEST_FAIL';

export const GET_PRACTICE_TEST_ANALYTICS_REQUEST = 'GET_PRACTICE_TEST_ANALYTICS_REQUEST';
export const GET_PRACTICE_TEST_ANALYTICS_SUCCESS = 'GET_PRACTICE_TEST_ANALYTICS_SUCCESS';
export const GET_PRACTICE_TEST_ANALYTICS_FAIL = 'GET_PRACTICE_TEST_ANALYTICS_FAIL';

export const GET_PRACTICE_QPAPER_REQUEST = 'GET_PRACTICE_QPAPER_REQUEST';
export const GET_PRACTICE_QPAPER_SUCCESS = 'GET_PRACTICE_QPAPER_SUCCESS';
export const GET_PRACTICE_QPAPER_FAIL = 'GET_PRACTICE_QPAPER_FAIL';

export const GET_PRACTICE_ANSWERSHEET_REQUEST = 'GET_PRACTICE_ANSWERSHEET_REQUEST';
export const GET_PRACTICE_ANSWERSHEET_SUCCESS = 'GET_PRACTICE_ANSWERSHEET_SUCCESS';
export const GET_PRACTICE_ANSWERSHEET_FAIL = 'GET_PRACTICE_ANSWERSHEET_FAIL';

export const GET_LIVE_TESTSERIES_DETAILS_REQUEST = 'GET_LIVE_TESTSERIES_DETAILS_REQUEST';
export const GET_LIVE_TESTSERIES_DETAILS_SUCCESS = 'GET_LIVE_TESTSERIES_DETAILS_SUCCESS';
export const GET_LIVE_TESTSERIES_DETAILS_FAIL = 'GET_LIVE_TESTSERIES_DETAILS_FAIL';

export const GET_LIVE_TEST_ANALYTICS_REQUEST = 'GET_LIVE_TEST_ANALYTICS_REQUEST';
export const GET_LIVE_TEST_ANALYTICS_SUCCESS = 'GET_LIVE_TEST_ANALYTICS_SUCCESS';
export const GET_LIVE_TEST_ANALYTICS_FAIL = 'GET_LIVE_TEST_ANALYTICS_FAIL';

export const GET_LIVE_QPAPER_REQUEST = 'GET_LIVE_QPAPER_REQUEST';
export const GET_LIVE_QPAPER_SUCCESS = 'GET_LIVE_QPAPER_SUCCESS';
export const GET_LIVE_QPAPER_FAIL = 'GET_LIVE_QPAPER_FAIL';

export const POST_SUBMIT_LIVE_TEST_REQUEST = 'POST_SUBMIT_LIVE_TEST_REQUEST';
export const POST_SUBMIT_LIVE_TEST_SUCCESS = 'POST_SUBMIT_LIVE_TEST_SUCCESS';
export const POST_SUBMIT_LIVE_TEST_FAIL = 'POST_SUBMIT_LIVE_TEST_FAIL';