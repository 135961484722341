import React, { useState, useEffect } from 'react';
import './PracticeTestSeries.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPracticeTestSeries } from '../../actions/EnrolledTestSeries';
import AlertDismissibleExample from '../../Components/Error/error';
import noImage from '../../assets/images/otp.png';
import { useHistory } from "react-router";

import SearchItem from '../../Components/Search/SearchItem';

import assignment1 from '../../assets/icons/assignment1.svg';
import { Col, Row } from 'react-bootstrap';
import MyTestseriesLoading from '../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading';

const PracticeTestSeries = () => {
    const history = useHistory();
    const [searchResults, setSearchResults] = useState([]);

    const dispatch = useDispatch();

    const getAllPracticeTest = useSelector(state => state.getAllPracticeTest)
    const { loading, error, PracticeTest } = getAllPracticeTest;
    const [tests, setTests] = useState([])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getAllPracticeTestSeries());
    }, [dispatch,userInfo])

    useEffect(() => {
        if (!error) {
            var tests = PracticeTest.map(item => ({
                ...item,
                name: item.paidTest.TestSeriesName
            }))

            setTests(tests)
            setSearchResults(tests)
        }

    }, [PracticeTest, error])

    const colors = [
        { bg: '#590196' },
        { bg: '#9f8400' },
        { bg: '#007947' },
        { bg: '#5b2e3d' },
    ]

    const minToHours = (min) => {
        var hr = min / 60;
        if (hr < 1) {
            hr = 0;
        }
        var m = min % 60;
        return `${(hr === 0) ? "" : (hr < 10) ? `0${hr.toString().split(".")[0]} hr` : `${hr.toString().split(".")[0]} hr`} ${(m < 10) ? `0${m}min` : `${m} min`}`
    }

    return (
        <div>
            {error && <AlertDismissibleExample data={error} />}
            {loading ? <MyTestseriesLoading/> : (
                <div className="my-4 practice-test-series">
                        <section className="d-md-flex mx-5 justify-content-center">
                            {PracticeTest && PracticeTest.length !== 0 &&
                                <SearchItem
                                    allItems={tests}
                                    from={"Test Series"}
                                    handleSetSearchResults={(results) => setSearchResults(results)}
                                />
                            }
                        </section>
                        <section>
                            {
                                searchResults.length === 0 ?
                                    <div className="no-live-tests">
                                        <img src={noImage} alt="" />
                                        <h3>No TestSeries Found</h3>
                                    </div> :
                                    <Row className="testSeries py-5 px-5">
                                        {
                                            searchResults.map((element, i) => {
                                                return (
                                                    <Col 
                                                        lg={5} xs={12} 
                                                        className="testSeries-card my-3" 
                                                        key={element.id} 
                                                        style={{ backgroundColor: colors[i < colors.length ? i : i % colors.length].bg, cursor: 'pointer' }}
                                                        onClick={(e)=>{e.preventDefault(); history.push(`/testseries/practice/${element.paidTest.id}`)}}
                                                    >
                                                        <img src={assignment1} alt="assignment" />
                                                        <div>
                                                            <p style={{ fontSize: 20, fontWeight: '500'}} title={element.paidTest.TestSeriesName}>
                                                                {element.paidTest.TestSeriesName.slice(0,16)}
                                                                {element.paidTest.TestSeriesName.length > 16 && '...'}
                                                            </p>
                                                            <p style={{ fontSize: 14, opacity: 0.7 }}>Type : {element.paidTest.TestSeriesType}</p>
                                                            <p style={{ fontSize: 14, opacity: 0.7 }}>Duration : {minToHours(element.paidTest.Duration)}</p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                            }
                        </section>
                    </div>
                
            )}
        </div>
    )
}

export default PracticeTestSeries
