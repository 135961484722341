import React,{useState, useEffect} from 'react'
import './PaymentDetails.scss'

import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../Components/Loader';
import AlertDismissibleExample from '../../../Components/Error/error';

import { AccountCircle, KeyboardArrowDown } from '@material-ui/icons';
import Logo from '../../../assets/icons/logo.svg';
import leftArrow from '../../../assets/images/ionic-ios-arrow-back.png';
import noImage from '../../../assets/images/otp.png';

import { Link } from 'react-router-dom';
import { Col, Row, Modal } from 'react-bootstrap';

import { getCourseInstallmentHistory, postInstallmentPayments } from '../../../actions/courseActions';
import { BASEURL, RazorpayKey } from '../../../config/config';
import axios from 'axios'

const PaymentDetails = ({match, history}) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [amount, setAmount] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getCoursePaymentDetails = useSelector(state => state.getCoursePaymentDetails)
    const { loading, error, paymentDetails } = getCoursePaymentDetails;

    const postInstallmentPayment = useSelector(state => state.postInstallmentPayment)
    const { loading:loadingPayment, error:errorPayment, message } = postInstallmentPayment;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(()=>{
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getCourseInstallmentHistory(match.params.id));
            window.scrollTo(0,0)
        }
    },[userInfo, dispatch, match, message])

    useEffect(()=>{
        if(message){
            handleClose()
        }
    },[message])

    const drop = (e) =>{
        e.preventDefault();

        const isDropdownButton = e.target.matches("[data-dropdown-button]")
        if(!isDropdownButton && e.target.closest("[data-dropdown]") != null){
            return
        }
        let currentDropdown
        if(isDropdownButton){
            currentDropdown = e.target.closest("[data-dropdown]")
            currentDropdown.classList.toggle('active')
        }

        document.querySelectorAll("[data-dropdown].active").forEach(dropdown =>{
            if(dropdown === currentDropdown){
                return
            }
            dropdown.classList.remove('active')
        })
    }

    const makePayment = async() =>{
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        try{
            let { data } = await axios.post(`${BASEURL}/user/foundational/course/${match.params.id}/orderid`, {amount}, config)
            let options  = {
                key: RazorpayKey,
                name: "Yes Mentor",
                description: "Yes Mentor",
                order_id: data[0].order.id,
                handler: async (response) => {
                    try {
                        dispatch(postInstallmentPayments(
                            match.params.id, 
                            response.razorpay_payment_id, 
                            response.razorpay_signature, 
                            response.razorpay_order_id, 
                            amount
                        ))
                    } catch (error) {
                        console.log(error);
                    }
                }
            }

            const rzp1 = new window.Razorpay(options);
            rzp1.open();
        }catch(error){
            console.log(error.message)
        }
    }

    return (
        <div className='enroll-foundation payment-details' onClick={drop}>
            {(loading || loadingPayment) && <Loader/>}
            {error && <AlertDismissibleExample data={error} />}
            {errorPayment && <AlertDismissibleExample data={errorPayment} />}

            <div className='nav-bar'>
                <div>
                    <div className='img-container'>
                        <img  src={leftArrow} alt="<" className="backBtn" onClick={(e)=>{e.preventDefault(); history.goBack()}}/>
                        <img src={Logo} alt="yes-mentor" onClick={(e)=>{e.preventDefault(); history.push('/')}} style={{cursor:'pointer'}} />
                    </div>
                    <div className='right-content'>
                        <div className='dropD' data-dropdown  >
                            <button className='foundational-menu' data-dropdown-button>
                                <AccountCircle/> {paymentDetails && paymentDetails.userName} <KeyboardArrowDown/>
                            </button>
                            <div className='drop-menu'>
                                <button className='items'>Live Lecture</button>
                                <Link className='items' to={`/enroll/foundational/course/${match.params.id}/${match.params.eid}`}>My Course</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='nav-back'></div>
            
            <div className='container'>
                {paymentDetails ? <div>
                    <Row className='bill-details d-flex justify-content-center'>
                        <Col lg={6} >
                            <div className='bill-pay'>
                                <h4>Balance Fee</h4>
                                <hr></hr>
                                <div className='d-flex justify-content-between'>
                                    <h5>Balance</h5>
                                    <p>₹ {paymentDetails.balanceBill}/-</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p>(Monthly<br/> Installment)</p>
                                    <button onClick={handleShow}>Make a Payment</button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            <div className='bill-Details'>
                                <h4>Bill Details</h4>
                                <hr></hr>
                                <div className='d-flex justify-content-between my-0'>
                                    <h5>Total Amount</h5>
                                    <p>₹ {paymentDetails.price}/-</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <h5>Paid Amount</h5>
                                    <p> - ₹ {paymentDetails.paidAmount}/-</p>
                                </div>
                                <hr style={{margin:'4px'}}/>
                                <div className='d-flex justify-content-between'>
                                    <h5>Due Amount</h5>
                                    <p>₹ {paymentDetails.dueAmount}/-</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} >
                            <div className='bill-history'>
                                <h4>Billing & payment history</h4>
                                <hr/>
                                <div style={{width:'100%', margin:'10px auto', overflowX:'auto'}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Reference No.</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Mode of payment</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentDetails.installments.map((installment,idx)=>(
                                                <tr key={idx}>
                                                    <td>{idx+1}.</td>
                                                    <td>{installment.paymentID}</td>
                                                    <td>{new Date(installment.date).toDateString()}</td>
                                                    <td>{new Date(installment.date).toLocaleTimeString('en-US', {hour:'2-digit',minute:'2-digit'})}</td>
                                                    <td>{installment.method}</td>
                                                    <td>₹ {installment.totalAmount / 100}/-</td>
                                                    <td>{installment.status === 'captured' ? "Success" : installment.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {paymentDetails.installments.length === 0 && <div>
                                        <div className='content-notAvail py-5'>
                                            <img src={noImage} alt="" />
                                            <h3 style={{marginBottom:'7px'}}>No Data</h3>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> : <AlertDismissibleExample data={"Something went wrong, please login again."} />}
            </div>
            <Modal 
                show = {show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                className='payment-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Make a payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='py-4 px-4'>
                        <table style={{width:'100%', overflowX:'auto'}}>
                            <thead>
                                <tr style={{height:'35px'}}>
                                    <th style={{width:'60px'}}>S.No</th>
                                    <th>Payment Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{height:'35px'}}>
                                    <td>1.</td>
                                    <td>Pay whole amount (₹ { paymentDetails && paymentDetails.dueAmount}/-)</td>
                                </tr>
                                <tr style={{height:'35px'}}>
                                    <td>2.</td>
                                    <td>Pay Monthly Installment (₹ {paymentDetails && paymentDetails.balanceBill}/-)</td>
                                </tr>
                                <tr style={{height:'35px'}}>
                                    <td>3.</td>
                                    <td>Pay amount of your choice</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='amount py-3'>
                            <div className='d-flex justify-content-between'>
                                <h5>Enter Amount: </h5>
                                <input 
                                    type="number" 
                                    min={0} 
                                    required 
                                    placeholder='Enter amount'
                                    value={amount}
                                    onChange={(e)=>setAmount(e.target.value)}
                                />
                            </div>
                            <div>
                                <button className='amount-btn' onClick={(e)=>{e.preventDefault(); makePayment()}}>Pay</button>
                                {message && <p className='mt-3' style={{color:'green',textAlign:'center'}}>{message}</p>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PaymentDetails