import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../../Components/main/sidebar/sidebar';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/icons/logo.svg';
import Heading from '../../../Components/Heading/Heading';
import SearchItem from '../../../Components/Search/SearchItem';
import AlertDismissibleExample from '../../../Components/Error/error';
import MyTestseriesLoading from '../../../Components/ShimmerEffect/MyTestseries/MyTestseriesLoading'
import { getAllUserFoundationalCourses } from '../../../actions/courseActions';
import { ProgressBar } from 'react-bootstrap';
import noImage from '../../../assets/images/otp.png';
import { Col, Row } from 'react-bootstrap';
import './EnrolledFoundationalCourses.scss';
import ReactStars from 'react-rating-stars-component';

const EnrolledFoundationalCourses = ({history}) => {
    const dispatch = useDispatch();

    const [course, setCourse] = useState([])
    const [searchResults, setSearchResults] = useState([])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const getUserEnrolledFoundationalCourses = useSelector(state => state.getUserEnrolledFoundationalCourses)
    const { loading, error, foundationalCourses } = getUserEnrolledFoundationalCourses;

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        } else {
            dispatch(getAllUserFoundationalCourses());
        }
    }, [userInfo, dispatch])

    useEffect(() => {
        if (foundationalCourses && foundationalCourses.length > 0) {
            var course = foundationalCourses.map(item => ({
                ...item,
                name: item.course.courseName
            }))

            setCourse(course)
            setSearchResults(course)
        }

    }, [foundationalCourses, error])

    return (
        <div className="main-Container foundationalCourse">
            
            {error && <AlertDismissibleExample data={error} />}
            <Sidebar activeLink={'myfoundationalcourse'}/>
            <main className="main">
                <section className="px-5  py-4">
                    <Heading Text={'Pro Courses'} />
                    <div className='pt-4'>
                        <SearchItem
                            allItems={course}
                            from={"Pro Courses"}
                            handleSetSearchResults={(results) => setSearchResults(results)}
                        />
                    </div>
                    
                    {loading ? <MyTestseriesLoading/>:
                        searchResults.length > 0 ? 
                        <div>
                            <Row className='py-5'>
                                {searchResults.map((course, idx)=>(
                                    <Col lg={6} key={idx} className="foundational-card">
                                        <div className='container' onClick={(e)=>{e.preventDefault(); history.push(`/enroll/foundational/course/${course.course.id}/${course.id}`)}}>
                                            <div className='image'>
                                                <img src={course.course.imageURL ? course.course.imageURL : Logo} className={!course.course.imageURL ? "noimage" : '' } alt="courseImg" />
                                            </div>
                                            <div className="stars-wrappers">
                                                <ReactStars
                                                    count={5}
                                                    size={24}
                                                    activeColor="#FFA700"
                                                    value={course.rating}
                                                    edit={false}
                                                />
                                            </div>
                                            <div className='title'>
                                                <p className='text' style={{margin:'0px', fontSize:'18px'}} title={course.course.courseName}>
                                                    {course.course.courseName.slice(0,47)}{course.course.courseName.length > 47 && '...'}
                                                </p>
                                                <p className='text' style={{margin:'0px', opacity:0.8, fontSize:'16px'}} title={course.course.subHeading}>
                                                    {course.course.subHeading.slice(0,47)}{course.course.subHeading.length > 47 && '...'}
                                                </p>
                                            </div>
                                            <div className='pg'>
                                                <ProgressBar
                                                    now={course.analytics}
                                                    variant='info'
                                                />
                                                <p>{`${Number(course.analytics).toFixed(0)}%`} Completed</p>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div> :
                        <div className="px-5  py-5">
                            <div className='content-notAvail'>
                                <img src={noImage} alt="" />
                                <h3 style={{marginBottom:'7px'}}>Courses Not Found </h3>
                                <p><Link to={'/explore'}>Explore</Link></p>
                            </div>
                        </div>
                    }
                </section>
            </main>
        </div>
    )
}

export default EnrolledFoundationalCourses