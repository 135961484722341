import axios from 'axios';
import {
    COUNSELLING_REQUEST,
    COUNSELLING_SUCCESS,
    COUNSELLING_FAIL
} from '../constants/counsellingConstants';

import {BASEURL} from '../config/config';

export const bookUserCounselling = (date)  => async(dispatch,getState) => {
    try{
        dispatch({
            type:COUNSELLING_REQUEST
        })
        const {userLogin : {userInfo}} = getState();
        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: userInfo.authToken },
        }
        const {data} = await axios.post(`${BASEURL}/user/counselling`,{date},config)
        dispatch({
            type:COUNSELLING_SUCCESS,
            payload:data[0].message
        })

    }catch(error){
        dispatch({
            type:COUNSELLING_FAIL,
            payload: error.response && error.response.data[0].message ? error.response.data[0].message : error.message
        })
    }
}