import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/icons/logo.svg';
import RightArrow from '../../assets/icons/right-arrow.svg'
import ForgotPass from '../../assets/images/forgotPass.png'
import { ReactComponent as BackArrow } from "../../assets/icons/back-arrow.svg"
import '../../Components/Auth/layout.scss';
import AlertDismissibleExample from '../../Components/Error/error';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { postResetPassword } from '../../actions/userActions';
import ProcessingModal from "../../Components/ProcessingModal"
import SuccessfulModal from '../../Components/SuccessfulModal';
import { Link } from 'react-router-dom';
// import { logout } from '../../actions/userActions';
function ResetPassword() {

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const resetPass = useSelector(state => state.postResetPassword)
    const { loading, error, message } = resetPass

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [showOldPass, setShowOldPass] = useState(false)
    const [showNewPass, setShowNewPass] = useState(false)

    const onSubmit = (pass) => {
        var data = {
            oldpassword: pass.oldPass,
            newpassword: pass.newPass
        }
        dispatch(postResetPassword(data))
    }

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
    }, [userInfo])

    // useEffect(()=>{
    //     const message = 'User is not authenticated, try logging in again...';
    //     if(
    //         error === message
    //     ){
    //         dispatch(logout());
    //     }
    // },[dispatch, error])




    return (
        <div>
            {loading && <ProcessingModal />}
            {message && <SuccessfulModal data={"Password changed successfully"} path="#" />}
            {error && <AlertDismissibleExample data={error} />}
            <main>
                <Row className="m-0">
                    <Col md="6" lg="4" className="p-0" >
                        <div className="form-wrapper">
                            <div className="d-flex align-items-center">
                                <Link to="/more"><BackArrow /></Link>
                                <div className="mx-4">
                                    <img src={Logo} alt="signup illustration" />
                                </div>
                            </div>
                            <h1>
                                Reset <br /> <span>Password</span>
                            </h1>
                            <div>
                                <div className="input-wrapper my-5">
                                    <div className="bg">
                                        <div className="input-cont d-flex justify-content-between">
                                            <input
                                                type={showOldPass ? "text" : "password"}
                                                autoComplete="off"
                                                {...register('oldPass', {
                                                    required: true,
                                                    minLength: 6
                                                })}
                                            />
                                            <label htmlFor="oldPasss" className="label-name"> <span className="content-name">Current Password</span></label>
                                            <div className="align-self-center">
                                                {
                                                    !showOldPass && <button
                                                        onClick={() => setShowOldPass(true)}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}
                                                    ><Visibility /></button>
                                                }
                                                {
                                                    showOldPass && <button
                                                        onClick={() => setShowOldPass(false)}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}>
                                                        <VisibilityOff /></button>
                                                }
                                            </div>
                                        </div>
                                        {errors.oldPass && errors.oldPass.type === 'required' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                Please enter the current password
                                            </span>
                                        )}
                                        {errors.oldPass && errors.oldPass.type === 'minLength' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                You password must be atleast 6 characters long.
                                            </span>
                                        )}

                                    </div>

                                    <div className="bg">
                                        <div className="input-cont d-flex justify-content-between">
                                            <input
                                                type={showNewPass ? "text" : "password"}
                                                autoComplete="off"
                                                {...register('newPass', {
                                                    required: true,
                                                    minLength: 6
                                                })}
                                            />
                                            <label className="label-name"> <span className="content-name">New Password</span></label>
                                            <div className="align-self-center">
                                                {
                                                    !showNewPass && <button
                                                        onClick={() => setShowNewPass(true)}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}
                                                    ><Visibility /></button>
                                                }
                                                {
                                                    showNewPass && <button
                                                        onClick={() => setShowNewPass(false)}
                                                        style={{ border: "none", background: "none", color: "var(--text-color)" }}>
                                                        <VisibilityOff /></button>
                                                }
                                            </div>
                                        </div>
                                        {errors.newPass && errors.newPass.type === 'required' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                Please enter the new password
                                            </span>
                                        )}
                                        {errors.newPass && errors.newPass.type === 'minLength' && (
                                            <span style={{ color: '#ff0101', fontSize: 12 }}>
                                                You password must be atleast 6 characters long.
                                            </span>
                                        )}

                                    </div>
                                    <button onClick={handleSubmit(onSubmit)} type="submit" className="next-btn mt-4">
                                        <img src={RightArrow} alt=">" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="8" className="p-0" style={{ backgroundColor: '#0669F8' }}>
                        <div className="image-wrapper">
                            <img src={ForgotPass} alt="signup illustration" className="m-0" />
                            <div>
                                <p style={{ width: ' 67%', margin: 'auto' }}>
                                    Reset your password
                                </p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </main>
        </div>
    )
}

export default ResetPassword
