export const GET_ALL_COURSES_REQUEST = 'GET_ALL_COURSES_REQUEST';
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_FAIL = 'GET_ALL_COURSES_FAIL';

export const GET_ALL_FOUNDATIONAL_COURSES_REQUEST = 'GET_ALL_FOUNDATIONAL_COURSES_REQUEST';
export const GET_ALL_FOUNDATIONAL_COURSES_SUCCESS = 'GET_ALL_FOUNDATIONAL_COURSES_SUCCESS';
export const GET_ALL_FOUNDATIONAL_COURSES_FAIL = 'GET_ALL_FOUNDATIONAL_COURSES_FAIL';

export const GET_COURSES_BY_ID_REQUEST = 'GET_COURSES_BY_ID_REQUEST';
export const GET_COURSES_BY_ID_SUCCESS = 'GET_COURSES_BY_ID_SUCCESS';
export const GET_COURSES_BY_ID_FAIL = 'GET_COURSES_BY_ID_FAIL';

export const GET_FOUNDATIONAL_COURSES_BY_ID_REQUEST = 'GET_FOUNDATIONAL_COURSES_BY_ID_REQUEST';
export const GET_FOUNDATIONAL_COURSES_BY_ID_SUCCESS = 'GET_FOUNDATIONAL_COURSES_BY_ID_SUCCESS';
export const GET_FOUNDATIONAL_COURSES_BY_ID_FAIL = 'GET_FOUNDATIONAL_COURSES_BY_ID_FAIL';

export const GET_USER_COURSES_REQUEST = 'GET_USER_COURSES_REQUEST';
export const GET_USER_COURSES_SUCCESS = 'GET_USER_COURSES_SUCCESS';
export const GET_USER_COURSES_FAIL = 'GET_USER_COURSES_FAIL';

export const POST_ENROLL_COURSES_REQUEST = 'POST_ENROLL_COURSES_REQUEST';
export const POST_ENROLL_COURSES_SUCCESS = 'POST_ENROLL_COURSES_SUCCESS';
export const POST_ENROLL_COURSES_FAIL = 'POST_ENROLL_COURSES_FAIL';

export const POST_ENROLL_FOUNDATIONAL_COURSES_REQUEST = 'POST_ENROLL_FOUNDATIONAL_COURSES_REQUEST';
export const POST_ENROLL_FOUNDATIONAL_COURSES_SUCCESS = 'POST_ENROLL_FOUNDATIONAL_COURSES_SUCCESS';
export const POST_ENROLL_FOUNDATIONAL_COURSES_FAIL = 'POST_ENROLL_FOUNDATIONAL_COURSES_FAIL';

export const GET_SUBJECTS_BY_ID_REQUEST = 'GET_SUBJECTS_BY_ID_REQUEST';
export const GET_SUBJECTS_BY_ID_SUCCESS = 'GET_SUBJECTS_BY_ID_SUCCESS';
export const GET_SUBJECTS_BY_ID_FAIL = 'GET_SUBJECTS_BY_ID_FAIL';

export const GET_CHAPTERS_BY_ID_REQUEST = 'GET_CHAPTERS_BY_ID_REQUEST';
export const GET_CHAPTERS_BY_ID_SUCCESS = 'GET_CHAPTERS_BY_ID_SUCCESS';
export const GET_CHAPTERS_BY_ID_FAIL = 'GET_CHAPTERS_BY_ID_FAIL';

export const GET_TOPICS_BY_ID_REQUEST = 'GET_TOPICS_BY_ID_REQUEST';
export const GET_TOPICS_BY_ID_SUCCESS = 'GET_TOPICS_BY_ID_SUCCESS';
export const GET_TOPICS_BY_ID_FAIL = 'GET_TOPICS_BY_ID_FAIL';

export const PUT_COMPLETED_STATUS_REQUEST = 'PUT_COMPLETED_STATUS_REQUEST';
export const PUT_COMPLETED_STATUS_SUCCESS = 'PUT_COMPLETED_STATUS_SUCCESS';
export const PUT_COMPLETED_STATUS_FAIL = 'PUT_COMPLETED_STATUS_FAIL';

export const GET_COURSE_TEST_INFO_REQUEST = 'GET_COURSE_TEST_INFO_REQUEST';
export const GET_COURSE_TEST_INFO_SUCCESS = 'GET_COURSE_TEST_INFO_SUCCESS';
export const GET_COURSE_TEST_INFO_FAIL = 'GET_COURSE_TEST_INFO_FAIL';

export const GET_FOUNDATIONAL_COURSE_TEST_INFO_REQUEST = 'GET_FOUNDATIONAL_COURSE_TEST_INFO_REQUEST';
export const GET_FOUNDATIONAL_COURSE_TEST_INFO_SUCCESS = 'GET_FOUNDATIONAL_COURSE_TEST_INFO_SUCCESS';
export const GET_FOUNDATIONAL_COURSE_TEST_INFO_FAIL = 'GET_FOUNDATIONAL_COURSE_TEST_INFO_FAIL';

export const GET_TESTSERIES_PROGRESS_REQUEST = 'GET_TESTSERIES_PROGRESS_REQUEST';
export const GET_TESTSERIES_PROGRESS_SUCCESS = 'GET_TESTSERIES_PROGRESS_SUCCESS';
export const GET_TESTSERIES_PROGRESS_FAIL = 'GET_TESTSERIES_PROGRESS_FAIL';


export const GET_ASSIGNMENT_QPAPER_REQUEST = 'GET_ASSIGNMENT_QPAPER_REQUEST';
export const GET_ASSIGNMENT_QPAPER_SUCCESS = 'GET_ASSIGNMENT_QPAPER_SUCCESS';
export const GET_ASSIGNMENT_QPAPER_FAIL = 'GET_ASSIGNMENT_QPAPER_FAIL';

export const GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_REQUEST = 'GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_REQUEST';
export const GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_SUCCESS = 'GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_SUCCESS';
export const GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_FAIL = 'GET_FOUNDATIONAL_ASSIGNMENT_QPAPER_FAIL';

export const POST_SUBMIT_TEST_REQUEST = 'POST_SUBMIT_TEST_REQUEST';
export const POST_SUBMIT_TEST_SUCCESS = 'POST_SUBMIT_TEST_SUCCESS';
export const POST_SUBMIT_TEST_FAIL = 'POST_SUBMIT_TEST_FAIL';

export const POST_SUBMIT_FOUNDATIONAL_TEST_REQUEST = 'POST_SUBMIT_FOUNDATIONAL_TEST_REQUEST';
export const POST_SUBMIT_FOUNDATIONAL_TEST_SUCCESS = 'POST_SUBMIT_FOUNDATIONAL_TEST_SUCCESS';
export const POST_SUBMIT_FOUNDATIONAL_TEST_FAIL = 'POST_SUBMIT_FOUNDATIONAL_TEST_FAIL';

export const GET_COURSE_CONTENT_REQUEST = 'GET_COURSE_CONTENT_REQUEST';
export const GET_COURSE_CONTENT_SUCCESS = 'GET_COURSE_CONTENT_SUCCESS';
export const GET_COURSE_CONTENT_FAIL = 'GET_COURSE_CONTENT_FAIL';

export const GET_COURSE_PAYMENT_HISTORY_REQUEST = 'GET_COURSE_PAYMENT_HISTORY_REQUEST';
export const GET_COURSE_PAYMENT_HISTORY_SUCCESS = 'GET_COURSE_PAYMENT_HISTORY_SUCCESS';
export const GET_COURSE_PAYMENT_HISTORY_FAIL = 'GET_COURSE_PAYMENT_HISTORY_FAIL';

export const POST_RESET_TEST_REQUEST = 'POST_RESET_TEST_REQUEST';
export const POST_RESET_TEST_SUCCESS = 'POST_RESET_TEST_SUCCESS';
export const POST_RESET_TEST_FAIL = 'POST_RESET_TEST_FAIL';

export const PUT_RESET_FOUNDATIONAL_TEST_REQUEST = 'PUT_RESET_FOUNDATIONAL_TEST_REQUEST';
export const PUT_RESET_FOUNDATIONAL_TEST_SUCCESS = 'PUT_RESET_FOUNDATIONAL_TEST_SUCCESS';
export const PUT_RESET_FOUNDATIONAL_TEST_FAIL = 'PUT_RESET_FOUNDATIONAL_TEST_FAIL';

export const GET_ANALYTICS_BY_ID_REQUEST = 'GET_ANALYTICS_BY_ID_REQUEST';
export const GET_ANALYTICS_BY_ID_SUCCESS = 'GET_ANALYTICS_BY_ID_SUCCESS';
export const GET_ANALYTICS_BY_ID_FAIL = 'GET_ANALYTICS_BY_ID_FAIL';

export const GET_ANSWER_SHEET_REQUEST = 'GET_ANSWER_SHEET_REQUEST';
export const GET_ANSWER_SHEET_SUCCESS = 'GET_ANSWER_SHEET_SUCCESS';
export const GET_ANSWER_SHEET_FAIL = 'GET_ANSWER_SHEET_FAIL';

export const GET_FOUNDATIONAL_ANALYTICS_BY_ID_REQUEST = 'GET_FOUNDATIONAL_ANALYTICS_BY_ID_REQUEST';
export const GET_FOUNDATIONAL_ANALYTICS_BY_ID_SUCCESS = 'GET_FOUNDATIONAL_ANALYTICS_BY_ID_SUCCESS';
export const GET_FOUNDATIONAL_ANALYTICS_BY_ID_FAIL = 'GET_FOUNDATIONAL_ANALYTICS_BY_ID_FAIL';

export const GET_FOUNDATIONAL_ANSWER_SHEET_REQUEST = 'GET_FOUNDATIONAL_ANSWER_SHEET_REQUEST';
export const GET_FOUNDATIONAL_ANSWER_SHEET_SUCCESS = 'GET_FOUNDATIONAL_ANSWER_SHEET_SUCCESS';
export const GET_FOUNDATIONAL_ANSWER_SHEET_FAIL = 'GET_FOUNDATIONAL_ANSWER_SHEET_FAIL';

export const POST_REVIEW_REQUEST = 'POST_REVIEW_REQUEST';
export const POST_REVIEW_SUCCESS = 'POST_REVIEW_SUCCESS';
export const POST_REVIEW_FAIL = 'POST_REVIEW_FAIL';

export const POST_FOUNDATIONAL_REVIEW_REQUEST = 'POST_FOUNDATIONAL_REVIEW_REQUEST';
export const POST_FOUNDATIONAL_REVIEW_SUCCESS = 'POST_FOUNDATIONAL_REVIEW_SUCCESS';
export const POST_FOUNDATIONAL_REVIEW_FAIL = 'POST_FOUNDATIONAL_REVIEW_FAIL';

export const POST_RECENT_SUBJECTS_REQUEST = 'POST_RECENT_SUBJECTS_REQUEST';
export const POST_RECENT_SUBJECTS_SUCCESS = 'POST_RECENT_SUBJECTS_SUCCESS';
export const POST_RECENT_SUBJECTS_FAIL = 'POST_RECENT_SUBJECTS_FAIL';

export const GET_RECENT_SUBJECTS_REQUEST = 'GET_RECENT_SUBJECTS_REQUEST';
export const GET_RECENT_SUBJECTS_SUCCESS = 'GET_RECENT_SUBJECTS_SUCCESS';
export const GET_RECENT_SUBJECTS_FAIL = 'GET_RECENT_SUBJECTS_FAIL';

export const POST_COURSE_COUPON_VALIDATION_REQUEST = 'POST_COURSE_COUPON_VALIDATION_REQUEST';
export const POST_COURSE_COUPON_VALIDATION_SUCCESS = 'POST_COURSE_COUPON_VALIDATION_SUCCESS';
export const POST_COURSE_COUPON_VALIDATION_FAIL = 'POST_COURSE_COUPON_VALIDATION_FAIL';

export const GET_ENROLL_FOUNDATIONAL_COURSES_REQUEST = 'GET_ENROLL_FOUNDATIONAL_COURSES_REQUEST';
export const GET_ENROLL_FOUNDATIONAL_COURSES_SUCCESS = 'GET_ENROLL_FOUNDATIONAL_COURSES_SUCCESS';
export const GET_ENROLL_FOUNDATIONAL_COURSES_FAIL = 'GET_ENROLL_FOUNDATIONAL_COURSES_FAIL';

export const GET_FOUNDATIONAL_CONTENT_REQUEST = 'GET_FOUNDATIONAL_CONTENT_REQUEST';
export const GET_FOUNDATIONAL_CONTENT_SUCCESS = 'GET_FOUNDATIONAL_CONTENT_SUCCESS';
export const GET_FOUNDATIONAL_CONTENT_FAIL = 'GET_FOUNDATIONAL_CONTENT_FAIL';

export const GET_FOUNDATIONAL_PAYMENT_HISTORY_REQUEST = 'GET_FOUNDATIONAL_PAYMENT_HISTORY_REQUEST';
export const GET_FOUNDATIONAL_PAYMENT_HISTORY_SUCCESS = 'GET_FOUNDATIONAL_PAYMENT_HISTORY_SUCCESS';
export const GET_FOUNDATIONAL_PAYMENT_HISTORY_FAIL = 'GET_FOUNDATIONAL_PAYMENT_HISTORY_FAIL';

export const POST_INSTALLMENT_PAYMENT_REQUEST = 'POST_INSTALLMENT_PAYMENT_REQUEST';
export const POST_INSTALLMENT_PAYMENT_SUCCESS = 'POST_INSTALLMENT_PAYMENT_SUCCESS';
export const POST_INSTALLMENT_PAYMENT_FAIL = 'POST_INSTALLMENT_PAYMENT_FAIL';

export const PUT_FOUNDATIONAL_COMPLETED_STATUS_REQUEST = 'PUT_FOUNDATIONAL_COMPLETED_STATUS_REQUEST';
export const PUT_FOUNDATIONAL_COMPLETED_STATUS_SUCCESS = 'PUT_FOUNDATIONAL_COMPLETED_STATUS_SUCCESS';
export const PUT_FOUNDATIONAL_COMPLETED_STATUS_FAIL = 'PUT_FOUNDATIONAL_COMPLETED_STATUS_FAIL';

export const PUT_FOUNDATIONAL_RECENT_TOPICS_REQUEST = 'PUT_FOUNDATIONAL_RECENT_TOPICS_REQUEST';
export const PUT_FOUNDATIONAL_RECENT_TOPICS_SUCCESS = 'PUT_FOUNDATIONAL_RECENT_TOPICS_SUCCESS';
export const PUT_FOUNDATIONAL_RECENT_TOPICS_FAIL = 'PUT_FOUNDATIONAL_RECENT_TOPICS_FAIL';