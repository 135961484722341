import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Clock from '../../assets/icons/clock.svg';
import './Timer.scss'

import { postSubmitTest, postSubmitFoundationalTest } from '../../actions/courseActions';
import { postSubmitPracticeTest } from '../../actions/EnrolledTestSeries';
import { postSubmitLiveTest } from '../../actions/EnrolledTestSeries';
import { postPreb4preSubmitAnswers } from '../../actions/preb4preTestAction';
import { postSubmitITW } from '../../actions/IssuesThisWeekAction';
import { postSubmitAskToEvaluate } from '../../actions/askToEvaluateActions';

const Timer = ({ id, type, paper }) => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const [close, setClose] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            let time = JSON.parse(localStorage.getItem(`${id}_time`));
            let now = Date.now()
            let TimeLeft = time.endingTime - now;
            if (TimeLeft >= 0) {
                let x = TimeLeft / 1000;
                let seconds = x % 60;
                x = x / 60;
                let minutes = x % 60;
                x = x / 60;
                let hours = x % 24;
                x = x / 24;
                let days = x;
                setText(`${(days > 0.99) ? (days > 9 ? Math.floor(days) + ":" : "0" + Math.floor(days) + ":") : ""}${(hours > 0.99) ? (hours > 9 ? Math.floor(hours) + ":" : "0" + Math.floor(hours) + ":") : ""}${minutes > 9.9 ? Math.floor(minutes) + ":" : "0" + Math.floor(minutes) + ":"}${seconds > 9 ? Math.floor(seconds) : "0" + Math.floor(seconds)}`)
            }

            if ((time.endingTime <= now) && !close) {
                setClose(true);
                const answer = JSON.parse(localStorage.getItem(`${id}_answerSheet`));
                if (type === 'assignment') {
                    dispatch(postSubmitTest(id, answer));
                } else if (type === 'liveTs') {
                    dispatch(postSubmitLiveTest(id, answer));
                } else if (type === 'practiceTs') {
                    dispatch(postSubmitPracticeTest(id, answer));
                } else if (type === 'preb4preTs') {
                    dispatch(postPreb4preSubmitAnswers(id, answer, paper))
                } else if(type === 'itwTs'){
                    dispatch(postSubmitITW(id,answer));
                } else if(type ==='f_assignment'){
                    dispatch(postSubmitFoundationalTest(id,answer));
                } else if(type === 'askToEvaluate'){
                    dispatch(postSubmitAskToEvaluate(id,answer));
                }

                setTimeout(() => {
                    if(localStorage.getItem(`${id}_time`)){
                        localStorage.removeItem(`${id}_time`);
                    }
                    localStorage.removeItem(`${id}_answerSheet`);
                    localStorage.removeItem(`${id}_chance`);
                    localStorage.removeItem(`${id}_markForReview`);
                    localStorage.removeItem(`${id}_attempt`);
                    localStorage.removeItem(`${id}_fullScreen`);
                    window.close()
                }, 1350);
            }
        }, 1000)
    })
    return (
        <div className='timer'>
            <img src={Clock} alt="clock" />
            <p>{text}</p>
        </div>
    )
}

export default Timer
