export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_FORGOT_REQUEST = 'USER_FORGOT_REQUEST'
export const USER_FORGOT_SUCCESS = 'USER_FORGOT_SUCCESS'
export const USER_FORGOT_FAIL = 'USER_FORGOT_FAIL'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_GOOGLE_REGISTER_REQUEST = 'USER_GOOGLE_REGISTER_REQUEST'
export const USER_GOOGLE_REGISTER_SUCCESS = 'USER_GOOGLE_REGISTER_SUCCESS'
export const USER_GOOGLE_REGISTER_FAIL = 'USER_GOOGLE_REGISTER_FAIL'

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL'


export const GET_USER_AUTHORIZATION_REQUEST = 'GET_USER_AUTHORIZATION_REQUEST'
export const GET_USER_AUTHORIZATION_SUCCESS = 'GET_USER_AUTHORIZATION_SUCCESS'
export const GET_USER_AUTHORIZATION_FAIL = 'GET_USER_AUTHORIZATION_FAIL'

export const PUT_USER_DETAILS_REQUEST = 'PUT_USER_DETAILS_REQUEST'
export const PUT_USER_DETAILS_SUCCESS = 'PUT_USER_DETAILS_SUCCESS'
export const PUT_USER_DETAILS_FAIL = 'PUT_USER_DETAILS_FAIL'

export const POST_USER_DOB_REQUEST = 'POST_USER_DOB_REQUEST'
export const POST_USER_DOB_SUCCESS = 'POST_USER_DOB_SUCCESS'
export const POST_USER_DOB_FAIL = 'POST_USER_DOB_FAIL'

export const PUT_USER_PICTURE_REQUEST = 'PUT_USER_PICTURE_REQUEST'
export const PUT_USER_PICTURE_SUCCESS = 'PUT_USER_PICTURE_SUCCESS'
export const PUT_USER_PICTURE_FAIL = 'PUT_USER_PICTURE_FAIL'

export const POST_SEND_MOBILE_OTP_REQUEST = 'POST_SEND_MOBILE_OTP_REQUEST'
export const POST_SEND_MOBILE_OTP_SUCCESS = 'POST_SEND_MOBILE_OTP_SUCCESS'
export const POST_SEND_MOBILE_OTP_FAIL = 'POST_SEND_MOBILE_OTP_FAIL'

export const POST_SEND_EMAIL_OTP_REQUEST = 'POST_SEND_EMAIL_OTP_REQUEST'
export const POST_SEND_EMAIL_OTP_SUCCESS = 'POST_SEND_EMAIL_OTP_SUCCESS'
export const POST_SEND_EMAIL_OTP_FAIL = 'POST_SEND_EMAIL_OTP_FAIL'

export const POST_MOBILE_VERIFICATION_REQUEST = 'POST_MOBILE_VERIFICATION_REQUEST'
export const POST_MOBILE_VERIFICATION_SUCCESS = 'POST_MOBILE_VERIFICATION_SUCCESS'
export const POST_MOBILE_VERIFICATION_FAIL = 'POST_MOBILE_VERIFICATION_FAIL'

export const POST_EMAIL_VERIFICTION_REQUEST = 'POST_EMAIL_VERIFICTION_REQUEST'
export const POST_EMAIL_VERIFICTION_SUCCESS = 'POST_EMAIL_VERIFICTION_SUCCESS'
export const POST_EMAIL_VERIFICTION_FAIL = 'POST_EMAIL_VERIFICTION_FAIL'

export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST'
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS'
export const POST_RESET_PASSWORD_FAIL = 'POST_RESET_PASSWORD_FAIL'