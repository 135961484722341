import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import AlertDismissibleExample from '../../Components/Error/error';
import Sidebar from '../../Components/main/sidebar/sidebar';
import SearchItem from '../../Components/Search/SearchItem';
import BarChart from '../../Components/Graphs/BarChart';
import Loader from '../../Components/Loader';

import { useSelector, useDispatch } from 'react-redux';

import leftArrow from '../../assets/images/ionic-ios-arrow-back.png';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import noImage from '../../assets/images/otp.png';

import { 
    getPreb4preQuestionPaperBySlotID, 
    getPreb4preTestAnalytics, 
    getPreb4preUserAnswerSheet,
    getPreb4preTestSeries
} from '../../actions/preb4preTestAction';

import './analytics.scss';

const Preb4preTestAnalytics = ({ match }) => {
    const dispatch = useDispatch();

    const getPreb4preAnalytics = useSelector(state => state.getPreb4preTestAnalytics)
    const { loading, error, analytics } = getPreb4preAnalytics;

    const getPreb4preQuestionPaperBySlotId = useSelector(state => state.getPreb4preQuestionPaperBySlotId)
    const { loading: loadingPaper, error: errorPaper, paper } = getPreb4preQuestionPaperBySlotId;

    const getPreb4preAnswerSheet = useSelector(state => state.getPreb4preAnswerSheet)
    const { loading: loadingAnswerSheet, error: errorAnswerSheet, answerSheet } = getPreb4preAnswerSheet;

    const getPreb4preTest = useSelector(state => state.getPreb4preTestSeries)
    const { loading :loadingDetails, error:preb4preError, preb4preTest } = getPreb4preTest;

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const [Analytics, setAnalytics] = useState({});
    const [attempt, setAttempt] = useState('');
    const [data, setData] = useState(undefined);
    const [graphType, setGraphType] = useState('');
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [scrollToQues, setScrollToQues] = useState(1);
    const [sections, setSections ] = useState({});

    useEffect(() => {
        if (!userInfo) {
            document.location.href = '/login';
        }
        dispatch(getPreb4preTestAnalytics(match.params.id))
        dispatch(getPreb4preQuestionPaperBySlotID(match.params.id, 1))
        dispatch(getPreb4preTestSeries(match.params.id))
    }, [dispatch,userInfo, match])

    useEffect(() => {
        if(analytics && analytics.length !== 0){
            setAttempt(`Paper ${analytics[0].rank[0].paper}`)
            setAnalytics(analytics[0])

            setGraphType('Classification')
            setData({
                labels: ['Conceptual', 'Application', 'Gk'],
                datasets: [{
                    label: 'Classification',
                    data: [
                        analytics[0].classification.conceptual,
                        analytics[0].classification.application,
                        analytics[0].classification.gk,
                    ],
                    backgroundColor: [
                        '#0c343d',
                        '#2f5c50',
                        '#007ea7',
                    ]
                }]
            })
            dispatch(getPreb4preUserAnswerSheet(analytics[0].rank[0].answerId));
        }
    },[analytics, dispatch])

    useEffect(()=>{
        if(!errorPaper){
            var question = questions.map(item => ({
                ...item,
                name: item.description
            }))
            setQuestion(question)
            setSearchResults(question)
        }
    },[questions,errorPaper])

    useEffect(()=>{
        if(analytics && paper.length > 0){
            if(analytics.length !== 0 && (!analytics[0].sections || analytics[0].sections.length === 0)){
                setQuestions(paper);
            }else if(analytics.length !== 0 && analytics[0].sections.length !==0){
                const questions = paper.filter((element)=> (element.sectionName.toUpperCase() === analytics[0].sections[0].section.toUpperCase()))
                setSections(analytics[0].sections[0])
                setQuestions(questions);
            }
        }
    }, [analytics, paper])

    const myFunction1 = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown1").classList.toggle('show');
    }

    const myFunction2 = (e) => {
        e.preventDefault();
        document.getElementById("myDropdown2").classList.toggle('show');
    }

    const selectAnalytics = (paperNumber) => {
        if (document.getElementById("myDropdown1").classList.contains('show')) {
            document.getElementById("myDropdown1").classList.remove('show')
        }

        setAttempt(`Paper ${paperNumber}`);
        const getAnalytics = analytics.find(element => Number(element.paper) === paperNumber)

        setAnalytics(getAnalytics);
        setGraphType('Classification');
        setData({
            labels: ['Conceptual', 'Application', 'Gk'],
            datasets: [{
                label: 'Classification',
                data: [
                    getAnalytics.classification.conceptual,
                    getAnalytics.classification.application,
                    getAnalytics.classification.gk,
                ],
                backgroundColor: [
                    '#0c343d',
                    '#2f5c50',
                    '#007ea7',
                ]
            }]
        })
        dispatch(getPreb4preQuestionPaperBySlotID(match.params.id, paperNumber))
        dispatch(getPreb4preUserAnswerSheet(getAnalytics.answerSheet));
    }

    const graphData = (type) => {
        if (document.getElementById("myDropdown2").classList.contains('show')) {
            document.getElementById("myDropdown2").classList.remove('show')
        }

        switch (type) {
            case 'classification':
                setData({
                    labels: [
                        'Conceptual',
                        'Applied',
                        'Factual'
                    ],
                    datasets: [{
                        label: 'Classification',
                        data: [
                            Analytics.classification.conceptual,
                            Analytics.classification.application,
                            Analytics.classification.gk],
                        backgroundColor: [
                            '#0c343d',
                            '#2f5c50',
                            '#007ea7',
                        ]
                    }]
                })
                setGraphType('Classification')
                break;
            case 'category':
                setData({
                    labels: [
                        'Category 1',
                        'Category 2',
                        'Gs Full Test Lv1',
                        'Gs Full Test Lv2',
                        'N.C.E.R.T',
                        'Reference Book'
                    ],
                    datasets: [{
                        label: 'Category',
                        data: [
                            Analytics.category.category1,
                            Analytics.category.category2,
                            Analytics.category.gsFullTestLv1,
                            Analytics.category.gsFullTestLv2,
                            Analytics.category.ncert,
                            Analytics.category.referenceBook
                        ],
                        backgroundColor: [
                            '#db2549',
                            '#720256',
                            '#4f3e2c',
                            '#810dd3',
                            '#cad942',
                            '#C6B269'
                        ]
                    }]
                })
                setGraphType('Category')
                break;
            case 'difficulty':
                setData({
                    labels: [
                        'Easy',
                        'Medium',
                        'Tough'
                    ],
                    datasets: [{
                        label: 'Difficulty',
                        data: [
                            Analytics.difficulty.easy,
                            Analytics.difficulty.medium,
                            Analytics.difficulty.hard
                        ],
                        backgroundColor: [
                            '#ffe599',
                            '#8fce00',
                            '#f44336',
                        ]
                    }]
                })
                setGraphType('Difficulty')
                break;
            case 'questions':
                setData({
                    labels: ['Correct', 'Wrong', 'Attempted','Unattempted','Total'],
                    datasets: [{
                        label: 'Questions',
                        data: [
                            Analytics.correctAnswer,
                            Analytics.incorrectAnswer,
                            Analytics.attemptedQuestions,
                            Analytics.totalQuestions - Analytics.attemptedQuestions,
                            Analytics.totalQuestions
                        ],
                        backgroundColor: [
                            '#40BF09',
                            '#FF666B',
                            '#f1c232',
                            '#53C7BB',
                            '#79309C'
                        ]
                    }]
                })
                setGraphType('Questions')
                break;
            default:
                console.log('invalid input')
        }
    }

    const selectSection = (section) => {
        if (document.getElementsByClassName('selected-section').length > 0)
            document.getElementsByClassName('selected-section')[0].classList.remove('selected-section')
        document.getElementById(`Preb4preAnalytics${section.section}`).classList.add('selected-section')

        const questions = paper.filter((element) => (element.sectionName.toUpperCase() === section.section.toUpperCase()))
        setQuestions(questions)

        setSections(section)
    }

    const scrollToQuestion = () => {
        if(scrollToQues){
            const element = document.getElementsByClassName(`question_${scrollToQues}`)
            if(element.length > 0){
                element[0].scrollIntoView({
                    behavior:"smooth",
                    block:"center",
                    inline:'nearest'
                });
            }
        }
    }

    return <div>
        {(loading || loadingPaper || loadingAnswerSheet || loadingDetails) && <Loader />}

        {error && <AlertDismissibleExample data={error} />}
        {errorPaper && <AlertDismissibleExample data={errorPaper} />}
        {errorAnswerSheet && <AlertDismissibleExample data={errorAnswerSheet} />}
        {preb4preError && <AlertDismissibleExample data={preb4preError} />}

        <Sidebar activeLink={'preB4Pre'} />

        {
            (analytics && (analytics.length > 0) && (paper.length > 0) && preb4preTest) && <div>
                <main  className="main practice-analytics">
                    <section className="px-5 py-5" >
                        <Link to={`/testseries/preb4pre/${match.params.id}`} style={{ textDecoration: 'none' }}>
                            <img src={leftArrow} alt="<" className="backBtn" />
                        </Link>
                    </section>

                    <section className="px-5" >
                        {
                            (Analytics && data) && <div>
                                <div className='d-flex justify-content-around py-3'>
                                    <div className="dropdown">
                                        <button className="dropbtn" onClick={myFunction1}>
                                            {attempt} <MdOutlineArrowDropDown />
                                        </button>
                                        <div id="myDropdown1" className="dropdown-content">
                                            {Analytics.rank.map((element, i) => {
                                                return (
                                                    <p key={i} onClick={(e) => { e.preventDefault(); selectAnalytics(element.paper) }}>
                                                        Paper {element.paper}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        <button className="dropbtn" onClick={myFunction2}>
                                            {graphType}  <MdOutlineArrowDropDown />
                                        </button>
                                        <div id="myDropdown2" className="dropdown-content">
                                            <p onClick={(e) => { e.preventDefault(); graphData('classification') }}>Classification</p>
                                            <p onClick={(e) => { e.preventDefault(); graphData('category') }}>Category</p>
                                            <p onClick={(e) => { e.preventDefault(); graphData('difficulty') }}>Difficulty</p>
                                            <p onClick={(e) => { e.preventDefault(); graphData('questions') }}>Questions</p>
                                        </div>
                                    </div>
                                </div>
                                <BarChart data={data} />
                                <div className="marks">
                                    <h3>
                                        <span>Final Score: </span>
                                        {Analytics.finalScore} out of {Analytics.totalMarks}
                                    </h3>
                                </div>
                            </div>
                        }
                    </section>
                    <section className="px-5 py-5">
                        <div>
                            <h1 className="mt-2" style={{ fontSize: 25, fontWeight: '600', color: 'rgb(1, 42, 93)' }}>
                                Answer Sheet
                            </h1>
                        </div>
                        <div>
                            <div className="section-selector">
                                {
                                    Analytics.sections && Analytics.sections.length !==0 && Analytics.sections.map((obj, i)=>{
                                        return(
                                            <button 
                                                id={`Preb4preAnalytics${obj.section}`} 
                                                key={obj.section}
                                                className={i===0 ? 'selected-section' : ''}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    selectSection(obj);
                                                }}>
                                                section {obj.section}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                            <div style={{marginTop:"10px"}}>
                                {
                                    questions.length !== 0 && <div>
                                        <SearchItem
                                            allItems={question}
                                            from={"Questions"}
                                            handleSetSearchResults={(results) => setSearchResults(results)}
                                        />
                                        <div className='question-scroller'>
                                            <label>Question Number : &nbsp;</label>
                                            <input 
                                                type='number' 
                                                min={1} 
                                                max={question.length} 
                                                value={scrollToQues} 
                                                onChange={e => {setScrollToQues(e.target.value)}} 
                                            />
                                            <button onClick={e => {e.preventDefault(); scrollToQuestion()}}>Scroll</button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='section-info'>
                                <h3><span>No. Of Questions : </span>{searchResults.length}</h3>
                                <h3><span>Positive Marking : </span>{sections.marksPerQuestion}</h3>
                                <h3><span>Negative Marking : </span>{sections.negativeQuestion}</h3>
                            </div>
                            <div>
                                {
                                    (searchResults.length !== 0 && answerSheet.answers ) ? searchResults.map((question, i) => {
                                        return (
                                            <div className={`questions-container question_${i+1}`} key={question.id}>
                                                <div className="question">
                                                    <div className="ques-line1">
                                                        <div className="qus-number">
                                                            <h1>{i + 1}</h1>
                                                        </div>
                                                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.description) }}></p>
                                                    </div>
                                                    {
                                                        question.imageURL && (
                                                            <div className="question-Img">
                                                                <img src={question.imageURL} alt="questionimg" />
                                                            </div>
                                                        )
                                                    }
                                                    <div className="options">
                                                        {
                                                            question.options.map((option, i) => (
                                                                <label className="option" key={i} >
                                                                    {option}
                                                                    <input type="radio" name={question.id} value={option}
                                                                        checked={
                                                                            answerSheet.answers.length !== 0 ? (
                                                                                answerSheet.answers.find((ele) => (ele.qid === question.id && ele.answer === option)) ? true : false
                                                                            ) : false
                                                                        }
                                                                        readOnly 
                                                                    />
                                                                    <span className={
                                                                        answerSheet.answers.length !== 0 ? (
                                                                            answerSheet.answers.find((ele) => (ele.qid === question.id && ele.answer === option)) ? (option === question.correctOption ? 'right' : 'wrong') : ''
                                                                        ) : ''
                                                                    }/>
                                                                </label>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="correctAnswer">
                                                    <p>Correct Answer:  <span>{question.correctOption}</span></p>
                                                </div>
                                                {
                                                    question.explaination && <div className="explainAnswer">
                                                        <p><strong>Explanation-</strong></p>
                                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.explaination)}} />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }) : <div className="questions-container">
                                        <div className='content-notAvail'>
                                            <img src={noImage} alt="" />
                                            <h3>No Questions Available</h3>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        }

    </div>
}

export default Preb4preTestAnalytics;