import React from 'react'

function CourseSubjects({ content }) {


    return (
        <div>
            {
                content.map((prop, i) =>
                    <div key={i}>
                        <h5>{prop.subjectName}</h5>
                        {
                            prop.chapter && prop.chapter.map((chap, id) =>
                                <li key={id}>
                                    <span className="font-weight-bold mr-3">
                                        {i + 1}.{id + 1}
                                    </span>{" "}
                                    {chap.chapterName}
                                </li>
                            )
                        }
                    </div>
                )
            }

        </div>
    )
}

export default CourseSubjects
